import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx } from "vue";
import { reactive, computed, watch, inject, ref } from 'vue';
import { constFormProps, constSetFormOptions, objectToArray } from '../../utils';
export default {
  __name: 'select',
  props: {
    data: {
      type: Object
    },
    modelValue: null,
    disabled: Boolean,
    transformOption: Function,
    options: {
      type: Array,
      default: () => []
    },
    type: String,
    remoteMethod: Function
  },
  emits: ['change', 'update:modelValue'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const setOptionsList = ref();
    const formProps = inject(constFormProps, {});
    const optionsList = computed(() => {
      // 如果有使用了setOptions设置时使用setOption的
      if (setOptionsList.value) {
        return setOptionsList.value;
      }
      if (props.type === 'slot') {
        // 作为input前后缀使用时，支持固定和字典取值、暂不支持数据源
        // 因formItem层获取不到slot层的相关参数，如需实际可自引入formItem里的getAxiosOptions
        const dictVal = formProps.value.dict;
        if (dictVal && props.data.config?.optionsType === 2) {
          const opt = dictVal[props.data.config?.optionsFun];
          if (opt !== undefined) {
            return objectToArray(opt);
          }
        }
        return props.data.options;
      } else {
        return props.options;
      }
    });
    const formOptions = inject(constSetFormOptions, {});
    watch(() => formOptions.value, val => {
      const opt = val[props.data.name];
      if (val && opt !== undefined) {
        setOptionsList.value = objectToArray(opt);
      }
    });
    const value = computed({
      get() {
        if (props.type === 'slot') {
          return formProps.value.model[props.data.name];
        } else {
          return props.modelValue;
        }
      },
      set(newVal) {
        if (props.type === 'slot') {
          emits('change', newVal, props.data.name);
        } else {
          emits('update:modelValue', newVal);
          if (props.data.control.change) {
            props.data.control.change(formProps);
          }
        }
      }
    });
    const state = reactive({
      loading: false // 远程搜索加载状态
    });
    watch(() => props.data.options, () => {
      state.loading = false;
    });
    // 符合远程搜索
    const isRemote = computed(() => {
      return !!(props.data.control?.remote && props.data.control?.filterable && props.data.config?.optionsType === 1 && props.data.config?.optionsFun);
    });
    // 远程搜索
    const remoteMethod = name => {
      if (isRemote.value) {
        state.loading = true;
        // 请求参数名，可使用config.queryName传进来
        const queryName = props.data.config?.queryName || 'name';
        props.remoteMethod && props.remoteMethod({
          [queryName]: name
        });
        if (props.data.control.remoteMethod) {
          props.data.control.remoteMethod(name);
        }
      }
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      return _openBlock(), _createBlock(_component_el_select, _mergeProps(__props.data.control, {
        disabled: __props.disabled,
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(value) ? value.value = $event : null),
        loading: state.loading,
        remoteMethod: remoteMethod
      }), {
        default: _withCtx(() => [__props.data.config?.addAll ? (_openBlock(), _createBlock(_component_el_option, {
          key: 0,
          value: "",
          label: "全部"
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(optionsList), item => {
          return _openBlock(), _createBlock(_component_el_option, {
            key: item.value,
            label: item.label,
            value: __props.transformOption(item.value, __props.data.config?.transformData)
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      }, 16, ["disabled", "modelValue", "loading"]);
    };
  }
};