import request from '@/utils/onlineRequest'

// 查询流程列表
export function listLeave(query) {
  return request({
    url: '/device-operate-monitor/monitorControlRecord/queryList',
    method: 'post',
    data: query
  })
}
// 查询流程列表
export function listLeaveAll(query) {
  return request({
    url: '/device-operate-monitor/monitorControlRecord/isAll',
    method: 'post',
    data: query
  })
}

// 查询流程详细
export function getLeave(id) {
  return request({
    url: '/device-operate-monitor/monitorControlRecord/queryById?id=' + id,
    method: 'get'
  })
}


// 新增流程
export function addLeave(data) {
  return request({
    url: '/device-operate-monitor/workflow/leave',
    method: 'post',
    data: data
  })
}

// 修改流程
export function updateLeave(data) {
  return request({
    url: '/device-operate-monitor/workflow/leave',
    method: 'put',
    data: data
  })
}

// 删除流程
export function delLeave(id) {
  return request({
    url: '/device-operate-monitor/workflow/leave/' + id,
    method: 'delete'
  })
}

// 导出流程
export function exportLeave(query) {
  return request({
    url: '/device-operate-monitor/workflow/leave/export',
    method: 'get',
    params: query
  })
}
