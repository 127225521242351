import {request,commProvRequest as deviceRequest,businessIngrationteRequest as businessRequest } from '@/utils/request'
// 查询参数列表
export function listConfig(query) {
  return request({
    url: '/system/config/list',
    method: 'get',
    params: query
  })
}

// 查询参数详细
export function getConfig(configId) {
  return request({
    url: '/system/config/' + configId,
    method: 'get'
  })
}

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return request({
    url: '/system/config/configKey/' + configKey,
    method: 'get'
  })
}

// 新增参数配置
export function addConfig(data) {
  return request({
    url: '/system/config',
    method: 'post',
    data: data
  })
}

// 修改参数配置
export function updateConfig(data) {
  return request({
    url: '/system/config/update',
    method: 'post',
    data: data
  })
}

// 删除参数配置
export function delConfig(configId) {
  return request({
    url: '/system/config/delete/' + configId,
    method: 'post'
  })
}

// 导出参数
export function exportConfig(query) {
  return request({
    url: '/system/config/export',
    method: 'get',
    params: query
  })
}

// 首页配置-新增
export function homeAdd(data) {
  return deviceRequest({
    url: '/home/config/add',
    method: 'post',
    data
  })
}
// 首页配置-页面元素信息获取
export function getHomeEleInfo(data) {
  return deviceRequest({
    url: '/home/config/queryList',
    method: 'post',
    data
  })
}
// 首页配置-工单管理数据获取
export function getWorkCellData(query) {
  return businessRequest({
    url: '/eipatrolorder/allcount',
    method: 'get',
    params: query
  })
}
// 首页配置-删除
export function homeDelete(data) {
  return deviceRequest({
    url: '/home/config/delete',
    method: 'post',
    data
  })
}

// 首页配置-编辑
export function homeEdit(data) {
  return deviceRequest({
    url: '/home/config/edit',
    method: 'post',
    data
  })
}

// 首页配置-分页获取数据
export function homeQueryList(data) {
  return deviceRequest({
    url: '/home/config/queryList',
    method: 'post',
    data
  })
}
