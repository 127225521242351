import { listLeaveAll, exportLeave } from '@/api/workflow/leave';
import leaveHistoryForm from "./leaveHistoryForm";
import { parseTime } from "@/utils/ruoyi";
import { deepClone } from "@/utils";
import { ElMessage } from 'element-plus';
export default {
  name: '',
  components: {
    leaveHistoryForm
  },
  data() {
    return {
      businessKey: '',
      //用户信息
      user: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      tableData: {
        total: 0,
        rows: []
      },
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      open2: false,
      // 请假类型字典
      typeOptions: [],
      // 状态字典
      statusOptions: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        status: '',
        confirmTelesignallName: ''
      },
      searchFormData: [{
        prop: "confirmTelesignallName",
        label: "遥信名称",
        placeholder: "请输入遥信名称",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "status",
        label: "状态",
        placeholder: "请选择状态",
        optionsUrl: "/sys-server/system/dict/data/dictType/process_status",
        type: "list",
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => this.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => this.resetQuery()
      }],
      buttonsList: [{
        buttonName: "审批详情",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.showDetails()
      }],
      showTableHeader: [],
      tableHeader: [{
        prop: "lineName",
        label: "线路名称",
        fixed: false,
        type: "text"
      }, {
        prop: "confirmTelesignallName",
        label: "遥信名称",
        fixed: false,
        type: "text"
      }, {
        prop: "operate",
        label: "执行操作",
        fixed: false,
        type: "text"
      }, {
        prop: "status_",
        label: "状态",
        fixed: false,
        type: "text"
      }],
      multipleSelection: []
    };
  },
  created() {
    this.cloneQueryParams = JSON.parse(JSON.stringify(this.queryParams));
    this.showTableHeader = deepClone(this.tableHeader);
  },
  methods: {
    parseTimeFilter(val) {
      return parseTime(val, '{y}-{m}-{d}');
    },
    /** 查询请假列表 */
    getList() {
      this.loading = true;
      listLeaveAll(this.queryParams).then(response => {
        for (let item of response.data.rows) {
          if (item.isOff === '2') {
            if (item.execAction === '1') {
              item['operate'] = '遥信-分';
            } else {
              item['operate'] = '遥信-合';
            }
          } else {
            if (response.execAction === '1') {
              item['operate'] = '投入';
            } else {
              item['operate'] = '退出';
            }
          }
        }
        this.tableData.rows = response.data.rows || [];
        this.tableData.total = response.data.total || 0;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    showDetails() {
      if (this.multipleSelection?.length !== 1) {
        ElMessage.closeAll();
        ElMessage.warning("最多或最少选择一条数据进行操作!");
        return;
      }
      this.historyFory(this.multipleSelection[0]);
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = JSON.parse(JSON.stringify(this.cloneQueryParams));
      this.handleQuery();
    },
    /** 审批详情 */
    historyFory(row) {
      this.businessKey = row.id;
      this.open2 = true;
      this.title = '审批详情';
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有请假数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return exportLeave(queryParams);
      }).then(response => {
        this.download(response.msg);
      });
    }
  }
};