export default {
  name: 'MainLayout',
  data() {
    return {
      iframeSrc: ''
    };
  },
  watch: {
    '$route': {
      handler: function (route) {
        if (route.meta && route.meta.link) {
          this.iframeSrc = route.meta.link;
        }
      },
      immediate: true
    }
  },
  computed: {
    iframeStyle() {
      if (this.$store.state.common.menuPositionSet == 1) {
        return {
          width: '100%',
          padding: '20px',
          height: '100%',
          overflow: 'hidden',
          border: 0
        };
      }
      return {
        width: '100%',
        padding: '0px',
        height: '100%',
        overflow: 'hidden',
        border: 0
      };
    }
  }
};