import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { deepClone, downloadFile } from '@/utils';
import { getOperationlog } from '@/api/system/user';
export default defineComponent({
  name: 'Operlog',
  components: {},
  props: {},
  setup(props) {
    const formContainer = ref(ElForm);
    const router = useRouter();
    const state = reactive({
      tableLoading: false,
      dialogVisible: false,
      multipleSelection: [],
      queryParams: {
        serverName: '',
        operateType: '',
        userName: '',
        deptName: '',
        startTime: '',
        endTime: '',
        pageNum: 1,
        pageSize: 10
      },
      searchFormData: [{
        prop: 'serverName',
        label: '服务名称',
        type: 'text',
        isSelect: true,
        span: 5
      }, {
        type: 'list',
        label: '操作类型',
        placeHolder: '请选择操作类型',
        prop: 'operateType',
        options: [{
          label: '查询',
          value: 'Query'
        }, {
          label: '新增',
          value: 'Add'
        }, {
          label: '编辑',
          value: 'Edit'
        }, {
          label: '删除',
          value: 'Delete'
        }, {
          label: '导入',
          value: 'Import'
        }, {
          label: '导出',
          value: 'Export'
        }],
        props: {
          label: 'label',
          value: 'value'
        },
        isShow: true,
        span: 5
      }, {
        prop: 'userName',
        label: '用户名称',
        type: 'text',
        isSelect: true,
        span: 5
      }, {
        prop: 'deptName',
        label: '企业名称',
        type: 'text',
        isSelect: true,
        span: 5
      }, {
        prop: 'dateRange',
        label: '时间',
        type: 'daterange',
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: '搜索',
        type: 'primary',
        icon: 'el-icon-search',
        handle: () => methods.handleQuery()
      }, {
        label: '重置',
        icon: 'el-icon-refresh',
        class: 'commonDarkBtn',
        handle: () => methods.resetQuery()
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      tableHeader: [{
        prop: 'userName',
        label: '用户名',
        fixed: false,
        type: 'text'
      }, {
        prop: 'deptName',
        label: '企业名',
        fixed: false,
        type: 'text'
      }, {
        prop: 'requestUrl',
        label: '请求url',
        fixed: false,
        type: 'text'
      }, {
        prop: 'serverName',
        label: '请求服务名',
        fixed: false,
        type: 'text'
      }, {
        prop: 'methodName',
        label: '请求方法名',
        fixed: false,
        type: 'text'
      }, {
        prop: 'operateType',
        label: '操作类型',
        fixed: false,
        statusList: [{
          label: '查询',
          value: 'Query'
        }, {
          label: 'Add',
          value: 'Add'
        }, {
          label: 'Edit',
          value: 'Edit'
        }, {
          label: 'Delete',
          value: 'Delete'
        }, {
          label: 'Import',
          value: 'Import'
        }, {
          label: 'Export',
          value: 'Export'
        }],
        type: 'text'
      }, {
        prop: 'errName',
        label: '错误名称',
        fixed: false,
        type: 'text'
      }, {
        prop: 'requestIp',
        label: '请求来源ip',
        fixed: false,
        type: 'text'
      }, {
        prop: 'requestType',
        label: '请求类型',
        fixed: false,
        type: 'text'
      }, {
        prop: 'operateDesc',
        label: '备注',
        fixed: false,
        type: 'text'
      }, {
        prop: 'operateTime',
        label: '操作时间',
        fixed: false,
        type: 'text'
      }]
    });
    // 表头初始值
    const key = router.currentRoute.value.name + ':colsettings';
    const colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const methods = reactive({
      close() {
        state.form = {
          dictName: '',
          dictType: '',
          status: '0',
          remark: ''
        };
      },
      //  查询
      handleQuery() {
        methods.getList();
      },
      // 重置
      resetQuery() {
        state.queryParams = {
          serverName: '',
          operateType: '',
          userName: '',
          deptName: '',
          beginTime: '',
          endTime: '',
          pageNum: 1,
          pageSize: 10
        }, methods.getList();
      },
      getList() {
        const params = {
          'startTime': state.queryParams.beginTime,
          'endTime': state.queryParams.endTime,
          'serverName': state.queryParams.serverName,
          'operateType': state.queryParams.operateType,
          'userName': state.queryParams.userName,
          'deptName': state.queryParams.deptName,
          'pageNum': state.queryParams.pageNum,
          'pageSize': state.queryParams.pageSize
        };
        state.tableData.rows = [];
        state.tableLoading = true;
        getOperationlog(params).then(response => {
          state.tableData.rows = response.rows;
          state.tableData.total = response.total;
          state.tableLoading = false;
        });
      }
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer
    };
  }
});