import { Delete, Edit, Search, Share, Upload, Refresh, Plus, Download, ArrowDown, ArrowUp } from "@element-plus/icons-vue";
import { unref, ref, onMounted, toRefs, reactive, defineAsyncComponent } from "vue";
import { ElTree } from "element-plus";
import request from "@/utils/onlineRequest";
const menu = ref(null);
export default {
  name: "commonForm",
  components: {
    dialogSelect: defineAsyncComponent(() => import("../dialogSelect")),
    EIUploadOnePic: defineAsyncComponent(() => import("../EIUploadOnePic")),
    dialogTabSelect: defineAsyncComponent(() => import('../dialogTabSelect')),
    IconSelect: defineAsyncComponent(() => import("../IconSelect")),
    ArrowDown,
    ArrowUp
  },
  props: {
    buttonsList: {
      type: Array,
      default: () => []
    },
    isHandle: {
      type: Boolean,
      default: true
    },
    //是否需要收起展开功能
    hasSearchToggle: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    labelWidth: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: "small"
    },
    formData: {
      type: Array,
      default: () => []
    },
    formHandle: {
      type: Array,
      default: () => {
        return [{
          label: "搜索",
          type: "primary",
          icon: Search,
          handle: () => this.handleQuery()
        }, {
          label: "重置",
          icon: Refresh,
          class: "commonDarkBtn",
          handle: () => this.resetQuery()
        }];
      }
    },
    form: {
      type: Object,
      default: () => {}
    },
    formRules: {
      type: Object,
      default: () => {}
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    labelPosition: {
      type: String,
      default: "top"
    },
    fileList: {
      type: Array,
      default: () => []
    }
  },
  // emits: {
  //   getObjForm: null,
  // },
  emits: ["fileList", "getObjForm"],
  setup(props, context) {
    let {
      isHandle,
      labelWidth,
      size,
      formData,
      formHandle,
      form,
      formRules,
      showLabel,
      labelPosition
    } = toRefs(props);
    let toggle = ref(true);
    let commonForm = ref(ElTree);
    const remark = reactive({
      remark: []
    });
    onMounted(() => {
      //formData:备注放最后一个
      console.log("props.formData1", props.formData);
      props.formData.forEach((item, index) => {
        if (item.type === "textarea") {
          remark.remark.push(item);
        }
      });
      const formRef = unref(commonForm);
      context.emit("getObjForm", formRef);
    });
    const psdStrongLevel = ref("");
    const cascader = ref(null);
    const setValue = function (item) {};
    const methods = reactive({
      //新增用户密码强度判断增加
      changePsdStrong(pwd) {
        if (pwd == null || pwd == "") {
          psdStrongLevel.value = "";
        } else {
          let S_level = methods.checkStrong(pwd);
          switch (S_level) {
            case 0:
              psdStrongLevel.value = "";
            case 1:
              psdStrongLevel.value = 0;
              break;
            case 2:
              psdStrongLevel.value = 1;
              break;
            default:
              psdStrongLevel.value = 2;
          }
        }
      },
      //判断密码的强度
      CharMode(iN) {
        if (iN >= 48 && iN <= 57)
          //数字
          return 1;
        if (iN >= 65 && iN <= 90)
          //大写字母
          return 2;
        if (iN >= 97 && iN <= 122)
          //小写
          return 4;else return 8; //特殊字符
      },
      //bitTotal函数
      //计算出当前密码当中一共有多少种模式
      bitTotal(num) {
        let modes = 0;
        for (let i = 0; i < 4; i++) {
          if (num & 1) modes++;
          num >>>= 1;
        }
        return modes;
      },
      //返回密码的强度级别
      checkStrong(sPW) {
        if (sPW.length <= 4) return 0; //密码太短
        let Modes = 0;
        for (let i = 0; i < sPW.length; i++) {
          //测试每一个字符的类别并统计一共有多少种模式.
          Modes |= methods.CharMode(sPW.charCodeAt(i));
        }
        return methods.bitTotal(Modes);
      }
      // EIUploadSuccess (res,type) {
      //   if(type == 'file'){
      //     //文件上传
      //     context.emit('update:fileList', res);
      //   }else{
      //     //图片上传
      //     let  newFilelist=[{
      //     id:null,
      //     relationshipId:null,
      //     fileName:null,
      //     fileSize:"",
      //     md5:null,
      //     fileGroup:"",
      //     path:"",
      //     type:null,
      //     orgId:null,
      //     ts:"",
      //     systemId:null,
      //     createBy:null,
      //     createTime:null,
      //     updateBy:null,
      //     updateTime:null,
      //   }]
      //   newFilelist[0].fileGroup=res.fileGroup;
      //   newFilelist[0].fileSize =res.fileSize ;
      //   newFilelist[0].fileName =res.filename ;
      //   newFilelist[0].path  =res.path;
      //   context.emit('update:fileList', newFilelist);
      //   }
      // },
    });
    const inputLimit = function (e) {
      let key = e.key;
      if (key === "e") {
        e.returnValue = false;
        return false;
      }
      return true;
    };
    const optionsList = ref(new Map());
    console.log("props.formData2", props.formData);
    props.formData.forEach((item, index) => {
      if (item.type !== "dialog-select" || item.type === "dialog-select" && !item.options) {
        if (item.optionsUrl) {
          let queryObj = item.method == "post" ? {
            data: item.queryParams ? item.queryParams : null
          } : {
            params: item.queryParams ? item.queryParams : {
              pageSize: 1000000,
              pageNum: 1,
              queryName: ""
            }
          };
          request({
            url: item.optionsUrl,
            method: item.method ? item.method : "get",
            ...queryObj
          }).then(res => {
            //res.data.rows获取list接口数据作为下拉数据
            let options = [];
            if (item.type === "list") {
              if (res.data && res.data.rows) {
                options = res.data.rows;
              } else if (res.rows) {
                options = res.rows;
              } else {
                options = res.data;
              }
            } else {
              options = res.data.rows || [];
              options = options.map(itemObj => {
                if (item.type === "bigData_list_multi") {
                  return {
                    value: itemObj.id,
                    label: itemObj.name
                  };
                }
              });
            }
            optionsList.value.set("options" + index, options);
          }).catch(err => {
            optionsList.value.set("options" + index, []);
          });
        }
      }
    });
    const getOptions = function (item, index) {
      //下拉框默认选中第一个数据 把整个options 传出去
      if (item.getOptions) {
        let options = (item.optionsUrl ? optionsList.value.get("options" + index) : item.options) || [];
        if (options.length) {
          item.getOptions(options);
        }
      }
      return (item.optionsUrl ? optionsList.value.get("options" + index) : item.options) || [];
    };
    const dateClearHandle = (val, form) => {
      if (val === null) {
        form.beginTime = "";
        form.endTime = "";
      } else {
        form.beginTime = val[0];
        form.endTime = val[1];
      }
    };
    const clearValidate = prop => {
      commonForm.value.clearValidate(prop);
    };
    const icon = function (iconLabel) {
      if (iconLabel == "删除") {
        return Delete;
      } else if (iconLabel == "重置") {
        return Refresh;
      } else if (iconLabel == "搜索") {
        return Search;
      } else if (iconLabel == "导入") {
        return Upload;
      } else if (iconLabel == "新增") {
        return Plus;
      } else if (iconLabel == "导出") {
        return Download;
      } else {
        return null;
      }
    };
    return {
      dateClearHandle,
      cascader,
      isHandle,
      labelWidth,
      size,
      formData,
      formHandle,
      icon,
      form,
      menu,
      formRules,
      showLabel,
      labelPosition,
      commonForm,
      Delete,
      Edit,
      Search,
      Share,
      Upload,
      Refresh,
      getOptions,
      setValue,
      inputLimit,
      clearValidate,
      ...toRefs(methods),
      ...toRefs(remark),
      psdStrongLevel,
      toggle
    };
  }
};