import { computed, defineComponent, reactive, toRefs } from 'vue';
import { useStore } from 'vuex';
import { ThemeMode } from '@/utils/dictionary';
export default defineComponent({
  setup() {
    const store = useStore();

    /**
     * 主题颜色处理
     */
    const paramsHandle = (type, key, value) => {
      const theme = {
        color: {
          ...store.state.theme.theme.color
        },
        text: {
          ...store.state.theme.theme.text
        },
        menu: {
          ...store.state.theme.theme.menu
        }
      };
      theme[type][key] = value;
      return theme;
    };
    const mode = computed({
      get: () => {
        return store.state.theme.mode;
      },
      set: val => {
        store.dispatch('theme/setMode', val);
      }
    });
    const navigation = computed({
      get: () => {
        return store.state.settings.navigationMode;
      },
      set: val => {
        store.dispatch('settings/setNavigationMode', val);
      }
    });
    const contanier = computed({
      get: () => {
        return store.state.settings.contanierMode;
      },
      set: val => {
        store.dispatch('settings/setContanierMode', val);
      }
    });
    const panel = computed({
      get: () => {
        return store.state.settings.panelMode;
      },
      set: val => {
        store.dispatch('settings/setPanelMode', val);
      }
    });
    const tabs = computed({
      get: () => {
        return store.state.settings.showTabs;
      },
      set: val => {
        store.dispatch('settings/setShowTabs', val);
      }
    });
    const colorPrimary = computed({
      get: () => {
        return store.state.theme.theme.color.primary;
      },
      set: val => {
        const theme = paramsHandle('color', 'primary', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const colorSuccess = computed({
      get: () => {
        return store.state.theme.theme.color.success;
      },
      set: val => {
        const theme = paramsHandle('color', 'success', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const colorWarning = computed({
      get: () => {
        return store.state.theme.theme.color.warning;
      },
      set: val => {
        const theme = paramsHandle('color', 'warning', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const colorDanger = computed({
      get: () => {
        return store.state.theme.theme.color.danger;
      },
      set: val => {
        const theme = paramsHandle('color', 'danger', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const colorError = computed({
      get: () => {
        return store.state.theme.theme.color.error;
      },
      set: val => {
        const theme = paramsHandle('color', 'error', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const colorInfo = computed({
      get: () => {
        return store.state.theme.theme.color.info;
      },
      set: val => {
        const theme = paramsHandle('color', 'info', val);
        store.dispatch('theme/setTheme', theme);
      }
    });

    // const textPrimary = computed({
    //   get: () => {
    //     return store.state.theme.theme.text.primary
    //   },
    //   set: (val) => {
    //     const theme = paramsHandle('text', 'primary', val)
    //     store.dispatch('theme/setTheme', theme)
    //   }
    // })
    // const textRegular = computed({
    //   get: () => {
    //     return store.state.theme.theme.text.regular
    //   },
    //   set: (val) => {
    //     const theme = paramsHandle('text', 'regular', val)
    //     store.dispatch('theme/setTheme', theme)
    //   }
    // })
    // const textSecondary = computed({
    //   get: () => {
    //     return store.state.theme.theme.text.secondary
    //   },
    //   set: (val) => {
    //     const theme = paramsHandle('text', 'secondary', val)
    //     store.dispatch('theme/setTheme', theme)
    //   }
    // })
    // const textPlaceholder = computed({
    //   get: () => {
    //     return store.state.theme.theme.text.placeholder
    //   },
    //   set: (val) => {
    //     const theme = paramsHandle('text', 'placeholder', val)
    //     store.dispatch('theme/setTheme', theme)
    //   }
    // })
    // const textDisabled = computed({
    //   get: () => {
    //     return store.state.theme.theme.text.disabled
    //   },
    //   set: (val) => {
    //     const theme = paramsHandle('text', 'disabled', val)
    //     store.dispatch('theme/setTheme', theme)
    //   }
    // })

    const menuBackgroundColor = computed({
      get: () => {
        return store.state.theme.theme.menu.backgroundColor;
      },
      set: val => {
        const theme = paramsHandle('menu', 'backgroundColor', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const menuTextColor = computed({
      get: () => {
        return store.state.theme.theme.menu.textColor;
      },
      set: val => {
        const theme = paramsHandle('menu', 'textColor', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const menuActiveTextColor = computed({
      get: () => {
        return store.state.theme.theme.menu.activeTextColor;
      },
      set: val => {
        const theme = paramsHandle('menu', 'activeTextColor', val);
        store.dispatch('theme/setTheme', theme);
      }
    });
    const data = reactive({
      visible: false,
      navigations: [{
        label: '固定导航',
        value: 1
      }, {
        label: '不固定导航',
        value: 2
      }],
      contaniers: [{
        label: '大小随内容变化',
        value: 1
      }, {
        label: '大小100%(固定导航栏的情况下生效)',
        value: 2
      }],
      panels: [{
        label: '头部、主要内容、脚部、左侧分离',
        value: 1
      }, {
        label: '左右面板分离',
        value: 2
      }, {
        label: '整个容器为面板',
        value: 3
      }]
    });
    const init = () => {
      data.visible = true;
    };
    return {
      mode,
      navigation,
      contanier,
      panel,
      tabs,
      colorPrimary,
      colorSuccess,
      colorWarning,
      colorDanger,
      colorError,
      colorInfo,
      // textPrimary,
      // textRegular,
      // textSecondary,
      // textPlaceholder,
      // textDisabled,

      menuBackgroundColor,
      menuTextColor,
      menuActiveTextColor,
      ...toRefs(data),
      ThemeMode,
      init
    };
  }
});