import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { ref, reactive } from 'vue';
import { getRequest } from '@/api/designForm';
import frontPagination from '@/components/frontPagination';
export default {
  __name: 'dataSourceTable',
  setup(__props) {
    const tableListEl = ref();
    const route = useRoute();
    const id = route.query.id;
    const dialog = reactive({
      visible: false,
      title: '字段详情',
      type: 4,
      id: ''
    });
    const searchData = ref({
      list: [{
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入数据库表名'
        },
        config: {},
        name: 'TABLE_NAME',
        item: {
          label: '数据表名'
        }
      }, {
        type: 'button',
        control: {
          label: '查询',
          type: 'primary',
          key: 'submit'
        },
        config: {},
        name: ''
      }, {
        type: 'button',
        config: {},
        name: '',
        control: {
          label: '重置',
          key: 'reset'
        }
      }],
      form: {
        size: 'small'
      }
    });
    const tableData = ref({
      columns: [{
        label: '序号',
        type: 'index',
        width: '70px'
      }, {
        label: '表名',
        prop: 'TABLE_NAME'
      }, {
        label: 'id',
        prop: 'id'
      }, {
        label: '操作',
        prop: '__control',
        width: '160px'
      }],
      config: {
        columnsSetting: false
      },
      operateBtn: [
      // {
      //     label: '编辑',
      //     click: (row) => {
      //         dialog.visible = true
      //         dialog.title = '修改数据源'
      //         dialog.type = 2
      //         dialog.id = row.id
      //         nextTick(() => {
      //             formEl.value.getData({ id: row.id })
      //         })
      //     }
      // },
      // { label: '删除', key: 'del' },
      {
        label: '添加数据源',
        click: row => {
          ElMessageBox.confirm('确定添加数据源', '添加数据源', {
            confirmButtonText: '是的',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            const params = {
              dbId: id,
              tableName: row.TABLE_NAME,
              remark: ''
            };
            getRequest('/form-design/customDatasource/addTable', params).then(() => {
              ElMessage.success('添加数据源成功');
            });
          });
          // dialog.visible = true
          // router.push({ path: '/devPlatform/design/dataSource/dataSourceTable', query: { id: row.id } })
        }
      }
      // {
      //     label: '创建表单',
      //     click: (row) => {
      //         router.push({ path: '/devPlatform/design/form', query: { source: row.id } })
      //     }
      // }
      ]
    });
    const beforeRequest = params => {
      params.id = id;
      params.pageSize = 100000;
      return params;
    };
    const tableDataList = ref([]);
    const getTableData = data => {
      tableDataList.value = data;
      console.log(888, tableDataList.value);
    };
    const setTableData = data => {
      tableListEl.value.setTableData(data);
    };
    return (_ctx, _cache) => {
      const _component_ak_list = _resolveComponent("ak-list");
      return _openBlock(), _createBlock(_unref(frontPagination), {
        searchData: searchData.value,
        tableData: tableDataList.value,
        setTableData: setTableData
      }, {
        default: _withCtx(() => [_createVNode(_component_ak_list, {
          ref_key: "tableListEl",
          ref: tableListEl,
          requestUrl: "/form-design/customDatasource/getTablesByDbId",
          onGetTableData: getTableData,
          formId: _unref(id),
          beforeRequest: beforeRequest,
          tableData: tableData.value
        }, null, 8, ["formId", "tableData"])]),
        _: 1
      }, 8, ["searchData", "tableData"]);
    };
  }
};