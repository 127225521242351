import request from '@/utils/onlineRequest';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, onMounted } from "vue";
import { deepClone, downFile } from "@/utils";
import { getTree } from "@/api/system/area";
import { useRouter } from 'vue-router';
import { download } from '@/utils/ruoyi';
import { useSocket } from "@/utils/socket/iotSocket/socket";
import iotRequest from "@/utils/iotRequest";
export default defineComponent({
  name: "",
  props: ['path'],
  setup(props) {
    const {
      proxy
    } = getCurrentInstance();
    const formContainer = ref(ElForm);
    const formContainerZY = ref(ElForm);
    const state = reactive({
      tabPosition: 'zcForm',
      saveLoading: false,
      tableLoading: false,
      searchFormData: [{
        prop: "city",
        label: "所属地市",
        placeholder: "请选择所属地市",
        type: "cascader",
        change: val => methods.cityChange(val),
        treeProps: {
          lazy: true,
          value: "name",
          label: "name",
          checkStrictly: true,
          lazyLoad(node, resolve) {
            let id = null;
            if (!node.root) {
              id = node.data.id;
            } else {
              id = node.level;
            }
            getTree(id).then(response => {
              let nodes = response.data;
              nodes.forEach(n => {
                n.leaf = !n.leaf;
              });
              resolve(nodes);
            }).catch(err => {
              console.log(err);
            });
          }
        },
        isSelect: true,
        span: 6
      }, {
        prop: "maintOrg",
        label: "运维单位",
        placeholder: "请输入运维单位",
        type: "text",
        isSelect: false,
        span: 6
      }, {
        // bug 12812 N-功能测试-终端运行-二次设备管理-设备名称和编码查询结果不正确 qz 2022/7/20
        prop: "equipCodeOrName",
        label: "设备名称/编码",
        placeholder: "请输入设备名称/编码",
        type: "text",
        isSelect: true,
        span: 6
      }],
      showTableHeader: [],
      cloneQueryParams: null,
      zcFormRules: {
        astNum: [{
          required: true,
          message: "资产编号不能为空",
          trigger: "blur"
        }, {
          pattern: /^.{1,40}$/,
          message: "资产编号最长为40位"
        }],
        astOrg: [{
          required: true,
          message: "资产单位不能为空",
          trigger: "blur"
        }],
        source: [{
          required: true,
          message: "设备来源不能为空",
          trigger: "change"
        }],
        deployState: [{
          required: true,
          message: "设备状态不能为空",
          trigger: "change"
        }],
        isCommission: [{
          required: true,
          message: "是否代维不能为空",
          trigger: "change"
        }],
        model: [{
          required: true,
          message: "生产型号不能为空",
          trigger: "blur"
        }],
        manufacturer: [{
          required: true,
          message: "生产厂家不能为空",
          trigger: "blur"
        }]
      },
      zyFormRules: {
        productId: {
          required: true,
          message: "产品不能为空",
          trigger: "blur"
        },
        city: [{
          required: true,
          message: "所属地市不能为空",
          trigger: "change"
        }],
        agreement: [{
          required: true,
          message: "所属协议不能为空",
          trigger: "change"
        }],
        maintOrg: [{
          required: true,
          message: "运维单位不能为空",
          trigger: "change"
        }],
        maintGroup: [{
          required: true,
          message: "运维班组不能为空",
          trigger: "blur"
        }],
        equipmentOwner: [{
          required: true,
          message: "设备主人不能为空",
          trigger: "blur"
        }],
        station: [{
          required: true,
          message: "所属站房不能为空",
          trigger: "blur"
        }],
        bay: [{
          required: true,
          message: "所属间隔不能为空",
          trigger: "blur"
        }],
        psrState: [{
          required: true,
          message: "运行状态不能为空",
          trigger: "change"
        }],
        stationType: [{
          required: true,
          message: "所属站房类型不能为空",
          trigger: "blur"
        }],
        isRural: [{
          required: true,
          message: "是否农网不能为空",
          trigger: "change"
        }],
        pubPrivFlag: [{
          required: true,
          message: "营配标识不能为空",
          trigger: "blur"
        }, {
          pattern: /^.{1,2}$/,
          message: "营配标识最长为2位"
        }],
        feeder: [{
          required: true,
          message: "所属馈线不能为空",
          trigger: "change"
        }],
        line: [{
          required: true,
          message: "所属线路不能为空",
          trigger: "change"
        }]
      },
      formRules: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        PIdList: "",
        city: "",
        maintOrg: "",
        equipCodeOrName: ""
      },
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "组网",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        hasPermi: ["edge:deviceManage:netWorking"],
        exp: item => methods.handleNetWork(item)
      }, {
        buttonName: "新增",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        hasPermi: ["edge:deviceManage:add"],
        exp: item => methods.handleAdd(item)
      }, {
        buttonName: "批量删除",
        buttonStyle: "danger",
        className: 'deleteBtn',
        buttonIcon: "Delete",
        hasPermi: ["edge:deviceManage:remove"],
        exp: item => methods.handleDel(true, item)
      }, {
        buttonName: "导出",
        buttonStyle: "primary",
        loading: false,
        className: 'exportBtn',
        buttonIcon: "Upload",
        hasPermi: ["edge:deviceManage:export"],
        exp: item => methods.handleExport(item)
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      multipleSelection: [],
      dialogVisible: false,
      tableHeader: [{
        prop: "psrId",
        label: "资源ID",
        fixed: false,
        type: "text"
      }, {
        prop: "name",
        label: "设备名称",
        fixed: false,
        type: "text"
      }, {
        prop: "agreement",
        label: "所属协议",
        fixed: false,
        type: "text"
      }, {
        prop: "city",
        label: "所属地市",
        fixed: false,
        type: "text"
      }, {
        prop: "isOnline",
        label: "在线状态",
        fixed: false,
        type: "text",
        formatter: row => row.isOnline == 1 ? "在线" : "离线"
      }, {
        prop: "maintOrg",
        label: "运维单位",
        fixed: false,
        type: "text"
      }, {
        prop: "maintGroup",
        label: "运维班组",
        fixed: false,
        type: "text"
      }, {
        prop: "equipmentOwner",
        label: "设备主人",
        fixed: false,
        type: "text"
      },
      // {
      //   prop: "voltageLevel_",
      //   label: "电压等级",
      //   fixed: false,
      //   type: "text",
      // },
      {
        prop: "psrState_",
        label: "运行状态",
        fixed: false,
        type: "list"
      }, {
        prop: "operateDate",
        label: "投运日期",
        fixed: false,
        type: "datetime"
      }, {
        prop: "stopTime",
        label: "退运日期",
        fixed: false,
        type: "datetime"
      }, {
        prop: "astId",
        label: "资产ID",
        fixed: false,
        type: "text"
      }, {
        prop: "equipCode",
        label: "设备编码",
        fixed: false,
        type: "text",
        router: {
          path: "/module/sysIot/deviceManage/deviceDetail",
          query: {
            path: "DTU",
            id: "aId",
            //资产
            astId: "pId",
            //资源
            psrId: 'psrId' //设备id
          }
        }
      }, {
        prop: "astNature_",
        label: "资产性质",
        fixed: false,
        type: "text"
      }, {
        prop: "deployState_",
        label: "设备状态",
        fixed: false,
        type: "list"
      }, {
        prop: "operateDate",
        label: "投运日期",
        fixed: false,
        type: "datetime"
      }, {
        prop: "manufacturer",
        label: "生产厂家",
        fixed: false,
        type: "text"
      }, {
        prop: "model",
        label: "设备型号",
        fixed: false,
        type: "list"
      }, {
        prop: "operation",
        label: "操作",
        fixed: 'right',
        operation: [{
          name: "删除",
          hasPermi: ["edge:deviceManage:remove"],
          clickFun: row => methods.handleDel(false, row)
        }, {
          name: "编辑",
          hasPermi: ["edge:deviceManage:edit"],
          clickFun: row => methods.handleEdit(row)
        }]
      }],
      form: {
        zcForm: {
          astId: '',
          equipCode: '',
          astNature: '',
          astOrg: '',
          astNum: '',
          projectNum: '',
          projectName: '',
          source: '',
          wbsNum: '',
          deployState: '',
          isCommission: '',
          operateDate: '',
          manufactureNum: '',
          manufactureDate: '',
          model: '',
          manufacturer: '',
          terminalType: '',
          communicationMet: '',
          macAddress: '',
          hardwareVersion: '',
          backupPower: '',
          backupPowerVl: '',
          terminalAddress: '',
          remark: '',
          ctime: ''
        },
        zyForm: {
          productId: '',
          psrId: '',
          name: '',
          city: [],
          maintOrg: '',
          maintGroup: '',
          equipmentOwner: '',
          station: '',
          bay: '',
          stationType: '',
          psrState: '',
          startTime: '',
          stopTime: '',
          isRural: '',
          powerEc: '',
          installMethod: '',
          powerMethod: '',
          loopQuantity: '',
          softwareVersion: '',
          simNum: '',
          ipAddress: '',
          gateway: '',
          subnetMask: '',
          portNum: '',
          ctime: '',
          pubPrivFlag: '',
          feeder: '',
          line: ''
          // sourceTerminalIp:'',
          // sourceTerminalPort:'',
          // sterminalLinkAddr:'',
          // sterminalAsduAddr:'',
          // targetTerminalIp:'',
          // targetTerminalPort:'',
          // tterminalLinkAddr:'',
          // tterminalAsduAddr:'',
          // terminalCerId:'',
          // terminalCerName:''
        }
      },
      cloneForm: null,
      zcFormdata: [{
        prop: "equipCode",
        label: "设备编码",
        placeholder: "请输入设备编码",
        type: "text"
      }, {
        prop: "astNature",
        label: "资产性质",
        placeholder: "请选择资产性质",
        optionsUrl: "/sys-server/system/dict/data/dictType/10403",
        type: "list"
      }, {
        prop: "astOrg",
        label: "资产单位",
        placeholder: "请选择资产单位",
        type: "text"
      }, {
        prop: "astNum",
        label: "资产编号",
        placeholder: "请输入资产编号",
        type: "text"
      }, {
        prop: "projectNum",
        label: "工程编号",
        placeholder: "请输入工程编号",
        type: "text"
      }, {
        prop: "projectName",
        label: "工程名称",
        placeholder: "请输入工程名称",
        type: "text"
      }, {
        prop: "source",
        label: "设备来源",
        placeholder: "请选择设备来源",
        optionsUrl: "/sys-server/system/dict/data/dictType/10419",
        type: "list"
      }, {
        prop: "wbsNum",
        label: "WBS编码",
        placeholder: "请输入WBS编码",
        type: "text"
      }, {
        prop: "deployState",
        label: "设备状态",
        placeholder: "请选择设备状态",
        optionsUrl: "/sys-server/system/dict/data/dictType/10402",
        type: "list"
      }, {
        prop: "isCommission",
        label: "是否代维",
        placeholder: "请选择是否代维",
        optionsUrl: "/sys-server/system/dict/data/dictType/10599",
        type: "list"
      }, {
        prop: "operateDate",
        label: "投运日期",
        placeholder: "请选择投运日期",
        type: "datetime",
        isSelect: true
      }, {
        prop: "manufactureNum",
        label: "出厂编号",
        placeholder: "请输入出厂编号",
        type: "text"
      }, {
        prop: "manufactureDate",
        label: "出厂日期",
        placeholder: "请选择出厂日期",
        type: "datetime",
        isSelect: true
      }, {
        prop: "model",
        label: "设备型号",
        placeholder: "请输入设备型号",
        type: "text"
      }, {
        prop: "manufacturer",
        label: "生产厂家",
        placeholder: "请输入生产厂家",
        type: "text"
      }, {
        prop: "terminalType",
        label: "终端类型",
        placeholder: "请输入终端类型",
        type: "text"
      }, {
        prop: "communicationMet",
        label: "通信方式",
        placeholder: "请输入通信方式",
        type: "text"
      }, {
        prop: "macAddress",
        label: "mac地址",
        placeholder: "请输入mac地址",
        type: "text"
      }, {
        prop: "backupPower",
        label: "后备电源",
        placeholder: "请输入后备电源",
        type: "text"
      }, {
        prop: "backupPowerVl",
        label: "后备电源电压等级",
        placeholder: "请输入后备电源电压等级",
        type: "text"
      }, {
        prop: "terminalAddress",
        label: "终端地址",
        placeholder: "请输入终端地址",
        type: "text"
      }, {
        prop: "ctime",
        label: "创建时间",
        placeholder: "请选择创建时间",
        type: "datetime",
        isSelect: true
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea"
      }],
      zyFormData: [{
        prop: "productId",
        label: "选择产品",
        placeholder: "请选择产品",
        optionsUrl: `${process.env.VUE_APP_IOT_API}/product/list`,
        type: "list",
        props: {
          label: 'name',
          value: 'id'
        }
      }, {
        prop: "astId",
        label: "资产ID",
        placeholder: "请选择资产ID",
        type: "list"
      }, {
        prop: "name",
        label: "设备名称",
        placeholder: "请输入设备名称",
        type: "text",
        isSelect: true
      }, {
        prop: "city",
        label: "所属地市",
        placeholder: "请输入所属地市",
        type: "cascader",
        change: val => methods.cityChange(val, 'zc'),
        treeProps: {
          lazy: true,
          value: "name",
          label: "name",
          checkStrictly: true,
          lazyLoad(node, resolve) {
            let id = null;
            if (!node.root) {
              id = node.data.id;
            } else {
              id = node.level;
            }
            getTree(id).then(response => {
              let nodes = response.data;
              nodes.forEach(n => {
                n.leaf = !n.leaf;
              });
              resolve(nodes);
            }).catch(err => {
              console.log(err);
            });
          }
        },
        isSelect: true
      }, {
        prop: "maintOrg",
        label: "运维单位",
        placeholder: "请输入运维单位",
        type: "text",
        isSelect: true
      }, {
        prop: "maintGroup",
        label: "维护班组",
        placeholder: "请输入维护班组",
        type: "text",
        isSelect: true
      }, {
        prop: "equipmentOwner",
        label: "设备主人",
        placeholder: "请输入设备主人",
        type: "text",
        isSelect: true
      }, {
        prop: "station",
        label: "所属站房",
        placeholder: "请输入所属站房",
        type: "text",
        isSelect: false
      }, {
        prop: "bay",
        label: "所属间隔",
        labelStr: "bay_",
        placeholder: "请输入所属间隔",
        optionsUrl: process.env.VUE_APP_YEWU_API + "/referDialog/dialog?dialogType=net_ds_bay_zy",
        type: "dialog-select",
        isSelect: false
      }, {
        prop: "stationType",
        label: "所属站房类型",
        placeholder: "请输入所属站房类型",
        optionsUrl: "/sys-server/system/dict/data/dictType/pdzflx",
        type: "list",
        isSelect: false
      }, {
        prop: "psrState",
        label: "运行状态",
        placeholder: "请输入运行状态",
        optionsUrl: "/sys-server/system/dict/data/dictType/10402",
        type: "list",
        isSelect: false
      }, {
        prop: "feeder",
        label: "所属馈线",
        labelStr: "feeder_",
        placeholder: "请输入所属馈线",
        type: "dialog-select",
        optionsUrl: process.env.VUE_APP_YEWU_API + "/referDialog/dialog?dialogType=net_df_feeder_zy",
        isSelect: false
      }, {
        prop: "line",
        label: "所属线路",
        labelStr: "line_",
        placeholder: "请输入所属线路",
        type: "dialog-select",
        optionsUrl: process.env.VUE_APP_YEWU_API + "/referDialog/dialog?dialogType=net_df_line_zy",
        isSelect: false
      }, {
        prop: "startTime",
        label: "投运日期",
        placeholder: "请选择投运日期",
        type: "datetime",
        isSelect: true
      }, {
        prop: "stopTime",
        label: "退运日期",
        placeholder: "请选择退运日期",
        type: "datetime",
        isSelect: true
      }, {
        prop: "isRural",
        label: "是否农网",
        placeholder: "请输入是否农网",
        optionsUrl: "/sys-server/system/dict/data/dictType/10599",
        type: "list",
        isSelect: false
      }, {
        prop: "installMethod",
        label: "安装方式",
        placeholder: "请输入安装方式",
        type: "text"
      }, {
        prop: "powerMethod",
        label: "取电方式",
        placeholder: "请输入取电方式",
        type: "text",
        isSelect: false
      }, {
        prop: "loopQuantity",
        label: "回路数",
        placeholder: "请输入回路数",
        type: "text"
      }, {
        prop: "powerEc",
        label: "外接电源接入点",
        placeholder: "请输入外接电源接入点",
        type: "text"
      }, {
        prop: "softwareVersion",
        label: "软件版本",
        placeholder: "请输入软件版本",
        type: "text"
      }, {
        prop: "simNum",
        label: "sim版本",
        placeholder: "请输入sim版本",
        type: "text"
      }, {
        prop: "ipAddress",
        label: "ip",
        placeholder: "请输入ip",
        type: "text"
      }, {
        prop: "gateway",
        label: "网关",
        placeholder: "请输入网关",
        type: "text"
      }, {
        prop: "subnetMask",
        label: "子网掩码",
        placeholder: "请输入子网掩码",
        type: "text"
      }, {
        prop: "portNum",
        label: "端口号",
        placeholder: "请输入端口号",
        type: "text"
      }, {
        prop: "pubPrivFlag",
        label: "营配标识",
        placeholder: "请输入营配标识",
        type: "text"
      }, {
        prop: "ctime",
        label: "创建时间",
        placeholder: "请选择创建时间",
        type: "datetime",
        isSelect: true
      }, {
        prop: "agreement",
        label: "所属协议",
        placeholder: "请选择所属协议",
        optionsUrl: "/sys-server/system/dict/data/dictType/agreement_type",
        type: "list"
      }
      // {
      //   prop: "sourceTerminalIp",
      //   label: "源终端ip",
      //   placeholder: "请输入源终端ip",
      //   type: "text",
      // },{
      //   prop: "sourceTerminalPort",
      //   label: "源终端port",
      //   placeholder: "请输入源终端port",
      //   type: "text",
      // },{
      //   prop: "sterminalLinkAddr",
      //   label: "源终端链路地址",
      //   placeholder: "请输入源终端链路地址",
      //   type: "text",
      // },{
      //   prop: "sterminalAsduAddr",
      //   label: "源终端asdu地址",
      //   placeholder: "请输入源终端asdu地址",
      //   type: "text",
      // },{
      //   prop: "targetTerminalIp",
      //   label: "目标终端ip",
      //   placeholder: "请输入目标终端ip",
      //   type: "text",
      // },{
      //   prop: "targetTerminalPort",
      //   label: "目标终端port",
      //   placeholder: "请输入目标终端port",
      //   type: "text",
      // },{
      //   prop: "tterminalLinkAddr",
      //   label: "目标终端链路地址",
      //   placeholder: "请输入目标终端链路地址",
      //   type: "text",
      // },{
      //   prop: "tterminalAsduAddr",
      //   label: "目标终端asdu地址",
      //   placeholder: "请输入目标终端asdu地址",
      //   type: "text",
      // },{
      //   prop: "terminalCerId",
      //   label: "终端证书编号",
      //   placeholder: "请输入终端证书编号",
      //   type: "text",
      // },{
      //   prop: "terminalCerName",
      //   label: "终端证书名字",
      //   placeholder: "请输入终端证书名字",
      //   type: "text",
      // }
      ],
      formData: [],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      title: "",
      url: {
        list: `${process.env.VUE_APP_IOT_API}/netSeDtuZy/linkageList`,
        zcAdd: `${process.env.VUE_APP_IOT_API}/netSeDtuZc/add`,
        zcEdit: `${process.env.VUE_APP_IOT_API}/netSeDtuZc/edit`,
        zcDel: `${process.env.VUE_APP_IOT_API}/netSeDtuZc/deleteBatch`,
        zyAdd: `${process.env.VUE_APP_IOT_API}/netSeDtuZy/add`,
        zyEdit: `${process.env.VUE_APP_IOT_API}/netSeDtuZy/edit`,
        zyDel: `${process.env.VUE_APP_IOT_API}/netSeDtuZy/deleteBatch`,
        exports: `${process.env.VUE_APP_IOT_API}/netSeDtuZy/exportXls`
      }
    });
    state.formData = state.zcFormdata;
    state.formRules = state.zcFormRules;
    const router = useRouter();
    // 弹窗标题
    state.title = "新增";
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    state.cloneForm = JSON.parse(JSON.stringify(state.form));
    // 表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const methods = reactive({
      handleClick(val) {
        // if(val==='zcForm'){
        //   state.formData=state.zcFormdata;
        //   state.formRules=state.zcFormRules;
        // }else{
        //   state.formData=state.zyFormData;
        //   state.formRules=state.zyFormRules;
        // }
      },
      cityChange(val, operate) {
        if (val && val.length > 0) {
          if (!operate) {
            state.queryParams.city = val.join(',');
          }
        }
      },
      // 详情
      handleView(row) {
        state.title = "详情";
        request({
          url: state.url.queryById,
          method: 'get',
          params: {
            id: row.id
          }
        }).then(res => {
          state.form = res.data;
          state.dialogVisible = true;
        }).catch(err => {
          state.saveLoading = false;
        });
      },
      changeTableheader(val) {
        state.showTableHeader = val;
      },
      handleSelectionAll(val) {},
      handleSelectionChange(val) {},
      subscribeSocket(clientSocket) {
        clientSocket.subscribe("/communication-protocol/rf/network", res => {
          console.log("收到推送communication-protocol/rf/network", res);
          methods.reciveMessage(res.body);
        });
      },
      //增加
      handleAdd() {
        state.title = "新增";
        state.tabPosition = 'zcForm';
        state.form = JSON.parse(JSON.stringify(state.cloneForm));
        request({
          url: process.env.VUE_APP_IOT_API + "/netSeFtuZc/getDataDropdown",
          method: "post"
        }).then(res => {
          const temp = [];
          if (res.data?.length) {
            for (let item of res.data) {
              temp.push({
                dictValue: item.id,
                dictLabel: item.name
              });
            }
          }
          state.zyFormData[0]['options'] = temp;
        }).finally(() => {
          state.dialogVisible = true;
        });
      },
      handleNetWork() {
        if (state.multipleSelection.some(r => r.isOnline == 1)) {
          ElMessage.warning("在线状态的记录不可组网，请取消勾选！");
          return;
        }
        if (!state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据");
          return;
        }
        let params = [];
        state.multipleSelection.forEach(item => {
          params.push({
            psrId: item.psrId,
            sourceTerminalIp: item.sourceTerminalIp,
            sourceTerminalPort: item.sourceTerminalPort,
            startStopFlag: 1,
            sterminalAsduAddr: item.sterminalAsduAddr,
            sterminalLinkAddr: item.sterminalLinkAddr,
            terminalName: item.terminalName,
            agreement: item.agreement
          });
        });
        iotRequest({
          url: '/network/startStop',
          method: 'post',
          data: params
        }).then(res => {
          if (res.code == 1000) {
            ElMessage.success("组网指令下发成功！");
          }
        });
      },
      //删除
      handleDel(isBatch, row) {
        if (isBatch && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        let zcParames = null,
          zyParames = null;
        if (state.multipleSelection.length) {
          zcParames = state.multipleSelection.map(item => {
            return item.aId;
          }).join(",");
          zyParames = state.multipleSelection.map(item => {
            return item.pId;
          }).join(",");
        } else {
          zcParames = row.aId;
          zyParames = row.pId;
        }
        //删除需要调用两个接口，资产批量删除和资源批量删除
        ElMessageBox.confirm("确定是否删除?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          let zcDeletePromise = new Promise((resolve, reject) => {
            request({
              url: state.url.zcDel,
              method: "post",
              params: {
                ids: zcParames
              }
            }).then(response => {
              resolve(response);
            }).catch(error => {
              reject(error);
              console.log(error);
            });
          });
          let zyDeletePromise = new Promise((resolve, reject) => {
            request({
              url: state.url.zyDel,
              method: "post",
              params: {
                ids: zyParames
              }
            }).then(response => {
              resolve(response);
            }).catch(error => {
              reject(error);
              console.log(error);
            });
          });
          Promise.all([zcDeletePromise, zyDeletePromise]).then(resArray => {
            let isDeleteSuccess = false;
            for (let i = 0; i < resArray.length; i++) {
              if (resArray[i].code === 1000) {
                isDeleteSuccess = true;
              } else {
                ElMessage.closeAll();
                ElMessage.warning(resArray[i].message);
              }
            }
            if (isDeleteSuccess) {
              ElMessage.closeAll();
              ElMessage.success("删除成功");
              methods.getList();
            }
          }).catch(error => {
            ElMessage.closeAll();
            ElMessage.error("删除失败");
          });
        });
      },
      handleImport() {},
      handleExport(row) {
        if (!state.tableData.rows || state.tableData.rows.length == 0) {
          ElMessage.closeAll();
          ElMessage.warning('暂无数据可导出！');
          return;
        }
        //没有勾选默认导出全部，勾选就导出勾选的部分
        let tipTitle = '';
        if (!row.id && !state.multipleSelection.length) {
          tipTitle = '是否确认导出所有的数据项?';
        } else {
          tipTitle = '是否确认导出所选的数据项?';
        }
        ElMessageBox.confirm(tipTitle, "导出", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          row.loading = true;
          //导出参数增加勾选的参数
          if (!state.multipleSelection.length) {
            state.queryParams.PIdList = "";
          } else if (row.id || state.multipleSelection.length) {
            if (row.id) {
              state.multipleSelection = [];
            }
            let zyParames = null;
            if (state.multipleSelection.length) {
              zyParames = state.multipleSelection.map(item => {
                return item.pId;
              }).join(',');
            } else {
              zyParames = row.pId;
            }
            state.queryParams.PIdList = zyParames;
          }
          request({
            url: state.url.exports,
            method: "post",
            params: state.queryParams,
            responseType: "blob"
          }).then(res => {
            let fileName = decodeURI(res.headers["content-disposition"].split("=")[1]);
            downFile(fileName, res.data);
            row.loading = false;
          }).catch(err => {
            console.log(err);
            row.loading = false;
          });
        });
      },
      //确定
      submitForm: function () {
        const formRef = unref(formContainer);
        const zyFormRef = unref(formContainerZY);
        let cloneStateForm = JSON.parse(JSON.stringify(state.form));
        if (state.tabPosition === 'zcForm') {
          formRef.commonForm.validate(valid => {
            if (valid) {
              state.saveLoading = true;
              console.log(cloneStateForm.zcForm);
              debugger;
              cloneStateForm.zcForm.id = cloneStateForm.zcForm.pId;
              let isEdit = cloneStateForm.zcForm.aId && cloneStateForm.zcForm.aId.length > 0 ? true : false;
              request({
                url: isEdit ? state.url.zcEdit : state.url.zcAdd,
                method: 'post',
                data: cloneStateForm.zcForm
              }).then(res => {
                if (res.code == 1000) {
                  ElMessage.success(isEdit ? '编辑成功' : '新增成功');
                }
                state.saveLoading = false;
                state.dialogVisible = false;
                methods.getList();
              }).catch(err => {
                console.log(err);
                state.saveLoading = false;
              });
            }
          });
        } else {
          zyFormRef.commonForm.validate(valid => {
            if (valid) {
              if (cloneStateForm.zyForm.city) {
                cloneStateForm.zyForm.city = Array.isArray(cloneStateForm.zyForm.city) ? cloneStateForm.zyForm.city.join(',') : "";
              }
              state.saveLoading = true;
              cloneStateForm.zyForm.id = cloneStateForm.zyForm.pId;
              let isEdit = cloneStateForm.zyForm.aId && cloneStateForm.zyForm.aId.length > 0 ? true : false;
              request({
                url: isEdit ? state.url.zyEdit : state.url.zyAdd,
                method: 'post',
                data: cloneStateForm.zyForm
              }).then(res => {
                if (res.code == 1000) {
                  ElMessage.success(isEdit ? '编辑成功' : '新增成功');
                }
                state.saveLoading = false;
                state.dialogVisible = false;
                methods.getList();
              }).catch(err => {
                console.log(err);
                state.saveLoading = false;
              });
            }
          });
        }
      },
      // 取消
      closeDialog() {
        state.dialogVisible = false;
      },
      formHandleForm() {
        console.log("formHandle");
      },
      getList() {
        nextTick(() => {
          state.tableLoading = true;
          request({
            url: state.url.list,
            method: 'post',
            data: state.queryParams
          }).then(res => {
            state.tableData = res.data;
            state.tableLoading = false;
          }).catch(err => {
            state.saveLoading = false;
            state.tableLoading = false;
          });
        });
      },
      /** 搜索按钮操作 */
      handleQuery() {
        state.queryParams.pageNum = 1;
        methods.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
        methods.handleQuery();
      },
      handleMultipleSelection() {},
      sortEvents() {},
      handleEdit(row) {
        state.title = "编辑";
        state.form.zcForm = JSON.parse(JSON.stringify(row));
        state.form.zyForm = JSON.parse(JSON.stringify(row));
        if (row.city) {
          state.form.zcForm.city = row.city.split(',');
          state.form.zyForm.city = row.city.split(',');
        }
        state.dialogVisible = true;
      },
      changeIsOnlineState(psrId) {
        state.tableData.rows.forEach(r => {
          if (r.psrId == psrId) {
            r.isOnline = 1;
          }
        });
      },
      subscribeSocket104(clientSocket) {
        clientSocket.subscribe("/rf/network/104", res => {
          console.log("104推送", res.body);
          //修改该条记录离线状态为在线状态
          let psrId = res.body;
          methods.changeIsOnlineState(psrId);
        });
      },
      subscribeSocket101(clientSocket) {
        clientSocket.subscribe("/rf/network/101", res => {
          console.log("101推送", res.body);
          let psrId = res.body;
          methods.changeIsOnlineState(psrId);
        });
      }
    });
    onMounted(() => {
      const {
        initWebSocket
      } = useSocket();
      initWebSocket(methods.subscribeSocket104, window.g.VUE_APP_104_SOCKET, 'communication-protocol');
      initWebSocket(methods.subscribeSocket101, window.g.VUE_APP_101_SOCKET, 'protocol101');
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      formContainer,
      formContainerZY
    };
  }
});