import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import store from "@/store";
import { getToken } from "./auth";
import { UserActionTypes } from "@/store/modules/user/action-types";
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 1000 * 60,
});
// request拦截器
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["Authorization"] = "Bearer " + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    const code = res.data.code;
    // 判断是否有code存在，兼容导出时返回整个文件流
    if (code) {
      if (code === 401) {
        ElMessageBox.close();
        // ElMessageBox.confirm(
        //   "登录状态已过期，您可以继续留在该页面，或者重新登录",
        //   "系统提示",
        //   {
        //     confirmButtonText: "重新登录",
        //     cancelButtonText: "取消",
        //     type: "warning",
        //   }
        // )
        ElMessageBox.confirm(
            'The login status has expired, you can stay on this page, or log in again',
            'system hint',
            {
              confirmButtonText: 'Log in again',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
        ).then(() => {
          store.dispatch(UserActionTypes.ACTION_LOGIN_OUT);
        });
      } else if (code == 1001) {
        ElMessage.closeAll();
        ElMessage({
          showClose: true,
          type: "warning",
          message: res.data.message,
        });
        return Promise.reject(res.data.message);
      } else if (code == 1002) {
        ElMessage.closeAll();
        return res.data;
      } else if (code !== 1000) {
        ElMessage.closeAll();
        ElMessage({
          showClose: true,
          message: res.data.message || "系统异常，请重新登录",
          type: "error",
          duration: 3 * 1000,
        });
        return Promise.reject("error");
      } else {
        return res.data;
      }
    } else {
      // code不存在时返回后端return对象
      return res;
    }
  },
  (error) => {
    //bug 5676 N-功能测试-源荷储协同-源荷储系统【新增选择关联台区，】【选择台区互济，选择多个台区，点击保存，提示语看不懂，】 by xbt
    if (error.response.data.code === 401) {
      ElMessageBox.close();
      // ElMessageBox.confirm(
      //   "登录状态已过期，您可以继续留在该页面，或者重新登录",
      //   "系统提示",
      //   {
      //     confirmButtonText: "重新登录",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }
      // )
      ElMessageBox.confirm(
          'The login status has expired, you can stay on this page, or log in again',
          'system hint',
          {
            confirmButtonText: 'Log in again',
            cancelButtonText: 'Cancel',
            type: 'warning'
          }
      ).then(() => {
        store.dispatch(UserActionTypes.ACTION_LOGIN_OUT);
      });
    } else {
      ElMessage.closeAll();
      ElMessage({
        showClose: true,
        message: error.message,
        type: "error",
        duration: 5 * 1000,
      });
      return Promise.reject(error);
    }
  }
);

export default service;
