export default {
  data() {
    return {
      theme: sessionStorage.getItem('theme') || '0',
      menuPositionSet: sessionStorage.getItem('menuPositionSet') || '0'
    };
  },
  computed: {
    fixedHeader: {
      get() {
        return this.$store.state.common.fixedHeader;
      },
      set(val) {
        this.$store.dispatch('common/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    tagsView: {
      get() {
        return this.$store.state.common.tagsView;
      },
      set(val) {
        this.$store.dispatch('common/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get() {
        return this.$store.state.common.sidebarLogo;
      },
      set(val) {
        this.$store.dispatch('common/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    }
  },
  methods: {
    themeChange(val) {
      sessionStorage.setItem('theme', val);
      this.$store.dispatch('common/changeSetting', {
        key: 'theme',
        value: val
      });
      //切换body撒谎给你的class 进行换肤
      this.$store.commit('common/setTheme');
    },
    menuPositionHandle(val) {
      sessionStorage.setItem('menuPositionSet', val);
      this.$store.dispatch('common/changeSetting', {
        key: 'menuPositionSet',
        value: val
      });
      if (val === '1') {
        this.$store.state.common.sidebar.opened = true;
      }
    }
  }
};