// 创建/更新一个数据库
export let creatUpdateStore = function (name, verson = 1) {
    console.log('creatUpdateStore');
    // 打开数据库
    let request = window.indexedDB.open(name, verson);

    request.onsuccess = function (event) {
        console.log('open success');
    };

    request.onerror = function (event) {
        console.log('open fail');
    };

    request.onupgradeneeded = function (event) {
        let db = event.target.result;
        if (!db.objectStoreNames.contains(name)) {
            // 创建仓库对象（创建表格）
            // 这里我将主键设置为id
            let objectStore = db.createObjectStore(name, {
                keyPath: 'url',
                autoIncrement: true
            });
        }
    };
};

// 往数据库中加数据
export let addDataStore = function (storeName, data, verson) {
    console.log('addDataStore');
    return new Promise((resolve, reject) => {
        let databaseName = storeName;
        let databaseVersion = verson || 1;
        let db;
        let request = indexedDB.open(databaseName, databaseVersion);
        request.onsuccess = function (event) {
            db = event.target.result;

            // 将数据保存到新建的对象仓库
            let objectStore = db
                .transaction(databaseName, 'readwrite')
                .objectStore(databaseName);
            if (Array.isArray(data)) {
                data.forEach(function (dataItem) {
                    // 添加一条数据
                    objectStore.add(dataItem);
                });
                resolve();
            } else {
                // 添加一条数据
                objectStore.add(data);
                resolve();
            }
        };
        request.onerror = function () {
            reject();
        };
        request.onupgradeneeded = function (event) {
            let db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                // 创建仓库对象（创建表格）
                // 这里我将主键设置为id
                let objectStore = db.createObjectStore(storeName, {
                    keyPath: 'id',
                    autoIncrement: true
                });
            }
        };
    });
};

// 获取数据
export let getStoreData = function (name, key = 1) {
    console.log('getStoreData');
    return new Promise((resolve, reject) => {
        let request = indexedDB.open(name);
        request.onsuccess = function (event) {
            let db = event.target.result;
            let req;
            try {
                req = db
                    .transaction(name, 'readwrite')
                    .objectStore(name)
                    .get(key); // 这里的“1”也是主键的键值
            } catch (e) {
                reject('用户失败');
            }
            if (!req) {
                return;
            }
            req.onsuccess = function () {
                resolve(req.result)
            };
            req.onerror = function () {
                reject('获取失败');
            };
        };
        request.onupgradeneeded = function (event) {
            let db = event.target.result;
            if (!db.objectStoreNames.contains(name)) {
                // 创建仓库对象（创建表格）
                // 这里我将主键设置为id
                let objectStore = db.createObjectStore(name, {
                    keyPath: 'url',
                    autoIncrement: true
                });
            }
        };
    });
};
// 删除数据
export let delectStoreData = function (name, key) {
    console.log('delectStoreData');
    return new Promise((resolve, reject) => {
        let databaseName = name;
        let db;
        let request = window.indexedDB.open(databaseName);
        request.onsuccess = function (event) {
            db = event.target.result;
            let req = db
                .transaction(databaseName, 'readwrite')
                .objectStore(databaseName)
                .delete(key); // 这里指定的是主键的键值

            req.onsuccess = function () {
                resolve('删除成功');
            };

            req.onerror = function () {
                reject('删除失败');
            };
        };
    });
};
// 更新
export let updateStoreData = function (storeName, newData, key) {
    console.log('updateStoreData');
    return new Promise((resolve, reject) => {
        let request = window.indexedDB.open(storeName);
        let db;
        request.onsuccess = function (event) {
            db = event.target.result;
            let transaction = db.transaction(storeName, 'readwrite');
            let store = transaction.objectStore(storeName);
            let storeData = store.get(key);

            storeData.onsuccess = function (e) {
                let data = e.target.result || {};
                for (let a in newData) {
                    data[a] = newData[a];
                }
                store.put(data);
                resolve();
            };
        };
        request.onupgradeneeded = function (event) {
            let db = event.target.result;
            if (!db.objectStoreNames.contains(storeName)) {
                // 创建仓库对象（创建表格）
                // 这里我将主键设置为id
                let objectStore = db.createObjectStore(storeName, {
                    keyPath: 'url',
                    autoIncrement: true
                });
            }
        };
    });
};










