import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { useRouter } from 'vue-router';
import 'vue-cropper/dist/index.css';
import { VueCropper } from "vue-cropper";
import { useStore } from "vuex";
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { uploadAvatar } from "@/api/system/user";
import { Plus, UploadFilled, Minus, RefreshLeft, RefreshRight } from '@element-plus/icons-vue';
export default defineComponent({
  name: "",
  components: {
    VueCropper
  },
  props: {
    user: {
      type: Object
    }
  },
  setup(props, context) {
    const cropper = ref({});
    const router = useRouter();
    const store = useStore();
    const options = ref({
      img: store.getters.avatar,
      //裁剪图片的地址
      autoCrop: true,
      // 是否默认生成截图框
      autoCropWidth: 200,
      // 默认生成截图框宽度
      autoCropHeight: 200,
      // 默认生成截图框高度
      fixedBox: true // 固定截图框大小 不允许改变
    });
    const state = reactive({
      imgUrl: "",
      imgUrl2: store.getters.avatar,
      // 是否显示弹出层
      open: false,
      // 弹出层标题
      title: "修改头像",
      previews: {}
    });
    const methods = reactive({
      //弹窗出来后赋值url，解决截图框错乱问题
      cropperOpen() {
        options.value.img = state.imgUrl ? state.imgUrl : store.getters.avatar;
      },
      // 编辑头像
      editCropper() {
        state.open = true;
      },
      // 覆盖默认的上传行为
      requestUpload() {},
      // 实时预览
      realTime(data) {
        state.previews = data;
      },
      // 向左旋转
      rotateLeft() {
        cropper.value.rotateLeft();
      },
      // 向右旋转
      rotateRight() {
        cropper.value.rotateRight();
      },
      // 图片缩放
      changeScale(num) {
        num = num || 1;
        cropper.value.changeScale(num);
      },
      // // 上传预处理
      beforeUpload(file) {
        if (file.type.indexOf("image/") == -1) {
          this.msgError("文件格式错误，请上传图片类型,如：JPG，PNG后缀的文件。");
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            options.value.img = reader.result;
          };
        }
      },
      // 上传图片
      uploadImg() {
        cropper.value.getCropBlob(data => {
          let formData = new FormData();
          formData.append("avatarfile", data);
          uploadAvatar(formData).then(response => {
            if (response.code === 1000) {
              state.open = false;
              state.imgUrl = process.env.VUE_APP_BASE_API + response.imgUrl;
              store.commit('SET_AVATAR', state.imgUrl);
              ElMessage.success("修改成功");
              context.emit("updateImgUrl", response.imgUrl);
            } else {
              ElMessage.error(response.msg);
            }
            cropper.value.clearCrop();
          });
        });
      }
    });
    state.imgUrl = state.imgUrl2;
    console.log("图片地址", store.getters.avatar);
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      cropper,
      options,
      Plus,
      UploadFilled,
      Minus,
      RefreshLeft,
      RefreshRight
    };
  }
});