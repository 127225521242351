import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, resolveDynamicComponent as _resolveDynamicComponent, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7225ec36"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "flex-box",
  style: {
    "height": "calc(100vh - 78px)"
  }
};
const _hoisted_2 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Headbar = _resolveComponent("Headbar");
  const _component_sidebar = _resolveComponent("sidebar");
  const _component_tags_view = _resolveComponent("tags-view");
  const _component_settings = _resolveComponent("settings");
  const _component_right_panel = _resolveComponent("right-panel");
  const _component_app_main = _resolveComponent("app-main");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass([$options.classObj, "app-wrapper"])
  }, [_createVNode(_component_Headbar), _createElementVNode("div", _hoisted_1, [$options.showSideBar ? (_openBlock(), _createBlock(_component_sidebar, {
    key: 0,
    class: "sidebar-container"
  })) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(["main-container flex-item_f-1", {
      hasTagsView: _ctx.needTagsView,
      noleftMenu: _ctx.$store.state.common.menuPositionSet === '1' || _ctx.$route.path === '/index'
    }]),
    style: _normalizeStyle(this.$route.path === '/index' ? 'background-color:#0B1919;background-size: 20px 20px;' : '')
  }, [this.$route.path != '/index' ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass({
      'fixed-header': _ctx.fixedHeader
    })
  }, [_ctx.needTagsView && !$options.isGLHome ? (_openBlock(), _createBlock(_component_tags_view, {
    key: 0
  })) : _createCommentVNode("", true)], 2)) : _createCommentVNode("", true), _ctx.showSettings ? (_openBlock(), _createBlock(_component_right_panel, {
    key: 1
  }, {
    default: _withCtx(() => [_createVNode(_component_settings)]),
    _: 1
  })) : _createCommentVNode("", true), $options.currentIframe ? (_openBlock(), _createElementBlock("iframe", {
    key: 2,
    allowfullscreen: "true",
    ref: "iframe",
    style: _normalizeStyle($options.iframeStyle),
    src: $options.currentIframe.src
  }, null, 12, _hoisted_2)) : $props.container && !$props.isNotQiankun ? (_openBlock(), _createBlock(_resolveDynamicComponent($props.container), {
    key: 3,
    class: "layout__container WH"
  })) : (_openBlock(), _createBlock(_component_app_main, {
    key: 4
  }))], 6)])], 2);
}