import { defineComponent, computed, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { linkActiveHandle } from '@/utils/index';
export default defineComponent({
  name: 'SubItem',
  props: {
    data: {
      type: Object,
      required: true
    },
    path: {
      type: String
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();

    // const data = computed(() => {
    //   if (props.data.children) {
    //     //详情页面不在菜单展示
    //     let filterChildren = props.data.children.filter(
    //       (item) => item.meta.menuType != "D"
    //     );
    //     return { ...props.data, children: filterChildren };
    //   } else {
    //     return props.data;
    //   }
    // });
    /**
     * 菜单点击事件
     * @param name 路由名称
     */
    const clickHandle = () => {
      // switch (props.data.type) {
      //   case 4: // 外链
      //     window.open(props.data.url);
      //     break;
      //   case 1: // 菜单
      //   case 3: // iframe
      //     router.push({ name: props.data.name });
      //     break;
      // }
      console.log('path----sss-', props.data);
      let query = '';
      const queryIndex = props.data.name.indexOf('?');
      if (queryIndex > -1) {
        const s = props.data.name.substring(queryIndex + 1, props.data.name.length);
        const arr = s.split('&');
        console.log('arr', arr);
        const queryObject = {};
        arr.forEach(item => {
          const p = item.split('=');
          const key = p[0];
          const val = p[1];
          queryObject[key] = val;
        });
        query = queryObject;
      }
      if (props.data.meta.link) {
        // 打开窗口
        store.commit('menu/setLink', true);
        if (props.data.meta.link.startsWith('http')) {
          window.open(props.data.meta.link);
        } else {
          router.push(props.data.meta.link);
        }
      } else {
        if (query) {
          router.push({
            path: props.path,
            query
          });
        } else {
          router.push({
            path: props.path
          });
        }
      }

      // router.push({ path: props.path });
    };
    return {
      clickHandle,
      data: props.data,
      linkActiveHandle
    };
  }
});