


import { request, algorithmRequest } from "@/utils/request";

//算法图标文件删除
export function iconDelete(icon) {
  return request({
    url: "/trainManage/problem/icon/delete/" + icon,
    method: "post"
  });
}

//获取算法图标base64编码
export function iconBase64(icon) {
  return request({
    url: "/trainManage/problem/icon/base64/" + icon,
    method: "get"
  });
}
// 算法新增
export function problemAdd(data) {
  return request({
    url: "/trainManage/problem/add",
    method: "post",
    data
  });
}

// 算法类别下拉列表查询
export function classifyList() {
  return request({
    url: "/trainManage/problem/classifyList",
    method: "get"
  });
}
//算法删除
export function problemDelete(problemId) {
  return request({
    url: "/trainManage/problem/delete/" + problemId,
    method: "post"
  });
}
//算法修改
export function problemUpdate(data) {
  return request({
    url: "/trainManage/problem/update",
    method: "post",
    data
  });
}
//算法详情
export function problemDetails(problemId) {
  return request({
    url: "/trainManage/problem/" + problemId,
    method: "get"
  });
}
//算法列表查询
export function problemList(data) {
  return request({
    url: "/trainManage/problem/list",
    method: "post",
    data
  });
}

//查询算法下的模型列表
export function childModel(data) {
  return request({
    url: "/trainManage/problem/childModel",
    method: "post",
    data
  });
}

//模型列表查询
export function modelList(data) {
  return request({
    url: "/trainManage/model/list",
    method: "post",
    data
  });
}
// 模型新增-应用算法下拉列表查询
export function itemList() {
  return request({
    url: "/trainManage/problem/item/list",
    method: "get"
  });
}
// 模型新增
export function modelAdd(data) {
  return request({
    url: "/trainManage/model/add",
    method: "post",
    data
  });
}
// 模型编辑
export function modelUpdate(data) {
  return request({
    url: "/trainManage/model/update",
    method: "post",
    data
  });
}
// 模型删除
export function modelDelete(modelId) {
  return request({
    url: "/trainManage/model/delete/" + modelId,
    method: "post"
  });
}
// 模型详细
export function modelDetails(modelId) {
  return request({
    url: "/trainManage/model/" + modelId,
    method: "get"
  });
}
// 模型下训练出的子模型列表查询
export function versionList(data) {
  return request({
    url: "/trainManage/model/version/list",
    method: "post",
    data
  });
}
// 模型发布
export function modelDeploy(modelId) {
  return request({
    url: "/trainManage/model/deploy/" + modelId,
    method: "post"
  });
}

let moduleName = '/dataset' // /dataset

//数据集列表查询
export function datasetList(query) { 
  return request({
    url: `${moduleName}/datasets/`,
    method: "get",
    params: query
  });
}

//模型训练
export function modelTrain(data) {
  return request({
    url: "/modelTrain/train/train",
    method: "post",
    data
  });
}
//新增数据集
export function datasetAdd(data) {
  return request({
    url: `${moduleName}/datasets/`,
    method: "post",
    data
  });
}
//修改数据集
export function datasetEdit(id,data) {
  return request({
    url: `${moduleName}/datasets/${id}/`,
    method: "put",
    data
  });
}
//远程上传文件
export function remoteFile(id,data) {
  return request({
    url: `${moduleName}/datasets/${id}/data/`,
    method: "post",
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data
  });
}
//查看数据集详情
export function getDatasetDetail(id) {
  return request({
    url: `${moduleName}/datasets/${id}/`,
    method: "get",
  });
}
//获取数据集详情下图片
export function getAnnotation(id,query){
  return request({
    url: `${moduleName}/datasets/${id}/data/`,
    method: "get",
    params:query
  });
}
//删除数据集下的图片
export function delAnnotation(id,data) {
  return request({
    url: `${moduleName}/datasets/${id}/data/`,
    method: "delete",
    data
    
  });
}

//删除数据集
export function datasetDelete(id) {
  return request({
    url: "/dataset/datasets/" + id + "/",
    method: "delete",
  });
}
//算法列表查询
export function algorithmList() {
  return algorithmRequest({
    url: "/list",
    method: "get",
  });
}

//模糊推理算法
export function fuzzyReasoning() {
  return algorithmRequest({
    url: "/fuzzyReasoning",
    method: "get",
  });
}
//多叉决策树
export function multiForkDecision() {
  return algorithmRequest({
    url: "/multiForkDecision",
    method: "get",
  });
}
//逻辑回归分析
export function logisticRegression() {
  return algorithmRequest({
    url: "/logisticRegression",
    method: "get",
  });
}
//支持向量机
export function svm() {
  return algorithmRequest({
    url: "/svm",
    method: "get",
  });
}
//贝叶斯网络因果推理算法
export function bayesInference() {
  return algorithmRequest({
    url: "/bayesInference",
    method: "get",
  });
}
//线性回归分析
export function linearRegression() {
  return algorithmRequest({
    url: "/linearRegression",
    method: "get",
  });
}
//网架潮流模型算法 
export function loadFlowNetworkTrafo() {
  return algorithmRequest({
    url: "/loadFlowNetworkTrafo",
    method: "get",
  });
}