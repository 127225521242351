import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-78b4a6ec"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "top-container"
};
const _hoisted_3 = {
  class: "search-left"
};
const _hoisted_4 = {
  class: "footer_select"
};
import { ref, onMounted, watch } from "vue";
import { ElForm, ElMessage } from "element-plus";
import request from '../../utils/onlineRequest';
export default {
  __name: 'index',
  props: {
    url: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Object,
      default: () => {
        return {};
      }
    },
    placeholder: {
      type: String,
      default: 'Click on the right side to select'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    // tab页内容
    tabsList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    noTabsListTip: {
      type: String,
      default: 'data error！'
    },
    tableHeader: {
      type: Array,
      default: () => {
        return [];
      }
    },
    showSearch: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue'],
  setup(__props, {
    emit
  }) {
    const props = __props;
    const dialogFormVisible = ref(false);
    const loading = ref(false);
    const inputStr = ref('');
    const table = ref(null);
    const selectionRows = ref([]);
    const selectionKeys = ref([]);
    const tableData = ref(null);
    const activeName = ref('');
    const formData = ref([]);
    const selectValue = ref({});
    const cloneValue = ref({});
    const formHandle = [{
      label: "Search",
      type: "primary",
      icon: "el-icon-search",
      handle: () => getList()
    }, {
      label: "Reset",
      icon: "el-icon-refresh",
      class: "commonDarkBtn",
      handle: () => resetQuery()
    }];
    const queryParams = ref({
      pageNum: 1,
      pageSize: 5
    });
    const getList = function (flag) {
      if (!flag) {
        activeName.value = props.tabsList?.length ? props.tabsList[0].name : '';
      }
      let currentTab = {};
      for (let item of props.tabsList) {
        if (item.name === activeName.value) {
          currentTab = item;
        }
      }
      if (!currentTab.url) {
        tableData.value = {
          rows: [],
          total: 0
        };
        return;
      }
      loading.value = true;
      request({
        url: currentTab.url,
        method: 'post',
        data: queryParams.value
      }).then(res => {
        debugger;
        tableData.value = res.data;
        const keys = [];
        for (let key in selectValue.value) {
          if (key === activeName.value) {
            for (let select of selectValue.value[key]) {
              keys.push(select.psrId);
            }
          }
        }
        selectionKeys.value = keys;
      }).catch(err => {}).finally(() => {
        loading.value = false;
      });
    };
    const open = function () {
      debugger;
      if (!props.tabsList?.length) {
        ElMessage.closeAll();
        ElMessage.warning(props.noTabsListTip);
        return;
      }
      cloneValue.value = JSON.parse(JSON.stringify(props.modelValue));
      selectValue.value = props.modelValue;
      dialogFormVisible.value = true;
      resetQuery();
    };
    const cancel = function () {
      emit("update:modelValue", cloneValue.value);
      dialogFormVisible.value = false;
    };
    const confirm = function () {
      dialogFormVisible.value = false;
      let labelStr = '';
      for (let key in selectValue.value) {
        for (let item of selectValue.value[key]) {
          if (labelStr) {
            labelStr += `,${item.name}`;
          } else {
            labelStr += item.name;
          }
        }
      }
      inputStr.value = labelStr;
      emit("update:modelValue", selectValue);
    };
    const handleSelect = function (selection, row) {
      if (selectValue.value[activeName.value]?.length) {
        // 判断是为选中还是去勾选
        const isSelect = selection.some(item => item.psrId === row.psrId);
        if (isSelect) {
          const ids = [];
          for (let item of selectValue.value[activeName.value]) {
            ids.push(item.psrId);
          }
          for (let i = 0; i < selection.length; i += 1) {
            if (!ids.includes(selection[i].psrId)) {
              selectValue.value[activeName.value].push(selection[i]);
            }
          }
        } else {
          const index = selectValue.value[activeName.value].findIndex(item => item.psrId === row.psrId);
          selectValue.value[activeName.value].splice(index, 1);
        }
      } else {
        selectValue.value[activeName.value] = selection;
      }
      console.log(selectValue.value);
    };
    // 标识是否是tabschange触发，此触发，不需要给定默认activename
    const resetQuery = function (flag) {
      queryParams.value = {
        pageNum: 1,
        pageSize: 5
      };
      formData.value.forEach(item => {
        queryParams.value[item.prop] = '';
      });
      getList(flag);
    };
    const handleChange = function (val) {
      activeName.value = val;
      resetQuery(true);
    };
    // 外部变更绑定的值，清空内部
    watch(() => props.modelValue, newVal => {
      debugger;
      let labelStr = '';
      for (let key in newVal) {
        for (let item of newVal[key]) {
          if (labelStr) {
            labelStr += `,${item.name}`;
          } else {
            labelStr += item.name;
          }
        }
      }
      inputStr.value = labelStr;
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      const _component_commonForm = _resolveComponent("commonForm");
      const _component_commonTable = _resolveComponent("commonTable");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
        disabled: "",
        modelValue: inputStr.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => inputStr.value = $event),
        formatter: value => `${value}`.replace(/(^\s*)|(\s*$)/g, ''),
        placeholder: __props.placeholder,
        class: "input-with-select"
      }, {
        append: _withCtx(() => [_createVNode(_component_el_button, {
          class: "btn",
          disabled: __props.disabled,
          onClick: open
        }, {
          default: _withCtx(() => [_createTextVNode("Select")]),
          _: 1
        }, 8, ["disabled"])]),
        _: 1
      }, 8, ["modelValue", "formatter", "placeholder"]), _createVNode(_component_el_dialog, {
        "append-to-body": true,
        "close-on-click-modal": false,
        modelValue: dialogFormVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dialogFormVisible.value = $event),
        title: "Select Data",
        class: "app-container"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tabs, {
          modelValue: activeName.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => activeName.value = $event),
          class: "table-tabs",
          onTabChange: handleChange
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tabsList, item => {
            return _openBlock(), _createBlock(_component_el_tab_pane, {
              label: item.label,
              name: item.name,
              key: item.name
            }, null, 8, ["label", "name"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"]), __props.showSearch ? (_openBlock(), _createBlock(_component_commonForm, {
          key: 0,
          form: queryParams.value,
          formData: formData.value,
          formHandle: formHandle,
          showLabel: false,
          style: {
            "margin-bottom": "20px"
          }
        }, null, 8, ["form", "formData"])) : _createCommentVNode("", true)])]), _withDirectives(_createVNode(_component_commonTable, {
          class: "common_table",
          hasSelect: __props.multiple,
          radioSelect: !__props.multiple,
          columnSetting: false,
          tableData: tableData.value,
          tableHeader: __props.tableHeader,
          queryParams: queryParams.value,
          ref_key: "table",
          ref: table,
          "row-key": "psrId",
          selectionKeys: selectionKeys.value,
          onGetList: getList,
          onSelect: handleSelect
        }, null, 8, ["hasSelect", "radioSelect", "tableData", "tableHeader", "queryParams", "selectionKeys"]), [[_directive_loading, loading.value]]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("Cancel")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: confirm
        }, {
          default: _withCtx(() => [_createTextVNode("Confirm")]),
          _: 1
        })])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};