import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
import { reactive, onMounted, watch, computed, nextTick, ref } from 'vue';
import { useRoute, onBeforeRouteLeave } from 'vue-router';
import { getRequest } from '@/api/designForm';
import { ElMessage } from 'element-plus';
import { string2json, stringToObj } from '@/utils/form';
// import { useLayoutStore } from '@/store/layout'
// const layoutStore = useLayoutStore()
// layoutStore.changeBreadcrumb([{ label: '内容管理' }, { label: '表单管理' }])

export default {
  __name: 'content',
  setup(__props) {
    const route = useRoute();
    // url参数说明：
    // id 获取当前设计的列表字段，查询条件等配置
    // form 跳转到新增编辑，列表数据加载
    const listEl = ref();
    const state = reactive({
      searchData: [],
      tableData: [],
      childHead: [],
      dict: {},
      visible: true,
      source: ''
    });
    /*const formId = computed(() => {
      return route.query.form
    })*/
    const listId = computed(() => {
      return route.query.id;
    });
    // 根据当前设计的表单id获取使用了此id设计的列表
    const initList = () => {
      if (!listId.value) {
        //  ElMessage.error('非法操作...')
        return false;
      }
      const params = {
        id: listId.value
      };
      getRequest('designById', params).then(res => {
        const result = res.data;
        if (result && Object.keys(result).length) {
          state.searchData = stringToObj(result.data);
          state.tableData = stringToObj(result.listData);
          state.childHead = result.childHead ? result.childHead : [];
          console.log('tableData: ', state.tableData);
          state.dict = string2json(result.dict);
          state.source = result.source;
          // 获取列表数据
          nextTick(() => {
            listEl.value.getListData();
          });
          getFormInit();
        }
      });
    };
    onBeforeRouteLeave(() => {
      unwatch(); // 停止监听
    });
    // 路由改变时需要重新加数据
    const unwatch = watch(() => route.query.id, () => {
      initList();
    }, {
      //deep: true
    });
    /*****弹窗口相关****/
    const formEl = ref();
    const dialog = reactive({
      visible: false,
      title: '',
      formType: 1,
      formData: {},
      width: '',
      dict: {},
      editId: ''
    });
    // 是否满足打开弹窗条件
    const canOpenDialog = computed(() => {
      return state.source && state.tableData.config?.openType === 'dialog';
    });
    // 为弹窗方式打开时，加载表单
    const getFormInit = () => {
      if (canOpenDialog.value) {
        dialog.width = state.tableData.config?.dialogWidth; // 弹口宽
        getRequest('designById', {
          id: state.source
        }).then(res => {
          const result = res.data;
          if (result && Object.keys(result).length) {
            dialog.formData = stringToObj(result.data);
            console.log(2222, dialog.formData);
            dialog.formData.list.forEach(item => {
              if (item.type === 'table') {
                subFormData.value.list.unshift(...item.list);
              }
            });
            dialog.dict = string2json(result.dict);
          }
        }).catch(res => {
          ElMessage.error(res.message || '非法操作.');
        });
      }
    };
    const listBtnClick = (btn, row) => {
      // 选择了数据源和设置了弹窗方式打开时
      if ((btn.key === 'add' || btn.key === 'edit') && canOpenDialog.value) {
        // 打开弹窗
        dialog.visible = true;
        dialog.title = btn.key === 'add' ? '新增' : '编辑';
        dialog.formType = btn.key === 'add' ? 1 : 2;
        dialog.editId = row && row.id;
        if (btn.key === 'add' && dialog.formData.config?.addLoad) {
          // 添加时需要加载数据
          nextTick(() => {
            formEl.value.getData({
              formId: state.source
            });
          });
        }
        // 编辑，根据id加载
        if (btn.key === 'edit') {
          nextTick(() => {
            formEl.value.getData({
              formId: state.source,
              id: row.id
            }, row);
          });
        }
      }
    };
    // 子表逻辑
    const subFormRef = ref('');
    const subDialog = ref(false);
    let subEditId = ref('');
    const subFormData = ref({
      form: {
        size: 'default'
      },
      list: [{
        type: "div",
        control: {},
        config: {
          textAlign: "center"
        },
        list: [{
          type: "button",
          control: {
            label: "保存",
            type: "primary",
            key: "submit"
          },
          config: {
            span: 0
          }
        }, {
          type: "button",
          control: {
            label: "取消",
            key: "reset"
          },
          config: {
            span: 0
          }
        }]
      }]
    });
    const subEdit = row => {
      subEditId.value = row && row.id;
      subDialog.value = true;
      nextTick(() => {
        subFormRef.value.getData({
          formId: state.source,
          id: row.id,
          subTable: true
        });
      });
    };
    const beforeSubmitChild = params => {
      params.formId = state.source;
      params.id = subEditId.value;
      params.subTable = true;
      return params;
    };
    const afterSubmitChild = type => {
      if (type === 'success') {
        //　添加成功，刷新列表数据
        subDialog.value = false;
        listEl.value.getListData();
      }
    };

    //

    const beforeSubmit = params => {
      params.formId = state.source;
      params.id = dialog.editId;
      return params;
    };
    const afterSubmit = type => {
      if (type === 'success') {
        //　添加成功，刷新列表数据
        closeResetDialog();
        listEl.value.getListData();
      }
    };
    const dialogBtnClick = type => {
      if (type === 'reset' || type === 'cancel') {
        closeResetDialog();
      }
    };
    const beforeClose = done => {
      closeResetDialog();
      done && done();
    };
    // 关闭弹窗并重置表单
    const closeResetDialog = () => {
      dialog.visible = false;
      dialog.editId = '';
      // formEl.value.resetFields() // 重置表单
    };
    /*****弹窗口相关结束****/
    onMounted(() => {
      initList();
    });
    return (_ctx, _cache) => {
      const _component_ak_list = _resolveComponent("ak-list");
      const _component_ak_form = _resolveComponent("ak-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", null, [state.visible ? (_openBlock(), _createBlock(_component_ak_list, {
        key: 0,
        ref_key: "listEl",
        ref: listEl,
        searchData: state.searchData,
        tableData: state.tableData,
        dict: state.dict,
        formId: state.source,
        childHead: state.childHead,
        requestUrl: "getContentList",
        deleteUrl: "delFormContent",
        autoLoad: false,
        onBtnClick: listBtnClick,
        onSubEdit: subEdit
      }, null, 8, ["searchData", "tableData", "dict", "formId", "childHead"])) : _createCommentVNode("", true), _unref(canOpenDialog) ? (_openBlock(), _createBlock(_component_el_dialog, {
        key: 1,
        "destroy-on-close": "",
        modelValue: dialog.visible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialog.visible = $event),
        "close-on-click-modal": false,
        title: dialog.title,
        width: dialog.width || '600px',
        "before-close": beforeClose
      }, {
        default: _withCtx(() => [_createVNode(_component_ak_form, {
          ref_key: "formEl",
          ref: formEl,
          formData: dialog.formData,
          dict: dialog.dict,
          type: dialog.formType,
          requestUrl: "getFormContent",
          addUrl: "saveFormContent",
          editUrl: "editFormContent",
          beforeSubmit: beforeSubmit,
          afterSubmit: afterSubmit,
          onBtnClick: dialogBtnClick
        }, null, 8, ["formData", "dict", "type"])]),
        _: 1
      }, 8, ["modelValue", "title", "width"])) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
        modelValue: subDialog.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => subDialog.value = $event),
        title: "编辑",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [_createVNode(_component_ak_form, {
          ref_key: "subFormRef",
          ref: subFormRef,
          dict: dialog.dict,
          type: 2,
          formData: subFormData.value,
          requestUrl: "getFormContent",
          addUrl: "saveFormContent",
          editUrl: "editFormContent",
          afterSubmit: afterSubmitChild,
          beforeSubmit: beforeSubmitChild,
          onBtnClick: _cache[1] || (_cache[1] = $event => subDialog.value = false)
        }, null, 8, ["dict", "formData"])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};