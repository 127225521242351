import { onMounted, reactive, toRefs, ref, watch, nextTick, computed } from "vue";
import { Setting, ArrowDown } from "@element-plus/icons-vue";
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from "element-plus";
import { useRouter } from "vue-router";
import Sortable from "sortablejs";
import { deepClone } from "@/utils/index.js";
import { addDataStore, getStoreData, updateStoreData } from "@/utils/indexDB";
import { conformsTo } from "lodash";
export default {
  name: "commonTable",
  components: {
    Setting,
    ArrowDown,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem
  },
  props: {
    //是否是单选
    radioSelect: {
      type: Boolean,
      default: false
    },
    selectionKeys: {
      type: Array,
      default: []
    },
    //行内编辑状态表单组件的大小
    size: {
      type: String,
      default: "small"
    },
    selectable: {
      type: Function,
      default: function () {
        return true;
      }
    },
    rowKey: {
      type: String,
      default: "id"
    },
    isLazy: {
      type: Boolean,
      default: false
    },
    load: {
      type: Function,
      default: () => function () {}
    },
    // 是否可设置动态列
    dynamicColumnSetting: {
      type: Boolean,
      default: false
    },
    //是否需要隔行换色
    hasStripe: {
      type: Boolean,
      default: true
    },
    //表格内容数据
    tableData: {
      type: Object,
      default: () => {}
    },
    spanMethod: {
      type: Function,
      default: () => function () {}
    },
    cellStyle: {
      type: Function,
      default: () => function () {}
    },
    cellClass: {
      type: Function,
      default: () => function () {}
    },
    // 显示的表头数据
    tableHeader: {
      type: Array,
      default: () => []
    },
    // 原始表头数据
    tableOrgingHeader: {
      type: Array,
      default: () => []
    },
    // 复选框
    hasSelect: {
      type: Boolean,
      default: true
    },
    // 边框
    hasBorder: {
      type: Boolean,
      default: true
    },
    //多选数据
    multipleSelection: {
      type: Array,
      default: () => []
    },
    // 列文字显示位置
    textAlign: {
      type: String,
      default: "center"
    },
    // tabled的size
    tableSize: {
      type: String,
      default: "small"
    },
    //文字过长是否展示省略号
    isTooltip: {
      type: Boolean,
      default: false
    },
    // 查询参数集合
    queryParams: {
      type: Object,
      default: () => {
        return {
          pageNum: 1,
          pageSize: 10
        };
      }
    },
    // 是否显示分页
    showPagination: {
      type: Boolean,
      default: true
    },
    // 是否显示表头动态列控制
    columnSetting: {
      type: Boolean,
      default: false
    },
    tableHeaderName: {
      type: String,
      default: ""
    },
    // 判断是否需要监听表头变动，statuslist动态添加需要此为true
    needWatchHeader: {
      type: Boolean,
      default: false
    },
    // 判断是否在全选时，模拟触发select事件 目前只有dialog-select需要
    selectAllNeedSelect: {
      type: Boolean,
      default: false
    },
    //分页步长
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 20, 30, 50, 100];
      }
    },
    //拖拽排序
    dragSort: {
      type: Boolean,
      default: false
    }
  },
  emits: ["changeTableheader", "getList", "update:multipleSelection", "selection-change", "select-all", "select", "sort-events", "radio-change"],
  setup(props, context) {
    const router = useRouter();
    const settingColumns = ref([]);
    const editCell = ref("");
    const multipleTable = ref();
    const state = reactive({
      baseUrl: `${window.location.protocol}//${window.location.host}`,
      tableLoading: false,
      columns: [],
      showSetContainer: false
    });
    const tableHeight = ref(500);
    nextTick(() => {
      methods.getTableHeight();
    });
    console.log('数据', state.baseUrl);
    let currentRef;
    const setItemRef = el => {
      currentRef = el;
    };
    const methods = reactive({
      getTableHeight() {
        let topContainerHeight = document.getElementsByClassName("top-container")[0] ? document.getElementsByClassName("top-container")[0].clientHeight + 20 : 0;
        let fixedHeaderHeight = document.getElementsByClassName("fixed-header")[0] ? document.getElementsByClassName("fixed-header")[0].clientHeight + 15 : 0;
        let headbarContainerHeight = document.getElementsByClassName("headbar-container")[0] ? document.getElementsByClassName("headbar-container")[0].clientHeight : 0;
        let paginationContainerHeight = document.getElementsByClassName("pagination-container")[0] ? document.getElementsByClassName("pagination-container")[0].clientHeight : 0;
        let analysisContainerHeight = document.getElementsByClassName("analysis_container")[0] ? document.getElementsByClassName("analysis_container")[0].clientHeight : 0;
        let elTabsHeaderHeight = document.getElementsByClassName("el-tabs__header")[0] ? document.getElementsByClassName("el-tabs__header")[0].clientHeight + 15 : 0;
        let tabelTopCount = document.getElementsByClassName("tabel-top-count")[0] ? document.getElementsByClassName("tabel-top-count")[0].clientHeight : 0;
        tableHeight.value = window.innerHeight - topContainerHeight - fixedHeaderHeight - headbarContainerHeight - paginationContainerHeight - analysisContainerHeight - elTabsHeaderHeight - tabelTopCount - 26 - 80;
      },
      getSelectionRows() {
        return multipleTable.value.getSelectionRows();
      },
      goDetail(nav, row) {
        let query = {
          path: nav.query.path
        };
        for (const [key, value] of Object.entries(nav.query)) {
          if (key !== "path") {
            if (value == "row") {
              query[key] = JSON.stringify(row);
            } else {
              query[key] = row[value] || value;
            }
          }
        }
        router.push({
          path: nav.path,
          query
        });
      },
      initColumns() {
        let key = router.currentRoute.value.name + ":colsettings";
        let colSettings = JSON.parse(localStorage.getItem(key));
        if (colSettings == null || colSettings == undefined) {
          let allSettingColumns = [];
          props.tableOrgingHeader.forEach(function (item, i, array) {
            allSettingColumns.push(item.prop);
          });
          settingColumns.value = allSettingColumns;
          state.columns = {
            ...props.tableOrgingHeader
          };
        } else {
          settingColumns.value = colSettings;
          const cols = props.tableOrgingHeader.filter(item => {
            if (item.prop == "operation") {
              return true;
            }
            if (colSettings.includes(item.prop)) {
              return true;
            }
            return false;
          });
          state.columns = cols;
        }
      },
      //列设置更改事件
      onColSettingsChange(checkedValues) {
        console.log(checkedValues, "checkedValues==");
        let key = router.currentRoute.value.name + ":colsettings";
        localStorage.setItem(key, JSON.stringify(checkedValues));
        settingColumns.value = checkedValues;
        const cols = props.tableOrgingHeader.filter(item => {
          if (settingColumns.value.includes(item.prop)) {
            return true;
          }
          return false;
        });
        state.columns = cols;
        context.emit("changeTableheader", state.columns);
      },
      getList(params) {
        props.queryParams.pageNum = params.pageNum;
        props.queryParams.pageSize = params.pageSize;
        context.emit("getList", props.queryParams);
      },
      handleSelect(selection, row) {
        context.emit("select", selection, row);
      },
      handleSelectionChange(val) {
        context.emit("update:multipleSelection", val);
        context.emit("selection-change", val);
      },
      handleSelectionAll(val) {
        context.emit("update:multipleSelection", val);
        context.emit("select-all", val);
        // bug 12617 N-功能测试-终端运行管理-点表模板-新增，点号选择页面，建议增加全选勾选框 qz 2022/7/20
        if (props.selectAllNeedSelect && val?.length) {
          for (let item of val) {
            context.emit("select", val, item);
          }
        } else {
          for (let item of props.tableData.rows) {
            context.emit("select", [], item);
          }
        }
      },
      handleCellBlur() {
        editCell.value = "";
      },
      hanldeDbclick(row, column, cell) {
        console.log("currentRef", currentRef);
        // currentRef && currentRef.target && currentRef.target.focus()
        editCell.value = column.property + '-' + row[props.rowKey];
      },
      handleSort(sort) {
        context.emit("sort-events", sort);
      },
      handleRadioChange(row) {
        context.emit("radio-change", row);
      }
    });
    onMounted(() => {
      methods.getList({
        pageSize: props.queryParams.pageSize,
        pageNum: props.queryParams.pageNum
      });

      //  methods.initColumns();
    });
    const formatters = (val, format) => {
      if (typeof format === "function") {
        return format(val);
      } else return val;
    };
    const setStatus = (row, list, key, multiple, type) => {
      let temp = "";
      if (multiple) {
        if (row[key]) {
          const valueArray = row[key].split(",");
          for (let i = 0; i < valueArray.length; i += 1) {
            for (let item of list) {
              if (valueArray[i] === item.value) {
                if (temp) {
                  temp += `,${item.label}`;
                } else {
                  temp += `${item.label}`;
                }
                break;
              }
            }
          }
        }
      } else {
        for (let item of list) {
          if (item.value === row[key]) {
            if (type === "color") {
              temp = `background:${item.color}`;
            } else {
              temp = item.label;
            }
            break;
          }
        }
      }
      return temp;
    };
    watch(() => [props.selectionKeys, props.tableData], ([newSelectionKeys, newTableData]) => {
      if (!newSelectionKeys.length || !newTableData) return;
      nextTick(() => {
        multipleTable.value.clearSelection();
        newSelectionKeys.forEach(key => {
          newTableData.rows.forEach(row => {
            if (row[props.rowKey] == key) {
              multipleTable.value.toggleRowSelection(row, true);
            }
          });
        });
      });
    }, {
      immediate: true
    });
    let currentRadio = computed(() => {
      return props.selectionKeys[0] || "";
    });

    /**
     * @description 处理动态表头逻辑
     * @param   复制内容
     * @param tableHeader 表头数据
     * @param checkTableHeader 已选表头数据
     * @param noCheckTableHeader 未选表头数据
     * @author xuqiang
     * @date 2022/4/27
     */
    let checkTableHeader = ref({});
    let filterData = ref(null);
    let indexDBParse = (data, handleData = ["clickFun", "disabledHandle"]) => {
      let operationsItem = props.tableHeader.filter(item => {
        return item.prop === "operation";
      });
      data.forEach(item => {
        if (item.prop == "operation" && operationsItem[0]) {
          item.operation = operationsItem[0].operation;
        }
      });
      checkTableHeader.value = data;
      filterData.value = deepClone(checkTableHeader.value);
    };

    // 函数转换字符串处理函数
    let stringDataHandle = () => {
      let addData = deepClone(checkTableHeader.value);
      addData.forEach(item => {
        if (item.prop == "operation") {
          item.operation = [];
        }
      });
      return addData;
    };
    console.log(props.tableHeader, "header");
    // let indexDBRes = getStoreData("kuitun", "/list3");
    if (props.columnSetting) {
      // 有表头操作列设置功能
      getStoreData("kuitun", props.tableHeaderName).then(indexDBRes => {
        //存储过数据
        if (indexDBRes) {
          console.log("indexDBRes", indexDBRes);
          indexDBParse(indexDBRes.data);
          console.log("checkTableHeader", checkTableHeader);
        } else {
          //第一次存储
          checkTableHeader.value = props.tableHeader;
          checkTableHeader.value.forEach(item => {
            //不进行表头操作列设置功能，通过传isShow判断是否隐藏
            if (item.isShow == false) {
              item.isShow = false;
            } else {
              item.isShow = true;
            }
            item.noDrag = false;
          });
          filterData.value = deepClone(checkTableHeader.value);
          let addData = stringDataHandle();
          console.log("addData", addData);

          //添加本地数据库
          addDataStore("kuitun", [{
            url: props.tableHeaderName,
            data: addData
          }]);
        }
      });
    } else {
      // 无表头操作列设置功能
      checkTableHeader.value = props.tableHeader;
      checkTableHeader.value.forEach(item => {
        //不进行表头操作列设置功能，通过传isShow判断是否隐藏
        if (item.isShow == false) {
          item.isShow = false;
        } else {
          item.isShow = true;
        }
      });
    }
    //操作列设置成不可拖拽
    //  filterData.value[filterData.value.length - 1].noDrag = true;
    //过滤

    let filterVal = ref("");
    let filterClearHandle = () => {
      filterData.value = checkTableHeader.value;
      filterData.value.forEach(item => {
        nextTick(() => {
          item.noDrag = false;
        });
      });

      // filterData.value[filterData.value.length - 1].noDrag = true;
      console.log(filterData.value);
    };
    let filterInputHandle = () => {
      if (filterVal.value === "") {
        filterClearHandle();
      } else {
        filterData.value = checkTableHeader.value.filter(item => {
          if (item.label.includes(filterVal.value)) {
            item.noDrag = true;
            return true;
          } else {
            return false;
          }
        });
      }
    };

    //显示隐藏
    let checkboxChange = (isShow, prop) => {
      console.log(isShow, prop);
      for (let item of checkTableHeader.value) {
        if (item.prop == prop) {
          item.isShow = isShow;
          break;
        }
      }
      let updateData = stringDataHandle();
      updateStoreData("kuitun", {
        url: props.tableHeaderName,
        data: updateData
      }, props.tableHeaderName);
    };
    watch(() => {
      return props.tableHeader;
    }, (newVal, oldVal) => {
      if (props.needWatchHeader) {
        // 无表头操作列设置功能
        checkTableHeader.value = newVal;
        checkTableHeader.value.forEach(item => {
          item.isShow = true;
        });
      }
    });
    watch(() => {
      return state.showSetContainer;
    }, newVal => {
      if (newVal) {
        nextTick(() => {
          let dom = document.getElementsByClassName("header-container");
          console.log(dom);
          new Sortable(dom[0], {
            group: "drag",
            animation: 150,
            filter: ".ignore-elements",
            //左侧顺序更新
            onUpdate: function (e) {
              console.log("update", e);
              const {
                oldIndex,
                newIndex
              } = e;
              nextTick(() => {
                checkTableHeader.value.splice(newIndex, 0, checkTableHeader.value.splice(oldIndex, 1)[0]);
                let updateData = stringDataHandle();
                updateStoreData("kuitun", {
                  url: props.tableHeaderName,
                  data: updateData
                }, props.tableHeaderName);
              });
              console.log(checkTableHeader.value);
            }
          });
        });
      }
    });

    //
    return {
      ...toRefs(state),
      ...toRefs(methods),
      settingColumns,
      formatters,
      setStatus,
      editCell,
      setItemRef,
      multipleTable,
      currentRadio,
      filterVal,
      filterData,
      filterInputHandle,
      checkTableHeader,
      checkboxChange,
      tableHeight
    };
  }
};