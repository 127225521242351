import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { deepClone, downloadFile } from "@/utils";
import { listType, getType, delType, addType, updateType, exportType } from "@/api/system/dict/type";
export default defineComponent({
  name: "Dict",
  components: {},
  props: {},
  setup(props) {
    const formContainer = ref(ElForm);
    const router = useRouter();
    const state = reactive({
      tableLoading: false,
      saveLoading: false,
      dialogVisible: false,
      title: "添加字典类型",
      multipleSelection: [],
      queryParams: {
        dictName: "",
        dictType: "",
        status: "",
        dateRange: [],
        beginTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10
      },
      searchFormData: [{
        prop: "dictName",
        label: "字典名称",
        placeholder: "请输入字典名称查询",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "dictType",
        label: "字典类型",
        placeholder: "请输入字典类型查询",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        type: "list",
        label: "字典状态",
        placeHolder: "请选择字典状态",
        prop: "status",
        options: [{
          label: "正常",
          value: "0"
        }, {
          label: "停用",
          value: "1"
        }],
        props: {
          label: "label",
          value: "value"
        },
        isShow: true,
        span: 5
      }, {
        prop: "dateRange",
        label: "时间",
        type: "daterange",
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['system:dict:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => methods.handleAdd(item)
      }, {
        buttonName: "修改",
        hasPermi: ['system:dict:edit'],
        className: 'editBtn',
        buttonIcon: "Edit",
        exp: item => methods.handleUpdate(item)
      }, {
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['system:dict:remove'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => methods.handleDel(item)
      }, {
        buttonName: "导出",
        hasPermi: ['system:dict:export'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => methods.handleExport(item)
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      tableHeader: [{
        prop: "dictId",
        label: "字典编号",
        fixed: false,
        type: "text"
      }, {
        prop: "dictName",
        label: "字典名称",
        fixed: false,
        type: "text"
      }, {
        prop: "dictType",
        label: "字典类型",
        fixed: false,
        type: "text",
        clickHandle: row => {
          toRoute('./data', row.dictType);
        }
      }, {
        prop: "status",
        label: "状态",
        fixed: false,
        statusList: [{
          label: '正常',
          value: "0"
        }, {
          label: '停用',
          value: "1"
        }],
        type: "text"
      }, {
        prop: "remark",
        label: "备注",
        fixed: false,
        type: "text"
      }, {
        prop: "createTime",
        label: "创建时间",
        fixed: false,
        type: "text"
      }, {
        prop: "operation",
        label: "操作",
        width: "400px",
        fixed: 'right',
        operation: [{
          name: "修改",
          clickFun: row => methods.handleUpdate(row),
          hasPermi: ['system:dict:edit']
        }, {
          name: "删除",
          clickFun: row => methods.handleDel(row),
          hasPermi: ['system:dict:remove']
        }]
      }],
      // 表单校验
      formRules: {
        dictName: [{
          required: true,
          message: "字典名称不能为空",
          trigger: "blur"
        }],
        dictType: [{
          required: true,
          message: "字典类型不能为空",
          trigger: "blur"
        }]
      },
      form: {
        dictName: "",
        dictType: "",
        status: "0",
        remark: ""
      },
      formData: [{
        prop: "dictName",
        label: "字典名称",
        placeholder: "请输入字典名称",
        type: "text",
        isSelect: true
      }, {
        prop: "dictType",
        label: "字典类型",
        placeholder: "请输入字典类型",
        type: "text",
        isSelect: true
      }, {
        prop: "status",
        label: '状态',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "0",
          value: '0',
          labelName: '正常'
        }, {
          label: "1",
          labelName: '停用',
          value: '1'
        }],
        span: 12
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea",
        isSelect: true,
        span: 24
      }]
    });
    // 表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const toRoute = function (path, id, type, name) {
      router.push({
        path: path,
        query: {
          dictType: id
        }
      });
    };
    const methods = reactive({
      close() {
        state.form = {
          dictName: "",
          dictType: "",
          status: "0",
          remark: ""
        };
      },
      handleQuery() {
        methods.getList();
      },
      resetQuery() {
        state.queryParams = {
          dictName: "",
          dictType: "",
          status: "",
          dateRange: [],
          beginTime: "",
          endTime: "",
          pageNum: 1,
          pageSize: 10
        }, methods.getList();
      },
      getList() {
        let params = {
          "beginTime": state.queryParams.beginTime,
          "endTime": state.queryParams.endTime,
          "dictName": state.queryParams.dictName,
          "status": state.queryParams.status,
          "dictType": state.queryParams.dictType,
          "pageNum": state.queryParams.pageNum,
          "pageSize": state.queryParams.pageSize
        };
        state.tableLoading = true;
        listType(params).then(response => {
          state.tableData.rows = response.rows;
          state.tableData.total = response.total;
          state.tableLoading = false;
        });
      },
      //新增
      handleAdd() {
        state.dialogVisible = true;
        state.title = "添加字典类型";
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        if (!row.dictId && !state.multipleSelection.length || state.multipleSelection.length > 1) {
          ElMessage.closeAll();
          ElMessage.warning("请选择一条数据修改");
          return;
        }
        if (row.dictId) {
          state.multipleSelection = [];
        }
        state.dialogVisible = true;
        state.title = "修改字典类型";
        let dictId = row.dictId || state.multipleSelection[0].dictId;
        getType(dictId).then(response => {
          state.form = response.data;
        });
      },
      //表单确定
      submitForm: function () {
        const formRef = unref(formContainer);
        formRef.commonForm.validate(valid => {
          if (valid) {
            state.saveLoading = true;
            if (state.title == "修改字典类型") {
              updateType(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("修改成功");
                  state.saveLoading = false;
                  state.dialogVisible = false;
                  methods.getList();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            } else if (state.title == "添加字典类型") {
              // 新增
              addType(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("新增成功");
                  state.dialogVisible = false;
                  state.saveLoading = false;
                  methods.resetQuery();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            }
          }
        });
      },
      //删除
      handleDel(row) {
        if (!row.dictId && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        if (row.dictId) {
          state.multipleSelection = [];
        }
        state.ids = state.multipleSelection.map(item => item.dictId);
        let dictIds = row.dictId || state.ids;
        ElMessageBox.confirm("确定是否删除?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delType(dictIds);
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("删除成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      },
      //导出
      handleExport() {
        let params = {
          pageNum: state.queryParams.pageNum,
          pageSize: state.queryParams.pageSize
        };
        ElMessageBox.confirm('是否确认导出所有岗位数据项?', "导出", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return exportType(params);
        }).then(response => {
          downloadFile(response.message);
        }).catch(err => {
          console.log(err);
        });
      }
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer,
      toRoute
    };
  }
});