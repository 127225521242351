import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRouter } from 'vue-router';
import { ref, reactive, onMounted, nextTick } from 'vue';
import { getRequest } from '@/api/designForm';
import { ElMessage } from 'element-plus';
import frontPagination from '@/components/frontPagination';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const tableListEl = ref();
    const formEl = ref();
    // const dialog = reactive({
    //   visible: false,
    //   title: '关联子表',
    //   type: 1,
    //   id: ''
    // })
    // const formData = reactive({
    //   tableId: '',
    //   parentIdName: ''
    // })

    // const submitForm = () => {
    //   getRequest('/aaaa', formData).then(res => {

    //     ElMessage.success('关联成功')
    //   })
    // }

    const dialog = reactive({
      visible: false,
      title: '',
      type: 1,
      id: ''
    });
    const formData = ref({
      list: [{
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入数据源名称'
        },
        config: {},
        name: 'name',
        item: {
          label: '名称'
        },
        customRules: [{
          type: 'required',
          message: '请输入数据源名称',
          trigger: 'blur'
        }]
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入数据表名'
        },
        config: {
          editDisabled: true
        },
        name: 'tableName',
        item: {
          label: '数据表名'
        },
        customRules: [{
          type: 'required',
          message: '请输入创建生成的数据表名',
          trigger: 'blur'
        }]
      }, {
        type: 'select',
        control: {
          modelValue: 0,
          appendToBody: true
        },
        options: [{
          label: '表单',
          value: 0
        }],
        name: 'category',
        item: {
          label: '分类'
        },
        config: {
          addDisabled: true,
          editDisabled: true
        }
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '数据库表注释'
        },
        config: {
          editDisabled: true
        },
        name: 'remark',
        item: {
          label: '表注释'
        }
      }, {
        type: 'switch',
        control: {
          modelValue: 1,
          activeValue: 1,
          inactiveValue: 0
        },
        config: {},
        name: 'status',
        item: {
          label: '状态'
        }
      }, {
        type: 'title',
        control: {
          modelValue: '数据库表字段'
        },
        config: {
          span: 24
        },
        name: 'title'
      }, {
        type: 'table',
        list: [{
          type: 'input',
          control: {
            modelValue: '',
            size: 'small',
            placeholder: '中文标题名称'
          },
          config: {},
          name: 'label',
          item: {
            label: '标题'
          }
        }, {
          type: 'input',
          control: {
            modelValue: '',
            size: 'small'
          },
          config: {},
          name: 'name',
          item: {
            label: '表名字'
          },
          customRules: [{
            type: 'required',
            message: '名字不能为空',
            trigger: 'blur'
          }, {
            type: 'numberLetter',
            message: '只能为字母数字',
            trigger: 'blur'
          }]
        }, {
          type: 'select',
          control: {
            modelValue: '',
            appendToBody: true,
            size: 'small'
          },
          options: [{
            label: 'INT',
            value: 'INT'
          }, {
            label: 'VARCHAR',
            value: 'VARCHAR'
          }, {
            label: 'TEXT',
            value: 'TEXT'
          }, {
            label: 'DATETIME',
            value: 'DATETIME'
          }, {
            label: 'FLOAT',
            value: 'FLOAT'
          }, {
            label: 'BOOLEAN',
            value: 'BOOLEAN'
          }],
          config: {
            optionsType: 0
          },
          name: 'type',
          item: {
            label: '类型'
          },
          customRules: [{
            type: 'required',
            message: '类型不能为空',
            trigger: 'change'
          }]
        }, {
          type: 'input',
          control: {
            modelValue: '',
            size: 'small'
          },
          config: {},
          name: 'length',
          item: {
            label: '长度/值'
          }
        }, {
          type: 'input',
          control: {
            modelValue: '',
            size: 'small'
          },
          config: {},
          name: 'default',
          item: {
            label: '默认'
          }
        }, {
          type: 'switch',
          control: {
            modelValue: false,
            size: 'small'
          },
          config: {},
          name: 'empty',
          item: {
            label: '空'
          }
        }, {
          type: 'input',
          control: {
            modelValue: '',
            size: 'small'
          },
          config: {},
          name: 'remark',
          item: {
            label: '注释'
          }
        }, {
          type: 'switch',
          control: {
            modelValue: true,
            size: 'small'
          },
          config: {},
          name: 'enterable',
          item: {
            label: '可录入'
          }
        }],
        tableData: [],
        control: {
          border: true
        },
        config: {
          editDisabled: true,
          addBtnText: '添加一行',
          delBtnText: '删除',
          span: 24
        },
        name: 'tableData'
      }, {
        type: 'txt',
        control: {
          modelValue: '提示：默认会添加id自增主键；可录入表示要在表单里作入录入字段，如更新时间这类字段一般为不可录入，其他字段对应数据库字段'
        },
        config: {
          span: 24
        }
      }, {
        type: 'div',
        control: {},
        config: {
          span: 24,
          textAlign: 'center'
        },
        list: [{
          type: 'button',
          control: {
            label: '保存',
            type: 'primary',
            key: 'submit'
          },
          config: {
            span: 0
          }
        }, {
          type: 'button',
          control: {
            label: '取消',
            key: 'reset'
          },
          config: {
            span: 0
          }
        }]
      }],
      form: {
        labelWidth: '80px',
        class: 'form-row-2',
        size: 'default',
        name: 'source'
      }
    });
    const beforeSubmit = params => {
      if (dialog.type === 1) {
        if (!params.tableData.length) {
          ElMessage.error('数据库表字段内容不能为空');
          return false;
        }
        let errorTip = [];
        params.tableData.forEach(item => {
          if (['INT', 'VARCHAR'].includes(item.type) && !item.length) {
            errorTip.push(`名字列${item.name}的长度值不能为空`);
          }
        });
        if (errorTip.length) {
          ElMessage.error(errorTip.join(','));
          return false;
        }
      }
      if (dialog.type === 2) {
        // 添加编辑提交参数
        params.id = dialog.id;
      }
      return params;
    };
    // 提交完成事件
    const afterSubmit = type => {
      if (type === 'success') {
        dialog.visible = false;
        tableListEl.value.getListData();
      }
    };
    // 添加编辑窗口取消
    const cancelClick = type => {
      if (type === 'reset') {
        dialog.visible = false;
      }
    };

    //
    // 筛选表单
    const searchData = ref({
      list: [{
        type: 'input',
        width: '180px',
        control: {
          modelValue: '',
          placeholder: '请输入数据库表名'
        },
        config: {},
        name: 'tableName',
        item: {
          label: '数据表名'
        }
      }, {
        type: 'button',
        control: {
          label: '查询',
          type: 'primary',
          key: 'submit'
        },
        config: {},
        name: ''
      }, {
        type: 'button',
        config: {},
        name: '',
        control: {
          label: '重置',
          key: 'reset'
        }
      }],
      form: {
        size: 'small'
      }
    });
    const tableData = ref({
      columns: [{
        label: '勾选',
        type: 'selection'
      }, {
        prop: 'id',
        label: '编号'
      }, {
        prop: 'name',
        label: '名称'
      }, {
        prop: 'tableName',
        label: '数据表名'
      }, {
        prop: 'updateDate',
        label: '修改时间',
        config: {
          formatter: '{y}-{m}-{d} {h}:{i}:{s}'
        }
      }, {
        label: '操作',
        prop: '__control',
        width: '160px'
      }],
      operateBtn: [
      // {
      //   label: '关联子表',
      //   click: (row) => {
      //     dialog.id = row.id
      //     dialog.visible = true
      //     dialog.title = `主表名称   ${row.tableName}`
      //   }

      // },
      {
        label: '创建表单',
        click: row => {
          router.push({
            path: '/devPlatform/design/form',
            query: {
              source: row.id
            }
          });
        }
      }
      // {
      //   label: '删除',
      //   key: 'del'
      // }
      ],
      controlBtn: [{
        label: '新增',
        type: 'primary',
        size: 'small',
        click: () => {
          dialog.visible = true;
          dialog.title = '创建数据源';
          dialog.type = 1;
          dialog.id = '';
        }
      }],
      config: {
        expand: true
      }
    });
    const dataSourceOption = ref([]);
    const dataSourceList = ref([]);
    const getDataSource = () => {
      // 获取数据源，表单设计才加载，搜索设置不需要

      getRequest('/form-design/customDatasource/getTableByDbId').then(res => {
        const options = res.data.map(item => {
          return {
            value: item.id,
            label: item.tableName,
            id: item.id,
            dbId: item.dbId
          };
        });
        dataSourceOption.value = options;
      });
    };
    const getFormFieldBySource = id => {
      const source = id;
      if (source) {
        getRequest('/form-design/customDatasource/getFieldByTableId', {
          tableId: source
        }).then(res => {
          console.log(222, res);
          const tableData = res.data;
          if (tableData && tableData.length) {
            dataSourceList.value = tableData.map(item => {
              return {
                name: item.fieldName,
                label: item.fieldName
              };
            });
            // state.dataSourceList = tableData.filter(
            //   (item) => item.editable
            // )
            console.log('dataSourceList', state.dataSourceList);
          }
        }).catch(res => {
          console.log(res);
        });
      }
    };
    getDataSource();
    const tableDataList = ref([]);
    const getTableData = data => {
      tableDataList.value = data;
      console.log(555555, tableDataList.value);
    };
    const setTableData = data => {
      tableListEl.value.setTableData(data);
    };
    const beforeRequestList = params => {
      return {
        pageInfo: {
          pageSize: 100000,
          pageIndex: 1
        }
      };
    };
    return (_ctx, _cache) => {
      const _component_ak_list = _resolveComponent("ak-list");
      const _component_ak_form = _resolveComponent("ak-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(frontPagination), {
        searchData: searchData.value,
        tableData: tableDataList.value,
        setTableData: setTableData
      }, {
        default: _withCtx(() => [_createVNode(_component_ak_list, {
          ref_key: "tableListEl",
          ref: tableListEl,
          requestUrl: "/form-design/customDatasource/getAllDataSource",
          deleteUrl: "/form-design/dataSource/delete",
          tableData: tableData.value,
          showPage: false,
          onGetTableData: getTableData,
          beforeRequest: beforeRequestList
        }, null, 8, ["tableData"])]),
        _: 1
      }, 8, ["searchData", "tableData"]), _createVNode(_component_el_dialog, {
        modelValue: dialog.visible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialog.visible = $event),
        title: dialog.title,
        width: "1000px",
        "destroy-on-close": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ak_form, {
          ref_key: "formEl",
          ref: formEl,
          formData: formData.value,
          type: dialog.type,
          addUrl: "/form-design/dataSource/creat",
          editUrl: "/form-design/dataSource/edit",
          requestUrl: "/form-design/dataSource/id",
          beforeSubmit: beforeSubmit,
          afterSubmit: afterSubmit,
          onBtnClick: cancelClick
        }, null, 8, ["formData", "type"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};