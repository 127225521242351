import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import store from "@/store";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
export default {
  __name: 'microContainer',
  setup(__props) {
    const route = useRoute();
    const menus = computed(() => {
      return store.state.menu.menus;
    });
    let data = {
      route: [],
      path: route.path
    };
    console.log("menus", menus);
    watch(() => {
      return menus;
    }, () => {
      data.routes = store.state.menu.menus.filter(item => {
        return item.path.startsWith("/module/Analysis");
      });
    }, {
      immediate: true,
      deep: true
    });
    watch(() => {
      return route.path;
    }, v => {
      data = {
        routes: store.state.menu.menus.filter(item => {
          return item.path.startsWith("/module/Analysis");
        }),
        path: v.split("/module/Analysis")[1]
      };
    }, {
      immediate: true,
      deep: true
    });
    return (_ctx, _cache) => {
      const _component_micro_app = _resolveComponent("micro-app");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_micro_app, {
        name: "Analysis",
        url: "http://localhost:5555/",
        baseroute: "/module/Analysis",
        data: _unref(data),
        inline: ""
      }, null, 8, ["data"])]);
    };
  }
};