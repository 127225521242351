import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7309fbbb"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "social-signup-container"
};
const _hoisted_2 = {
  class: "wx-svg-container"
};
const _hoisted_3 = {
  class: "qq-svg-container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "sign-btn",
    onClick: _cache[0] || (_cache[0] = $event => $options.wechatHandleClick('wechat'))
  }, [_createElementVNode("span", _hoisted_2, [_createVNode(_component_svg_icon, {
    "icon-class": "wechat",
    class: "icon"
  })]), _createTextVNode(" WeChat ")]), _createElementVNode("div", {
    class: "sign-btn",
    onClick: _cache[1] || (_cache[1] = $event => $options.tencentHandleClick('tencent'))
  }, [_createElementVNode("span", _hoisted_3, [_createVNode(_component_svg_icon, {
    "icon-class": "qq",
    class: "icon"
  })]), _createTextVNode(" QQ ")])]);
}