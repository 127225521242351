import {lowcCodeRequest as request} from '@/utils/request';

export function queryTables(query) {
    return request({
        url: '/online/cgform/head/queryTables',
        method: 'get',
        params: query
    })
}
  
export function listByHeadId(query) {
    return request({
        url: '/online/cgform/field/listByHeadId',
        method: 'get',
        params: query
    })
}

export function getFormlist(query) {
    return request({
        url: '/online/cgform/head/list',
        method: 'get',
        params: query
    })
}

export function transTables(data) {
    return request({
      url: '/online/cgform/head/transTables/' + data,
      method: 'post',
    })
}
  
export function editAll(data) {
    return request({
      url: '/online/cgform/api/editAll',
      method: 'post',
      data,
    })
}

export function addAll(data) {
    return request({
      url: '/online/cgform/api/addAll',
      method: 'post',
      data,
    })
}

export function codeGenerate(data) {
    return request({
      url: '/online/cgform/api/codeGenerate',
      method: 'post',
      data,
    })
}

export function tableInfo(query) {
    return request({
      url: '/online/cgform/head/tableInfo',
      method: 'get',
      params: query
    })
}

export function downGenerateCode(data) {
    return request({
      url: '/online/cgform/api/downGenerateCode',
      method: 'post',
      data,
      responseType: 'blob'
    })
}


export function queryEnhanceJs(code,type) {
  return request({
    url: `/online/cgform/head/enhanceJs/${code}/${type}` ,
    method: 'get',
    // params:{
    //   code,
    //   type
    // }
  })
}

export function addEnhanceJs(data,code) {
  return request({
    url: '/online/cgform/head/enhanceJs/'+code,
    method: 'post',
    data,
  })
}
export function updateEnhanceJs(data,code) {
  return request({
    url: '/online/cgform/head/enhanceJs/'+code,
    method: 'post',
    data,
  })
}