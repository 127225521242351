
import {bigRequest} from "@/utils/request";
//模型管理下载页面的下载接口
export function modelManageDownload(file) {
    return bigRequest({
        url: `modelManagement/download/${file.dir}/${file.fileName}`,
        method: "get",
        responseType:'blob'
    });
}
//模型管理下载页面的列表查询接口
export function fetchModelManageDownList(data) {
    return bigRequest({
        url: "modelManagement/pageRecord",
        method: "post",
        data
    });
}