import store from "@/store";
import { state } from "./state";

import { UserMutationTypes } from "./mutation-types";
import { UserActionTypes } from "./action-types";
import { getInfo, logout } from "@/api/login";
import { removeToken, setToken } from "@/utils/auth";
//import { PermissionActionType } from '../permission/action-types'
import { useRouter } from "vue-router";
import { resolve } from "path-browserify";

const OK = 1000;
const router = useRouter();

export const actions = {
  [UserActionTypes.ACTION_RESET_TOKEN]({ commit }) {
    removeToken();
    commit(UserMutationTypes.SET_TOKEN, "");
    commit(UserMutationTypes.SET_ROLES, []);
  },

  async [UserActionTypes.ACTION_GET_USER_INFO]({ commit }) {
    return await getInfo().then((res) => {
      return new Promise((resolve) => {
        if (res?.code === OK) {
          commit(UserMutationTypes.SET_ROLES, res.roles);
          commit(UserMutationTypes.SET_NAME, res.user.userName);
          commit(UserMutationTypes.SET_DEPT, res.user.dept);
          commit(UserMutationTypes.SET_AVATAR, res.user.avatar);
          commit(UserMutationTypes.SET_EMAIL, res.user.email);
          commit(UserMutationTypes.SET_PERMISSION, res.permissions);
          resolve(res);
        } else {
          throw Error("Verification failed, please Login again.");
        }
      });
    });
  },

  async [UserActionTypes.ACTION_CHANGE_ROLES]({ commit }, role) {
    const token = role + "-token";

    commit(UserMutationTypes.SET_TOKEN, token);
    setToken(token);
    await store.dispatch(UserActionTypes.ACTION_GET_USER_INFO, undefined);
    // store.dispatch(PermissionActionType.ACTION_SET_ROUTES, undefined)
    store.state.permission.dynamicRoutes.forEach((item) => {
      router.addRoute(item);
    });
  },

  [UserActionTypes.ACTION_LOGIN_OUT]({ commit }) {
    logout().then(() => {
      removeToken();
      commit(UserMutationTypes.SET_TOKEN, "");
      commit(UserMutationTypes.SET_ROLES, []);
    });

    // resetRouter()
  },
  setMode({ commit }, mode) {
    commit("SET_THEMEMODE", mode);
  },
};
