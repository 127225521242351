import { computed, defineComponent, ref, reactive, nextTick } from "vue";
import SidebarItem from "./SidebarItem.vue";
import { useRoute } from "vue-router";
import { useStore } from 'vuex';
import router from "@/router";
import { deepClone, titleCase } from "@/utils";
import { parseTime } from '@/utils/lz';
export default defineComponent({
  name: "TopBar",
  components: {
    SidebarItem
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const sysTitle = ref(window.g.VUE_APP_TITLE);
    const topBar = ref(null);
    const currentType = computed(() => {
      return store.state.menu.currentType;
    });
    // 判断是否显示左右操作按钮
    const hasScrollbar = () => {
      let topBarScroll = document.getElementsByClassName("topBar-scrollContainer")[0];
      if (topBarScroll) {
        return topBarScroll.scrollWidth > topBarScroll.clientWidth;
      }
    };
    const currentTime = ref({
      day: "",
      time: "",
      week: ""
    });
    const timeInterval = ref(null);
    currentTime.value.day = parseTime(new Date()).split(" ")[0];
    if (!timeInterval.value) {
      timeInterval.value = setInterval(() => {
        currentTime.value.time = parseTime(new Date()).split(" ")[1];
      }, 1000);
    }
    const weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    currentTime.value.week = weeks[new Date().getDay()];
    const moveHandle = direction => {
      const step = 115;
      nextTick(() => {
        let scrollLeft = document.getElementsByClassName("topBar-scrollContainer")[0].scrollLeft;
        if (direction === "right") {
          topBar.value.setScrollLeft(scrollLeft + step);
        } else {
          if (topBar.value.scrollLeft < step) {
            topBar.value.setScrollLeft(0);
          } else {
            topBar.value.setScrollLeft(scrollLeft - step);
          }
        }
      });
    };
    const theme = computed(() => store.state.theme.theme.menu);
    const activeMenu = computed(() => {
      const {
        name
      } = route;
      return name;
    });

    //总菜单
    let menus = computed(() => {
      // 横向菜单扁平化，不按照子系统划分菜单显示
      let copy_menus = deepClone(store.state.menu.rowMenus);
      let child_menu = [];
      copy_menus.forEach(item => {
        let prefixPath = item.path;
        if (item?.children) {
          item.children.forEach(child => {
            child.path = prefixPath + "/" + child.path;
          });
          child_menu.push(...item.children);
        } else {
          child_menu.push(item);
        }
      });
      return child_menu;
    });
    const state = reactive({
      mainData: [],
      adminData: []
    });
    //获取主页面菜单（六大系统）
    state.mainData = menus.value.filter(item => {
      return item.path.startsWith("/module") && item.hidden === "0";
    });
    const frameWork = menus.value.find(item => {
      return item.path.startsWith("/baseFramework");
    });
    if (frameWork) {
      let prefixPath = frameWork.path.replace('/baseFramework', '');
      let a = frameWork.children.filter(item => {
        item.path = prefixPath + "/" + item.path;
        return item.hidden === "0";
      });
      let admin = a;
      admin.forEach(it => {
        it.path = it.path.startsWith('/') ? it.path : '/' + it.path;
      });
      state.adminData = admin;
    }
    console.log('state', state);
    let routes = ref([]);
    const rowMenuHandle = () => {
      let it = state.mainData.find(item => {
        return route.path.startsWith(item.path);
      });
      console.log('it', it);
      console.log('state.mainData', state.mainData);
      console.log('state.adminData', state.adminData);
      if (it) {
        routes.value = state.mainData;
        store.commit("menu/setCurrentType", "main");
      } else {
        routes.value = state.adminData;
        store.commit("menu/setCurrentType", "admin");
      }
      console.log('routes', routes);
    };
    rowMenuHandle();
    const findPath = data => {
      let path = '';
      const withPath = data => {
        path = path + (data.path.startsWith('/') ? data.path : '/' + data.path);
        if (data.children && data.children.length) {
          withPath(data.children[0]);
        }
      };
      withPath(data);
      return path;
    };

    //切换后台管一级菜单
    const adminToggle = val => {
      let path = "";
      //切换到后台管理菜单
      if (val === "admin") {
        routes.value = state.adminData;
        path = findPath(state.adminData[0]);
        store.commit("menu/setCurrentType", "admin");
      } else {
        routes.value = state.mainData;
        path = findPath(state.mainData[0]);
        store.commit("menu/setCurrentType", "main");
      }
      router.push(path);
    };
    return {
      routes,
      activeMenu,
      adminToggle,
      currentType,
      theme,
      moveHandle,
      topBar,
      currentTime,
      sysTitle,
      hasScrollbar
    };
  }
});