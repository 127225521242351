import { versionList, modelDeploy, fileDelete, modelDelete } from "@/api/AI/index";
import axios from "axios";
import { getDate } from "@/utils/formatDate.js";
import { setRequestUrl } from "@/utils/setHeader.js";
export default {
  name: "",
  data() {
    return {
      modelId: "",
      // 是否显示弹出层
      dialogVisible: false,
      saveLoading: false,
      loading: true,
      pageSize: 10,
      page: 1,
      total: 0,
      totalElement: "",
      parentName: "",
      description: "",
      query: {
        parentModelId: "",
        pageSize: "",
        page: ""
      },
      list: [],
      form: {
        id: ""
      }
    };
  },
  filters: {
    FormatDay: function (val) {
      return getDate(val, "year");
    }
  },
  created() {
    if (this.$route.query.type == "modelDetails") {
      this.query.parentModelId = this.$route.query.data.id;
      this.parentName = this.$route.query.data.name;
      this.description = this.$route.query.data.description;
      console.log(this.parentName);
      console.log(this.description);
    }
    this.getList();
  },
  methods: {
    submitDownload(row) {
      if (row.outputFile.indexOf(".zip") != -1) {
        if (row.trainingStatus == "trainFinished" || row.trainingStatus == "deployFinished") {
          // window.location.href = process.env.VUE_APP_BASE_API_AI + '/trainManage/model/file/download/'+row.outputFile
          const url = process.env.VUE_APP_BASE_API_AI + "/trainManage/model/file/download/" + row.outputFile;
          var xhr = new XMLHttpRequest();
          xhr.open("GET", url, true);
          const temp = setRequestUrl({
            url: "/trainManage/model/file/download"
          });
          xhr.setRequestHeader("signature", temp.signature);
          xhr.setRequestHeader("timestamp", temp.timestamp);
          xhr.setRequestHeader("nonce", temp.nonce);
          xhr.responseType = "blob";
          xhr.onload = function (e) {
            if (this.status == 200) {
              var blob = this.response;
              var filename = row.problemName;
              var a = document.createElement("a");
              var url = URL.createObjectURL(blob);
              a.href = url;
              a.download = filename;
              a.click();
              window.URL.revokeObjectURL(url);
            }
          };
          xhr.send();
        } else if (row.trainingStatus == "trainFailed") {
          this.msgWarning("训练失败，无法下载！");
        } else if (row.trainingStatus == "trainInProgress") {
          this.msgWarning("训练中，无法下载！");
        }
      } else {
        this.msgWarning("该模型不能被下载！");
      }
      // axios({
      //     url: process.env.VUE_APP_BASE_API_AI + '/trainManage/model/file/download/'+row.outputFile,
      //     method: 'post',
      //     // data:outputFile ,
      //     // responseType: 'blob'    // 重点在于配置responseType: 'blob'
      // })
      //   .then(res => {
      //       const link = document.createElement('a');  // 创建元素
      //       let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
      //       link.style.display = 'none';
      //       link.href = URL.createObjectURL(blob);   // 创建下载的链接
      //       //num++
      //       link.setAttribute('download', '文件.xlsx');  // 给下载后的文件命名
      //        document.body.appendChild(link);
      //       link.click();  // 点击下载
      //       document.body.removeChild(link);  //  下载完成移除元素
      //       window.URL.revokeObjectURL(link.href);  // 释放掉blob对象
      //  })
      //   .catch(_ => {
      //  });
    },
    //查询子模型
    getList() {
      this.loading = true;
      let params = {
        ...this.query
      };
      params.page = this.page - 1;
      params.pageSize = this.pageSize;
      versionList(params).then(response => {
        this.list = response.data.result;
        this.total = response.data.totalElement;
      });
    },
    //模型发布
    handleModelDeploy(row) {
      console.log("$$$$$$$$$$$$", row.trainingStatus);
      this.modelId = row.id;
      if (row.trainingStatus == "trainFinished") {
        // console.log('!!!!!!!!!',modelId)
        modelDeploy(this.modelId).then(response => {
          if (response.code == "1000") {
            this.msgSuccess("发布成功");
            this.dialogVisible = false;
          } else {
            this.msgError(response.msg);
          }
        });
      } else if (row.trainingStatus == "trainFailed") {
        this.msgWarning("训练失败，无法发布！");
      } else if (row.trainingStatus == "trainInProgress") {
        this.msgWarning("训练中，无法发布！");
      } else if (row.trainingStatus == "deployFinished") {
        this.msgWarning("已部署！");
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let modelId = row.id;
      console.log("+++", modelId);
      this.$confirm("确定是否删除该模型?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return modelDelete(modelId);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      }).catch(function () {});
    }
  }
};