import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0ba91442"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "action-container flex-box flex_j_c-flex-end flex_a_i-center"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = ["src"];
const _hoisted_4 = ["src"];
const _hoisted_5 = ["src"];
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  class: "username"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  const _component_el_switch = _resolveComponent("el-switch");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_el_avatar = _resolveComponent("el-avatar");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.isGLHome ? (_openBlock(), _createBlock(_component_el_dropdown, {
    key: 0,
    trigger: "click",
    onCommand: _ctx.dropdownHandle,
    placement: "bottom-end"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, {
      class: "personalInfo"
    }, {
      default: _withCtx(() => [_ctx.isGlMenuShow('Home') ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        command: "personal",
        onClick: _cache[0] || (_cache[0] = $event => _ctx.goto('/module/manhole/home'))
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: require('@/assets/image/首页.png'),
          class: "icon"
        }, null, 8, _hoisted_3), _createTextVNode("首页")]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.isGlMenuShow('HomeSJZ') ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 1,
        command: "personal",
        onClick: _cache[1] || (_cache[1] = $event => _ctx.goto('/module/manhole/homeSJZ'))
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: require('@/assets/image/告警首页.png'),
          class: "icon"
        }, null, 8, _hoisted_4), _createTextVNode("首页")]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.isGlMenuShow('AlarmHome') ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 2,
        command: "personal",
        onClick: _cache[2] || (_cache[2] = $event => _ctx.goto('/module/manhole/alarmHome'))
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: require('@/assets/image/告警首页.png'),
          class: "icon"
        }, null, 8, _hoisted_5), _createTextVNode("告警首页")]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.isGlMenuShow('DeviceRunHome') ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 3,
        command: "personal",
        onClick: _cache[3] || (_cache[3] = $event => _ctx.goto('/module/manhole/deviceRunHome'))
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: require('@/assets/image/设备运行.png'),
          class: "icon"
        }, null, 8, _hoisted_6), _createTextVNode("设备运行")]),
        _: 1
      })) : _createCommentVNode("", true), _createVNode(_component_el_dropdown_item, {
        command: "logout",
        onClick: _ctx.logout
      }, {
        default: _withCtx(() => [_createElementVNode("i", {
          class: "iconfont icon-tuichudenglu",
          style: _normalizeStyle({
            'fontSize': '24px',
            'fontWeight': '600',
            color: _ctx.mode == 'dark' ? '#44fae8' : '#409eff'
          })
        }, null, 4), _createTextVNode(" 退出登录")]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_el_dropdown_item, {
        class: "dropdown-backstage",
        command: "admin",
        onClick: _ctx.goDeviceManage
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "backstage",
          style: _normalizeStyle({
            'background': _ctx.mode == 'dark' ? '#03877A' : '#409eff'
          })
        }, "后台配置页面", 4)]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    default: _withCtx(() => [_createElementVNode("img", {
      size: 50,
      src: require('@/assets/img/设置.png'),
      class: "cursor-pointer setting"
    }, null, 8, _hoisted_2)]),
    _: 1
  }, 8, ["onCommand"])) : _ctx.currentType == 'main' ? (_openBlock(), _createElementBlock(_Fragment, {
    key: 1
  }, [_createVNode(_component_el_tooltip, {
    content: "Theme switch",
    placement: "bottom",
    "show-after": 500
  }, {
    default: _withCtx(() => [_createVNode(_component_el_switch, {
      class: "margin_r-15",
      modelValue: _ctx.mode,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _ctx.mode = $event),
      "active-value": _ctx.ThemeMode.DARK,
      "inactive-value": _ctx.ThemeMode.LIGHT,
      "inline-prompt": "",
      "active-text": _ctx.$t('message.themeGreen'),
      "inactive-text": _ctx.$t('message.themeBright'),
      "active-color": "#222222"
    }, null, 8, ["modelValue", "active-value", "inactive-value", "active-text", "inactive-text"])]),
    _: 1
  }), _createVNode(_component_el_dropdown, {
    trigger: "click",
    onCommand: _ctx.dropdownHandle,
    placement: "bottom-end"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, {
      class: "personalInfo"
    }, {
      default: _withCtx(() => [_createVNode(_component_el_dropdown_item, {
        command: "logout",
        onClick: _ctx.logout
      }, {
        default: _withCtx(() => [_createElementVNode("i", {
          class: "iconfont icon-tuichudenglu",
          style: _normalizeStyle({
            'fontSize': '24px',
            'fontWeight': '600',
            color: _ctx.mode == 'dark' ? '#44fae8' : '#409eff'
          })
        }, null, 4), _createTextVNode(" " + _toDisplayString(_ctx.$t('message.Logut')), 1)]),
        _: 1
      }, 8, ["onClick"]), _ctx.showAdmin ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        class: "dropdown-backstage",
        command: "admin",
        onClick: _ctx.adminHandle
      }, {
        default: _withCtx(() => [_createElementVNode("div", {
          class: "backstage",
          style: _normalizeStyle({
            'background': _ctx.mode == 'dark' ? '#03877A' : '#409eff'
          })
        }, "企业管理后台", 4)]),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true)]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_el_avatar, {
      size: 50,
      src: _ctx.circleUrl,
      class: "cursor-pointer"
    }, null, 8, ["src"])]),
    _: 1
  }, 8, ["onCommand"]), _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.username), 1)], 64)) : (_openBlock(), _createBlock(_component_el_button, {
    key: 2,
    onClick: _ctx.goMain,
    type: "primary"
  }, {
    default: _withCtx(() => [_createTextVNode("返回首页")]),
    _: 1
  }, 8, ["onClick"]))]);
}