import vuexStore from "@/store";
import store from "./store";
// 全局路由前缀
export const MODULE_NAME = "module";
import bus from "@/utils/bus";
import { getToken } from "@/utils/auth";
import router from "../router";
import { deepClone } from "@/utils/index";
import microUtils from "./microUtils";
// import commonComponent from '@/components/index'
import { prefetchApps } from "qiankun";

/**
 * 根据应用名称获取菜单，比如pms
 * @param {string} name - 应用名
 * @returns {array} 应用路列表
 */

function getRoute(name) {
  console.log(name, "555555=======================");
  const routerList = deepClone(vuexStore.state.common["flatRoute"]);
  console.log(routerList, "66666=======================");
  const childPath = `/${MODULE_NAME}/${name}`;
  const childList = routerList.filter((item) => {
    return item.path.startsWith(childPath);
  });

  if (childList.length && childList[0].children) {
    childList[0].children.forEach((item) => {
      item.path = item.path.startsWith("/") ? item.path : "/" + item.path;
    });
  }

  console.log(
    "下发路由" + name,
    (childList.length && childList[0].children) || []
  );
  return (childList.length && childList[0].children) || [];
}

// 是否生产环境
const isDevelopment = process.env.NODE_ENV === "development";

const apps = [
  {
    name: "dataPlatform/bigData", //大数据服务名称
    entry: window.g.VUE_APP_BIGDATA_URL,
    container: "#demo-container-dataPlatform-bigData",
    activeRule: "/module/dataPlatform/bigData",
  },
  {
    name: "dataPlatform/Kettle",
    entry: window.g.VUE_APP_KETTLE,
    container: "#demo-container-dataPlatform-Kettle",
    activeRule: "/module/dataPlatform/Kettle",
  },
  {
    name: "synergy",
    entry: window.g.VUE_APP_SYNERGY_URL,
    container: "#demo-container-synergy",
    activeRule: "/module/synergy",
  },
  {
    name: "operation",
    entry: window.g.VUE_APP_OPERATION_URL,
    container: "#demo-container-operation",
    activeRule: "/module/operation",
  },
  {
    name: "courts",
    entry: window.g.VUE_APP_COURTS_URL,
    container: "#demo-container-courts",
    activeRule: "/module/courts",
  },
  {
    name: "HDGKIPUE",
    entry: window.g.VUE_APP_hdgk_URL,
    container: "#demo-container-HDGKIPUE",
    activeRule: "/module/HDGKIPUE",
  },
  {
    name: "devices",
    entry: window.g.VUE_APP_DEVICES_URL,
    container: "#demo-container-devices",
    activeRule: "/module/devices",
  },
  {
    name: "videoPlatform",
    entry: window.g.VUE_APP_VIDEO_URL,
    container: "#demo-container-videoPlatform",
    activeRule: "/module/videoPlatform",
  },
  {
    name: "sysIot",
    entry: window.g.VUE_APP_SYSIOT_URL,
    container: "#demo-container-sysIot",
    activeRule: "/module/sysIot",
  },
  {
    name: "manhole",
    entry: window.g.VUE_APP_MANHOLE_URL,
    container: "#demo-container-manhole",
    activeRule: "/module/manhole",
  },
  {
    name: "gisMap",
    entry: window.g.VUE_APP_GISMAP_URL,
    container: "#demo-container-gisMap",
    activeRule: "/module/gisMap",
  },
  // {
  //   name: 'nacos',
  //   entry: isDevelopment
  //     ? 'http://localhost:16012/jmreport/list'
  //     : 'http://10.1.2.21:30112/jmreport/list',
  //   container: '#demo-container',
  //   activeRule: '/module/nacos',
  // },
];
const pathHandle = (str) => {
  if (str.includes("/")) {
    let id = str.replace("/", "-");
    return id;
  } else {
    return str;
  }
};
// export default apps
export default (routerList) =>
  apps.map((e) => ({
    ...e,
    container: e.container, // KeepAlive
    loader: (loading) => {
      if (loading) {
        vuexStore.commit(`load/OPEN_LOADING`);
      } else {
        vuexStore.commit(`load/CLOSE_LOADING`);
      }
    },
    props: {
      rootRouter: router,
      routerList: getRoute(e.name), // 提供给子应用的路由列表
      routerBase: e.activeRule, // 微服务基础路由
      container: e.container, //微服务容器
      getGlobalState: store.getGlobalState, //获取公共数据方法
      bus: bus, //父子应用公共事件总线 eventBus
      storeBase: vuexStore,
      store: vuexStore, //整个
      token: getToken(), //子应用接收的token
      loginUrl: window.g.VUE_APP_AUTH_URL, //子应用接收的登录页面地址
      microUtils: microUtils, //微应用工具函数对象
    },
  }));
// 主应用window挂载子应用function（一起启动，找不到子应用window上挂载的方法）
bus.on("getMicroFun", (fc, fcKey) => {
  window[fcKey] = fc;
});
//bug 12303 N-功能测试-即席分析-仪表板-编辑-编辑视图-提示重新登录-点击确定，没有转到登录页面 zhangqingqing 2022/6/22
bus.on("LogOut", () => {
  vuexStore.dispatch("common/LogOut").then(() => {
    location.reload(); // 为了重新实例化vue-router对象 避免bug
  });
});
//预加载
// prefetchApps([apps[0]]);
