import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { useRouter, useRoute } from 'vue-router';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { deepClone, downloadFile } from "@/utils";
import { getDown, listData, getData, delData, addData, updateData, exportData } from "@/api/system/dict/data";
export default defineComponent({
  name: "",
  components: {},
  props: {},
  setup(props) {
    const formContainer = ref(ElForm);
    const router = useRouter();
    const route = useRoute();
    const lineOptions = ref([]);
    const state = reactive({
      tableLoading: false,
      saveLoading: false,
      dialogVisible: false,
      title: "添加字典类型",
      multipleSelection: [],
      queryParams: {
        dictLabel: "",
        dictType: route.query.dictType,
        status: "",
        pageNum: 1,
        pageSize: 10
      },
      searchFormData: [{
        prop: "dictType",
        label: "字典名称",
        placeholder: "字典名称",
        options: lineOptions,
        props: {
          label: "dictName",
          value: "dictType"
        },
        type: "bigData_list_multi",
        isSelect: true,
        span: 4
      }, {
        prop: "dictLabel",
        label: "字典标签",
        placeholder: "请输入字典标签查询",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        type: "list",
        label: "数据状态",
        placeHolder: "请选择字典状态",
        prop: "status",
        options: [{
          label: "正常",
          value: "0"
        }, {
          label: "停用",
          value: "1"
        }],
        props: {
          label: "label",
          value: "value"
        },
        isShow: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['system:dict:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => methods.handleAdd(item)
      }, {
        buttonName: "修改",
        hasPermi: ['system:dict:edit'],
        className: 'editBtn',
        buttonIcon: "Edit",
        exp: item => methods.handleUpdate(item)
      }, {
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['system:dict:remove'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => methods.handleDel(item)
      }, {
        buttonName: "导出",
        hasPermi: ['system:dict:export'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => methods.handleExport(item)
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      tableHeader: [{
        prop: "dictType",
        label: "字典类型",
        fixed: false,
        type: "text"
      }, {
        prop: "dictLabel",
        label: "数据标签",
        fixed: false,
        type: "text"
      }, {
        prop: "dictValue",
        label: "数据键值",
        fixed: false,
        type: "text"
      }, {
        prop: "dictSort",
        label: "显示排序",
        fixed: false,
        type: "text"
      }, {
        prop: "status",
        label: "状态",
        fixed: false,
        statusList: [{
          label: '正常',
          value: "0"
        }, {
          label: '停用',
          value: "1"
        }],
        type: "text"
      }, {
        prop: "remark",
        label: "备注",
        fixed: false,
        type: "text"
      }, {
        prop: "operation",
        label: "操作",
        width: "700px",
        fixed: 'right',
        operation: [{
          name: "修改",
          clickFun: row => methods.handleUpdate(row),
          hasPermi: ['system:dict:edit']
        }, {
          name: "删除",
          clickFun: row => methods.handleDel(row),
          hasPermi: ['system:dict:remove']
        }]
      }],
      // 表单校验
      formRules: {
        dictLabel: [{
          required: true,
          message: "数据标签不能为空",
          trigger: "blur"
        }],
        dictValue: [{
          required: true,
          message: "数据键值不能为空",
          trigger: "blur"
        }],
        dictSort: [{
          required: true,
          message: "数据顺序不能为空",
          trigger: "blur"
        }]
      },
      form: {
        dictLabel: "",
        dictType: "",
        dictValue: "",
        dictSort: "",
        status: "0",
        remark: ""
      },
      formData: [{
        prop: "dictType",
        label: "字典类型",
        disabled: true,
        placeholder: "请输入字典类型",
        type: "text",
        isSelect: true
      }, {
        prop: "dictLabel",
        label: "数据标签",
        placeholder: "请输入数据标签",
        type: "text",
        isSelect: true
      }, {
        prop: "dictValue",
        label: "数据键值",
        placeholder: "请输入数据键值",
        type: "text",
        isSelect: true
      }, {
        prop: "dictSort",
        label: "显示排序",
        placeholder: "请输入显示排序",
        type: "textNum",
        isSelect: true
      }, {
        prop: "status",
        label: '状态',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "0",
          value: '0',
          labelName: '正常'
        }, {
          label: "1",
          labelName: '停用',
          value: '1'
        }],
        span: 12
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea",
        isSelect: true,
        span: 24
      }]
    });
    // 表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const toRoute = function (path, id, type, name) {
      router.push({
        path: path,
        query: {
          dictId: id
        }
      });
    };
    const methods = reactive({
      //下拉数据
      getData() {
        getDown().then(res => {
          lineOptions.value = res.rows.map(item => ({
            value: item.dictType,
            label: item.dictName
          }));
        }).catch(err => {});
      },
      close() {
        state.form = {
          dictLabel: "",
          dictType: "",
          dictValue: "",
          dictSort: "",
          status: "0",
          remark: ""
        };
      },
      handleQuery() {
        methods.getList();
      },
      resetQuery() {
        state.queryParams = {
          dictType: route.query.dictType,
          dictLabel: "",
          status: "",
          pageNum: 1,
          pageSize: 10
        }, methods.getList();
      },
      getList() {
        let params = {
          "dictType": state.queryParams.dictType,
          "dictLabel": state.queryParams.dictLabel,
          "status": state.queryParams.status,
          "pageNum": state.queryParams.pageNum,
          "pageSize": state.queryParams.pageSize
        };
        state.tableLoading = true;
        listData(params).then(response => {
          state.tableData.rows = response.rows;
          state.tableData.total = response.total;
          state.tableLoading = false;
        });
      },
      //新增
      handleAdd() {
        state.form.dictType = route.query.dictType, state.dialogVisible = true;
        state.title = "添加字典数据";
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        if (!row.dictCode && !state.multipleSelection.length || state.multipleSelection.length > 1) {
          ElMessage.closeAll();
          ElMessage.warning("请选择一条数据修改");
          return;
        }
        if (row.dictCode) {
          state.multipleSelection = [];
        }
        state.dialogVisible = true;
        state.title = "修改字典数据";
        let dictCode = row.dictCode || state.multipleSelection[0].dictCode;
        getData(dictCode).then(response => {
          state.form = response.data;
        });
      },
      //表单确定
      submitForm: function () {
        const formRef = unref(formContainer);
        formRef.commonForm.validate(valid => {
          if (valid) {
            state.saveLoading = true;
            if (state.title == "修改字典数据") {
              updateData(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("修改成功");
                  state.saveLoading = false;
                  state.dialogVisible = false;
                  methods.getList();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            } else if (state.title == "添加字典数据") {
              // 新增
              addData(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("新增成功");
                  state.dialogVisible = false;
                  state.saveLoading = false;
                  methods.resetQuery();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            }
          }
        });
      },
      //删除
      handleDel(row) {
        if (!row.dictCode && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        if (row.dictCode) {
          state.multipleSelection = [];
        }
        state.ids = state.multipleSelection.map(item => item.dictCode);
        let dictCodeIds = row.dictCode || state.ids;
        ElMessageBox.confirm("确定是否删除?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delData(dictCodeIds);
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("删除成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      },
      //导出
      handleExport() {
        let params = {
          pageNum: state.queryParams.pageNum,
          pageSize: state.queryParams.pageSize
        };
        ElMessageBox.confirm('是否确认导出所有数据项?', "导出", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return exportData(params);
        }).then(response => {
          downloadFile(response.message);
        }).catch(err => {
          console.log(err);
        });
      }
    });
    methods.getData();
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer,
      lineOptions
    };
  }
});