import {bigRequest as request} from '@/utils/request'

// 查询数据质量评价
export function queryDataQualityEvaluate(query) {
  return request({
    url: '/queryDataQualityEvaluate',
    method: 'post',
    data: query
  })
}

// add数据质量评价打分和备注
export function addDataQualityEvaluate(query) {
  return request({
    url: '/dataQualityEvaluate',
    method: 'post',
    data: query
  })
}

// update数据质量评价打分和备注
export function updateDataQualityEvaluate(query) {
  return request({
    url: '/update/dataQualityEvaluate',
    method: 'post',
    data: query
  })
}

// 删除多个数据质量评价
export function deleteDataQualityEvaluate(data) {
  return request({
    url: '/delete/dataQualityEvaluate',
    method: 'post',
    data: data
  })
}

// 删除单个数据质量评价
export function dataQualityEvaluate(id) {
  return request({
    url: '/delete/dataQualityEvaluate/'+id,
    method: 'post',
  })
}

export function queryHistoryData(query) {
  return request({
    url: '/queryHistoryData',
    method: 'post',
    data: query
  })
}
