import { unref as _unref, withModifiers as _withModifiers, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["onClick"];
const _hoisted_2 = {
  class: "expand-user-dialog"
};
const _hoisted_3 = {
  class: "sidebar-tree"
};
const _hoisted_4 = {
  class: "table-list"
};
const _hoisted_5 = {
  class: "search"
};
const _hoisted_6 = {
  class: "list"
};
const _hoisted_7 = {
  class: "page"
};
const _hoisted_8 = {
  class: "has-select"
};
const _hoisted_9 = {
  class: "total"
};
import { computed, ref, onMounted, reactive, nextTick } from 'vue';
// import { getRequest } from '@/api'
import { getRequest } from '@/api/designForm';
import formatResult from '@/utils/formatResult';
export default {
  __name: 'user',
  props: {
    modelValue: String,
    disabled: Boolean
  },
  emits: ['update:modelValue'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const visible = ref(false);
    const value = computed({
      get() {
        return props.modelValue;
      },
      set(newVal) {
        emits('update:modelValue', newVal);
      }
    });
    // 侧栏处理
    const treeEl = ref();
    const treeData = ref([]);
    const department = ref();
    const handleNodeClick = data => {
      department.value = data.id;
    };
    const getTreeData = () => {
      getRequest('deptList', {}).then(res => {
        treeData.value = formatResult(res.data, 'transformDataToChild');
      });
    };
    // 侧栏处理结束
    //　table
    const userName = ref();
    const tableData = ref([]);
    const page = reactive({
      total: 0,
      current: 1,
      pageSize: 20
    });
    const searchClick = () => {
      getUserList();
    };
    const resetClick = () => {
      userName.value = '';
      department.value = '';
      treeEl.value.setCurrentKey(null);
      getUserList();
    };
    const getUserList = () => {
      const params = {
        pageInfo: {
          pageIndex: page.current,
          pageSize: page.pageSize
        },
        name: userName.value,
        department: department.value
      };
      getRequest('userList', params).then(res => {
        tableData.value = res.data.list;
      });
    };
    const currentChange = current => {
      page.current = current;
      getUserList();
    };
    const tableRowClick = row => {
      if (!checkData.value.includes(row.userName)) {
        checkData.value.push(row.userName);
      }
    };
    //　已选
    const checkData = ref([]);
    const delRowClick = row => {
      checkData.value.forEach((item, index) => {
        if (item === row) {
          checkData.value.splice(index, 1);
        }
      });
    };
    const delAllClick = () => {
      checkData.value = [];
    };
    // 弹窗处理
    const openDialog = () => {
      visible.value = true;
      if (props.modelValue) {
        checkData.value = props.modelValue.split(',');
      }
    };
    // 关闭弹窗
    const confirmClick = () => {
      emits('update:modelValue', checkData.value.join(','));
      visible.value = false;
    };
    onMounted(() => {
      nextTick(() => {
        // 可根据实际情况放在点击打开弹窗后加载，会出现loading
        getTreeData();
        getUserList();
      });
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_tree = _resolveComponent("el-tree");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_ArrowRight = _resolveComponent("ArrowRight");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_input, _mergeProps({
        placeholder: "请选择用户或输入用户名称"
      }, _ctx.$props, {
        modelValue: _unref(value),
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(value) ? value.value = $event : null),
        onClick: openDialog
      }), {
        append: _withCtx(() => [_createElementVNode("i", {
          class: "icon-user",
          onClick: _withModifiers(openDialog, ["stop"])
        }, null, 8, _hoisted_1)]),
        _: 1
      }, 16, ["modelValue"]), _createVNode(_component_el_dialog, {
        title: "用户选择",
        modelValue: visible.value,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => visible.value = $event),
        "append-to-body": true,
        width: "800px"
      }, {
        footer: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_el_button, {
          onClick: _cache[2] || (_cache[2] = $event => visible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: confirmClick
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_tree, {
          ref_key: "treeEl",
          ref: treeEl,
          data: treeData.value,
          "node-key": "id",
          onNodeClick: handleNodeClick,
          "highlight-current": ""
        }, null, 8, ["data"])]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_input, {
          placeholder: "请输入用户名",
          modelValue: userName.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => userName.value = $event)
        }, null, 8, ["modelValue"]), _createVNode(_component_el_button, {
          type: "primary",
          onClick: searchClick
        }, {
          default: _withCtx(() => [_createTextVNode("查询")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: resetClick
        }, {
          default: _withCtx(() => [_createTextVNode("重置")]),
          _: 1
        })]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_table, {
          data: tableData.value,
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "userName",
            label: "用户名"
          }), _createVNode(_component_el_table_column, {
            prop: "nickName",
            label: "昵称"
          }), _createVNode(_component_el_table_column, {
            label: "操作",
            width: "60px"
          }, {
            default: _withCtx(({
              row
            }) => [_createVNode(_component_el_button, {
              circle: "",
              size: "small",
              onClick: $event => tableRowClick(row)
            }, {
              default: _withCtx(() => [_createVNode(_component_el_icon, null, {
                default: _withCtx(() => [_createVNode(_component_ArrowRight)]),
                _: 1
              })]),
              _: 2
            }, 1032, ["onClick"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"]), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_pagination, {
          background: "",
          layout: "prev, pager, next",
          small: "",
          "hide-on-single-page": "",
          "current-page": page.current,
          total: page.total,
          "page-size": page.pageSize,
          onCurrentChange: currentChange
        }, null, 8, ["current-page", "total", "page-size"])])])]), _createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", null, "已选择：" + _toDisplayString(checkData.value.length), 1), _createVNode(_component_el_button, {
          type: "danger",
          onClick: delAllClick,
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode("全部移除")]),
          _: 1
        })]), _createVNode(_component_el_table, {
          data: checkData.value
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            label: "用户名"
          }, {
            default: _withCtx(({
              row
            }) => [_createTextVNode(_toDisplayString(row), 1)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "操作",
            width: "60px"
          }, {
            default: _withCtx(({
              row
            }) => [_createVNode(_component_el_button, {
              size: "small",
              type: "danger",
              onClick: $event => delRowClick(row)
            }, {
              default: _withCtx(() => [_createTextVNode("移除")]),
              _: 2
            }, 1032, ["onClick"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])])])]),
        _: 1
      }, 8, ["modelValue"])], 64);
    };
  }
};