import { getToken } from "@/utils/auth";
import { ThemeMode } from "@/utils/dictionary";
export const state = {
  token: getToken() || "",
  name: "",
  avatar: "",
  introduction: "",
  roles: [],
  email: "",
  permissions: [],
  dept: {},
  serverTime: 0,
  themeMode: ThemeMode.DARK,
  // themeMode: "light",
};
