import { defineComponent, reactive, ref, toRefs, computed, onMounted, onBeforeUnmount, getCurrentInstance } from 'vue';
export default defineComponent({
  emits: ['scroll'],
  setup(_, context) {
    const scrollContainerRef = ref(null);
    const scrollWrapper = computed(() => {
      return scrollContainerRef.value.$el;
    });
    const {
      ctx
    } = getCurrentInstance();
    const tagSpacing = 4;
    const state = reactive({
      handleScroll: e => {
        const eventDelta = e.wheelDelta || -e.deltaY * 40;
        if (scrollWrapper.value && scrollWrapper.value.scrollLeft) {
          scrollWrapper.value.scrollLeft = scrollWrapper.value.scrollLeft + eventDelta / 4;
        }
      },
      moveToCurrentTag: currentTag => {
        const container = scrollContainerRef.value.$el;
        const containerWidth = container.offsetWidth;
        const tagList = ctx.$parent.$refs.tag;
        let fristTag = null;
        let lastTag = null;

        // find first tag and last tag
        if (tagList.length > 0) {
          fristTag = tagList[0];
          lastTag = tagList[tagList.length - 1];
        }
        if (fristTag === currentTag) {
          if (scrollWrapper.value && scrollWrapper.value.scrollLeft) {
            scrollWrapper.value.scrollLeft = 0;
          }
        } else if (lastTag === currentTag) {
          if (scrollWrapper.value && scrollWrapper.value.scrollLeft && scrollWrapper.value.scrollWidth) {
            scrollWrapper.value.scrollLeft = scrollWrapper.value.scrollWidth - containerWidth;
          }
        } else {
          // find preTag and nextTag
          const currentIndex = tagList.findIndex(item => item === currentTag);
          const prevTag = tagList[currentIndex - 1];
          const nextTag = tagList[currentIndex + 1];
          // the tag's offsetLeft after of nextTag
          const afterNextTagOffsetLeft = nextTag.$el.offsetLeft + nextTag.$el.offsetWidth + tagSpacing;
          // the tag's offsetLeft before of prevTag
          const beforePrevTagOffsetLeft = prevTag.$el.offsetLeft - tagSpacing;
          if (afterNextTagOffsetLeft > scrollWrapper.value.scrollLeft + containerWidth) {
            scrollWrapper.value.scrollLeft = afterNextTagOffsetLeft - containerWidth;
          } else if (beforePrevTagOffsetLeft < scrollWrapper.value.scrollLeft) {
            scrollWrapper.value.scrollLeft = beforePrevTagOffsetLeft;
          }
        }
      }
    });
    const emitScroll = () => {
      context.emit('scroll');
    };
    onMounted(() => {
      scrollWrapper.value.addEventListener('scroll', emitScroll, true);
    });
    onBeforeUnmount(() => {
      scrollWrapper.value.removeEventListener('scroll', emitScroll);
    });
    return {
      scrollContainerRef,
      ...toRefs(state)
    };
  }
});