/*
 * @Author: yyl
 * @Date: 2021-07-01 13:27:26
 * @LastEditTime: 2021-07-08 18:09:27
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \energy-internet-web\src\utils\socket\index.js
 */
import SockJS from "sockjs-client";
import Stomp from "stompjs";
// function createSocketStomp() {
//   return new Promise((res, rej) => {
//     const url = "https://cdn.bootcss.com/stomp.js/2.3.3/stomp.min.js";
//     const script = document.createElement("script");
//     script.type = "text/javascript";
//     script.src = url;
//     script.async = true;
//     script.onerror = rej;
//     document.head.appendChild(script);
//     script.onload = () => {
//       res();
//     };
//   });
// }
// function createSocketClient() {
//   return new Promise((res, rej) => {
//     const url = "https://cdn.bootcss.com/sockjs-client/1.1.4/sockjs.min.js";
//     let script = document.createElement("script");
//     script.type = "text/javascript";
//     script.src = url;
//     script.async = true;
//     script.onerror = rej;
//     document.head.appendChild(script);
//     script.onload = () => {
//       res();
//     };
//   });
// }
// 引入SockJS及Stomp并挂载到window上
async function initSocket() {
  try {
    await createSocketStomp();
    await createSocketClient();
    return new Promise((resolve, reject) => {
      resolve([window.SockJS, window.Stomp]);
    });
  } catch (err) {
    console.error(err);
  }
}
// 建立socket连接
export async function socketInstance({
  
  errorCallBack = () => {},
  subscribeSocket = () => {},
  hostName=window.g.VUE_APP_IOT_SOCKET,
  servicePrefix='iot-manage-platform/wsendpoint',
  ...params
}) {
  // await initSocket();
  // const hostName = window.g.VUE_APP_101_SOCKET;
  // const socket = new SockJS(`${hostName}/wsendpoint`);
  // const socket = new SockJS(`${hostName}/iot-manage-platform/wsendpoint`);
  // const socket = new SockJS(`${hostName}/iot-manage-platform/wsendpoint`);
  const socket = new SockJS(`${hostName}/${servicePrefix}`);
 
  // const socket = new SockJS(`${hostName}/power-distribute/energy`);   //最新修改，待后台修改完成
  const stompClient = Stomp.over(socket);
  stompClient.debug = null;
  // stompClient.heartbeat.outgoing = 50000;
  return new Promise((resolve, reject) => {
    stompClient.connect(
      {
        ...params
      },
      () => {
        console.log(servicePrefix + "连接成功")
        //成功
        subscribeSocket(stompClient);
        resolve(stompClient);
      },
      e => {
        errorCallBack();
        // reject("连接失败！");
        reject();
      }
    );
  });
}
