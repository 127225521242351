import { createI18n } from 'vue-i18n'
import zh from './config/zh.js'
import en from './config/en.js'
const messages = {
  zh,
  en
}

const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  messages
})
export default i18n