const getters = {
    sidebar: (state) => state.common.sidebar,
    size: (state) => state.common.size,
    device: (state) => state.common.device,
    visitedViews: (state) => state.common.visitedViews,
    cachedViews: (state) => state.common.cachedViews,
    token: (state) => state.common.token,
    avatar: (state) => state.common.avatar,
    name: (state) => state.user.name,
    introduction: (state) => state.common.introduction,
    roles: (state) => state.common.roles,
    permissions: (state) => state.common.permissions,
    permission_routes: (state) => state.common.menu,
    theme: (state) => state.common.theme,
    userInfo: (state) => state.common.userInfo,
  }
  export default getters
  