function findFirstLevelNode(nodeData) {
  if (nodeData.children) {
    return findFirstLevelNode(nodeData.children[0]);
  } else {
    return nodeData;
  }
}
import LazyLoadTreeSelect from "../lazyLoadTreeSelect";
import { defineComponent, ref, nextTick } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import request from "../../utils/onlineRequest";
export default defineComponent({
  name: "lineTree",
  components: {
    LazyLoadTreeSelect
  },
  props: {
    url: {
      type: String,
      default: ''
    },
    nodeKey: {
      type: String,
      default: () => {
        return "name";
      }
    }
  },
  emits: ["handle-node-click"],
  setup(props, {
    emit
  }) {
    let defaultProps = ref({
      value: "id",
      label: "nodeName"
    });
    const lazyLoadTreeSelect = ref(null);
    let deviceTree = ref([]);
    const route = useRoute();
    const store = useStore();
    const queryLineData = (parentId = "") => {
      return new Promise((resolve, reject) => {
        request({
          url: props.url ? props.url : "/energy-comm-provider/cim/getCimTopuDeviceTree",
          method: "post",
          data: {
            parentId: parentId
          }
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err);
        });
      });
    };
    const handleTree = function (treeList = []) {
      treeList.forEach(item => {
        if (item.nodeType !== 5 && item.children.length === 0) {
          item.isLeaf = false;
        }
        handleTree(item.children);
      });
    };
    const queryDefaultTreeData = () => {
      queryLineData().then(res => {
        res = [res];
        handleTree(res);
        deviceTree.value = res;
        findFirstDevice();
      });
    };

    //找到第一个设备节点
    const findFirstDevice = async () => {
      let deviceTreeCopy = JSON.parse(JSON.stringify(deviceTree.value));
      let value = deviceTreeCopy.length && deviceTreeCopy[0];
      while (value.nodeType !== 5) {
        // 如果有children 且不是设备类型则继续向下查找
        if (value.children.length) {
          value = value.children[0];
        } else {
          await queryLineData(value.id).then(res => {
            handleTree(res);
            lazyLoadTreeSelect.value.$refs.treeRef.updateKeyChildren(value.id, res);
            if (res.length) {
              value = res[0];
            }
          });
        }
      }
      defaultCheckNode.value = [{
        id: value.id,
        nodeName: value.nodeName
      }];
      emit("handle-node-click", value);
    };

    //
    let defaultCheckNode = ref([]);
    queryDefaultTreeData();
    const handleNodeClick = function (value) {
      if (value.psrId) {
        defaultCheckNode.value = [{
          id: value.id,
          nodeName: value.nodeName
        }];
      }
      //nodeType = 5 是设备类型 也是叶子节点，不需要再发起请求
      if (value.nodeType !== 5) {
        queryLineData(value.id).then(res => {
          handleTree(res);
          lazyLoadTreeSelect.value.$refs.treeRef.updateKeyChildren(value.id, res);
        });
      }
      emit("handle-node-click", value);
    };
    const currentNodeKey = computed(() => "netsmaintrans");
    const handleSearchByName = inputName => {
      if (!inputName) {
        queryDefaultTreeData();
      } else {
        request({
          url: "/energy-comm-provider/cim/searchCimTopuDevice",
          method: "post",
          data: {
            deviceName: inputName
          }
        }).then(res => {
          if (!res.data) {
            deviceTree.value = [];
          } else {
            res.data = [res.data];
            handleTree(res.data);
            deviceTree.value = res.data;
          }
        }).catch(err => {});
      }
    };
    return {
      defaultCheckNode,
      deviceTree,
      defaultProps,
      handleNodeClick,
      currentNodeKey,
      lazyLoadTreeSelect,
      handleSearchByName
    };
  }
});