export default {
  name: 'EICount',
  props: {
    iconArrayList: {
      default: function () {
        return [];
      },
      type: Array
    }
  },
  data() {
    return {
      allCourt: {},
      backgroundColor: ['rgba(85,199,255,0.8)', 'rgba(96,255,210,0.8)', 'rgba(255,205,106,0.8)', 'rgba(90,142,255,0.8)', 'rgba(255,153,88,0.8)', 'rgba(85,199,255,0.8)', 'rgba(96,255,210,0.8)'],
      fontColors: ['#2DA3FB', '#F18A4C', '#29CFD1', '#4778fe', '#ffb320', '#a189ff', '#46CCCD'],
      itemBackgroundColor: ['rgba(85,199,255,0.3)', 'rgba(96,255,210,0.3)', 'rgba(255,205,106,0.3)', 'rgba(90,142,255,0.3)', 'rgba(255,153,88,0.3)', 'rgba(80,229,255,0.3)', 'rgba(199,180,255,0.5)'],
      lightLinearBgStart: ['#41CFFD', '#FFC549', '#44DBD1', '#53A2FF', '#FFCE6C', '#C4AAFE', '#63E2E8'],
      lightLinearBgEnd: ['#2B9FFB', '#F0854C', '#05C0D2', '#4778FE', '#FFB320', '#A189FF', '#46CCCD']
    };
  },
  mounted() {},
  created() {},
  methods: {}
};