import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { listDept, getDept, delDept, addDept, updateDept, getDicts, getDeptClient, buildDeptClient, reBuildDeptClient } from "@/api/system/dept";
import { useRouter } from 'vue-router';
import { deepClone, download, handleTree, selectDictLabel } from "@/utils";
import { getTree } from "@/api/system/area";
import { method } from 'lodash';
export default defineComponent({
  name: "Dept",
  components: {},
  props: ['path'],
  setup(props) {
    const formContainer = ref(ElForm);
    const voucherFormContainer = ref(ElForm);
    const router = useRouter();
    const areaValidator = (rule, value, callback) => {
      if (state.form.areaAllArray && state.form.areaAllArray.length) {
        if (state.form.areaAllArray.length < 3) {
          callback(new Error('必须选择到区县级'));
        } else {
          callback();
        }
      } else {
        callback(new Error('部门区域不能为空'));
      }
    };
    const deptOptions = ref([]);
    const isShow = ref(false);
    const state = reactive({
      statusOptions: [],
      datas: [],
      deptList: [],
      tableLoading: false,
      title: "添加岗位",
      dialogVisible: false,
      voucherDialogVisible: false,
      // 表单校验
      formRules: {
        parentId: [{
          required: true,
          message: "上级部门不能为空",
          trigger: "blur"
        }],
        deptName: [{
          required: true,
          message: "部门名称不能为空",
          trigger: "blur"
        }],
        orderNum: [{
          required: true,
          message: "菜单顺序不能为空",
          trigger: "blur"
        }],
        email: [{
          type: "email",
          message: "'请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }],
        phone: [{
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        areaAllArray: [{
          type: 'array',
          required: true,
          validator: areaValidator,
          trigger: 'change'
        }]
      },
      form: {
        parentId: undefined,
        deptName: "",
        orderNum: "",
        leader: "",
        phone: "",
        email: "",
        status: "0",
        areaAllArray: []
      },
      formData: [{
        prop: "parentId",
        label: "上级部门",
        placeholder: "请输入上级部门",
        props: {
          label: "deptName",
          value: "deptId",
          children: "children"
        },
        hideHandle: () => {
          return isShow.value;
        },
        //是否显示
        options: deptOptions,
        type: "tree",
        isSelect: true,
        span: 24
      }, {
        prop: "deptName",
        label: "部门名称",
        placeholder: "请输入部门名称",
        type: "text",
        isSelect: true
      }, {
        prop: "orderNum",
        label: "显示排序",
        placeholder: "请输入显示排序",
        type: "textNum",
        isSelect: true
      }, {
        prop: "leader",
        label: "负责人",
        placeholder: "请输入负责人",
        type: "text",
        isSelect: true
      }, {
        prop: "phone",
        label: "联系电话",
        placeholder: "请输入联系电话",
        type: "text",
        isSelect: true
      }, {
        prop: "email",
        label: "邮箱",
        placeholder: "请输入邮箱",
        type: "text",
        isSelect: true
      }, {
        prop: "status",
        label: '部门状态',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "0",
          value: '0',
          labelName: '正常'
        }, {
          label: "1",
          labelName: '停用',
          value: '1'
        }],
        span: 12
      }, {
        prop: "areaAllArray",
        label: "地区",
        placeholder: "请选择地区",
        type: "cascader",
        width: "100%",
        change: val => cityChange(val),
        treeProps: {
          lazy: true,
          value: "id",
          label: "name",
          checkStrictly: true,
          lazyLoad(node, resolve) {
            let id = null;
            if (!node.root) {
              id = node.data.id;
            } else {
              id = node.level;
            }
            getTree(id).then(response => {
              let nodes = response.data;
              nodes.forEach(n => {
                n.leaf = !n.leaf;
              });
              resolve(nodes);
            }).catch(err => {
              console.log(err);
            });
          }
        },
        isSelect: true,
        span: 12
      }],
      voucherForm: {
        deptId: undefined,
        clientId: "",
        clientSecret: ""
      },
      voucherFormData: [{
        prop: "clientId",
        label: "Clinet ID",
        placeholder: "请点击生成",
        type: "text",
        disabledHandle: () => true
      }, {
        prop: "clientSecret",
        label: "Clinet Secret",
        placeholder: "请点击生成",
        type: "text",
        disabledHandle: () => true
      }],
      queryParams: {
        deptName: "",
        status: ""
      },
      searchFormData: [{
        prop: "deptName",
        label: "部门名称",
        placeholder: "请输入部门名称查询",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        type: "list",
        label: "岗位状态",
        placeHolder: "请选择部门状态",
        prop: "status",
        options: [{
          label: "正常",
          value: "0"
        }, {
          label: "停用",
          value: "1"
        }],
        props: {
          label: "label",
          value: "value"
        },
        isShow: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['system:dept:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => methods.handleAdd(item)
      }]
    });
    const cityChange = function (val, operate) {
      if (val && val.length > 0) {
        if (!operate) {
          console.log(val);
          state.form.areaAllArray = val;
          state.form.areaAll = val.join(',');
        }
      }
    };
    const methods = reactive({
      close() {
        state.form = {
          parentId: undefined,
          deptName: "",
          orderNum: "",
          leader: "",
          phone: "",
          email: "",
          status: "0",
          areaAllArray: []
        };
      },
      getOptions() {
        getDicts("sys_normal_disable").then(response => {
          state.statusOptions = response.data;
        });
      },
      // closeSelect (val) {
      //   if(!val||(!val.length)){
      //     state.form['areaAllArray']=[];
      //     state.form['areaAll']=null
      //     state.form['areaId']=null
      //   }
      //   //第三级消失
      //   if (val && val.length === 3) {
      //     state.form['areaAll']=val.join(',')
      //     state.form['areaId']=val[1]
      //     // state.$refs.cascaderHandle.dropDownVisible = false; //监听值发生变化就关闭它
      //   }
      // },

      //搜索
      handleQuery() {
        methods.getList();
      },
      //重置
      resetQuery() {
        state.queryParams = {
          deptName: "",
          status: ""
        };
        methods.getList();
      },
      //列表查询
      getList() {
        state.tableLoading = true;
        listDept(state.queryParams).then(response => {
          state.deptList = handleTree(response.data, "deptId");
          state.tableLoading = false;
        });
      },
      //新增
      handleAdd(row) {
        state.dialogVisible = true;
        state.title = "添加部门";
        if (row.deptId) {
          state.form.parentId = row.deptId;
        }
      },
      /** 查询下拉树结构 */
      getTreeselect() {
        listDept().then(response => {
          deptOptions.value = handleTree(response.data, "deptId");
        });
      },
      // 字典状态字典翻译
      statusFormat(row, column) {
        return selectDictLabel(state.statusOptions, row.status);
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        // this.reset();
        // this.getTreeselect();
        state.dialogVisible = true;
        state.title = "修改部门";
        if (row.parentId == 0) {
          isShow.value = true;
        } else {
          isShow.value = false;
        }
        getDept(row.deptId).then(response => {
          state.form = response.data;
          if (row.areaAll) {
            state.form.areaAllArray = row.areaAll.split(',');
            state.form['areaId'] = row.areaAll.split(',')[1];
          }
        });
      },
      //表单确定
      submitForm: function () {
        console.log("表单数据", state.form);
        const formRef = unref(formContainer);
        formRef.commonForm.validate(valid => {
          if (valid) {
            state.saveLoading = true;
            if (state.title == "修改部门") {
              updateDept(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("修改成功");
                  state.saveLoading = false;
                  state.dialogVisible = false;
                  methods.getList();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            } else if (state.title == "添加部门") {
              // 新增
              addDept(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("新增成功");
                  state.dialogVisible = false;
                  state.saveLoading = false;
                  methods.resetQuery();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            }
          }
        });
      },
      // 打开Client凭证界面
      handleVoucher(row) {
        state.voucherDialogVisible = true;
        state.voucherForm.deptId = row.deptId;
        state.voucherForm.clientId = '';
        state.voucherForm.clientSecret = '';
        getDeptClient(row.deptId).then(response => {
          if (response.data) {
            state.voucherForm.clientId = response.data?.appId || '';
            state.voucherForm.clientSecret = response.data?.appSecret || '';
          }
        });
      },
      //Client 凭证表单提交
      buildVoucher: function () {
        if (!state.voucherForm.clientId) {
          buildDeptClient(state.voucherForm.deptId).then(response => {
            state.voucherForm.clientId = response.data.appId || '';
            state.voucherForm.clientSecret = response.data.appSecret || '';
          });
        } else {
          reBuildDeptClient(state.voucherForm.deptId).then(response => {
            state.voucherForm.clientId = response.data.appId || '';
            state.voucherForm.clientSecret = response.data.appSecret || '';
          });
        }
      },
      //删除
      handleDel(row) {
        ElMessageBox.confirm("确定是否删除?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delDept(row.deptId);
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("删除成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      }
    });
    methods.getList();
    methods.getOptions();
    methods.getTreeselect();
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer,
      areaValidator,
      deptOptions,
      isShow
    };
  }
});