import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2f6a7501"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "title-box"
};
const _hoisted_2 = {
  key: 0,
  class: "title-box"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  const _component_sidebar_item = _resolveComponent("sidebar-item", true);
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  return _ctx.isShow ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: _normalizeClass(['menu-wrapper', {
      'admin': _ctx.currentType === 'admin'
    }])
  }, [_ctx.hasOneShowingChild(_ctx.item.children, _ctx.item) && (!_ctx.onlyOneChild.children || _ctx.onlyOneChild.noShowingChildren) ? (_openBlock(), _createBlock(_component_el_menu_item, {
    key: 0,
    index: _ctx.showChildName(_ctx.item.children, _ctx.item),
    class: _normalizeClass({
      'submenu-title-noDropdown': !_ctx.isNest,
      'darkMenu': _ctx.themeSet == 'dark',
      'lightMenu': _ctx.themeSet == 'light'
    }),
    onClick: _ctx.clickHandle
  }, {
    default: _withCtx(() => [_ctx.item.meta ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.onlyOneChild.meta.icon ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      class: _normalizeClass(['iconfont', `${_ctx.onlyOneChild.meta.icon}`])
    }, null, 2)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_ctx.onlyOneChild.meta.title), 1)])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["index", "class", "onClick"])) : (_openBlock(), _createBlock(_component_el_sub_menu, {
    key: 1,
    index: _ctx.linkActiveHandle(_ctx.item),
    class: _normalizeClass({
      'darkMenu': _ctx.themeSet == 'dark',
      'lightMenu': _ctx.themeSet == 'light'
    }),
    "popper-class": ['nav-sub-menu', {
      'admin-sub-menu': _ctx.currentType === 'admin'
    }]
  }, {
    title: _withCtx(() => [_ctx.item.meta ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_ctx.onlyOneChild.meta.icon ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      class: _normalizeClass(['iconfont', `${_ctx.item.meta.icon}`])
    }, null, 2)) : _createCommentVNode("", true), _createElementVNode("span", null, _toDisplayString(_ctx.item.meta.title), 1)])) : _createCommentVNode("", true)]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item.children, child => {
      return _openBlock(), _createBlock(_component_sidebar_item, {
        key: child.path,
        "is-nest": true,
        item: child,
        "base-path": _ctx.resolvePath(child.path),
        class: "nest-menu"
      }, null, 8, ["item", "base-path"]);
    }), 128))]),
    _: 1
  }, 8, ["index", "class", "popper-class"]))], 2)) : _createCommentVNode("", true);
}