import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d869f184"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_sidebar = _resolveComponent("sidebar");
  const _component_row_navbar = _resolveComponent("row-navbar");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass($options.classObj),
    style: {
      "height": "100%"
    }
  }, [_ctx.$store.state.common.menuPositionSet === '0' ? (_openBlock(), _createBlock(_component_sidebar, {
    key: 0,
    class: "sidebar-container"
  })) : _createCommentVNode("", true), _ctx.$store.state.common.menuPositionSet === '1' ? (_openBlock(), _createBlock(_component_row_navbar, {
    key: 1
  })) : _createCommentVNode("", true), _createElementVNode("div", {
    class: _normalizeClass(["main-container", {
      noleftMenu: _ctx.$store.state.common.menuPositionSet === '1'
    }]),
    style: _normalizeStyle(this.$route.path === '/index' ? 'background-color:#0B1919;background-size: 20px 20px;' : '')
  }, [_createElementVNode("iframe", {
    ref: "MainLayout",
    style: _normalizeStyle($options.iframeStyle),
    src: $data.iframeSrc
  }, null, 12, _hoisted_1)], 6)], 2);
}