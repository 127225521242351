import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { updateUserProfile } from "@/api/system/user";
export default defineComponent({
  name: "",
  components: {},
  props: {
    user: {
      type: Object
    }
  },
  setup(props) {
    const formContainer = ref(ElForm);
    const router = useRouter();
    const state = reactive({
      // 表单校验
      rules: {
        nickName: [{
          required: true,
          message: "用户昵称不能为空",
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: "邮箱地址不能为空",
          trigger: "blur"
        }, {
          type: "email",
          message: "'请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }],
        phonenumber: [{
          required: true,
          message: "手机号码不能为空",
          trigger: "blur"
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }]
      }
    });
    const methods = reactive({
      submit() {
        const formRef = unref(formContainer);
        formRef.validate(valid => {
          if (valid) {
            updateUserProfile(props.user).then(response => {
              if (response.code === 1000) {
                ElMessage.success("修改成功");
              } else {
                ElMessage.error(response.msg);
              }
            });
          }
        });
      },
      close() {
        router.go(-1);
      }
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer
    };
  }
});