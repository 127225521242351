<template><section><html><head></head><body><h1>AKList 内容列表</h1>
<pre v-pre=""><code v-pre="" class="language-html"><span class="hljs-tag">&lt;<span class="hljs-name">ak-list</span> <span class="hljs-attr">:tableData</span>=<span class="hljs-string">"tableData"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ak-list</span>&gt;</span>
</code></pre>
<h2>API</h2>
<h3>Props</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>tableData</td>
<td>object</td>
<td>设计表格配置数据</td>
</tr>
<tr>
<td>searchData</td>
<td>object</td>
<td>列表页条件筛选表单数据，同表单的<code v-pre="">formData</code></td>
</tr>
<tr>
<td>beforeRequest</td>
<td>function(params,route)</td>
<td>请求列表前参数处理方法，可对请求参数处理</td>
</tr>
<tr>
<td>afterResponse</td>
<td>function/string</td>
<td>请求完成后列表数据处理方法</td>
</tr>
<tr>
<td>beforeDelete</td>
<td>function(params,route)</td>
<td>删除请求前事件</td>
</tr>
<tr>
<td>showPage</td>
<td>boolean/true</td>
<td>是否显示分页信息</td>
</tr>
<tr>
<td>requestUrl</td>
<td>string</td>
<td>请求的api接口方法</td>
</tr>
<tr>
<td>deleteUrl</td>
<td>string</td>
<td>删除的api接口方法</td>
</tr>
<tr>
<td>dict</td>
<td>object</td>
<td>用于匹配的字典数据，一般不设置，从接口获取</td>
</tr>
<tr>
<td>fixedBottomScroll</td>
<td>boolean/true</td>
<td>横向滚动条固定在浏览器底部</td>
</tr>
<tr>
<td>autoLoad</td>
<td>boolean/true</td>
<td>初始时是否自动请求加载数据</td>
</tr>
<tr>
<td>treeData</td>
<td>object</td>
<td>列表左侧栏树数据</td>
</tr>
</tbody>
</table>
<h3>Methods</h3>
<table>
<thead>
<tr>
<th>方法</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>getListData</td>
<td>列表请求方法，可手动调用</td>
</tr>
<tr>
<td>delClick</td>
<td>数据删除</td>
</tr>
<tr>
<td>table</td>
<td>表格方法</td>
</tr>
<tr>
<td>setSearchFormValue</td>
<td>设置查询条件表单初始值。可根据url参数先设置查询表单初始值再加载列表请求方法</td>
</tr>
</tbody>
</table>
<h3>Slot</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>default</td>
<td>位于筛选表单和表格列表间，可用于存放添加删除数据的按钮或其他操作</td>
</tr>
<tr>
<td>controlBtn</td>
<td>位于筛列表可配置新增删除按钮后面</td>
</tr>
<tr>
<td>searchForm</td>
<td>位于条件筛选表单内部</td>
</tr>
<tr>
<td>-</td>
<td>columns对应的props</td>
</tr>
</tbody>
</table>
<h3>tableData</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>tableProps</td>
<td>Object</td>
<td>表格配置</td>
</tr>
<tr>
<td>columns</td>
<td>Array</td>
<td>表格列配置</td>
</tr>
<tr>
<td>controlBtn</td>
<td>Array</td>
<td>列表上方按钮，如新增/删除</td>
</tr>
<tr>
<td>operateBtn</td>
<td>Array</td>
<td>操作拦按钮设置，仅对props='__control'列</td>
</tr>
<tr>
<td>events.beforeRequest</td>
<td>Function</td>
<td>同<code v-pre="">props.beforeRequest</code></td>
</tr>
<tr>
<td>events.afterResponse</td>
<td>Function/string</td>
<td>同<code v-pre="">props.afterResponse</code></td>
</tr>
<tr>
<td>events.beforeDelete</td>
<td>Function</td>
<td>同<code v-pre="">props.beforeDelete</code></td>
</tr>
</tbody>
</table>
<h3>tree</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>类型</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>show</td>
<td>boolean</td>
<td>是否显示</td>
</tr>
<tr>
<td>name</td>
<td>string</td>
<td>唯一标识，查询条件参数值</td>
</tr>
<tr>
<td>method</td>
<td>string</td>
<td>数据接口请求方式，get/post默认post</td>
</tr>
<tr>
<td>requestUrl</td>
<td>string</td>
<td>数据接口请求地址，必填</td>
</tr>
<tr>
<td>beforeRequest</td>
<td>Function(data,route)</td>
<td>接口请求前数据参数处理方式</td>
</tr>
<tr>
<td>afterResponse</td>
<td>Function/string</td>
<td>接口请求后数据参数处理方式</td>
</tr>
<tr>
<td>treeProps</td>
<td>object</td>
<td>组件tree对应props</td>
</tr>
</tbody>
</table>
</body></html></section></template>

