import request from '@/utils/onlineRequest'

// 查询task列表
export function listTask(query) {
  return request({
    url: '/device-operate-monitor/task/list',
    method: 'get',
    params: query
  })
}

// 查询表单
export function formDataShow(taskID) {
  return request({
    url: '/device-operate-monitor/task/formDataShow/'+taskID,
    method: 'get',
  })
}

// 查询表单
export function formDataSave(taskID,data) {
  return request({
    url: '/device-operate-monitor/task/formDataSave/'+taskID,
    method: 'post',
    data:data
  })
}

// 查询点号value
export function queryTelesignallCommaId(data) {
  return request({
    url: '/device-operate-monitor/monitorControlRecord/queryTelesignallCommaId',
    method: 'post',
    data:data
  })
}

// 查询点号value
export function updateStatus(data) {
  return request({
    url: '/device-operate-monitor/monitorControlRecord/updateStatus',
    method: 'post',
    data:data
  })
}




