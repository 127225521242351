import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-542e7f16"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
import { algorithmList, fuzzyReasoning, multiForkDecision, logisticRegression, svm, bayesInference, linearRegression, loadFlowNetworkTrafo } from "@/api/AI/index";
import { reactive } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const state = reactive({
      detailsDialogVisible: false,
      name: "",
      value: "",
      inputStr: "",
      algorithmListData: [],
      aIplatformLoading: false,
      // 总条数
      total: 0,
      // 表单参数
      form: {
        name: "",
        classify: "",
        description: "",
        icon: "",
        iconUrl: ""
      }
    });
    state.cloneForm = {
      ...state.form
    };
    const closeDialog = () => {
      state.name = "";
      state.value = "";
      state.inputStr = "";
    };
    const algorithmOperation = row => {
      state.detailsDialogVisible = true;
      if (row.name == "模糊推理算法") {
        fuzzyReasoning().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      } else if (row.name == "多叉决策树") {
        multiForkDecision().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      } else if (row.name == "逻辑回归分析") {
        logisticRegression().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      } else if (row.name == "支持向量机") {
        svm().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      } else if (row.name == "贝叶斯网络因果推理算法") {
        bayesInference().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      } else if (row.name == "线性回归分析") {
        linearRegression().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      } else if (row.name == "网架潮流模型算法") {
        loadFlowNetworkTrafo().then(response => {
          state.name = response.data.name;
          state.value = response.data.value;
          state.inputStr = response.data.inputStr;
        });
      }
    };
    const algorithmListHandle = () => {
      state.aIplatformLoading = true;
      algorithmList().then(response => {
        state.algorithmListData = response.data;
        state.aIplatformLoading = false;
      }).catch(() => {
        state.aIplatformLoading = false;
      });
    };
    algorithmListHandle();
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table = _resolveComponent("el-table");
      const _component_commonDialog = _resolveComponent("commonDialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_el_table, {
        stripe: "",
        data: state.algorithmListData
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table_column, {
          width: "55"
        }), _createVNode(_component_el_table_column, {
          label: "算法类型",
          prop: "type",
          "show-overflow-tooltip": ""
        }), _createVNode(_component_el_table_column, {
          label: "算法名称",
          prop: "name",
          "show-overflow-tooltip": ""
        }), _createVNode(_component_el_table_column, {
          label: "描述",
          prop: "description",
          "show-overflow-tooltip": ""
        }), _createVNode(_component_el_table_column, {
          label: "创建时间",
          prop: "createDate",
          "show-overflow-tooltip": ""
        }), _createVNode(_component_el_table_column, {
          label: "操作",
          width: "160",
          align: "center",
          "class-name": "small-padding fixed-width"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            size: "mini",
            type: "text",
            onClick: $event => algorithmOperation(scope.row)
          }, {
            default: _withCtx(() => [_createTextVNode("运行")]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })]),
        _: 1
      }, 8, ["data"])), [[_directive_loading, state.aIplatformLoading]]), _createVNode(_component_commonDialog, {
        modelValue: state.detailsDialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => state.detailsDialogVisible = $event),
        title: "运行结果",
        showFullScreen: true,
        onClose: closeDialog
      }, {
        default: _withCtx(() => [_createElementVNode("p", null, [_createTextVNode(" 算法名称："), _createElementVNode("span", null, _toDisplayString(state.name), 1)]), _createElementVNode("p", null, [_createTextVNode(" 输出结果："), _createElementVNode("span", null, _toDisplayString(state.value), 1)]), _createElementVNode("p", null, [_createTextVNode(" 输入参数："), _createElementVNode("span", null, _toDisplayString(state.inputStr), 1)])]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};