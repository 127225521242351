
import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import store from '@/store'
import { UserActionTypes } from "@/store/modules/user/action-types";
import { getToken } from '@/utils/auth'
import {setRequestUrl,setRequestBody} from './setHeader'
// import { useI18n } from 'vue-i18n'
// const { t } = useI18n()

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例



  const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL: process.env.VUE_APP_IOT_API,
    // 超时
    timeout: 1000 * 60
  })
  // request拦截器
  service.interceptors.request.use(
    (config) => {
      if (getToken()) {
        config.headers.Authorization = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        if(config.method==='post'){
          const temp=setRequestBody(config)
          console.log(temp,config,config.url)
          config.headers['signature'] = temp.signature
          config.headers['timestamp']=temp.timestamp
          config.headers['nonce']=temp.nonce
        }else{
          const temp=setRequestUrl(config)
          console.log(temp,config,config.url)
          config.headers['signature'] = temp.signature
          config.headers['timestamp']=temp.timestamp
          config.headers['nonce']=temp.nonce
        }
      }
      return config
    },
    (error) => {
      console.log(error)
      Promise.reject(error)
    }
  )

  // 响应拦截器
  service.interceptors.response.use(
    (res) => {
      const code = res.data.code
      // 判断是否有code存在，兼容导出时返回整个文件流
      if (code) {
        if (code === 401) {
          ElMessageBox.close()
          ElMessageBox.confirm(
            'The login status has expired, you can stay on this page, or log in again',
            'system hint',
            {
              confirmButtonText: 'Log in again',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
          ).then(() => {
            store.dispatch('common/LogOut').then(() => {
              location.reload() // 为了重新实例化vue-router对象 避免bug
            })
          })
        } else if (code === 1001) {
          ElMessage.closeAll()
          ElMessage({
            showClose: true,
            type: 'warning',
            message: 'warning'
          })
          // 7960 N-功能测试-数据库管理-数据库，选择一个已经删除掉的数据库中创建数据表，点击确定，提示语错误。  by yeyunli 2021/8/26
          // return res.data;
          return Promise.reject('warning')
        } else if (code === 1002) {
          ElMessage.closeAll()
          // 9051 N-功能测试-网格管理-数据导入-中压设备导入、低压设备导入-导入-上传文档提示异常  by yeyunli 2021/11/22
          // ElMessage({
          //   type: 'warning',
          //   message: res.data.message || res.data.data,
          // })
          return res.data
        } else if (code !== 1000) {
          ElMessage.closeAll()
          ElMessage({
            showClose: true,
            message: 'error' || '系统异常，请重新登录',
            type: 'error',
            duration: 3 * 1000
          })
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject('error')
        } else {
          return res.data
        }
      } else {
        // code不存在时返回后端return对象
        return res
      }
    },
    (error) => {
      ElMessageBox.close()
      if (error.response?.status === 401) {
        ElMessageBox.close()
        ElMessageBox.confirm(
          'The login status has expired, you can stay on this page, or log in again',
            'system hint',
            {
              confirmButtonText: 'Log in again',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
        ).then(() => {
          store.dispatch(UserActionTypes.ACTION_LOGIN_OUT);
        })
      } else {
        ElMessage.closeAll()
        ElMessage({
          showClose: true,
          message: error.message,
          type: 'error',
          duration: 5 * 1000
        })
        return Promise.reject(error)
      }
    }
  )

export default service
