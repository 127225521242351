const en = {
  message: {
    themeGreen: 'Green',
    themeBright: 'Bright',
    personalCenter: 'Personal center',
    Logut: 'Log out',
    loading: 'Loading...',
    menuCollapseAndExpand: 'collapse/expand menu',
    logoutPrompt: 'System Hint',
    confirmLogout: 'Are you sure you want to log out and exit the system?',
    loginTimeout: 'The login status has expired. You can continue to stay on this page or log in again.?',
    reLogin: 'Log in again?',
    cancel: 'Cancel',
  }
}
export default en
