import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  class: "tree-sidebar"
};
import { ref, reactive, onMounted } from 'vue';
import { getRequest } from '@/api/designForm';
import { useRoute } from 'vue-router';
import formatResult from '@/utils/formatResult';
export default {
  __name: 'listTreeSide',
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  emits: ['nodeClick'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const route = useRoute();
    // const treeRef = ref()
    const filterText = ref('');
    const loading = ref(false);
    const state = reactive({
      treeData: []
    });
    const inputChange = () => {
      const name = props.data.name;
      if (!name) {
        console.error(new Error('请设置侧栏树name值'));
        return;
      }
      loading.value = true;
      init(name);
    };
    const nodeClick = data => {
      emits('nodeClick', data.id || data.label);
    };
    const init = name => {
      const {
        requestUrl,
        method = 'post',
        beforeRequest,
        afterResponse
      } = props.data;
      if (requestUrl && method) {
        // 处理请求前的数据
        const params = name ? {
          [name]: filterText.value
        } : {};
        //console.log('params', params)
        let formatData = params;
        if (typeof beforeRequest === 'function') {
          formatData = beforeRequest(params, route);
        }
        if (formatData === false) {
          return;
        }
        if (method === 'get') {
          formatData = {
            params: formatData
          };
        }
        const options = {
          method: method
        };
        getRequest(requestUrl, formatData, options).then(res => {
          let result = res.data;
          // 这里做数据转换，很多时候后端并不能提供完全符合当前组件的数据
          if (afterResponse && typeof afterResponse === 'string') {
            result = formatResult(result, afterResponse);
          } else if (typeof afterResponse === 'function') {
            result = afterResponse(result);
          }
          state.treeData = result;
          loading.value = false;
        }).catch(() => {
          state.treeData = [];
          loading.value = false;
        });
      }
    };
    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_tree = _resolveComponent("el-tree");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_input, {
        modelValue: filterText.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => filterText.value = $event),
        placeholder: "输入关键字进行查找",
        onChange: inputChange
      }, null, 8, ["modelValue"]), _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_tree, _mergeProps({
        ref: "treeRef",
        class: "filter-tree"
      }, __props.data.treeProps, {
        data: state.treeData,
        "default-expand-all": "",
        "highlight-current": true,
        onNodeClick: nodeClick
      }), null, 16, ["data"])])), [[_directive_loading, loading.value]])]);
    };
  }
};