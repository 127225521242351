import axios from "axios";
import { remoteFile } from "@/api/AI/index";
import { setELUpload } from "@/utils/setHeader.js";
import { getToken } from "@/utils/auth";
export default {
  name: "DataSetUpload",
  props: {
    id: {
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      fileList: {},
      activeName: "本地文件",
      remote_file: ""
    };
  },
  computed: {
    uploadUrl() {
      return process.env.VUE_APP_BASE_API_AI + `/dataset/datasets/${this.id}/data/`;
    }
    // uploadUrl(){
    //     return `http://192.168.3.117:8000/datasets/${this.id}/data/`
    // }
  },
  methods: {
    fileOnchange(file, fileList) {
      console.log(file, fileList);
      this.fileList = fileList;
      console.log(file);
    },
    successHandle() {},
    //上传
    submit() {
      if (this.activeName === "本地文件") {
        //    this.$refs.upload.submit()
        let fileFormData = new FormData();
        console.log(111, this.fileList);
        this.fileList.forEach(item => {
          fileFormData.append("client_files", item.raw, item.name);
        });
        const temp = setELUpload(fileFormData);
        let token = "";
        if (getToken()) {
          // 判定是否有token
          token = "Bearer " + getToken();
        }
        let config = {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: token,
            signature: temp.signature,
            timestamp: temp.timestamp,
            nonce: temp.nonce
          }
        };
        axios.post(this.uploadUrl, fileFormData, config).then(response => {
          this.$emit("updateData");
        });
      } else {
        this.remoteFile();
      }
      //
    },
    //远程上传
    async remoteFile() {
      if (this.remote_file) {
        let remote_files = this.remote_file.split("\n");
        let fileFormData = new FormData();
        remote_files.forEach(item => {
          fileFormData.append("remote_files", item);
        });
        let {
          message
        } = await remoteFile(this.id, fileFormData);
        if (message === "上传文件成功") {
          this.remote_file = "";
          this.$emit("updateData");
        }
      }
    }
  }
};