import { problemDetails, childModel } from "@/api/AI/index";
import { getDate } from "@/utils/formatDate.js";
export default {
  name: "",
  data() {
    return {
      url: "",
      pageSize: 10,
      page: 1,
      total: 0,
      totalElement: "",
      tableData: [],
      loading: true,
      columns: [],
      query: {
        problemId: "",
        pageSize: "",
        page: ""
      },
      // 表单参数
      form: {
        icon: "",
        classify: {
          id: "",
          title: ""
        },
        description: "",
        creatTime: ""
      }
    };
  },
  filters: {
    FormatDay: function (val) {
      return getDate(val, "day");
    }
  },
  created() {
    if (this.$route.query.type == "algorithmDetails") {
      this.getForm(this.$route.query.id);
      this.query.problemId = this.$route.query.id;
    }
    // console.log('@',this.$route.query.id)
    this.getList();
  },
  methods: {
    handleTableData(tableData) {
      //所有校验集标签数量字段的并集
      let validationCountKeyUnion = new Set();
      //所有训练集召回率字段的并集
      let trainRecallKeyUnion = new Set();
      //所有训练集ap字段的并集
      let trainApKeyUnion = new Set();
      const newTableData = tableData.map(record => {
        const {
          name,
          validationSetLab,
          trainSetLab,
          evaluates
        } = record;
        let newRecord = {
          name
        };
        (validationSetLab || []).forEach(item => {
          const propName = "validation-" + (item.labName || "") + "-count";
          validationCountKeyUnion.add(propName);
          newRecord = {
            ...newRecord,
            [propName]: item.count
          };
        });
        (trainSetLab || []).forEach(item => {
          const propName = "train-" + (item.labName || "") + "-recalls";
          trainRecallKeyUnion.add(propName);
          newRecord = {
            ...newRecord,
            [propName]: item.recalls
          };
        });
        ((evaluates || {}).metrics || []).forEach(item => {
          if (item.key == "ap") {
            const propName = "train-" + (item.displayName || "") + "-ap";
            trainApKeyUnion.add(propName);
            newRecord = {
              ...newRecord,
              [propName]: item.value
            };
          } else if (item.key == "size") {
            //每行记录都有一个size字段
            newRecord = {
              ...newRecord,
              size: (item.value || "") + "Mp"
            };
          } else if (item.key == "mAP") {
            //每行记录都有一个Map字段
            newRecord = {
              ...newRecord,
              map: item.value
            };
          }
        });
        return newRecord;
      });
      this.genColumns([...trainApKeyUnion], [...validationCountKeyUnion], [...trainRecallKeyUnion]);
      this.tableData = newTableData;
    },
    genColumns(trainApKeyUnion, validationCountKeyUnion, trainRecallKeyUnion) {
      let columns = [{
        label: "模型名称",
        prop: "name"
      }, {
        label: "MAP(%)",
        prop: "map"
      }];
      trainApKeyUnion.forEach(item => {
        const label = item.split("-")[1];
        columns.push({
          label,
          prop: item
        });
      });
      validationCountKeyUnion.forEach(item => {
        const label = item.split("-")[1] + "样本数量";
        columns.push({
          label,
          prop: item
        });
      });
      columns.push({
        label: "大小",
        prop: "size"
      });
      trainRecallKeyUnion.forEach(item => {
        const label = item.split("-")[1] + "召回率";
        columns.push({
          label,
          prop: item
        });
      });
      this.columns = columns;
    },
    getList() {
      this.loading = true;
      let params = {
        page: "",
        pageSize: "",
        query: {
          problemId: ""
        }
      };
      params.query.problemId = this.query.problemId;
      params.page = this.page - 1;
      params.pageSize = this.pageSize;
      childModel(params).then(response => {
        // console.log('@@',response.data.result)
        // this.list = response.data.result;
        this.handleTableData(response.data.result);
        this.total = response.data.totalElement;
        this.loading = false;
      });
    },
    getForm(id) {
      problemDetails(id).then(response => {
        this.form = response.data;
        this.url = response.data.imagesUrls;
        if (this.url == null) {
          this.url = require("@/assets/img/noImg.png");
        }
        // console.log('@@@@@@@',response.data)
      });
    }
  }
};