import logo from "./test.png";
export const imgPath = logo;
export const title = "模板1";

export const formData = {
  list: [
    {
      type: "input",
      control: {
        modelValue: "",
      },
      config: {},
      name: "input1682389795297",
      item: {
        label: "单行文本",
      },
      customRules: [
        {
          type: "required",
          message: "必填项",
          trigger: "blur",
        },
      ],
    },
    {
      type: "input",
      control: {
        modelValue: "",
      },
      config: {},
      name: "input1682389812522",
      item: {
        label: "单行文本",
      },
      customRules: [
        {
          type: "required",
          message: "必填项",
          trigger: "blur",
        },
      ],
    },
    {
      type: "select",
      control: {
        modelValue: "",
        appendToBody: true,
      },
      options: [
        {
          label: "选项1",
          value: "1",
        },
        {
          label: "选项2",
          value: "2",
        },
        {
          label: "选项3",
          value: "3",
        },
      ],
      config: {
        optionsType: 0,
      },
      name: "select1682389815826",
      item: {
        label: "下拉选择框",
        rules: [
          {
            required: true,
            message: "必填项",
            trigger: "change",
          },
        ],
      },
    },
    {
      type: "input",
      control: {
        modelValue: "",
      },
      config: {},
      name: "input1682389821603",
      item: {
        label: "单行文本",
      },
      customRules: [
        {
          type: "required",
          message: "必填项",
          trigger: "blur",
        },
      ],
    },
    {
      type: "select",
      control: {
        modelValue: "",
        appendToBody: true,
      },
      options: [],
      config: {
        optionsType: 2,
        optionsFun: "alarm_type",
      },
      name: "select1682389826106",
      item: {
        label: "下拉选择框",
        rules: [
          {
            required: true,
            message: "必填项",
            trigger: "change",
          },
        ],
      },
    },
    {
      type: "input",
      control: {
        modelValue: "",
      },
      config: {},
      name: "input1682389830697",
      item: {
        label: "单行文本",
      },
      customRules: [],
    },
    {
      type: "textarea",
      control: {
        modelValue: "",
      },
      config: {
        span: 24,
      },
      name: "textarea1682389838214",
      item: {
        label: "多行文本",
      },
    },
    {
      type: "div",
      control: {},
      config: {
        textAlign: "center",
        span: 24,
      },
      list: [
        {
          type: "button",
          control: {
            label: "保存",
            key: "submit",
            type: "primary",
          },
          config: {
            span: 0,
          },
        },
        {
          type: "button",
          control: {
            label: "取消",
            key: "cancel",
            type: "",
          },
          config: {
            span: 0,
          },
        },
      ],
    },
  ],
  form: {
    labelWidth: "100",
    class: "form-row-2",
    size: "default",
    name: "form1663920704871",
  },
  config: {},
};
