import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "top-container searchTop"
};
const _hoisted_2 = {
  class: "search-left"
};
const _hoisted_3 = {
  style: {
    "width": "100%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_commonForm = _resolveComponent("commonForm");
  const _component_commonTable = _resolveComponent("commonTable");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_commonForm, {
    form: _ctx.queryParams,
    formData: _ctx.searchFormData,
    formHandle: _ctx.formHandle
  }, null, 8, ["form", "formData", "formHandle"])])]), _createElementVNode("div", _hoisted_3, [_withDirectives(_createVNode(_component_commonTable, {
    ref: "tableContainer",
    class: "flex-table",
    tableData: _ctx.tableData,
    tableHeader: _ctx.showTableHeader,
    tableOrgingHeader: _ctx.tableHeader,
    queryParams: _ctx.queryParams,
    multipleSelection: _ctx.multipleSelection,
    "onUpdate:multipleSelection": _cache[0] || (_cache[0] = $event => _ctx.multipleSelection = $event),
    onGetList: _ctx.getList,
    hasSelect: true,
    columnSetting: false
  }, null, 8, ["tableData", "tableHeader", "tableOrgingHeader", "queryParams", "multipleSelection", "onGetList"]), [[_directive_loading, _ctx.tableLoading]])])]);
}