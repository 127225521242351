export const UserMutationTypes = {
  SET_TOKEN: "SET_TOKEN",
  SET_NAME: "SET_NAME",
  SET_AVATAR: "SET_AVATAR",
  SET_INTRODUCTION: "SET_INTRODUCTION",
  SET_ROLES: "SET_ROLES",
  SET_EMAIL: "SET_EMAIL",
  SET_PERMISSION: "SET_PERMISSION",
  SET_DEPT: "SET_DEPT",
  SET_THEMEMODE: "SET_THEMEMODE",
};
