/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
    return /^(https?:|mailto:|tel:)/.test(path);
  }
  
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  export function validUsername(str) {
    const valid_map = ["admin", "editor"];
    return valid_map.indexOf(str.trim()) >= 0;
  }
  
  /**
   * @param {string} url
   * @returns {Boolean}
   */
  export function validURL(url) {
    const reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
    return reg.test(url);
  }
   
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  export function validLowerCase(str) {
    const reg = /^[a-z]+$/;
    return reg.test(str);
  }
  
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  export function validUpperCase(str) {
    const reg = /^[A-Z]+$/;
    return reg.test(str);
  }
  
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  export function validAlphabets(str) {
    const reg = /^[A-Za-z]+$/;
    return reg.test(str);
  }
  
  /**
   * @param {string} email
   * @returns {Boolean}
   */
  export function validEmail(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(email);
  }
  
  /**
   * @param {string} str
   * @returns {Boolean}
   */
  export function isString(str) {
    if (typeof str === "string" || str instanceof String) {
      return true;
    }
    return false;
  }
  
  /**
   * @param {Array} arg
   * @returns {Boolean}
   */
  export function isArray(arg) {
    if (typeof Array.isArray === "undefined") {
      return Object.prototype.toString.call(arg) === "[object Array]";
    }
    return Array.isArray(arg);
  }
  // -------------------- From表单验证规则  可用于公用的校验部分---------------------------------------------------------------
  const intNumber7 = /^[1-9\\g][0-9\\g]{0,6}$/; //7位内的正数
  const txtChineseNum30 = /^[a-zA-Z0-9\.\u4e00-\u9fa5_\\g]{1,30}$/; //30个字符支持中英文、数字、下划线（_）、点（.）
  const txtChineseNumlow70 = /^[a-zA-Z0-9\_\-\u4e00-\u9fa5_\\g]{1,70}$/; //70个字符支持中英文、数字、下划线（_）、中划线（-）
  const txtEglishNum24 = /^[a-zA-Z0-9\\g]{1,24}$/; //24个字符支持英文、数字
  const newTxtEglishNum24 = /^[a-zA-Z0-9\_\-\u4e00-\u9fa5_\\g]{1,24}$/; //24个字符支持英文、数字、下划线（_）、中划线（-）
  const txtChinese30 = /^[\u4e00-\u9fa5\\g]{1,30}$/; //30个中文字符
  const txtChineseEnglish30 = /^[a-zA-Z\u4e00-\u9fa5_\\g]{1,30}$/; //30个字符支持中英文、下划线（_）
  // 6914 N-功能测试-网格管理-网格配置-台区管理-添加台区 【台区纬度输入框输入03也可以保存成功，其他界面也存在】 by yeyunli
  const longitude = /^-?(\d{1,2}(\.\d{1,6})?|1[0-7]\d(\.\d{1,6})?|180)$/; //经度范围-180~180,
  const latitude = /^-?(\d(\.\d{1,6})?|[1-8]\d(\.\d{1,6})?|90)$/; // 纬度范围-90~90，
  const intNumberlow150 = /^-?((0|[1-9]\d?|1[1-4]\d)(\.\d{1,2})?|150(\.0{1,2})?)?$/; //150以内的数字，最多两位小数
  const intNumberlow120 = /^-?((0|[1-9]\d?|1[01]\d)(\.\d{1,2})?|120(\.0{1,2})?)?$/; //120以内的数字，最多两位小数
  const twoFloatNum = /^\d{1,2}\.\d{0,2}$/; //整数位2位，保留两位小数twoFloatNum
  
  const formValidate = (function() {
    function formValidate() {}
  
    formValidate.Form = function() {
      return {
        intNumber150(rule, value, callback) {
          if (value && !intNumberlow150.test(value)) {
            callback(new Error("请输入150以内的数字"));
          } else {
            callback();
          }
        },
        intNumber120(rule, value, callback) {
          if (value && !intNumberlow120.test(value)) {
            callback(new Error("请输入120以内的数字"));
          } else {
            callback();
          }
        },
        intNumber7(rule, value, callback) {
          if (value && !intNumber7.test(value)) {
            callback(new Error("请输入7位内的正数"));
          } else {
            callback();
          }
        },
        intNumber10(rule, value, callback) {
          const intNumber10 = /^[1-9\\g][0-9\\g]{0,9}$/; //10位内的正数
          if (value && !intNumber10.test(value)) {
            callback(new Error("请输入10位内的正数"));
          } else {
            callback();
          }
        },
        txtChineseNum30(rule, value, callback) {
          if (value && !txtChineseNum30.test(value)) {
            callback(new Error("30个字符支持中英文、数字、下划线（_）、点（.）"));
          } else {
            callback();
          }
        },
        txtChineseNum70(rule, value, callback) {
          if (value && !txtChineseNumlow70.test(value)) {
            callback(
              new Error("70个字符支持中英文、数字、下划线（_）、中划线（-）")
            );
          } else {
            callback();
          }
        },
        txtChineseEnglish30(rule, value, callback) {
          if (value && !txtChineseEnglish30.test(value)) {
            callback(new Error("30个字符支持中英文、下划线（_）"));
          } else {
            callback();
          }
        },
        txtEglishNum24(rule, value, callback) {
          if (value && !txtEglishNum24.test(value)) {
            callback(new Error("24个字符支持英文、数字"));
          } else {
            callback();
          }
        },
        newTxtEglishNum24(rule, value, callback) {
          if (value && !newTxtEglishNum24.test(value)) {
            callback(new Error("24个字符支持英文、数字、下划线（_）、中划线（-）"));
          } else {
            callback();
          }
        },
        txtChinese30(rule, value, callback) {
          if (value && !txtChinese30.test(value)) {
            callback(new Error("30个中文字符"));
          } else {
            callback();
          }
        },
        longitude(rule, value, callback) {
          if (value && !longitude.test(value)) {
            callback(new Error("经度整数部分为-180到180,小数部分为0到6位!"));
          } else {
            callback();
          }
        },
        latitude(rule, value, callback) {
          if (value && !latitude.test(value)) {
            callback(new Error("纬度整数部分为-90到90,小数部分为0到6位!"));
          } else {
            callback();
          }
        },
        validateName(rule, value, callback) {
          // 英文字母、数字、下划线（_）、连接号（-）；
          var reg = /^[_\-\/a-zA-Z0-9\\g]{0,128}$/;
  
          if (reg.test(value)) {
            callback();
          } else {
            var errorMsg = "支持英文数字下划线(_)和连接符(-)，不超过128个字符";
            callback(new Error(errorMsg));
          }
        },
        checkCpu(rule, value, callback) {
          var reg = /^(2|3|4)$/;
          if (reg.test(value)) {
            callback();
          } else {
            callback(new Error("值为2、3、4 必填项"));
          }
        },
        checkCpuLmt(rule, value, callback) {
          var reg = /^[1-9][0-9]$/;
          if (value == "" || value == null) {
            callback();
          } else {
            if (reg.test(value)) {
              callback();
            } else {
              callback(new Error("百分数值10-99%的整数"));
            }
          }
        },
        checkDisks(rule, value, callback) {
          var reg = /^[1-9]+\d{0,}$/;
          if (reg.test(value) && Number(10) <= Number(value)) {
            callback();
          } else {
            callback(new Error("必填项,大于等于10的整数"));
          }
        },
        checkMemory(rule, value, callback) {
          var reg = /^[0-9]+\d{0,}$/;
          if (reg.test(value) && Number(4) < Number(value)) {
            callback();
          } else {
            callback(new Error("单位M必填项,大于10M的整数"));
          }
        },
        checkMemLmt(rule, value, callback) {
          var reg = /^[1-9][0-9]$/;
          if (value == "" || null == value) {
            callback();
          } else {
            if (reg.test(value)) {
              callback();
            } else {
              callback(new Error("百分数值10-99%的整数"));
            }
          }
        },
        checkMount(rule, value, callback) {
          var reg = /^(\/([0-9a-zA-Z:_]+))+$/;
          var mount = value;
          if (mount != "" && mount != null) {
            // bug 2535 【必现】【边缘设备】容器配置后挂载的宿主机文件夹展示的数据重复 wangzhixin
            // var mounts=mount.split(";");
            var mounts = mount.split(",");
            var ss = true;
            for (var i = 0; i < mounts.length; i++) {
              if (reg.test(mounts[i])) {
              } else {
                ss = false;
              }
            }
            if (ss) {
              callback();
            } else {
              callback(
                new Error('文件夹以"/"开头且不可有中文,多个用","逗号隔开')
              );
            }
          } else {
            callback();
          }
        },
        checkPort(rule, value, callback) {
          var flag = false;
          var reg = /^[0-9:]+$/;
          var port = value;
          if (port != "" && port != null) {
            var ports = port.split(":");
            for (var i = 0; i < ports.length; i++) {
              if (
                reg.test(ports[i]) &&
                parseInt(ports[i]) <= 65535 &&
                parseInt(ports[i]) >= 0
              ) {
                // callback();
              } else {
                // eslint-disable-next-line standard/no-callback-literal
                flag = true;
                break;
              }
            }
            if (flag) {
              callback(new Error('整数请用":"英文冒号分隔端口(范围:0-65535)'));
            } else {
              callback();
            }
          } else {
            callback();
          }
        },
        twoFloatNum(rule, value, callback) {
          if (value && !twoFloatNum.test(value)) {
            callback(new Error(" 整数最多2位，小数最多2位"));
          } else {
            callback();
          }
        }
      };
    };
    return formValidate;
  })();
  
  export { formValidate };
  