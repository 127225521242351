import RightPanel from "../components/RightPanel";
import { AppMain, Navbar, RowNavbar, Settings, Sidebar, TagsView } from "./components";
import ResizeMixin from "./mixin/ResizeHandler";
import { mapState } from "vuex";
import Hamburger from "../components/Hamburger";
import Screenfull from "../components/Screenfull";
import { getToken } from "../utils/auth.js";
import { mapGetters } from "vuex";
import { base64Handle } from "../utils/index.js";
import Headbar from "./components/headbar/index.vue";
//  import start from '@/micros'

export default {
  name: "Layout",
  components: {
    AppMain,
    Navbar,
    RowNavbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView,
    Hamburger,
    Screenfull,
    Headbar
  },
  props: {
    container: {
      type: Object,
      default: null
    },
    isNotQiankun: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedMenuName: "",
      selectedTree: "",
      safeDays: [2, 0, 0],
      iframeData: new Map([["/devPlatform/dataReport", `${window.g.VUE_APP_DATA_TABLE}`, "http://10.1.2.21:16119/"], ["/devPlatform/hightopo", `${window.g.VUE_APP_HIGHTOPO_URL}`],
      //三维地图
      ["/devPlatform/3dmap", window.g.VUE_APP_3DMAP_URL],
      //集群管理
      ['/module/dataPlatform/colony-manage/colony-list', `${window.g.VUE_APP_ColonyManage_List}`],
      //集群框架
      ['/module/dataPlatform/colony-manage/colony-frame', `${window.g.VUE_APP_ColonyManage_Frame}`]])
    };
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.common.sidebar,
      device: state => state.common.device,
      showSettings: state => state.common.showSettings,
      needTagsView: state => state.common.tagsView,
      fixedHeader: state => state.common.fixedHeader,
      visitedViews: state => state.common.visitedViews,
      userInfo: state => state.common.userInfo
      // theme: state=> state.common.theme,
    }),
    showSideBar() {
      if (this.$store.state.common.menuPositionSet === "0" && this.$route.path !== "/index" && !this.isGLHome) {
        return true;
      } else {
        return false;
      }
    },
    cachedViews() {
      return this.$store.state.common.cachedViews;
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    setting: {
      get() {
        return this.$store.state.common.showSettings;
      },
      set(val) {
        this.$store.dispatch("common/changeSetting", {
          key: "showSettings",
          value: val
        });
      }
    },
    iframeStyle() {
      return {
        width: "100%",
        padding: "0 16px",
        height: "100%",
        overflow: "hidden",
        border: 0
      };
    },
    currentIframe() {
      let path = this.$route.path;
      if (this.iframeData.get(path)) {
        return {
          src: this.iframeData.get(path)
        };
      } else {
        return false;
      }
    },
    isGLHome() {
      //管廊项目的首页、告警首页、设备运行页面不需要展示顶部和左侧导航
      if (this.$route.path.includes("manhole/home") || this.$route.path.includes("manhole/homeSJZ") || this.$route.path.includes("manhole/alarmHome") || this.$route.path.includes("manhole/deviceRunHome")) {
        return true;
      } else {
        return false;
      }
    }
  },
  created() {
    let username = base64Handle(this.$store.state.common.name);
    // this.iframeData.set(
    //   "/module/reportManagement/dataReport",
    //   `${window.g.VUE_APP_DATA_TABLE}?token=${username}`
    // );
  },
  mounted() {
    window.onresize = () => {
      if (document.documentElement.clientWidth < 1020) {
        this.$store.state.common.sidebar.opened = false;
      } else {
        this.$store.state.common.sidebar.opened = true;
      }
    };
  },
  watch: {
    // theme:{
    //   handler(){
    //     this.$store.commit('common/setTheme')
    //   },
    //   immediate:true
    // }
  },
  methods: {
    // 侧边栏展开收起
    toggleSideBar() {
      this.$store.dispatch("common/toggleSideBar");
    },
    handleClickOutside() {
      this.$store.dispatch("common/closeSideBar", {
        withoutAnimation: false
      });
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch("common/LogOut").then(() => {
          location.reload();
        });
      });
    }
  }
};