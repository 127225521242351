import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { useRouter } from 'vue-router';
import userAvatar from "./userAvatar";
import userInfo from "./userInfo";
import resetPwd from "./resetPwd";
import icon from './icon.vue';
import { getUserProfile } from "@/api/system/user";
import { Delete, Edit, Search, Share, Upload, Refresh, Plus, Download } from "@element-plus/icons-vue";
export default defineComponent({
  name: "Profile",
  components: {
    userAvatar,
    userInfo,
    resetPwd,
    icon
  },
  props: ['path'],
  setup(props) {
    const router = useRouter();
    const state = reactive({
      user: {},
      roleGroup: {},
      postGroup: {},
      activeTab: "userinfo"
    });
    const methods = reactive({
      getUser() {
        getUserProfile().then(response => {
          state.user = response.data;
          state.roleGroup = response.roleGroup;
          state.postGroup = response.postGroup;
        });
      },
      updateImgUrl(data) {
        console.log(data, "图片地址");
        state.user.avatar = data;
      }
    });
    methods.getUser();
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      Search
    };
  }
});