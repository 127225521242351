import {request} from '@/utils/request'

// 查询部门列表
export function listDept(query) {
  return request({
    url: '/system/dept/list',
    method: 'get',
    params: query
  })
}

// 查询部门详细
export function getDept(deptId) {
  return request({
    url: '/system/dept/' + deptId,
    method: 'get'
  })
}

// 查询部门下拉树结构
export function treeselect() {
  return request({
    url: '/system/dept/treeselect',
    method: 'get'
  })
}

// 根据角色ID查询部门树结构
export function roleDeptTreeselect(roleId) {
  return request({
    url: '/system/dept/roleDeptTreeselect/' + roleId,
    method: 'get'
  })
}

// 新增部门
export function addDept(data) {
  return request({
    url: '/system/dept',
    method: 'post',
    data: data
  })
}

// 修改部门
export function updateDept(data) {
  return request({
    url: '/system/dept/update',
    method: 'post',
    data: data
  })
}

// 删除部门
export function delDept(deptId) {
  return request({
    url: '/system/dept/delete/' + deptId,
    method: 'post'
  })
}

//查询部门区域下拉选项
export function getTree(id) {
  return request({
    url: '/area/tree/' + id,
    method: 'post',
    data: {}
  })
}
  // 根据字典类型查询字典数据信息
  export function getDicts(dictType) {
    return request({
      url: '/system/dict/data/dictType/' + dictType,
      method: 'get'
    })
  }

// 查询 Client凭证
export function getDeptClient(deptId) {
  return request({
    url: '/client/dept/' + deptId,
    method: 'get'
  })
}

// 生成 Client凭证
export function buildDeptClient(deptId) {
  return request({
    url: '/client/build/' + deptId,
    method: 'get'
  })
}

// 重新生成 Client凭证
export function reBuildDeptClient(deptId) {
  return request({
    url: '/client/rebuild/' + deptId,
    method: 'get'
  })
}