import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f228fd4"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_oneLevelMenu = _resolveComponent("oneLevelMenu");
  const _component_Action = _resolveComponent("Action");
  const _component_TopBar = _resolveComponent("TopBar");
  return _ctx.isGLHome ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_withDirectives(_createVNode(_component_oneLevelMenu, {
    ref: "menuRef"
  }, null, 512), [[_vShow, false]]), _createVNode(_component_Action, {
    adminToggle: _ctx.adminToggle
  }, null, 8, ["adminToggle"])])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(["headbar-container padding-n-10 padding_r-44 flex-box flex_w-wrap flex_a_i-center", {
      'headbar-container1': _ctx.isDark
    }])
  }, [_ctx.menuPositionSet === '1' ? (_openBlock(), _createBlock(_component_TopBar, {
    key: 0,
    ref: "topBarRef"
  }, null, 512)) : (_openBlock(), _createBlock(_component_oneLevelMenu, {
    key: 1,
    ref: "menuRef"
  }, null, 512)), _createVNode(_component_Action, {
    adminToggle: _ctx.adminToggle
  }, null, 8, ["adminToggle"])], 2));
}