import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { useRouter } from 'vue-router';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { updateUserPwd, getPublicKey } from "@/api/system/user";
import JSEncrypt from 'jsencrypt';
export default defineComponent({
  name: "",
  components: {},
  props: {},
  setup(props) {
    const router = useRouter();
    const formContainer = ref(ElForm);
    const equalToPassword = (rule, value, callback) => {
      if (this.user.newPassword !== value) {
        callback(new Error("两次输入的密码不一致"));
      } else {
        callback();
      }
    };
    const state = reactive({
      publicKey: "",
      psdStrongLevel: '',
      test: "1test",
      user: {
        oldPassword: undefined,
        newPassword: undefined,
        confirmPassword: undefined
      },
      // 表单校验
      rules: {
        oldPassword: [{
          required: true,
          message: "旧密码不能为空",
          trigger: "blur"
        }],
        newPassword: [{
          required: true,
          message: "新密码不能为空",
          trigger: "blur"
        }, {
          pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,12}$/,
          message: "请输入8-12位包含字母、数字、特殊符号的密码",
          trigger: "blur"
        }],
        confirmPassword: [{
          required: true,
          message: "确认密码不能为空",
          trigger: "blur"
        }, {
          required: true,
          validator: equalToPassword.value,
          trigger: "blur"
        }]
      }
    });
    const methods = reactive({
      submit() {
        //获取公钥
        getPublicKey().then(response => {
          if (response.code === 1000) {
            let encrypt = new JSEncrypt();
            encrypt.setPublicKey(response.data); //此处为RSA公钥
            let passwordRSA = encrypt.encrypt(state.user.newPassword);
            let passwordRSB = encrypt.encrypt(state.user.oldPassword);
            const formRef = unref(formContainer);
            formRef.validate(valid => {
              if (valid) {
                updateUserPwd(passwordRSB, passwordRSA).then(response => {
                  if (response.code === 1000) {
                    ElMessage.success("修改成功");
                  } else {
                    this.msgError(response.msg);
                  }
                });
              }
            });
          } else {
            ElMessage.error(response.message);
          }
        });
      },
      //新增用户密码强度判断增加
      changePsdStrong(pwd) {
        if (pwd == null || pwd == '') {
          state.psdStrongLevel = '';
        } else {
          let S_level = methods.checkStrong(pwd);
          switch (S_level) {
            case 0:
              state.psdStrongLevel = '';
            case 1:
              state.psdStrongLevel = 0;
              break;
            case 2:
              state.psdStrongLevel = 1;
              break;
            default:
              state.psdStrongLevel = 2;
          }
        }
      },
      //判断密码的强度
      CharMode(iN) {
        if (iN >= 48 && iN <= 57)
          //数字
          return 1;
        if (iN >= 65 && iN <= 90)
          //大写字母
          return 2;
        if (iN >= 97 && iN <= 122)
          //小写
          return 4;else return 8; //特殊字符
      },
      //bitTotal函数
      //计算出当前密码当中一共有多少种模式
      bitTotal(num) {
        let modes = 0;
        for (let i = 0; i < 4; i++) {
          if (num & 1) modes++;
          num >>>= 1;
        }
        return modes;
      },
      //checkStrong函数
      //返回密码的强度级别
      checkStrong(sPW) {
        if (sPW.length <= 4) return 0; //密码太短
        let Modes = 0;
        for (let i = 0; i < sPW.length; i++) {
          //测试每一个字符的类别并统计一共有多少种模式.
          Modes |= methods.CharMode(sPW.charCodeAt(i));
        }
        return methods.bitTotal(Modes);
      },
      close() {
        router.go(-1);
      }
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer,
      equalToPassword
    };
  }
});