export default {
  name: 'AppMain',
  computed: {
    cachedViews() {
      // return this.$store.state.common.cachedViews
      return [];
    },
    key() {
      return this.$route.fullPath;
    },
    isAppContainer() {
      let route = this.$route;
      if (route.path === '/index') {
        return true;
      } else if (route.path.startsWith('/appLinkContainer')) {
        return true;
      } else {
        return false;
      }
    },
    styleObj() {
      if (this.isAppContainer) {
        return {
          'padding-top': '0px'
        };
      }
      //顶部菜单
      else if (this.$store.state.common.menuPositionSet === '1') {
        //顶部菜单首页
        return {
          'padding-top': '0px'
        };
      } else {
        // 左侧菜单
        return {
          'padding-top': '0px'
        };
      }
    }
  }
};