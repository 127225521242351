import { deepClone } from "@/utils/index";

export function filterAsyncRouter(asyncRouterMap, callback, name) {
  return asyncRouterMap.filter((route) => {
    if (route.component) {
      linkPageHandle(route, callback, name);
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
const linkPageHandle = (route, loadView, name) => {
  if (name) {
    let hasIndex = route.path.indexOf(":");
    if (hasIndex !== -1) {
      route.component = route.path.slice(0, hasIndex - 1);
    }
    route.path = name + route.path;
  }
  route.component = loadView(route.component);
};

export function routerHandle(routerList, callback, name) {
  if (routerList.length) {
    var routerCopy = deepClone(routerList);
    //
    detailData(routerCopy);
    //
    filterAsyncRouter(routerCopy, callback, name);
    return routerCopy;
  } else {
    return [];
  }
}

//把路由的path赋值给component

export function detailData(childrenData) {
  childrenData.forEach((item) => {
    // 没有设置组件路径 component
    if (
      item.component == "null" ||
      item.component == "" ||
      item.component === "Layout"
    ) {
      item.component = item.path;
    }
    if (item.meta.link && item.component) {
      delete item.component;
    }

    if (item.children && item.children.length > 0) {
      detailData(item.children);
    }
  });
}
//处理微服务静态路由
export function routerBaseHandle(routerList) {
  let copyData = deepClone(routerList);
  let baseData = [];
  if (copyData.length) {
    copyData.forEach((item) => {
      if (item.children) {
        item.children.forEach((it) => {
          it.path = `${item.path}/${it.path}`;
        });
        baseData.push(...item.children);
      }
    });
    return baseData;
  } else {
    return [];
  }
}
// 处理微服务路由回退丢失base基础路径问题
// 重写子服务路由router.push 方法，判断处理手动添加base路径
const toHandle = (toPath, routerBase) => {
  if (toPath.startsWith("./") || toPath.startsWith("/module")) {
    //做一个限定，在子服务中通过router.push 跳转到主服务，不需要拼接 routerBase
    // 限定这中情况要以 /module/main 开头进行配置路径，再经处理 去掉/module/main
    const mainPath = "/module/main";
    if (toPath.startsWith(mainPath)) {
      return toPath.slice(mainPath.length);
    }

    return toPath;
  } else {
    return routerBase + (toPath.startsWith("/") ? toPath : "/" + toPath);
  }
};

const routerPushHandle = (router, routerBase) => {
  //缓存原始push
  const push = router.push;
  //
  const pushHandler = (to) => {
    if (typeof to === "string") {
      //相对路径

      let path = toHandle(to, routerBase);
      push.call(router, path);
    }
    //
    if (typeof to === "object") {
      if (!to.path && to.query) {
        push.call(router, to);
        return;
      }

      let path = toHandle(to.path, routerBase);

      to.path = path;
      console.log("to======", to);
      push.call(router, to);
    }
  };
  router.push = pushHandler;
};

export default {
  routerHandle,
  routerBaseHandle,
  routerPushHandle,
};

// export function generateFlatRoutes(accessRoutes) {
//   let flatRoutes = []

//   for (let item of accessRoutes) {
//     let breadcrumb = []
//     if(item.meta&&item.meta.title){
//       breadcrumb = [item.meta.title]
//       item.meta.breadcrumb = breadcrumb
//     }
//     let childrenFflatRoutes = []
//     if (item.children && item.children.length > 0) {
//       childrenFflatRoutes = castToFlatRoute(item.children, '',item.meta.breadcrumb)
//     }

//     // 一级路由是布局路由,需要处理的只是其子路由数据
//     flatRoutes.push({
//       name: item.name,
//       path: item.path,
//       component: item.component,
//       redirect: item.redirect,
//       children: childrenFflatRoutes,
//     })
//   }

//   return flatRoutes
// }

// /**
//  * 将子路由转换为扁平化路由数组（仅一级）
//  * @param {待转换的子路由数组} routes
//  * @param {父级路由路径} parentPath
//  */
// function castToFlatRoute(routes, parentPath,breadcrumb, flatRoutes = []) {
//   for (let item of routes) {
//     let childBreadcrumb = []
//     if(item.meta&&item.meta.title){
//       childBreadcrumb = [...breadcrumb,item.meta.title]
//       item.meta.breadcrumb = childBreadcrumb
//     }
//     if (item.children && item.children.length > 0) {
//       if (item.redirect && item.redirect !== 'noRedirect') {
//         flatRoutes.push({
//           name: item.name,
//           path: (parentPath + '/' + item.path).substring(1),
//           redirect: item.redirect,
//           meta: item.meta,
//         })
//       }
//       castToFlatRoute(item.children, parentPath + '/' + item.path,item.meta.breadcrumb, flatRoutes)
//     } else {
//       flatRoutes.push({
//         name: item.name,
//         path: (parentPath + '/' + item.path).substring(1),
//         component: (parentPath + '/' + item.path).substring(1),
//         meta: item.meta,
//       })
//     }
//   }

//   return flatRoutes
// }

// let Base64 = require('js-base64').Base64
// export function base64Handle(str) {
//   return Base64.encode(str)
// }
