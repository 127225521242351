import request from "./requestProdApi";
import store from "@/store";
import md5 from "js-md5";
export function getServerTime() {
  return request({
    url: "/system/getTimestamp",
    method: "get",
  });
}

const salt = "energy-internet";
// 获取16位uuid指定长度和基数
function setUUID(len, radix) {
  let chars =
    "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split("");
  let uuid = [];
  radix = radix || chars.length;
  if (len) {
    for (let i = 0; i < len; i++)
      uuid[i] =
        chars[
          0 | (window.crypto.getRandomValues(new Uint8Array(1)) * 0.001 * radix)
        ];
  } else {
    // rfc4122, version 4 form
    let r;

    // rfc4122 requires these characters
    uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
    uuid[14] = "4";

    // Fill in random data.  At i==19 set the high bits of clock sequence as
    // per rfc4122, sec. 4.1.5
    for (let i = 0; i < 36; i++) {
      if (!uuid[i]) {
        r = 0 | (window.crypto.getRandomValues(new Uint8Array(1)) * 0.001 * 16);
        uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
      }
    }
  }
  return uuid.join("");
}
function getRequestParams(url = "") {
  let queryString = url.split("?")[1] || "";
  let obj = {};
  if (queryString) {
    let arr = queryString.split("&"); //先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
    for (let i of arr) {
      obj[i.split("=")[0]] = i.split("=")[1]; //对数组每项用=分解开，=前为对象属性名，=后为属性值
    }
  }
  return obj;
}
// requestUrl参数变更
export function setRequestUrl(config) {
  let tempString = "";
  config = JSON.parse(JSON.stringify(config));
  let requestParams = getRequestParams(config.url);
  requestParams = config.params
    ? Object.assign(config.params, requestParams)
    : requestParams;
  const requestObj = config.data ? config.data : requestParams;
  if (requestObj) {
    const requestObjKeys = Object.keys(requestObj);
    const requestObjKeysSort = requestObjKeys.sort(function (s, t) {
      let a = s.toLowerCase();
      let b = t.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    for (let i = 0; i < requestObjKeysSort.length; i += 1) {
      tempString += `${requestObjKeysSort[i]}=${
        requestObj[requestObjKeysSort[i]]
      }`;
    }
  } else {
    tempString = "";
  }
  const nonce = setUUID(16, 16);
  return {
    signature: md5(salt + store.state.serverTime + nonce + tempString),
    nonce,
    timestamp: store.state.serverTime,
    tempString,
    signatureTemp: salt + store.state.serverTime + nonce + tempString,
  };
}

// requestBody参数变更
export function setRequestBody(config, isFormData) {
  let requestObj;
  // formData需要特殊处理
  if (isFormData) {
    let data = config.data.entries();
    const dataArray = {};
    let i;
    for (i = 0; i < 100; i += 1) {
      let dataTemp = data.next();
      if (!dataTemp.done) {
        if (
          Object.prototype.toString.call(dataTemp.value[1]) !== "[object File]"
        ) {
          dataArray[dataTemp.value[0]] = dataTemp.value[1];
        } else {
          dataArray[dataTemp.value[0]] = dataTemp.value[1].name;
        }
      } else {
        break;
      }
    }
    // i=0 代表formdata中没有数据
    if (i === 0) {
      requestObj = {};
    } else {
      requestObj = dataArray;
    }
  } else {
    // 正常post请求参数处理
    config = JSON.parse(JSON.stringify(config));
    requestObj = config.data ? config.data : config.params;
  }
  let tempString = "";
  if (isFormData) {
    if (requestObj) {
      const requestObjKeys = Object.keys(requestObj);
      const requestObjKeysSort = requestObjKeys.sort(function (s, t) {
        let a = s.toLowerCase();
        let b = t.toLowerCase();
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      for (let i = 0; i < requestObjKeysSort.length; i += 1) {
        tempString += `${requestObjKeysSort[i]}=${
          requestObj[requestObjKeysSort[i]]
        }`;
      }
    }
  } else {
    tempString =
      setObjectString(requestObj) === ""
        ? ""
        : JSON.stringify(setObjectString(requestObj));
  }
  const nonce = setUUID(16, 16);
  return {
    signature: md5(salt + store.state.serverTime + nonce + tempString),
    nonce,
    timestamp: store.state.serverTime,
    tempString,
    signatureTemp: salt + store.state.serverTime + nonce + tempString,
  };
}

// ELUPLOAD上传接口参数变更  formdata对象需要特殊处理，params-额外参数
export function setELUpload(formdata, params) {
  let obj;
  let dataArray = {};
  let requestObj = {};
  let tempString = "";
  if (!formdata && !params) {
    obj = "";
  } else {
    if (formdata) {
      let data = formdata.entries();
      const dataArray = {};
      let i;
      for (i = 0; i < 100; i += 1) {
        let dataTemp = data.next();
        if (!dataTemp.done) {
          if (
            Object.prototype.toString.call(dataTemp.value[1]) !==
            "[object File]"
          ) {
            dataArray[dataTemp.value[0]] = dataTemp.value[1];
          } else {
            dataArray[dataTemp.value[0]] = dataTemp.value[1].name;
          }
        } else {
          break;
        }
      }
      requestObj = {
        ...dataArray,
      };
    }
    if (params) {
      requestObj = {
        ...dataArray,
        ...params,
      };
    }
  }
  if (formdata || params) {
    const requestObjKeys = Object.keys(requestObj);
    const requestObjKeysSort = requestObjKeys.sort(function (s, t) {
      let a = s.toLowerCase();
      let b = t.toLowerCase();
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    });
    for (let i = 0; i < requestObjKeysSort.length; i += 1) {
      tempString += `${requestObjKeysSort[i]}=${
        requestObj[requestObjKeysSort[i]]
      }`;
    }
  }
  const nonce = setUUID(16, 16);
  return {
    signature: md5(salt + store.state.serverTime + nonce + tempString),
    nonce,
    timestamp: store.state.serverTime,
    tempString,
    signatureTemp: salt + store.state.serverTime + nonce + tempString,
  };
}

// chunk上传接口参数变更  不校验参数
export function setChunkUpload() {
  const nonce = setUUID(16, 16);
  return {
    signature: md5(salt + store.state.serverTime + nonce),
    nonce,
    timestamp: store.state.serverTime,
    signatureTemp: salt + store.state.serverTime + nonce,
  };
}

// 递归排序参数key
export function setObjectString(requestObj) {
  // 参数可能为空字符串  为null 这是合法参数，不能剔除，需要返回相应的字符串
  if (requestObj === undefined) {
    return "";
  }
  if (requestObj === "") {
    return "";
  }
  // 判断是否为对象
  if (Object.prototype.toString.call(requestObj) === "[object Object]") {
    // 对象 空对象也该返回'{}'
    const requestObjKeys = Object.keys(requestObj);
    //
    if (requestObjKeys.length) {
      // 排序
      const requestObjKeysSort = requestObjKeys.sort(function (s, t) {
        let a = s.toLowerCase();
        let b = t.toLowerCase();
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      });
      const temp = {};
      for (let i = 0; i < requestObjKeysSort.length; i += 1) {
        // 为null的参数需要剔除
        if (requestObj[requestObjKeysSort[i]] !== null) {
          temp[requestObjKeysSort[i]] = setObjectString(
            requestObj[requestObjKeysSort[i]]
          );
        }
      }
      return temp;
    } else {
      return {};
    }
  } else if (Object.prototype.toString.call(requestObj) === "[object Array]") {
    if (requestObj.length) {
      // 数组对象
      if (Object.prototype.toString.call(requestObj[0]) === "[object Object]") {
        const temp = [];
        for (let i = 0; i < requestObj.length; i += 1) {
          temp.push(setObjectString(requestObj[i]));
        }
        return temp;
      } else {
        // 纯数组
        requestObj = requestObj.sort(function (s, t) {
          if (s < t) return -1;
          if (s > t) return 1;
          return 0;
        });
        for (let i = 0; i < requestObj.length; i += 1) {
          if (typeof (requestObj[i] !== "string")) {
            requestObj[i] = requestObj[i].toString();
          }
        }
        return requestObj;
      }
    } else {
      return [];
    }
  } else {
    return requestObj;
  }
}
