import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "top-container"
};
const _hoisted_3 = {
  class: "search-left"
};
const _hoisted_4 = {
  class: "button-container"
};
import { modelManageDownload, fetchModelManageDownList } from "@/api/AI/modelManageDownload";
import { setRequestUrl } from "@/utils/setHeader.js";
import { reactive, ref } from "vue";
import { downFile2 } from "@/utils";
export default {
  __name: 'index',
  setup(__props) {
    let queryParams = reactive({
      fileName: "",
      pageNum: 1,
      pageSize: 10
    });
    let searchFormData = [{
      prop: "fileName",
      label: "文件名称",
      placeholder: "请输入名称查询",
      type: "text",
      isSelect: false,
      span: 4
    }];
    const tableHeader = [{
      prop: "fileName",
      label: "名称",
      fixed: false,
      type: "text"
    }, {
      prop: "type",
      label: "类型",
      fixed: false,
      type: "text"
    }, {
      prop: "time",
      label: "创建时间",
      fixed: false,
      type: "text"
    }, {
      prop: "operation",
      label: "操作",
      width: "400px",
      fixed: "right",
      operation: [{
        name: "下载",
        clickFun: row => downLoadFile(row),
        hasPermi: ["aIplatform:modelManageDownload:download"]
      }]
    }];
    let formHandle = [{
      label: "搜索",
      type: "primary",
      icon: "el-icon-search",
      handle: () => handleQuery()
    }, {
      label: "重置",
      icon: "el-icon-refresh",
      class: "commonDarkBtn",
      handle: () => resetQuery()
    }];
    let loading = ref(false);
    let tableData = reactive({
      rows: [],
      total: 0
    });

    /** 重置按钮操作 */
    const resetQuery = () => {
      //bug 9276 N-功能测试-AI平台-数据集管理、模型管理下载，搜索按钮后缺少重置按钮 mosen 2021/12/01
      this.queryParams.fileName = "";
      this.handleQuery();
    };
    // 搜索
    const handleQuery = () => {
      queryParams.pageNum = 1;
      getList();
    };
    const downLoadFile = row => {
      modelManageDownload(row).then(response => {
        if (response.data) {
          const fileName = decodeURI(response.headers['content-disposition'].split('=')[1]);
          downFile2(fileName, response.data);
        }
      });
    };
    const download = row => {
      // window.location.href = process.env.VUE_APP_BASE_API_bigData + `/modelManagement/download/${row.dir}/${row.fileName}`
      const url = process.env.VUE_APP_BASE_API_bigData + `/modelManagement/download/${row.dir}/${row.fileName}`;
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      const temp = setRequestUrl({
        url: "/modelManagement/download"
      });
      xhr.setRequestHeader("signature", temp.signature);
      xhr.setRequestHeader("timestamp", temp.timestamp);
      xhr.setRequestHeader("nonce", temp.nonce);
      xhr.responseType = "blob";
      xhr.onload = function (e) {
        if (this.status == 200) {
          var blob = this.response;
          var filename = row.fileName;
          var a = document.createElement("a");
          var url = URL.createObjectURL(blob);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      };
      xhr.send();
    };
    const getList = () => {
      loading.value = true;
      fetchModelManageDownList(queryParams).then(response => {
        tableData.rows = response.rows;
        tableData.total = response.total;
      }).finally(() => {
        loading.value = false;
      });
    };
    return (_ctx, _cache) => {
      const _component_commonForm = _resolveComponent("commonForm");
      const _component_el_button = _resolveComponent("el-button");
      const _component_commonTable = _resolveComponent("commonTable");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_commonForm, {
        showLabel: false,
        form: _unref(queryParams),
        formData: _unref(searchFormData),
        formHandle: _unref(formHandle)
      }, null, 8, ["form", "formData", "formHandle"])]), _createElementVNode("div", _hoisted_4, [_withDirectives((_openBlock(), _createBlock(_component_el_button, null, {
        default: _withCtx(() => [_createTextVNode(" 下载 ")]),
        _: 1
      })), [[_directive_hasPermi, ['aIplatform:modelManageDownload:download']]])])]), _withDirectives(_createVNode(_component_commonTable, {
        class: "flex-table",
        tableData: _unref(tableData),
        tableHeader: tableHeader,
        queryParams: _unref(queryParams),
        onGetList: getList,
        hasSelect: false,
        columnSetting: false
      }, null, 8, ["tableData", "queryParams"]), [[_directive_loading, _unref(loading)]])]);
    };
  }
};