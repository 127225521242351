import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  created() {
    const {
      params,
      query
    } = useRoute();
    const {
      path
    } = params;
    const router = useRouter();
    setTimeout(() => {
      router.replace({
        path: "/" + path,
        query
      }).catch(err => {
        console.warn(err);
      });
    }, 0);
  }
});