import Cookies from 'js-cookie'
import axios from 'axios'
import querystring from 'querystring'
const TokenKey = 'Admin-Token'

export function getToken () {
  return Cookies.get(TokenKey)
}

export function setToken (token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken () {
  return Cookies.remove(TokenKey)
}

const title = process.env.VUE_APP_TITLE || '奎屯主动配电网系统'
const staticSetting = {
  appId: 'xWIqqaOPHQqJ',
  appSecret: '2a06IArpGsVH9qLH8C1U1',
  authUrl: `${window.g.VUE_APP_AUTH_URL}`,
  baseUrl: `${window.g.VUE_APP_BASE_URL}`,
  grant_type: 'authorization_code'
}
let trustToken = ''
export const getCode = function () {
  const name = 'code'
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  const r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}
export const accestokenByCode = async function () {
  const trustCode = getCode()

  if (!trustCode) {
    return false
  }

  const res = await axios.post(
    staticSetting.authUrl + '/oauth/token',
    {
      grant_type: 'authorization_code',
      code: trustCode,
      client: staticSetting.appId,
      redirect_uri: staticSetting.baseUrl
    },
    {
      transformRequest: [
        function (data) {
          return querystring.stringify(data)
        }
      ],
      auth: {
        username: staticSetting.appId,
        password: staticSetting.appSecret
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
    }
  )

  setToken(res.data.access_token)
  trustToken = res.data.access_token

  return trustToken
}

export async function analysisToken () {
  if (getCode()) {
    await accestokenByCode()
  }
}

export const toLogin = () => {
  if (staticSetting.grant_type === 'authorization_code') {
    window.location.href =
      staticSetting.authUrl +
      '/oauth/authorize?response_type=code&client_id=' +
      staticSetting.appId +
      '&redirect_uri=' +
      staticSetting.baseUrl + '&title=' + title
  } else if (staticSetting.grant_type === 'password') {
    // 密码模式demo
    // let userInfo = prompt('username&password').split('&')
    // Token.accestokenByBasic(userInfo[0], userInfo[1], next)
  }
}
