import errGif from '@/assets/401_images/401.gif';
export default {
  name: 'Page401',
  data() {
    return {
      errGif: errGif + '?' + +new Date()
    };
  },
  methods: {
    back() {
      if (this.$route.query.noGoBack) {
        this.$router.push({
          path: '/'
        });
      } else {
        this.$router.go(-1);
      }
    }
  }
};