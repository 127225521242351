import { unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, isRef as _isRef, mergeProps as _mergeProps, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent } from "vue";
import _imports_0 from './tinymce.png';
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 1,
  class: "icon-plus"
};
const _hoisted_7 = {
  class: "el-upload__tip"
};
const _hoisted_8 = {
  key: 1,
  alt: "",
  src: _imports_0,
  style: {
    "max-width": "100%"
  }
};
import { inject, onMounted, computed, watch, ref, onUnmounted, markRaw } from 'vue';
import md5 from 'md5';
import { ElMessage } from 'element-plus';
import Tooltip from '../../components/tooltip.vue';
import TinymceEdit from './tinymce.vue';
import { formatNumber, objectToArray } from '../../utils';
import validate from './validate';
import ExpandUser from './expand/user.vue';
import { constControlChange, constSetFormOptions, constFormProps, constGetControlByName } from '../../utils';
import AKSelect from './select.vue';
import { uploadUrl } from '@/api/designForm';
import { useRoute } from 'vue-router';
import formatResult from '@/utils/formatResult';
// import { getRequest } from '@/api/designForm'
import { getRequest } from '@/api/designForm';
import { debounce } from '@/utils';
import { getDicts } from "@/api/system/dept";
import areaCity from './components/areaCity';
export default {
  __name: 'formItem',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    modelValue: String,
    // 子表和弹性布局时时有传
    tProp: String // 子表时的form-item的prop值，用于子表校验用
  },
  emits: ['update:modelValue'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const route = useRoute();
    const formProps = inject(constFormProps, {});
    const type = computed(() => {
      return formProps.value.type;
    });
    const config = computed(() => {
      return props.data.config || {};
    });
    const control = ref(props.data.control);
    const options = ref(props.data.options);
    const changeEvent = inject(constControlChange, '');
    const updateModel = val => {
      changeEvent && changeEvent({
        key: props.data.name,
        value: val,
        data: props.data,
        tProp: props.tProp
      });
    };
    const value = computed({
      get() {
        if (props.tProp) {
          // 表格和弹性布局
          return props.modelValue;
        } else {
          return formProps.value.model[props.data.name];
        }
      },
      set(newVal) {
        if (props.tProp) {
          emits('update:modelValue', newVal);
        }
        updateModel(newVal);
      }
    });
    // 选择数据转换，默认尝试转数字
    const transformOption = (val, type) => {
      switch (config.value.transformData || type) {
        case 'none':
          return val;
        case 'string':
          try {
            return val.toString();
          } catch (e) {
            return val;
          }
      }
      return val;
    };

    // 当通用修改属性功能添加新字段时，数组更新但toRefs没更新
    const getControlByName = inject(constGetControlByName);
    const sourceFunKey = computed(() => {
      const iReg = new RegExp('(?<=\\${)(.*?)(?=})', 'g');
      //const iReg = new RegExp('\\${.*?}', 'g') // 结果会包含开头和结尾=>${name}
      const apiUrl = config.value.optionsFun;
      const replace = apiUrl?.match(iReg);
      return replace && replace[0];
    });
    const getLabel = ele => {
      const showColon = formProps.value.showColon ? '：' : '';
      if (ele) {
        return ele.showLabel ? '' : ele.label + showColon;
      } else {
        return '';
      }
    };
    const currentComponent = computed(() => {
      if (props.data.type === 'component') {
        // 自定义组件
        return config.value.componentName;
      }
      if (props.data.type === 'expand-user') {
        return markRaw(ExpandUser);
      }
      return `el-${props.data.type}`;
    });
    // 控制编辑模式下是否可用
    const editDisabled = computed(() => {
      if (type.value === 3) {
        return true; // 查看模式，为不可编辑状态
      }
      if (type.value === 1 && config.value.addDisabled) {
        return true;
      }
      if (type.value === 2 && config.value.editDisabled) {
        return true; // 编辑模式
      }
      return control.value.disabled;
    });
    // 返回当前item项的校验规则
    const itemRules = computed(() => {
      let temp = undefined;
      const itemR = props.data.item?.rules || [];
      const customR = formatCustomRules();
      // 如果三个都没有设置，则返回undefined
      if (itemR?.length || customR?.length) {
        temp = [...customR, ...itemR];
      }
      return temp;
    });
    // data 根据条件搜索，select远程搜索里data有值
    const getAxiosOptions = debounce(data => {
      const {
        optionsType,
        optionsFun,
        method = 'post',
        afterResponse,
        beforeRequest,
        label,
        value
      } = config.value;
      if (optionsType !== 0) {
        let sourceFun = optionsFun;

        // 接口数据源
        if (optionsType === 1 && sourceFun) {
          // 当前控件为动态获取数据，防多次加载，先从本地取。data=true时直接请求
          const key = 'getOptions_' + props.data.name + md5(sourceFun + data);
          const storage = window.sessionStorage.getItem(key);
          if (storage && !data) {
            const val = JSON.parse(storage);
            if (props.data.type === 'treeSelect') {
              control.value.data = val;
            } else {
              console.log('val', val);
              options.value = val;
            }
          } else {
            // 从url里提取一个动态值,${name}形式提取name
            if (sourceFunKey.value) {
              const val = formProps.value.model[sourceFunKey.value];
              const string = '${' + sourceFunKey.value + '}';
              sourceFun = sourceFun.replace(string, val);
            }
            // 处理请求前的数据
            //let newData = Object.assign({}, data || {}, queryParams)
            let newData = data || {};
            if (typeof beforeRequest === 'function') {
              newData = beforeRequest(newData, route, formProps.value.model) ?? data;
            }
            if (newData === false) {
              return;
            }
            if (method === 'get') {
              newData = {
                params: newData
              };
            }
            getRequest(sourceFun, newData, {
              method: method
            }).then(res => {
              const result = res.data.list || res.data;
              let formatRes = result;
              const copyResult = JSON.parse(JSON.stringify(result));
              console.log('getRequest', res);

              // 这里做数据转换，很多时候后端并不能提供完全符合且一样的数据
              if (typeof afterResponse === 'string' && afterResponse) {
                formatRes = formatResult(result, afterResponse);
              } else if (typeof afterResponse === 'function') {
                // 没有return时，使用原来的，相当于没处理
                formatRes = afterResponse(result) ?? result;
              } else if (label || value) {
                // 没有设置afterResponse时，这里将数据转换为[{label:'',value:''}]形式。只处理一级
                formatRes = [];
                result.forEach(item => {
                  formatRes.push({
                    label: item[label] || item.label,
                    value: item[value] || item.value
                  });
                });
              }
              if (formatRes === false) {
                return;
              }
              console.log('formatRes', formatRes);
              if (props.data.type === 'treeSelect') {
                control.value.data = copyResult;
              } else {
                options.value = formatRes;
              }
              if (typeof formatRes === 'object') {
                window.sessionStorage.setItem(key, JSON.stringify(formatRes)); //缓存，例如子表添加时不用每添加一行就请求一次
              }
            }).catch(res => {
              if (props.data.type === 'treeSelect') {
                control.value.data = [];
              } else {
                options.value = [];
              }
              console.log(res);
            });
          }
        }

        //字典
        if (optionsType === 2 && sourceFun) {
          getDicts(sourceFun).then(res => {
            options.value = res.data.map(item => {
              return {
                label: item.dictLabel,
                value: item.dictValue
              };
            });
          });
        }
        setFormDict(formProps.value.dict); // 表格里新增时行时需要重新设一次
      }
    });
    watch(() => formProps.value.model[sourceFunKey.value], () => {
      getAxiosOptions();
    });
    // 处理自定义校验规则，将customRules转换后追加到rules里
    const formatCustomRules = () => {
      const rulesReg = {};
      validate && validate.forEach(item => {
        rulesReg[item.type] = item.regExp;
      });

      // 获取校验方法 父级使用provide方法注入
      const temp = [];
      props.data.customRules?.forEach(item => {
        if (!item.message && item.type !== 'methods') {
          return; // 方法时允许提示信息为空
        }
        let obj = {};
        if (item.type === 'required') {
          obj = {
            required: true
          };
        } else if (item.type === 'rules') {
          // 自定义表达式
          obj = {
            pattern: item.rules
          };
        } else if (item.type === 'methods') {
          // 方法时
          const methods = item.methods;
          if (methods) {
            obj = {
              validator: inject(methods, {})
            };
          }
        } else if (item.type) {
          obj = {
            pattern: rulesReg[item.type]
          };
        }
        // 这里判断下防某些条件下重复push的可能或存重复校验类型
        let message = {
          message: item.message
        };
        if (!item.message) {
          // 当使用validator校验时，如果存在message字段则不能使用 callback(new Error('x'));的提示
          message = {};
        }
        temp.push(Object.assign({
          trigger: item.trigger || 'blur'
        }, obj, message));
      });
      return temp;
    };
    // 从数据接口获取数据设置options，在表单添加或编辑时数据加载完成
    const setFormDict = val => {
      if (val && config.value.optionsType === 2) {
        const opt = val[config.value.optionsFun] || val[props.data.name]; // 不填写默认为当前字段名
        if (opt !== undefined) {
          options.value = objectToArray(opt);
        }
      }
    };
    // 从接口返回的dict会在这里触发
    watch(() => formProps.value.dict, val => {
      setFormDict(val);
    }, {
      /*deep: true*/
    });
    // 对单选多选select设置options
    const formOptions = inject(constSetFormOptions, {});
    watch(() => formOptions.value, val => {
      const opt = val[props.data.name];
      // 子表内的需要注意下，只有在子表有记录时才生效
      if (val && opt !== undefined) {
        if (props.data.type === 'treeSelect') {
          // 树结构的参数为data
          control.value.data = objectToArray(opt);
        } else {
          options.value = objectToArray(opt);
        }
      }
    });
    // ------------图片上传处理-----------
    const fileList = computed(() => {
      const imgVal = formProps.value.model[props.data.name];
      if (imgVal && typeof imgVal === 'string') {
        const temp = [];
        imgVal.split(',').forEach(item => {
          temp.push({
            name: item,
            url: item
          });
        });
        return temp;
      }
      return imgVal || []; // 这样可支持默认值为array([name:'',url:''这种形式])
    });
    // 上传成功时
    const uploadSuccess = (response, uploadFile, uploadFiles) => {
      const oldList = [];
      fileList.value.forEach(item => {
        oldList.push(item.url);
      });
      oldList.push(response.path);
      updateModel(oldList.join(','));
      control.value.onSuccess && control.value.onSuccess(response, uploadFile, uploadFiles);
    };
    //　从列表移除
    const uploadRemove = (uploadFile, uploadFiles) => {
      const oldList = [];
      fileList.value.forEach(item => {
        if (item.url !== uploadFile.url) {
          oldList.push(item.url);
        }
      });
      updateModel(oldList.join(','));
      control.value.onRemove && control.value.onRemove(uploadFile, uploadFiles);
      // todo 需从服务端删除已上传图片时，这里需要发删除请求接口
    };
    // 上传错误
    const uploadError = (err, file, fileList) => {
      // console.log('uploadError')
      ElMessage.error(file.name + '上传失败');
      control.value.onError && control.value.onError(err, file, fileList);
    };
    // -------------图片上传结束----------------
    /****input slot处理***/
    const getInputSlot = key => {
      const slot = key === 'p' ? config.value.prepend : config.value.append;
      const has = slot.indexOf('key:') === 0;
      if (!has) {
        return false;
      }
      const slotKey = slot.replace('key:', '');
      const control = getControlByName(slotKey);
      if (!control || Object.keys(control)?.length === 0) {
        return false;
      }
      return control;
    };
    const inputSlotChange = (val, name) => {
      changeEvent && changeEvent({
        key: name,
        value: val,
        data: {},
        tProp: ''
      });
    };
    /****input slot处理结束***/
    // treeSelect
    // const filterMethod = (val) => {
    //   if (props.data.type === 'treeSelect') {
    //     // 请求参数名，可使用config.queryName传进来
    //     const queryName = config.value.queryName || 'name'
    //     control.value.filterMethod && control.value.filterMethod(val)
    //     getAxiosOptions({ [queryName]: val })
    //   }
    // }

    onMounted(() => {
      getAxiosOptions();
    });
    onUnmounted(() => {});
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_radio = _resolveComponent("el-radio");
      const _component_el_radio_group = _resolveComponent("el-radio-group");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_upload = _resolveComponent("el-upload");
      const _component_el_form_item = _resolveComponent("el-form-item");
      return _openBlock(), _createBlock(_component_el_form_item, _mergeProps(__props.data.item, {
        prop: __props.tProp || __props.data.name,
        class: _unref(config).className,
        rules: _unref(itemRules),
        label: getLabel(__props.data.item)
      }), _createSlots({
        default: _withCtx(() => [_unref(type) === 4 ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "form-value",
          innerHTML: _unref(value)
        }, null, 8, _hoisted_1)) : (_openBlock(), _createElementBlock(_Fragment, {
          key: 1
        }, [['input', 'password'].includes(__props.data.type) ? (_openBlock(), _createBlock(_component_el_input, _mergeProps({
          key: 0
        }, control.value, {
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _isRef(value) ? value.value = $event : null),
          clearable: "",
          disabled: _unref(editDisabled),
          type: __props.data.type === 'password' ? 'password' : ''
        }), _createSlots({
          _: 2
        }, [_unref(config).prepend ? {
          name: "prepend",
          fn: _withCtx(() => [getInputSlot('p') ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(AKSelect, {
            data: getInputSlot('p'),
            disabled: _unref(editDisabled),
            "transform-option": transformOption,
            onChange: inputSlotChange,
            type: "slot"
          }, null, 8, ["data", "disabled"])])) : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_unref(config).prepend), 1))]),
          key: "0"
        } : undefined, _unref(config).append ? {
          name: "append",
          fn: _withCtx(() => [getInputSlot() ? (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(AKSelect, {
            data: getInputSlot(),
            disabled: _unref(editDisabled),
            "transform-option": transformOption,
            type: "slot",
            onChange: inputSlotChange
          }, null, 8, ["data", "disabled"])])) : (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_unref(config).append), 1))]),
          key: "1"
        } : undefined]), 1040, ["modelValue", "disabled", "type"])) : _createCommentVNode("", true), __props.data.type === 'textarea' ? (_openBlock(), _createBlock(_component_el_input, _mergeProps({
          key: 1
        }, control.value, {
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _isRef(value) ? value.value = $event : null),
          disabled: _unref(editDisabled),
          type: "textarea"
        }), null, 16, ["modelValue", "disabled"])) : _createCommentVNode("", true), __props.data.type === 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, _mergeProps({
          key: 2
        }, control.value, {
          disabled: _unref(editDisabled),
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _isRef(value) ? value.value = $event : null)
        }), {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_radio, {
              key: index,
              label: transformOption(item.value)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 16, ["disabled", "modelValue"])) : _createCommentVNode("", true), __props.data.type === 'checkbox' ? (_openBlock(), _createBlock(_component_el_checkbox_group, _mergeProps({
          key: 3
        }, control.value, {
          disabled: _unref(editDisabled),
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _isRef(value) ? value.value = $event : null)
        }), {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (item, index) => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              key: index,
              label: transformOption(item.value)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 16, ["disabled", "modelValue"])) : _createCommentVNode("", true), __props.data.type === 'select' || _unref(type) === 5 && __props.data.type === 'inputSlot' ? (_openBlock(), _createBlock(AKSelect, {
          key: 4,
          data: __props.data,
          disabled: _unref(editDisabled),
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => _isRef(value) ? value.value = $event : null),
          options: options.value,
          "remote-method": _unref(getAxiosOptions),
          transformOption: transformOption
        }, null, 8, ["data", "disabled", "modelValue", "options", "remote-method"])) : _createCommentVNode("", true), __props.data.type === 'upload' ? (_openBlock(), _createBlock(_component_el_upload, _mergeProps({
          key: 5,
          class: "upload-style",
          action: _unref(uploadUrl)
        }, control.value, {
          name: control.value.file || 'file',
          disabled: _unref(editDisabled),
          "file-list": _unref(fileList),
          class: {
            limit: control.value.limit <= _unref(fileList).length
          },
          "on-error": uploadError,
          "on-success": uploadSuccess,
          "on-remove": uploadRemove
        }), _createSlots({
          default: _withCtx(() => [_unref(config).btnText ? (_openBlock(), _createBlock(_component_el_button, {
            key: 0,
            type: "primary"
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_unref(config).btnText), 1)]),
            _: 1
          })) : (_openBlock(), _createElementBlock("i", _hoisted_6))]),
          _: 2
        }, [_unref(config).tip ? {
          name: "tip",
          fn: _withCtx(() => [_createElementVNode("div", _hoisted_7, _toDisplayString(_unref(config).tip), 1)]),
          key: "0"
        } : undefined]), 1040, ["action", "name", "disabled", "file-list", "class"])) : _createCommentVNode("", true), __props.data.type === 'areaCity' ? (_openBlock(), _createBlock(_unref(areaCity), _mergeProps({
          key: "areaCity"
        }, control.value, {
          disabled: _unref(editDisabled),
          options: options.value,
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => _isRef(value) ? value.value = $event : null)
        }), null, 16, ["disabled", "options", "modelValue"])) : _createCommentVNode("", true), ['cascader', 'treeSelect'].includes(__props.data.type) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(currentComponent)), _mergeProps({
          key: 7
        }, control.value, {
          disabled: _unref(editDisabled),
          options: options.value,
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => _isRef(value) ? value.value = $event : null)
        }), null, 16, ["disabled", "options", "modelValue"])) : _createCommentVNode("", true), ['rate', 'slider', 'switch', 'inputNumber', 'colorPicker', 'timePicker', 'datePicker', 'component', 'expand-user'].includes(__props.data.type) ? (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(currentComponent)), _mergeProps({
          key: 8
        }, control.value, {
          disabled: _unref(editDisabled),
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => _isRef(value) ? value.value = $event : null)
        }), null, 16, ["disabled", "modelValue"])) : _createCommentVNode("", true), __props.data.type === 'tinymce' ? (_openBlock(), _createElementBlock(_Fragment, {
          key: 9
        }, [[1, 2, 3].includes(_unref(type)) ? (_openBlock(), _createBlock(TinymceEdit, _mergeProps({
          key: 0
        }, control.value, {
          config: _unref(config),
          disabled: _unref(editDisabled),
          modelValue: _unref(value),
          "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => _isRef(value) ? value.value = $event : null)
        }), null, 16, ["config", "disabled", "modelValue"])) : _createCommentVNode("", true), _unref(type) === 5 ? (_openBlock(), _createElementBlock("img", _hoisted_8)) : _createCommentVNode("", true)], 64)) : _createCommentVNode("", true)], 64))]),
        _: 2
      }, [_unref(config).help ? {
        name: "label",
        fn: _withCtx(() => [_createTextVNode(_toDisplayString(getLabel(__props.data.item)) + " ", 1), _createVNode(Tooltip, {
          content: _unref(config).help
        }, null, 8, ["content"])]),
        key: "0"
      } : undefined]), 1040, ["prop", "class", "rules", "label"]);
    };
  }
};