import store from "@/store";
import { MAPPING } from "@/utils/constant";
import Layout from "../layout/index.vue";
import appLinkContainer from "../layout/components/appLinkContainer";
import mainLayout from "../layout/components/mainLayout";

/**
 * @description: 生成UUID
 * @param {*}
 * @return {*}
 * @author: gumingchen
 */
export function generateUUID() {
  let result = "";
  const code = "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx";
  result = code.replace(/[xy]/gu, (item) => {
    const random =
      (window.crypto.getRandomValues(new Uint8Array(1)) * 0.001 * 16) | 0;
    const value = item === "x" ? random : (random & 0x3) | 0x8;
    return value.toString(16);
  });
  return result;
}

// 封装指定id的容器全屏显示

export function fullScreen(ele) {
  // let element = document.documentElement;//设置后就是我们平时的整个页面全屏效果
  let element = document.getElementById(ele); //设置后就是指定id的容器全屏
  if (
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.mozFullScreenElement
  ) {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  } else {
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
      element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      // IE11
      element.msRequestFullscreen();
    }
  }
}
/**
 * @description: 树形数据转换
 * @param {Array} data 树形数据数组
 * @param {String} key 键值
 * @param {String} parentKey 父级键值
 * @param {String} childrenKey 下级键值
 * @return {*}
 * @author: gumingchen
 */
export function parseData2Tree(
  data,
  key = "id",
  parentKey = "parentId",
  childrenKey = "children"
) {
  const result = [];
  const temp = {};
  for (let i = 0; i < data.length; i++) {
    temp[data[i][key]] = data[i];
  }
  for (let k = 0; k < data.length; k++) {
    if (temp[data[k][parentKey]] && data[k][key] !== data[k][parentKey]) {
      if (!temp[data[k][parentKey]][childrenKey]) {
        temp[data[k][parentKey]][childrenKey] = [];
      }
      if (!temp[data[k][parentKey]]["_level"]) {
        temp[data[k][parentKey]]["_level"] = 1;
      }
      data[k]["_level"] = temp[data[k][parentKey]]._level + 1;
      temp[data[k][parentKey]][childrenKey].push(data[k]);
    } else {
      result.push(data[k]);
    }
  }
  return result;
}

/**
 * @description: 日期转字符串
 * @param {Date} time 日期 默认当前日期
 * @param {String} format 格式
 * @return {*}
 * @author: gumingchen
 */
export function parseDate2Str(
  time = new Date(),
  format = "{y}-{M}-{d} {h}:{m}:{s}"
) {
  let result = "";
  let date = new Date();
  const type = typeof time;
  if (type === "object") {
    date = time;
  } else if (type === "number") {
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    M: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    m: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  result = format.replace(/\{[yMdhmsa]+\}/g, (val) => {
    const key = val.replace(/\{|\}/g, "");
    const value = formatObj[key];
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return result;
}

/**
 * @description: 字符串转日期
 * @param {String} time 日期字符串
 * @param {Array} separator 分隔符
 * @return {*}
 * @author: gumingchen
 */
export function parseStr2Date(time = "", separator = ["-", " ", ":"]) {
  let result = new Date();
  const regexp = `/[${separator.join("")}]/g`;
  const data = time.split(eval(regexp));
  switch (data.length) {
    case 3:
      result = new Date(+data[0], +data[1] - 1, +data[2]);
      break;
    case 6:
      result = new Date(
        +data[0],
        +data[1] - 1,
        +data[2],
        +data[3],
        +data[4],
        +data[5]
      );
      break;
  }
  return result;
}

/**
 * @description: json 转 param
 * @param {Object} json json数据
 * @return {*}
 * @author: gumingchen
 */
export function parseJson2Param(json) {
  let result = "";
  result = Object.keys(json)
    .map((key) => {
      if (!json[key]) return "";
      const temp =
        encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
      return temp;
    })
    .join("&");
  return result;
}

/**
 * @description: param 转 json
 * @param {String} url 链接
 * @return {*}
 * @author: gumingchen
 */
export function parseParam2Json(url) {
  const result = {};
  const search = decodeURIComponent(url.split("?")[1]).replace(/\+/gu, " ");
  if (search) {
    const searchArr = search.split("&");
    searchArr.forEach((r) => {
      const index = r.indexOf("=");
      if (index !== -1) {
        const key = r.substring(0, index);
        const val = r.substring(index + 1, r.length);
        result[key] = val;
      }
    });
  }
  return result;
}

/**
 * @description: 置空json数据
 * @param {*} data json数据
 * @return {*}
 * @author: gumingchen
 */
export function clearJson(data) {
  const json = data;
  let key;
  for (key in json) {
    if (json[key] instanceof Array) {
      json[key] = [];
    } else if (
      typeof json[key] === "object" &&
      Object.prototype.toString.call(json[key]).toLowerCase() ===
        "[object object]" &&
      !json[key].length
    ) {
      json[key] = {};
    } else {
      json[key] = "";
    }
  }
}

/**
 * @description: 判断是否有按钮级权限
 * @param {String} permission 多个使用 & 或 | 分隔开
 * @param {String} separator 分隔符：&-并且 |-或者
 * @return {*}
 * @author: gumingchen
 */
export function havePermission(permission, separator = "&") {
  let result = false;
  const permissions = permission.split(separator);
  let fn = "";
  switch (separator) {
    case "&":
      fn = "every";
      break;
    case "|":
      fn = "some";
      break;
  }
  const list = store.getters["menu/permissions"];
  result =
    fn &&
    permissions[fn]((item) => {
      return list.indexOf(item) !== -1;
    });
  return result;
}

/**
 * @description: 获取Api BaseUrl
 * @param {*} key
 * @return {*}
 * @author: gumingchen
 */
export function getApiBaseUrl() {
  const baseUrl =
    process.env.VUE_APP_PROXY === "true"
      ? `/proxy${MAPPING}`
      : process.env.VUE_APP_BASE_API + MAPPING;
  return baseUrl;
}

/**
 * @description: 获取数据字典列表
 * @param {*} code 编码
 * @return {*}
 * @author: gumingchen
 */
export async function getDictionaryList(code) {
  const result = await store.dispatch("dictionary/getDictionary", code);
  return result;
}

/**
 * @description: 获取数据字典键值对
 * @param {*} key
 * @return {*}
 * @author: gumingchen
 */
export async function getDictionaryMap(code) {
  const response = await store.dispatch("dictionary/getDictionary", code);
  const result = {};
  response.forEach((item) => {
    result[item.value] = item.label;
  });
  return result;
}

/**
 * @description: hex 转 rgb
 * @param {*} color 颜色
 * @return {*}
 * @author: gumingchen
 */
export function hex2Rgb(color) {
  color = color.replace("#", "");
  const result = color.match(/../g);
  for (let i = 0; i < 3; i++) {
    result[i] = parseInt(result[i], 16);
  }
  return result;
}

/**
 * @description: rgb 转 hex
 * @param {*} r g b 颜色
 * @return {*}
 * @author: gumingchen
 */
export function rgb2Hex(r, g, b) {
  const hexs = [r.toString(16), g.toString(16), b.toString(16)];
  for (let i = 0; i < 3; i++) {
    if (hexs[i].length === 1) {
      hexs[i] = "0" + hexs[i];
    }
  }
  const result = "#" + hexs.join("");
  return result;
}

/**
 * @description: 使颜色变淡
 * @param {*} key
 * @return {*}
 * @author: gumingchen
 */
export function lighten(color, level) {
  const rgb = hex2Rgb(color);
  for (let i = 0; i < 3; i++) {
    rgb[i] = Math.floor((255 - rgb[i]) * level + rgb[i]);
  }
  const result = rgb2Hex(rgb[0], rgb[1], rgb[2]);
  return result;
}

/**
 * @description: 使颜色变深
 * @param {*} key
 * @return {*}
 * @author: gumingchen
 */
export function darken(color, level) {
  const rgb = hex2Rgb(color);
  for (let i = 0; i < 3; i++) {
    rgb[i] = Math.floor(rgb[i] * (1 - level));
  }
  const result = rgb2Hex(rgb[0], rgb[1], rgb[2]);
  return result;
}

/**
 * @description: 文件下载
 * @param {*} blob
 * @param {*} name 文件名称
 * @return {*}
 * @author: gumingchen
 */
export function download(blob, name) {
  if (blob) {
    const href = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = href;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(href);
  }
}

export function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter((route) => {
    if (route.component === "null") {
      delete route.component;
    }

    if (route.component) {
      // Layout组件特殊处理
      if (route.component === "Layout") {
        route.component = Layout;
      } else {
        linkPageHandle(route);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
//处理链接页面
const linkPageHandle = (route) => {
  if (route.component.includes("mainLayout")) {
    route.component = mainLayout;
  } else if (route.component.includes("appLinkContainer")) {
    route.component = appLinkContainer;
  } else {
    route.component = loadView(route.component);
  }
};

// export const loadView = (view) => {
//   // 路由懒加载
//   return (resolve) => require([`@/views/${view}`], resolve)
// }
const loadView = (view) => {
  // 路由懒加载
  //return (resolve) => require([`@/views/${view}`], resolve) router3版本
  return () => Promise.resolve(require(`@/views/${view}`).default); //router4版本
};

export function routerHandle(routerList, name, startAlone = false) {
  if (routerList.length) {
    //微服务独立启动处理路由
    var routerCopy = deepClone(routerList);
    if (startAlone) {
      routerList.forEach((it) => {
        //    /module/base/setNet   去掉 /module/base  保留 setNet
        let path = it.path.split("/")[3] ? it.path.split("/")[3] : "";
        it.path = "/" + path;
        detailData(it.children);
      });

      let list = generateFlatRoutes(routerList);

      filterAsyncRouter(list);
      return list;
    }

    let childData = [];

    detailData(routerCopy);

    routerCopy.forEach((item) => {
      let childName = item.path.split("/")[3] ? item.path.split("/")[3] : "";

      if (childName) {
        item.children.forEach((it) => {
          it.path = `/${childName}/${it.path}`;
        });
      } else {
        item.children.forEach((it) => {
          it.path = it.path.startsWith("/") ? it.path : `/${it.path}`;
        });
      }

      childData.push(...item.children);
    });

    filterAsyncRouter(childData);
    return childData;
  } else {
    return [];
  }
}
export function detailData(childrenData) {
  childrenData.forEach((item) => {
    item.component = item.path;
    if (item.children && item.children.length > 0) {
      detailData(item.children);
    }
  });
}

export function routerBaseHandle(routerList) {
  let copyData = deepClone(routerList);
  let baseData = [];
  if (copyData.length) {
    copyData.forEach((item) => {
      if (item.children) {
        item.children.forEach((it) => {
          it.path = `${item.path}/${it.path}`;
        });
        baseData.push(...item.children);
      }
    });
    return baseData;
  } else {
    return [];
  }
}

export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

export function generateFlatRoutes(accessRoutes) {
  let flatRoutes = [];

  for (let item of accessRoutes) {
    let breadcrumb = [];
    if (item.meta && item.meta.title) {
      breadcrumb = [item.meta.title];
      item.meta.breadcrumb = breadcrumb;
    }
    let childrenFflatRoutes = [];
    if (item.children && item.children.length > 0) {
      childrenFflatRoutes = castToFlatRoute(
        item.children,
        "",
        item.meta.breadcrumb
      );
    }

    // 一级路由是布局路由,需要处理的只是其子路由数据
    flatRoutes.push({
      name: item.name,
      path: item.path,
      component: item.component,
      redirect: item.redirect,
      children: childrenFflatRoutes,
    });
  }

  return flatRoutes;
}

/**
 * 将子路由转换为扁平化路由数组（仅一级）
 * @param {待转换的子路由数组} routes
 * @param {父级路由路径} parentPath
 */
function castToFlatRoute(routes, parentPath, breadcrumb, flatRoutes = []) {
  for (let item of routes) {
    let childBreadcrumb = [];
    if (item.meta && item.meta.title) {
      childBreadcrumb = [...breadcrumb, item.meta.title];
      item.meta.breadcrumb = childBreadcrumb;
    }
    if (item.children && item.children.length > 0) {
      if (item.redirect && item.redirect !== "noRedirect") {
        flatRoutes.push({
          name: item.name,
          path: (parentPath + "/" + item.path).substring(1),
          redirect: item.redirect,
          meta: item.meta,
        });
      }
      castToFlatRoute(
        item.children,
        parentPath + "/" + item.path,
        item.meta.breadcrumb,
        flatRoutes
      );
    } else {
      flatRoutes.push({
        name: item.name,
        path: (parentPath + "/" + item.path).substring(1),
        component: (parentPath + "/" + item.path).substring(1),
        meta: item.meta,
      });
    }
  }

  return flatRoutes;
}

let Base64 = require("js-base64").Base64;
export function base64Handle(str) {
  return Base64.encode(str);
}
// 通用下载方法
const baseURL = process.env.VUE_APP_BASE_API;
export function downloadFile(fileName) {
  const url =
    baseURL +
    "/common/download?fileName=" +
    encodeURI(fileName) +
    "&delete=" +
    true;
  var xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";
  xhr.onload = function (e) {
    if (this.status == 200) {
      var blob = this.response;
      var a = document.createElement("a");
      var url = URL.createObjectURL(blob);
      // 15182 N-功能测试-定时任务-导出文件名称错误，导出日志，文件名称错误，导出用户、角色、文件名称都是错误的， by yeyunli 2022/12/15
      var filename = decodeURI(
        xhr.getResponseHeader("content-disposition")
      ).split("=")[1];
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };
  xhr.send();
}
export function handleTree(data, id, parentId, children, rootId) {
  id = id || "id";
  parentId = parentId || "parentId";
  children = children || "children";
  rootId = rootId || 0;
  //对源数据深度克隆
  const cloneData = JSON.parse(JSON.stringify(data));
  //循环所有项
  const treeData = cloneData.filter((father) => {
    let branchArr = cloneData.filter((child) => {
      //返回每一项的子级数组
      return father[id] === child[parentId];
    });
    branchArr.length > 0 ? (father.children = branchArr) : "";
    //返回第一层
    return father[parentId] === rootId;
  });
  return treeData != "" ? treeData : data;
}
// 回显数据字典
export function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map((key) => {
    if (datas[key].dictValue == "" + value) {
      actions.push(datas[key].dictLabel);
      return false;
    }
  });
  return actions.join("");
}
//统计图颜色渐变处理
export function hexToRgba(hex, opacity) {
  let rgbaColor = "";
  let reg = /^#[\da-f]{6}$/i;
  if (reg.test(hex)) {
    rgbaColor = `rgba(${parseInt("0x" + hex.slice(1, 3))},${parseInt(
      "0x" + hex.slice(3, 5)
    )},${parseInt("0x" + hex.slice(5, 7))},${opacity})`;
  }
  return rgbaColor;
}
//处理查询query
const queryActiveHandel = (url) => {
  if (url.indexOf("#")) {
    let urlData = url.split("#");
    return titleCase(urlData[0]);
  }
  return titleCase(url);
};

export const linkActiveHandle = (data) => {
  if (data.meta?.link) {
    return titleCase(data.meta.activeMenu || "");
  }
  return data.name;
};

//表单设计器用到的
export function debounce(fn, delay = 500) {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fn.apply(this, args);
    }, delay);
  };
}
// 时间格式化
export const dateFormatting = (time, cFormat) => {
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  // 字符串数字形式的时间戳要转换下
  let newTime = time;
  if (/^\d+?$/.test(time)) {
    newTime = parseInt(time);
  }
  const date = typeof time === "object" ? time : new Date(newTime);
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a")
      return ["一", "二", "三", "四", "五", "六", "日"][value - 1];
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });

  return timeStr;
};
// 动态远程加载script脚本
export function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.onload = resolve;
    script.onerror = reject;
    script.src = src;
    document.head.appendChild(script);
  });
}
// 随机数字符串
export const randomString = (len) => {
  len = len || 32;
  const str = "ABCDEFGHIJKMNOPQSTWXYZabcdefghijklmnopqrstwxyz1234567890";
  let n = "";
  for (let i = 0; i < len; i++) {
    n += str.charAt(Math.floor(Math.random() * str.length));
  }
  return n;
};

export const jsonParseStringify = (val) => {
  if (typeof val === "object") {
    return JSON.parse(JSON.stringify(val));
  } else {
    return val;
  }
};

//正则+replace  首字母大写
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
}
