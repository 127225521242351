import { computed, defineComponent, nextTick, reactive, ref, toRefs, onBeforeMount, onBeforeUnmount, onMounted, onUnmounted } from "vue";
import { useFullscreen } from "@vueuse/core";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import Theme from "./theme.vue";
import Message from "./message.vue";
import { ThemeMode } from "@/utils/dictionary";
import { havePermission } from "@/utils";
import { useI18n } from 'vue-i18n';
export default defineComponent({
  components: {
    Theme,
    Message
  },
  props: {
    adminToggle: {
      type: Function
    }
  },
  setup(props) {
    const {
      t
    } = useI18n();
    const store = useStore();
    console.log('store.state.menu', store.state.menu);
    let on = false;
    const noDetail = data => {
      data.forEach(item => {
        if (item.meta.menuType !== 'D') {
          on = true;
        }
        if (item.children) {
          on !== true && noDetail(item.children);
        }
      });
    };

    //后台都是详情页隐藏 企业管理后台
    const showAdmin = computed(() => {
      const backRoute = store.state.menu.route.filter(item => {
        return item.path === '/baseFramework';
      });
      if (backRoute.length > 0) {
        const data = backRoute[0].children;
        noDetail(data);
        return on;
      } else {
        return false;
      }
    });
    const username = computed(() => store.state.common.name);
    const circleUrl = ref(`${window.g.VUE_APP_BASE_URL}${store.state.common.avatar}`);
    const router = useRouter();
    const route = useRoute();
    const isGlMenuShow = function (name, arr = store.state.menu.route) {
      let res = false;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].name == name) {
          return true;
        } else {
          res = isGlMenuShow(name, arr[i].children || []);
          if (res) return true;
        }
      }
      return res;
    };
    const {
      isSupported,
      isFullscreen,
      toggle
    } = useFullscreen();
    const refTheme = ref();
    const data = reactive({
      visible: false
    });

    // const administrator = computed(
    //   () => store.state.administrator.administrator
    // );
    const isGLHome = computed(() => {
      //管廊项目的首页、告警首页、设备运行页面不需要展示顶部和左侧导航
      if (route.path.includes("manhole/home") || route.path.includes("manhole/alarmHome") || route.path.includes("manhole/deviceRunHome")) {
        return true;
      } else {
        return false;
      }
    });
    const mode = computed({
      get: () => {
        return store.state.theme.mode;
      },
      set: val => {
        console.log(val, '____主题颜色值set___');
        localStorage.setItem("storeTheme", val);
        store.dispatch("theme/setMode", val);
      }
    });

    //
    const currentType = computed(() => {
      return store.state.menu.currentType;
    });
    const iconfontClickHandle = type => {
      switch (type) {
        case "full-screen":
          if (isSupported.value) {
            toggle();
          } else {
            ElMessage({
              message: `Your browser doesn't support full screen`,
              type: "warning"
            });
          }
          break;
        case "refresh":
          store.dispatch("settings/setRefresh", true);
          nextTick(() => {
            store.dispatch("settings/setRefresh", false);
          });
          break;
        case "setting":
          data.visible = true;
          nextTick(() => {
            refTheme.value.init();
          });
          break;
        case "clear":
          store.dispatch("clear");
          break;
      }
    };
    const dropdownHandle = async command => {
      switch (command) {
        case "personal":
          router.push({
            name: "personal"
          });
          break;
        case "logout":
          // await store.dispatch("administrator/logout");
          store.dispatch("logout");
          // router.push({ name: "login" });
          break;
      }
    };

    //后台管理
    const adminHandle = () => {
      props.adminToggle("admin");
    };

    /**
     * 阻止 F11 默认事件 使用项目内的方式全屏
     * @param {*} event
     */
    const fullScreenHandle = event => {
      const {
        key
      } = event;
      if (key === "F11") {
        event.preventDefault();
        toggle();
      }
    };
    let _beforeUnload_time = '';
    let _gap_time = '';
    let unloadHandler = e => {
      _gap_time = new Date().getTime() - _beforeUnload_time;
      console.log(_gap_time, '___判断刷新还是关闭');
      //判断是窗口关闭还是刷新
      if (_gap_time <= 5) {
        console.log('xxxxxxxx');
        window.localStorage.removeItem('storeTheme');
      }
    };
    onMounted(() => {
      window.addEventListener("unload", e => unloadHandler(e));
    });
    onUnmounted(() => {
      window.removeEventListener("unload", e => unloadHandler(e));
    });
    onBeforeMount(() => {
      store.dispatch("theme/setMode", localStorage.getItem('storeTheme') || 'dark');
      if (isSupported.value) {
        window.addEventListener("keydown", fullScreenHandle, true);
      }
    });
    onBeforeUnmount(() => {
      if (isSupported.value) {
        window.removeEventListener("keydown", fullScreenHandle, true);
      }
    });
    const goMain = () => {
      props.adminToggle("main");
    };
    const goDeviceManage = () => {
      if (isGlMenuShow('TwoDeviceManage')) {
        router.push("/module/manhole/deviceManage/twoDeviceManage");
      } else {
        router.push("/module/manhole/deviceManage/deviceList");
      }
    };
    const goProfile = () => {
      router.push("/systemCenter/system/profileNew");
    };
    const goto = path => {
      router.push(path);
    };
    //退出登录
    const logout = () => {
      ElMessageBox.confirm(t('message.confirmLogout'), t('message.logoutPrompt'), {
        // confirmButtonText: "确定",
        // cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        store.dispatch("common/LogOut");
      });
    };
    return {
      refTheme,
      ...toRefs(data),
      ThemeMode,
      currentType,
      isSupported,
      isFullscreen,
      // administrator,
      mode,
      iconfontClickHandle,
      dropdownHandle,
      havePermission,
      adminHandle,
      goMain,
      username,
      goProfile,
      logout,
      circleUrl,
      goto,
      goDeviceManage,
      isGLHome,
      showAdmin,
      isGlMenuShow
    };
  }
});