export default {
  dictSave: "/form-design/system/dict/save",
  dictList: "/form-design/system/dict/list",
  dictDelete: "/form-design/system/dict/delete",
  dictEdit: "/form-design/system/dict/edit",
  deptSave: "/form-design/system/dept/save",
  deptEdit: "/form-design/system/dept/edit",
  deptList: "/form-design/system/dept/list",
  deptDelete: "/form-design/system/dept/delete",
  menuSave: "/form-design/system/menu/save",
  menuEdit: "/form-design/system/menu/edit",
  menuList: "/form-design/system/menu/list",
  menuDelete: "/form-design/system/menu/delete",
  roleSave: "/form-design/system/role/save",
  roleEdit: "/form-design/system/role/edit",
  roleList: "/form-design/system/role/list",
  roleDelete: "/form-design/system/role/delete",
  userSave: "/form-design/system/user/save",
  userEdit: "/form-design/system/user/edit",
  userList: "/form-design/system/user/list",
  userDelete: "/form-design/system/user/delete",
};
