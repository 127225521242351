import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-70aaa678"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container noCommonPageBg"
};
const _hoisted_2 = {
  "slot-scope": "scope"
};
const _hoisted_3 = {
  "slot-scope": "scope"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  key: 2
};
const _hoisted_7 = {
  key: 3
};
const _hoisted_8 = {
  key: 4
};
const _hoisted_9 = {
  "slot-scope": "scope"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table = _resolveComponent("el-table");
  const _component_pagination = _resolveComponent("pagination");
  const _directive_hasPermi = _resolveDirective("hasPermi");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_row, {
    class: "shadowOne",
    style: {
      "margin-bottom": "16px"
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      style: {
        "margin-bottom": "10px"
      }
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(this.parentName), 1)]),
      _: 1
    }), _createVNode(_component_el_col, null, {
      default: _withCtx(() => [_createTextVNode("描述：" + _toDisplayString(this.description), 1)]),
      _: 1
    })]),
    _: 1
  }), _createVNode(_component_el_row, {
    class: "shadowOne"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table, {
      stripe: "",
      data: $data.list
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, {
        width: "1"
      }), _createVNode(_component_el_table_column, {
        label: "版本",
        prop: "version",
        "show-overflow-tooltip": "",
        align: "center"
      }), _createVNode(_component_el_table_column, {
        label: "应用算法",
        prop: "problemName",
        "show-overflow-tooltip": "",
        align: "center"
      }), _createVNode(_component_el_table_column, {
        label: "精确率",
        prop: "accurateRate",
        "show-overflow-tooltip": "",
        align: "center"
      }), _createVNode(_component_el_table_column, {
        label: "训练开始时间",
        prop: "trainingStartTime",
        align: "center"
      }, {
        default: _withCtx(() => [_createElementVNode("template", _hoisted_2, [_createTextVNode(_toDisplayString(_ctx.scope.row.trainingStartTime * 1000 | _ctx.FormatDay), 1)])]),
        _: 1
      }), _createVNode(_component_el_table_column, {
        label: "训练状态",
        prop: "trainingStatus",
        align: "center"
      }, {
        default: _withCtx(() => [_createElementVNode("template", _hoisted_3, [_ctx.scope.row.trainingStatus == 'trainDefault' ? (_openBlock(), _createElementBlock("span", _hoisted_4, "初始状态")) : _createCommentVNode("", true), _ctx.scope.row.trainingStatus == 'trainInProgress' ? (_openBlock(), _createElementBlock("span", _hoisted_5, "训练中")) : _createCommentVNode("", true), _ctx.scope.row.trainingStatus == 'trainFinished' ? (_openBlock(), _createElementBlock("span", _hoisted_6, "训练完成")) : _createCommentVNode("", true), _ctx.scope.row.trainingStatus == 'trainFailed' ? (_openBlock(), _createElementBlock("span", _hoisted_7, "训练失败")) : _createCommentVNode("", true), _ctx.scope.row.trainingStatus == 'deployFinished' ? (_openBlock(), _createElementBlock("span", _hoisted_8, "已部署")) : _createCommentVNode("", true)])]),
        _: 1
      }), _createVNode(_component_el_table_column, {
        label: "操作",
        align: "center",
        "class-name": "small-padding fixed-width"
      }, {
        default: _withCtx(() => [_createElementVNode("template", _hoisted_9, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
          size: "medium",
          type: "text",
          onClick: _cache[0] || (_cache[0] = $event => $options.handleModelDeploy(_ctx.scope.row)),
          disabled: _ctx.scope.row.version === '-' ? true : false
        }, {
          default: _withCtx(() => [_createTextVNode("发布 ")]),
          _: 1
        }, 8, ["disabled"])), [[_directive_hasPermi, ['aIplatform/modelManagement:delopy']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          size: "medium",
          type: "text",
          onClick: _cache[1] || (_cache[1] = $event => $options.submitDownload(_ctx.scope.row)),
          disabled: _ctx.scope.row.version === '-' ? true : false
        }, {
          default: _withCtx(() => [_createTextVNode("下载 ")]),
          _: 1
        }, 8, ["disabled"])), [[_directive_hasPermi, ['aIplatform/modelManagement:download']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          size: "mini",
          type: "text",
          onClick: _cache[2] || (_cache[2] = $event => $options.handleDelete(_ctx.scope.row))
        }, {
          default: _withCtx(() => [_createTextVNode("删除 ")]),
          _: 1
        })), [[_directive_hasPermi, ['aIplatform/modelManagement:remove']]])])]),
        _: 1
      }), _createTextVNode("> ")]),
      _: 1
    }, 8, ["data"]), _withDirectives(_createVNode(_component_pagination, {
      total: $data.total,
      page: $data.page,
      limit: $data.pageSize,
      onPagination: $options.getList
    }, null, 8, ["total", "page", "limit", "onPagination"]), [[_vShow, $data.total > 0]])]),
    _: 1
  })]);
}