import * as echarts from "echarts";
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch } from "vue";
import { hexToRgba } from "@/utils";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
export default defineComponent({
  name: 'NewEcharts',
  props: {
    //是否开启坐标轴对齐（双y下使用，其他不建议使用）
    isAlignment: {
      type: Boolean,
      default: false
    },
    //是否横向(比如横向柱状图)
    isTransverse: {
      type: Boolean,
      default: false
    },
    //x轴刻度线
    axisTickShow: {
      type: Boolean,
      default: false
    },
    //symbol样式
    symbolStyle: {
      type: String,
      default: "emptyCircle"
    },
    //是否显示symbol
    showSymbol: {
      type: Boolean,
      default: false
    },
    //拐点大小
    symbolSize: {
      type: Number,
      default: 4
    },
    //y轴name(当tooltip和y轴name不一致时使用)
    yaxisName: {
      type: String,
      default: ""
    },
    //是否显示tooltip
    tooltipShow: {
      type: Boolean,
      default: true
    },
    //是否开启鼠标效果
    isEmphasis: {
      type: Boolean,
      default: false
    },
    //是否开启点击事件
    isClick: {
      type: Boolean,
      default: false
    },
    clickLink: {
      type: String,
      default: "/equipment/equipmentHealth/calculationResults"
    },
    //y轴标题大小（0即为不显示）
    yaxisFontSize: {
      type: String,
      default: "12"
    },
    //分割线类型
    splitLineStyle: {
      type: String,
      default: "solid"
    },
    className: {
      type: String,
      default: "chart"
    },
    //echarts类型：折线图（line）或柱状图（bar）
    echartsType: {
      type: Array,
      default: function () {
        return ["line"];
      }
    },
    //所依赖的对称轴，0 以左对称轴，1 以右对称轴
    yAxisIndex: {
      type: Array,
      default: function () {
        return [0];
      }
    },
    //警戒线生成的对称轴，0左轴，1右轴（只针对于双y轴）
    markLineSymmetry: {
      type: Number,
      default: 0
    },
    //单位
    unit: {
      type: Array,
      default: function () {
        return ["%"];
      }
    },
    //柱状图顶部弧度
    barBorderRadius: {
      type: Array,
      default: function () {
        return [0, 0, 0, 0];
      }
    },
    // 折线图线条宽度
    lineWidth: {
      type: Number,
      default: 1
    },
    //是否显示legend
    legendShow: {
      type: Boolean,
      default: true
    },
    //legend位置
    legendPosition: {
      type: String,
      default: 'top'
    },
    //legend图标大小
    itemHeight: {
      type: Number,
      default: 14
    },
    //legend图标大小
    itemWidth: {
      type: Number,
      default: 14
    },
    //是否显示y轴
    yAxisLineShow: {
      type: Boolean,
      default: true
    },
    //是否显示x轴
    xAxisLineShow: {
      type: Boolean,
      default: true
    },
    // 是否显示平滑折线图
    lineSmooth: {
      type: Boolean,
      default: true
    },
    //柱状图是否显示数值
    numberShow: {
      type: Boolean,
      default: false
    },
    //y轴间隔线
    splitLine: {
      type: Boolean,
      default: false
    },
    // 线条颜色
    lineColor: {
      type: Array,
      default: function () {
        return ["#0f6afc", "#06be92", "#7b8cff", "#f8e004"];
      }
    },
    //legend样式
    legendIcon: {
      type: String,
      default: "circle"
    },
    // legend字体大小
    legendFontSize: {
      type: Number,
      default: 10
    },
    // 是否为折线面积图
    isAreaLine: {
      type: Boolean,
      default: false
    },
    // 是否为渐变面积图
    isGradualAreaLine: {
      type: Boolean,
      default: false
    },
    //渐变条件下最深程度
    darkestColorLevel: {
      type: Number,
      default: 0.3
    },
    //渐变条件下最浅程度
    lightestColorLevel: {
      type: Number,
      default: 0.01
    },
    //透明度
    opacity: {
      type: Number,
      default: 0.8
    },
    // 曲线图上右下左的位置
    grid: {
      type: Array,
      default: function () {
        return [12, 10, 40, 20];
      }
    },
    //坐标轴配置
    axisConfig: {
      type: Object,
      default: function () {
        return {
          axisFontSize: 10,
          //x.y坐标轴字体大小
          yaxisLineStyle: "#fff",
          //y坐标轴颜色
          axisLineStyle: "#fff" //x坐标轴颜色
        };
      }
    },
    //是否显示滚动条
    isdataZoomShow: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "350px"
    },
    chartData: {
      type: Object,
      default: function () {
        return {};
      }
    },
    //标题配置
    titleConfig: {
      type: Object,
      default: function () {
        return {
          titleShow: false,
          //是否显示标题
          titleName: "",
          //标题名字
          titleSize: 16,
          //标题大小
          titleColor: "#fff",
          //标题颜色
          titleTop: null,
          //标题位置
          titleLeft: 16
        };
      }
    },
    //警戒线数据
    markData: {
      type: Array,
      default: function () {
        return [];
      }
    },
    //警戒线名字
    markName: {
      type: Array,
      default: function () {
        return ['警戒线'];
      }
    },
    //警戒线颜色
    markNameColor: {
      type: Array,
      default: function () {
        return ['red'];
      }
    },
    //警戒线位置x轴/y轴
    markPosition: {
      type: String,
      default: "x"
    },
    //是否从原点起（柱状图应为true）
    boundaryGap: {
      type: Boolean,
      default: false
    },
    //柱状图宽度
    barWidth: {
      type: Number,
      default: null
    },
    //柱状图最大宽度
    barMaxWidth: {
      type: Number,
      default: 30
    },
    //柱状图间隔
    barGap: {
      type: Number,
      default: 0.3
    },
    split: {
      type: Number,
      default: 0
    },
    // 额外参数 
    otherParams: {
      type: Object
    },
    // x轴文字过多时的旋转角度
    xRotate: {
      type: Number,
      default: 0
    },
    // x轴刻度标签的显示间隔,0显示所有
    xInterval: {
      type: Number
    }
  },
  emits: ["dialogLoading"],
  setup(props, context) {
    //左侧树拖拽重新渲染echarts
    watch(() => props.split, value => {
      if (myChart) myChart.resize();
    });
    const store = useStore();
    const router = useRouter();
    let myEcharts = ref(null);
    let myChart = null; //要用普通变量来接收 echarts 实例,否则echarts图 tooltip 不显示
    const state = reactive({
      height: props.height,
      width: props.width,
      option: null,
      //主题颜色,固定禁止修改！！！
      themeColor: {
        xyAxisLabel: "#E9E9E9",
        //xy轴字体颜色，包括单位颜色
        ayAxisLine: "#465E6A",
        //xy轴颜色
        legendColor: "#fff",
        tooltioBackgroundColor: "#0A4C5D"
      }
    });
    const setOptions = ({
      legend,
      series,
      xaxis,
      yaxis,
      anotherXAxis
    } = {}) => {
      let legendData = [];
      for (let l in legend) {
        let json = {
          name: legend[l],
          color: props.lineColor[l],
          textStyle: {
            fontSize: props.legendFontSize,
            // 字体大小
            color: state.themeColor.legendColor // 字体颜色
          }
        };
        legendData.push(json);
      }
      let seriesData = [];
      for (let l in legend) {
        let json = {
          name: legend[l],
          symbol: props.symbolStyle,
          yAxisIndex: props.yAxisIndex[l],
          //选择对称轴0或1
          type: props.echartsType.length == 1 ? props.echartsType[0] : props.echartsType[l],
          smooth: props.lineSmooth,
          //曲线是否平滑
          symbolSize: props.symbolSize,
          showSymbol: props.showSymbol,
          //是否显示 symbol, 如果 false 则只有在 tooltip hover 的时候显示(折线图拐点显示数字必须为true)。
          //柱状图配置
          barWidth: props.barWidth,
          //柱子宽度
          barMaxWidth: props.barMaxWidth,
          barGap: props.barGap,
          //柱子之间间距
          itemStyle: {
            normal: {
              barBorderRadius: props.barBorderRadius,
              // 重点
              label: {
                show: props.numberShow,
                //是否显示数值
                position: 'top',
                //数值显示位置
                textStyle: {
                  //数值样式
                  color: state.themeColor.xyAxisLabel,
                  //字体颜色
                  fontSize: 10 //字体大小
                }
              }
            }
          }
        };
        //判断是否为面积图
        if (props.isAreaLine) {
          if (props.isGradualAreaLine) {
            json.areaStyle = {
              opacity: props.opacity,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: hexToRgba(props.lineColor[l], props.darkestColorLevel)
              }, {
                offset: 1,
                color: hexToRgba(props.lineColor[l], props.lightestColorLevel)
              }], false)
            };
          } else {
            json.areaStyle = {
              opacity: props.opacity
            };
          }
        }
        //修复legend中数据顺序和series中数据顺序不一致导致的bug
        const targetData = series.find(item => item.name === json.name) || {};
        json.data = targetData.data || [];
        seriesData.push(json);
      }
      let yaxisData = [];
      for (let i = 0; i < props.unit.length; i++) {
        let json = {
          // max:120,
          // min:-30,
          type: "value",
          alignTicks: false,
          //双y轴设置该属性可使双对称轴对称
          name: props.yaxisName ? props.yaxisName : props.unit[i],
          nameTextStyle: {
            align: "right",
            color: state.themeColor.xyAxisLabel,
            fontSize: props.yaxisFontSize
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: state.themeColor.ayAxisLine
            } // 刻度的颜色
          },
          axisLine: {
            show: props.yAxisLineShow,
            lineStyle: {
              color: state.themeColor.ayAxisLine // 线段的颜色
            }
          },
          axisLabel: {
            color: state.themeColor.xyAxisLabel,
            fontSize: props.axisConfig.axisFontSize ? props.axisConfig.axisFontSize : 10
          },
          // y轴字体颜色
          splitLine: {
            show: props.splitLine,
            lineStyle: {
              type: props.splitLineStyle,
              color: state.themeColor.ayAxisLine
            }
          }
        };
        //双y轴刻度及值对齐（方法：遍历获取所有数据最大值最小值实现）
        if (props.isAlignment) {
          let max = 0;
          let min = 0;
          for (let i = 0; i < props.chartData.series.length; i++) {
            if (Math.max(...props.chartData.series[i].data) > max) {
              max = Math.max(...props.chartData.series[i].data);
            }
            if (Math.min(...props.chartData.series[i].data) < min) {
              min = Math.min(...props.chartData.series[i].data);
            }
          }
          if (max !== 0) {
            max = Math.round(max / 10 + 0.5) * 10;
          }
          if (min !== 0) {
            min = Math.round(Math.abs(min) / 10 + 0.5) * 10;
          }
          json.max = max;
          json.min = -min;
        }
        yaxisData.push(json);
      }
      state.option = {
        //曲线颜色
        color: props.lineColor,
        //标题
        title: {
          show: props.titleConfig.titleShow ? props.titleConfig.titleShow : false,
          text: props.titleConfig.titleName ? props.titleConfig.titleName : "",
          left: props.titleConfig.titleLeft ? props.titleConfig.titleLeft : 16,
          top: props.titleConfig.titleTop ? props.titleConfig.titleTop : null,
          textStyle: {
            color: props.titleConfig.titleColor ? props.titleConfig.titleColor : state.themeColor.legendColor,
            fontSize: props.titleConfig.titleSize ? props.titleConfig.titleSize : 16
          }
        },
        //背景颜色
        backgroundColor: "#00357700",
        //整体背景
        tooltip: {
          transitionDuration: 0,
          show: props.tooltipShow,
          trigger: "axis",
          //触发类型(系统都为坐标轴触发)
          backgroundColor: state.themeColor.tooltioBackgroundColor,
          //设置背景颜色
          axisPointer: {
            type: "cross",
            //指示器类型,十字准星指示器。
            label: {
              //坐标轴指示器的文本标签。
              backgroundColor: "#6a7985"
            }
          },
          formatter: params => {
            let relVal = params[0].name;
            let unit = "";
            for (let i = 0; i < params.length; i++) {
              if (props.unit.length == 1) {
                //在此分单轴和双轴
                unit = series[i].unit || props.unit[0];
              } else {
                unit = series[i].unit || yaxisData[props.yAxisIndex[i]].name; //从所依托的对称轴取单位
              }
              //17217 N-功能测试-台区监视-发电预测-点击左侧设备树勾选一个光伏场站-曲线图展示错误 by liyan 2023/5/25
              let paramesValue = '';
              if (params[i].value || params[i].value == 0) {
                paramesValue = params[i].value;
              }
              relVal += "<br/>" + params[i].marker + params[i].seriesName + " : " + paramesValue + unit;
            }
            return relVal;
          }
        },
        legend: {
          top: props.legendPosition,
          show: props.legendShow,
          icon: props.legendIcon,
          type: 'scroll',
          data: legendData,
          textStyle: {
            color: state.themeColor.legendColor
          },
          itemHeight: props.itemHeight,
          itemWidth: props.itemWidth
        },
        grid: {
          top: props.grid[0],
          right: props.grid[1],
          bottom: props.grid[2],
          left: props.grid[3],
          containLabel: true //grid 区域是否包含坐标轴的刻度标签。
        },
        dataZoom: [{
          show: props.isdataZoomShow
        }, {
          type: 'inside',
          //鼠标滑轮滚动缩放功能
          realtime: true
        }],
        xAxis: props.isTransverse ? yaxisData : [{
          boundaryGap: props.boundaryGap,
          type: "category",
          data: xaxis,
          axisTick: {
            show: props.axisTickShow,
            lineStyle: {
              color: state.themeColor.ayAxisLine
            } // 刻度的颜色
          },
          axisLine: {
            show: props.xAxisLineShow,
            lineStyle: {
              color: state.themeColor.ayAxisLine
            }
          },
          axisLabel: {
            color: state.themeColor.xyAxisLabel,
            fontSize: props.axisConfig.axisFontSize ? props.axisConfig.axisFontSize : 10,
            interval: props.xIinterval,
            rotate: props.xRotate
          } // x轴字体颜色
        }],
        yAxis: props.isTransverse ? [{
          boundaryGap: props.boundaryGap,
          type: "category",
          data: xaxis,
          axisTick: {
            show: props.axisTickShow,
            lineStyle: {
              color: state.themeColor.ayAxisLine
            } // 刻度的颜色
          },
          axisLine: {
            lineStyle: {
              color: state.themeColor.ayAxisLine
            }
          },
          axisLabel: {
            color: state.themeColor.xyAxisLabel,
            fontSize: props.axisConfig.axisFontSize ? props.axisConfig.axisFontSize : 10
          } // x轴字体颜色
        }] : yaxisData,
        series: seriesData
      };
      //根据后端返回的markLine动态添加警戒线
      if (props.markData && props.markData.length) {
        for (let j = 0; j < props.chartData.series.length; j++) {
          state.option.series[j].markLine = {
            symbol: "none",
            //去掉警戒线最后面的箭头
            silent: true,
            //是否不需要触发警戒线效果
            data: []
          };
          for (let i = 0; i < props.markData.length; i++) {
            if (props.markPosition == "x") {
              state.option.series[j].markLine.data.push({
                xAxis: props.markData[i],
                //x轴警戒线
                silent: true,
                emphasis: {
                  disabled: true
                },
                label: {
                  formatter: props.markName[i],
                  color: props.markNameColor[i]
                },
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  normal: {
                    width: 2,
                    color: props.markNameColor[i]
                  }
                }
              });
            } else {
              if (props.markLineSymmetry == state.option.series[j].yAxisIndex) {
                //根据需求判断在哪个轴生成警戒线，单对称轴时不需要传markLineSymmetry，都走默认值即可
                state.option.series[j].markLine.data.push({
                  yAxis: props.markData[i],
                  //y轴警戒线
                  silent: true,
                  emphasis: {
                    disabled: true
                  },
                  label: {
                    formatter: props.markName[i],
                    color: props.markNameColor[i]
                  },
                  lineStyle: {
                    //警戒线的样式  ，虚实  颜色
                    normal: {
                      width: 2,
                      color: props.markNameColor[i]
                    }
                  }
                });
              }
            }
          }
        }
      }
      if (anotherXAxis) {
        state.option.xAxis.push({
          ...anotherXAxis
        });
      }
      if (yaxis) {
        yaxis.splitLine = yaxisData[0].splitLine;
        yaxis.axisLabel = yaxisData[0].axisLabel;
        yaxis.axisFontSize = yaxisData[0].axisFontSize;
        yaxis.nameTextStyle = yaxisData[0].nameTextStyle;
        state.option.yAxis.push({
          ...yaxis
        });
      }
      console.log(state.option);
      if (myChart) myChart.setOption(state.option);
    };
    onMounted(() => {
      nextTick(() => {
        if (store.state.user.themeMode == 'dark') {
          //绿色系颜色
          state.themeColor.xyAxisLabel = "#E9E9E9";
          state.themeColor.ayAxisLine = "#465E6A";
          state.themeColor.legendColor = "#fff";
          state.themeColor.tooltioBackgroundColor = "#0A4C5D";
        } else {
          //白色系
          state.themeColor.xyAxisLabel = "#000";
          state.themeColor.ayAxisLine = "#898d96";
          state.themeColor.legendColor = "#000";
          state.themeColor.tooltioBackgroundColor = "rgba(50,50,50,0.7)";
        }
        myChart = echarts.init(myEcharts.value);
        //无数据情况处理
        if (props.chartData && props.chartData.legend) {
          setOptions(props.chartData);
        } else {
          let newChartData = {
            "legend": [],
            "series": [],
            "xaxis": [],
            "yaxis": null
          };
          setOptions(newChartData);
        }
        bindListen(myChart);
      });
    });
    const bindListen = myChart => {
      if (props.isClick) {
        myChart.on("click", params => {
          console.log(params, "params");
          let curActive = "";
          // 设备健康概览-各设备类型地区统计、各设备类型健康度统计点击柱状图跳转至设备健康列表页面
          if (props.otherParams?.module == 'healthOverview-1') {
            curActive = params.seriesName == '配变' ? 'transformerInfo' : 'switchInfo';
          } else if (props.otherParams?.module == 'healthOverview-4') {
            curActive = params.name == '配变' ? 'transformerInfo' : 'switchInfo';
          }
          let activeName = props.otherParams?.deviceType || curActive;
          router.push({
            path: props.clickLink,
            query: {
              deviceState: params.seriesName,
              activeName: activeName
            }
          });
        });
      }
    };
    onBeforeUnmount(() => {
      if (myChart) {
        myChart.dispose(); //销毁
      }
    });
    //主题监听
    watch(() => store.state.user.themeMode, (newValue, oldValue) => {
      if (newValue == "dark") {
        //绿色系颜色
        state.themeColor.xyAxisLabel = "#E9E9E9";
        state.themeColor.ayAxisLine = "#465E6A";
        state.themeColor.legendColor = "#fff";
        state.themeColor.tooltioBackgroundColor = "#0A4C5D";
      } else {
        //白色系
        state.themeColor.xyAxisLabel = "#000";
        state.themeColor.ayAxisLine = "#898d96";
        state.themeColor.legendColor = "#000";
        state.themeColor.tooltioBackgroundColor = "rgba(50,50,50,0.7)";
      }
      setOptions(props.chartData);
    }, {
      deep: true
    });
    //颜色监听(主题切换父组件传颜色)
    watch(() => props.lineColor, () => {
      setOptions(props.chartData);
    }, {
      deep: true
    });
    //数据监听
    watch(() => props.chartData, () => {
      if (myChart) {
        myChart.clear();
      }
      setOptions(props.chartData);
    }, {
      deep: true
    });
    //左侧树拖拽重新渲染echarts
    watch(() => props.split, value => {
      if (myChart) myChart.resize();
    });
    //窗口监听渲染
    window.addEventListener('resize', () => {
      if (myChart) myChart.resize();
    });
    return {
      ...toRefs(state),
      setOptions,
      myEcharts
    };
  }
});