import logo from "./mainSubForm.png";
export const imgPath = logo;
export const title = "主子表";

export const formData = {
  list: [
    {
      type: "input",
      control: {
        modelValue: "",
        clearable: true,
      },
      config: {
        span: "",
      },
      name: "name",
      item: {
        label: "产品名称",
      },
      customRules: [
        {
          type: "required",
          message: "产品名称不能为空",
          trigger: "blur",
        },
      ],
    },
    {
      type: "input",
      control: {
        modelValue: "",
        clearable: true,
      },
      config: {
        span: "",
      },
      name: "productModel",
      item: {
        label: "产品型号",
        rules: [],
      },
      customRules: [
        {
          type: "required",
          message: "产品型号不能为空",
          trigger: "blur",
        },
      ],
    },
    {
      type: "select",
      control: {
        modelValue: "",
        appendToBody: true,
        clearable: true,
      },
      options: [],
      config: {
        optionsType: 2,
        optionsFun: "product_type",
        linkKey: false,
        span: "",
      },
      name: "productType",
      item: {
        label: "产品类型",
        rules: [],
      },
    },
    {
      type: "title",
      control: {
        modelValue: "标题",
      },
      config: {
        span: 24,
      },
    },
    {
      type: "table",
      list: [
        {
          type: "input",
          control: {
            modelValue: "",
            placeholder: "请输入设备名称",
            clearable: true,
          },
          config: {},
          name: "deviceName",
          item: {
            label: "设备名称",
          },
          customRules: [],
        },
        {
          type: "input",
          control: {
            modelValue: "",
            placeholder: "请输入设备编号",
            clearable: true,
          },
          config: {},
          name: "deviceSn",
          item: {
            label: "设备编号",
          },
        },
      ],
      tableData: [],
      control: {
        border: true,
      },
      config: {
        addBtnText: "添加一行",
        span: 24,
        delBtnText: "删除",
      },
      name: "table",
    },
    {
      type: "div",
      control: {},
      config: {
        span: 24,
        textAlign: "center",
      },
      list: [
        {
          type: "button",
          control: {
            label: "确定",
            key: "submit",
            type: "primary",
          },
          config: {
            span: 0,
          },
        },
        {
          type: "button",
          control: {
            label: "取消",
            key: "reset",
          },
          config: {
            span: 0,
          },
        },
      ],
    },
  ],
  form: {
    size: "default",
    class: "form-row-2",
  },
  config: {
    addUrl: "/add",
    editUrl: "/edit",
    requestUrl: "/detail",
  },
};
