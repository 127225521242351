/*
 * @Author: yyl
 * @Date: 2021-07-21 13:59:34
 * @LastEditTime: 2021-08-10 14:58:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \energy-internet-web\src\utils\aiRequest.js
 */
import axios from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import store from '@/store'
import { getToken } from './auth.js'
import { setRequestUrl, setRequestBody } from './setHeader'
// import { useI18n } from 'vue-i18n'
// const { t } = useI18n()
export let axiosTemplate = (baseURL) => {
  axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
  // 创建axios实例
  const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL,
    // 超时
    timeout: 1000 * 60,
  })
  // request拦截器
  service.interceptors.request.use(
    (config) => {
      if (getToken()) {
        config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        if (config.method === 'post') {
          const temp = setRequestBody(config)
          config.headers['signature'] = temp.signature
          config.headers['timestamp'] = temp.timestamp
          config.headers['nonce'] = temp.nonce
        } else {
          const temp = setRequestUrl(config)
          config.headers['signature'] = temp.signature
          config.headers['timestamp'] = temp.timestamp
          config.headers['nonce'] = temp.nonce
        }
      }
      return config
    },
    (error) => {
      Promise.reject(error)
    },
  )

  // 响应拦截器
  service.interceptors.response.use(
    (res) => {

      const code = res.data.code
      // 判断是否有code存在，兼容导出时返回整个文件流
      if (code) {
        if (code === 401) {
          ElMessageBox.close()
          ElMessageBox.confirm(
            'The login status has expired, you can stay on this page, or log in again',
            'system hint',
            {
              confirmButtonText: 'Log in again',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
          ).then(() => {
            store.dispatch(UserActionTypes.ACTION_LOGIN_OUT);
          })
        } if (code != 1000) {
          if (code == 1001) {
            ElMessage.closeAll()
            ElMessage({
              type: 'warning',
              message: 'warning',
            })

            return Promise.reject('warning')
          } else if (code == 1002) {
            ElMessage.closeAll()
            return res.data
          } else {
            ElMessage.closeAll()
            ElMessage({
              message: 'error',
              type: 'error',
              duration: 3 * 1000,
            })
          }

          return Promise.reject('error')
        } else {
          return res.data
        }
      } else {
        // code不存在时返回后端return对象
        return res
      }
    },
    (error) => {
      console.log('err',error)
      ElMessage.closeAll()
      if (error.response.status === 401) {
        console.log('66666666')
          ElMessageBox.close()
          ElMessageBox.confirm(
            'The login status has expired, you can stay on this page, or log in again',
            'system hint',
            {
              confirmButtonText: 'Log in again',
              cancelButtonText: 'Cancel',
              type: 'warning'
            }
          ).then(() => {
            store.dispatch('user/ACTION_LOGIN_OUT');
          })
      } else {
        ElMessage({
          message: error.message,
          type: 'error',
          duration: 5 * 1000,
        })
        return Promise.reject(error)
      }
    },
  )

  return service
}
