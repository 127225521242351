import path from "path";
import { isExternal } from "@/utils/validate";
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { defineComponent, reactive, toRefs, onMounted, computed } from "vue";
import { linkActiveHandle } from '@/utils/index';
export default defineComponent({
  name: "SidebarItem",
  props: {
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ""
    }
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const currentType = computed(() => {
      return store.state.menu.currentType;
    });
    const themeSet = computed(() => {
      return store.state.theme.mode;
    });
    onMounted(() => {
      //  console.log("item", props.item);
    });
    const state = reactive({
      onlyOneChild: null
    });
    const methods = reactive({
      // linkHandle() {
      //   if (props.item.meta.link) {
      //     console.log("link", props.item.meta.link);
      //     return props.item.meta.link
      //   } else {
      //     console.log('roew=====', methods.resolvePath(state.onlyOneChild.path))
      //     return methods.resolvePath(state.onlyOneChild.path)

      //   }
      // },
      showChildName(children = [], parent) {
        const showingChildren = children.filter(item => {
          if (item.meta && item.meta.hidden) {
            return false;
          } else {
            return true;
          }
        });
        if (showingChildren.length === 1) {
          return showingChildren[0].name;
        } else {
          return linkActiveHandle(parent);
        }
      },
      hasOneShowingChild(children = [], parent) {
        const showingChildren = children.filter(item => {
          if (item.meta && item.meta.hidden) {
            return false;
          } else {
            // Temp set(will be used if only has one showing child)
            state.onlyOneChild = item;
            return true;
          }
        });

        // When there is only one child router, the child router is displayed by default
        if (showingChildren.length === 1) {
          return true;
        }

        // Show parent if there are no child router to display
        if (showingChildren.length === 0) {
          state.onlyOneChild = {
            ...parent,
            path: "",
            noShowingChildren: true
          };
          return true;
        }
        return false;
      },
      resolvePath(routePath) {
        if (isExternal(routePath)) {
          return routePath;
        }
        if (isExternal(props.basePath)) {
          return props.basePath;
        }
        if (props.basePath === "/") {
          return `${props.basePath}${routePath}`;
        } else if (!routePath) {
          return props.basePath;
        } else {
          return `${props.basePath}/${routePath}`;
        }
      }
    });
    //menuType为D代表详情页面，详情页面不在菜单展示
    let isShow = computed(() => {
      return !props.item.meta?.hidden && props.item.meta?.menuType != 'D';
    });

    //
    const clickHandle = () => {
      let query = '';
      let queryIndex = props.item.name.indexOf('?');
      if (queryIndex > -1) {
        let s = props.item.name.substring(queryIndex + 1, props.item.name.length);
        let arr = s.split('&');
        console.log('arr', arr);
        let queryObject = {};
        arr.forEach(item => {
          let p = item.split('=');
          let key = p[0];
          let val = p[1];
          queryObject[key] = val;
        });
        query = queryObject;
      }
      if (props.item.meta.link) {
        //打开窗口
        if (props.item.meta.link.startsWith("http")) {
          window.open(props.item.meta.link);
        } else {
          router.push(props.item.meta.link);
        }
      } else {
        const path = methods.resolvePath(state.onlyOneChild.path);
        console.log('path=====', path);
        if (query) {
          router.push({
            path: path,
            query
          });
        } else {
          router.push({
            path: path
          });
        }
      }

      // router.push({ path: props.path });
    };
    return {
      ...toRefs(state),
      ...toRefs(methods),
      clickHandle,
      linkActiveHandle,
      isShow,
      currentType,
      themeSet
    };
  }
});