import { reactive, toRefs, watch, computed } from 'vue';
import { getToken } from '@/utils/auth';
import { ElMessage } from 'element-plus';
import { setChunkUpload } from '@/utils/setHeader';
export default {
  name: 'EIUploadOnePic',
  props: {
    action: {
      default: process.env.VUE_APP_ALARM_MANAGE + '/upload/chunk',
      type: String
    },
    fileList: {
      default: function () {
        return [];
      },
      type: Array
    },
    type: {
      default: 'image',
      type: String
    },
    fileType: {
      default: '',
      type: String
    },
    uploadTip: {
      default: '',
      type: String
    }
  },
  emits: ['update:fileList', 'clearValidate'],
  setup(props, context) {
    watch(props.fileList, val => {
      state.url = '';
    }, {
      lazy: true
    });
    const state = reactive({
      url: '',
      baseUrl: '',
      headers: {
        Authorization: ''
      }
    });
    state.baseUrl = `${window.location.protocol}//${window.location.host}`;
    const fileName = computed(() => {
      return props.fileList[0]?.fileName;
    });
    const methods = reactive({
      // 上传文件格式一律转为小写
      commonHandleCheck(val) {
        let testmsg = val.substring(val.lastIndexOf('.') + 1);
        testmsg && (testmsg = testmsg.toLowerCase());
        return testmsg;
      },
      // 设置请求头
      requestHeaderSet() {
        state.headers['Authorization'] = 'Bearer ' + getToken(); // 让每个请求携带自定义token 请根据实际情况自行修改
        const temp = setChunkUpload();
        state.headers['signature'] = temp.signature;
        state.headers['timestamp'] = temp.timestamp;
        state.headers['nonce'] = temp.nonce;
      },
      handleFileSuccess(res, file) {
        let fileList = [{
          chunkNumber: null,
          chunkSize: null,
          currentChunkSize: null,
          fileGroup: res.group,
          fileSize: file.size,
          fileType: null,
          fileName: file.name,
          group: res.group,
          identifier: null,
          merge: false,
          path: res.path,
          relativePath: null,
          skipAll: false,
          skipUpload: false,
          totalChunks: null,
          totalSize: null,
          uploaded: null
        }];
        context.emit('update:fileList', fileList);
        context.emit('clearValidate');
      },
      handleAvatarSuccess(res, file) {
        console.log('handleAvatarSuccess', res);
        state.url = URL.createObjectURL(file.raw);
        res.fileGroup = res.group;
        res.fileName = res.filename;
        delete res.filename;
        res = [res];
        context.emit('update:fileList', res);
        context.emit('clearValidate');
      },
      beforeAvatarUpload(file) {
        if (getToken()) {
          // 判定是否有token
          methods.requestHeaderSet();
        }
        //9336 N-功能测试-图模、图形管理-图模管理（cim）-导入文件错误，没有校验。by liyan 2021-12-06
        if (props.type === 'file' && props.fileType === 'cim') {
          let testmsg = methods.commonHandleCheck(file.name);
          if (testmsg === 'cim' || testmsg === 'CIM') {
            return true;
          } else {
            //bug 9961 U-功能测试-网格管理-数据导入-图模管理-导入非cim的文件，提示内容错误 mosen 2022/1/18
            ElMessage.error('上传文件只能是cim格式的文件');
            return false;
          }
        } else if (props.type === 'file' && props.fileType === 'svg') {
          let testmsg = methods.commonHandleCheck(file.name);
          if (testmsg === 'svg' || testmsg === 'SVG') {
            return true;
          } else {
            ElMessage.error('上传文件只能是svg格式的文件');
            return false;
          }
        } else if (props.type === 'file' && props.fileType === 'json') {
          let testmsg = methods.commonHandleCheck(file.name);
          if (testmsg === 'json' || testmsg === 'JSON') {
            return true;
          } else {
            ElMessage.error('上传文件只能是json格式的文件');
            return false;
          }
        } else if (props.type === 'file' && props.fileType === 'excel') {
          let testmsg = methods.commonHandleCheck(file.name);
          if (testmsg === 'xls' || testmsg === 'xlsx' || testmsg === 'csv' || testmsg === 'pdf') {
            return true;
          } else {
            ElMessage.error('上传文件只能是excel格式的文件');
            return false;
          }
        } else {
          // 5961 N-功能测试-配电管理-配电设备列表-添加设备-上传不符合标准的图片（JPEG格式）-保存，上传成功，没有错误提示 by yeyunli
          let testmsg = methods.commonHandleCheck(file.name);
          const isJPEG = testmsg === 'jpeg';
          const isJPG = testmsg === 'jpg';
          const isPNG = testmsg === 'png';
          const isLt2M = file.size / 1024 / 1024 < 2;
          if (!isJPG && !isPNG && !isJPEG) {
            ElMessage.error('上传图片只能是 JPG、JPEG、PNG、GIF格式!');
            return false;
          }
          if (!isLt2M && file.size == 0) {
            ElMessage.error('上传图片大小不能超过 2MB!');
            return false;
          }
          return isJPG || isPNG || isJPEG;
        }
      }
    });
    return {
      ...toRefs(state),
      ...toRefs(methods),
      fileName
    };
  }
};