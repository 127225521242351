import request from '@/utils/onlineRequest';
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, onMounted, watch } from "vue";
import { deepClone, downFile } from "@/utils";
import { getTree } from "@/api/system/area";
import { useRouter } from 'vue-router';
export default defineComponent({
  name: "",
  props: ['path'],
  setup(props) {
    const formContainer = ref(ElForm);
    const formContainerZY = ref(ElForm);
    const formDispose = ref(ElForm);
    const state = reactive({
      saveLoading: false,
      dialogVisibleTwo: false,
      disposeSaveLoading: false,
      tableLoading: false,
      searchFormData: [{
        prop: "productId",
        label: "产品",
        placeholder: "请选择产品",
        optionsUrl: `${process.env.VUE_APP_IOT_API}/product/list`,
        type: "list",
        span: 4,
        props: {
          label: 'name',
          value: 'id'
        }
      }, {
        prop: "activeStatus",
        label: "激活状态",
        placeholder: "请选择激活状态",
        options: [{
          dictValue: null,
          dictLabel: '全部'
        }, {
          dictValue: "0",
          dictLabel: '未激活'
        }, {
          dictValue: "1",
          dictLabel: '已激活'
        }],
        type: "list",
        span: 4
      }, {
        prop: "name",
        label: "设备名称",
        placeholder: "请输入设备名称",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "sn",
        label: "设备SN",
        placeholder: "请输入设备SN",
        type: "text",
        span: 6
      }],
      showTableHeader: [],
      cloneQueryParams: null,
      zcFormRules: {
        astNum: [{
          required: true,
          message: "资产编号不能为空",
          trigger: "blur"
        }, {
          pattern: /^.{1,40}$/,
          message: "资产编号最长为40位"
        }],
        astOrg: [{
          required: true,
          message: "资产单位不能为空",
          trigger: "blur"
        }],
        source: [{
          required: true,
          message: "设备来源不能为空",
          trigger: "change"
        }],
        deployState: [{
          required: true,
          message: "设备状态不能为空",
          trigger: "change"
        }],
        isCommission: [{
          required: true,
          message: "是否代维不能为空",
          trigger: "change"
        }],
        model: [{
          required: true,
          message: "生产型号不能为空",
          trigger: "blur"
        }],
        manufacturer: [{
          required: true,
          message: "生产厂家不能为空",
          trigger: "blur"
        }]
      },
      disposeFormRules: {
        port: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        baud: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        dataSize: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        parity: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        ruleId: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }],
        dataDeep: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }, {
          pattern: /^(?!-)[0-9\\.\\%\\g]{1,20}$/,
          message: "只支持正整数输入，长度1~20"
        }],
        slaveAddr: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }, {
          pattern: /^[-0-9\\g]{1,3}$/,
          message: '必填，1-3个字符支持数字'
        }],
        reqInterval: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }, {
          pattern: /^(?!-)[0-9\\g]{1,10}$/,
          message: "只支持正整数输入，长度1~10"
        }],
        expTime: [{
          required: true,
          message: '必填项',
          trigger: 'blur'
        }, {
          pattern: /^(?!-)[0-9\\.\\%\\g]{1,10}$/,
          message: "只支持正整数输入，长度1~10"
        }]
      },
      zyFormRules: {
        name: [{
          required: true,
          message: "设备名称不能为空",
          trigger: "blur"
        }],
        productId: [{
          required: true,
          message: "选择产品不能为空",
          trigger: "change"
        }],
        city: [{
          required: true,
          message: "所属地市不能为空",
          trigger: "change"
        }],
        maintOrg: [{
          required: true,
          message: "运维单位不能为空",
          trigger: "change"
        }],
        maintGroup: [{
          required: true,
          message: "运维班组不能为空",
          trigger: "blur"
        }],
        sn: [{
          required: true,
          message: '设备SN不能为空',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z0-9\\g]{1,32}$/,
          message: '32个字符支持英文、数字'
        }],
        equipmentOwner: [{
          required: true,
          message: "设备主人不能为空",
          trigger: "blur"
        }],
        psrState: [{
          required: true,
          message: "运行状态不能为空",
          trigger: "change"
        }],
        isRural: [{
          required: true,
          message: "是否农网不能为空",
          trigger: "change"
        }],
        pubPrivFlag: [{
          required: true,
          message: "营配标识不能为空",
          trigger: "blur"
        }, {
          pattern: /^.{1,2}$/,
          message: "营配标识最长为2位"
        }],
        feeder: [{
          required: true,
          message: "所属馈线不能为空",
          trigger: "change"
        }],
        line: [{
          required: true,
          message: "所属线路不能为空",
          trigger: "change"
        }]
      },
      formRules: {},
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        PIdList: "",
        equipCodeOrName: "",
        sn: "",
        productId: "",
        activeStatus: ''
      },
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        className: 'addBtn',
        buttonIcon: "Plus",
        hasPermi: ["edge:deviceManage:add"],
        exp: item => methods.handleAdd(item)
      }, {
        buttonName: "批量删除",
        buttonStyle: "danger",
        className: 'deleteBtn',
        buttonIcon: "Delete",
        hasPermi: ["edge:deviceManage:remove"],
        exp: item => methods.handleDel(true)
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      multipleSelection: [],
      dialogVisible: false,
      tableHeader: [{
        prop: "name",
        label: "设备名称",
        fixed: false,
        type: "text"
      }, {
        prop: "sn",
        label: "设备SN",
        fixed: false,
        type: "text"
      }, {
        prop: "isOnline",
        label: "运行状态",
        fixed: false,
        type: "text",
        formatter: row => row.isOnline == 1 ? "在线" : "离线"
      }, {
        prop: "activeStatus",
        label: "激活状态",
        fixed: false,
        type: "text",
        formatter: row => row.activeStatus == 1 ? "已激活" : "未激活"
      }, {
        prop: "isForbidden",
        label: "启用状态",
        fixed: false,
        type: "switch",
        switch: {
          changeFun: row => {
            methods.handleSwitch(row);
          },
          active: 1,
          inActive: 0
        }
      }, {
        prop: "operation",
        label: "操作",
        fixed: 'right',
        operation: [{
          name: "查看",
          hasPermi: ["edge:deviceManage:details"],
          clickFun: row => methods.toDeviceInfo(row)
        }, {
          name: "删除",
          hasPermi: ["edge:deviceManage:remove"],
          clickFun: row => methods.handleDel(false, row)
        }, {
          name: "编辑",
          hasPermi: ["edge:deviceManage:edit"],
          clickFun: row => methods.handleEdit(row)
        }, {
          name: "配置规则",
          hasPermi: ["edge:deviceManage:dispose"],
          clickFun: row => methods.handleDispose(row)
        }]
      }],
      disposeForm: {
        deviceId: "",
        id: null,
        slaveAddr: "",
        port: "",
        baud: "",
        dataSize: "",
        parity: "",
        ruleId: "",
        expTime: "",
        reqInterval: "",
        dataDeep: "",
        alarmDeep: "",
        isActivate: 0
      },
      form: {
        zyForm: {
          productId: '',
          sn: '',
          acqAddr: '',
          name: ''
        }
      },
      cloneForm: null,
      zcFormdata: [{
        prop: "equipCode",
        label: "设备编码",
        placeholder: "请输入设备编码",
        type: "text"
      }, {
        prop: "astNature",
        label: "资产性质",
        placeholder: "请选择资产性质",
        optionsUrl: "/sys-server/system/dict/data/dictType/10403",
        type: "list"
      }, {
        prop: "astOrg",
        label: "资产单位",
        placeholder: "请选择资产单位",
        type: "text"
      }, {
        prop: "astNum",
        label: "资产编号",
        placeholder: "请输入资产编号",
        type: "text"
      }, {
        prop: "projectNum",
        label: "工程编号",
        placeholder: "请输入工程编号",
        type: "text"
      }, {
        prop: "projectName",
        label: "工程名称",
        placeholder: "请输入工程名称",
        type: "text"
      }, {
        prop: "source",
        label: "设备来源",
        placeholder: "请选择设备来源",
        optionsUrl: "/sys-server/system/dict/data/dictType/10419",
        type: "list"
      }, {
        prop: "wbsNum",
        label: "WBS编码",
        placeholder: "请输入WBS编码",
        type: "text"
      }, {
        prop: "deployState",
        label: "设备状态",
        placeholder: "请选择设备状态",
        optionsUrl: "/sys-server/system/dict/data/dictType/10402",
        type: "list"
      }, {
        prop: "isCommission",
        label: "是否代维",
        placeholder: "请选择是否代维",
        optionsUrl: "/sys-server/system/dict/data/dictType/10599",
        type: "list"
      }, {
        prop: "operateDate",
        label: "投运日期",
        placeholder: "请选择投运日期",
        type: "datetime",
        isSelect: true
      }, {
        prop: "manufactureNum",
        label: "出厂编号",
        placeholder: "请输入出厂编号",
        type: "text"
      }, {
        prop: "manufactureDate",
        label: "出厂日期",
        placeholder: "请选择出厂日期",
        type: "datetime",
        isSelect: true
      }, {
        prop: "model",
        label: "设备型号",
        placeholder: "请输入设备型号",
        type: "text"
      }, {
        prop: "manufacturer",
        label: "生产厂家",
        placeholder: "请输入生产厂家",
        type: "text"
      }, {
        prop: "terminalType",
        label: "终端类型",
        placeholder: "请输入终端类型",
        type: "text"
      }, {
        prop: "communicationMet",
        label: "通信方式",
        placeholder: "请输入通信方式",
        type: "text"
      }, {
        prop: "macAddress",
        label: "mac地址",
        placeholder: "请输入mac地址",
        type: "text"
      }, {
        prop: "backupPower",
        label: "后备电源",
        placeholder: "请输入后备电源",
        type: "text"
      }, {
        prop: "backupPowerVl",
        label: "后备电源电压等级",
        placeholder: "请输入后备电源电压等级",
        type: "text"
      }, {
        prop: "terminalAddress",
        label: "终端地址",
        placeholder: "请输入终端地址",
        type: "text"
      }, {
        prop: "ctime",
        label: "创建时间",
        placeholder: "请选择创建时间",
        type: "datetime",
        isSelect: true
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea"
      }],
      zyFormData: [{
        prop: "productId",
        label: "选择产品",
        placeholder: "请选择产品",
        optionsUrl: `${process.env.VUE_APP_IOT_API}/product/list`,
        type: "list",
        props: {
          label: 'name',
          value: 'id'
        }
      }, {
        prop: "name",
        label: "设备名称",
        placeholder: "请输入设备名称",
        type: "text"
      }, {
        prop: "sn",
        label: "设备SN",
        placeholder: "请输入设备SN",
        type: "text",
        disabledHandle: () => state.title == '编辑'
      }, {
        prop: "acqAddr",
        label: "通信地址",
        type: "text",
        span: 24,
        disabledHandle: () => true
      }],
      disposeFormData: [{
        prop: "slaveAddr",
        label: "通讯地址",
        placeholder: "请输入通讯地址",
        type: "text"
      }, {
        prop: "port",
        label: "设备端口",
        placeholder: "请选择设备端口",
        optionsUrl: "/sys-server/system/dict/data/dictType/port",
        type: "list"
      }, {
        prop: "baud",
        label: "波特率",
        placeholder: "请选择波特率",
        optionsUrl: "/sys-server/system/dict/data/dictType/baud",
        type: "list"
      }, {
        prop: "dataSize",
        label: "数据长度",
        placeholder: "请选择数据长度",
        optionsUrl: "/sys-server/system/dict/data/dictType/data_size",
        type: "list"
      }, {
        prop: "parity",
        label: "奇偶校验",
        placeholder: "请选择奇偶校验",
        optionsUrl: "/sys-server/system/dict/data/dictType/parity",
        type: "list"
      }, {
        prop: "ruleId",
        label: "解析引擎",
        placeholder: "请选择解析引擎",
        optionsUrl: `${process.env.VUE_APP_BASE_API_modbus}/rule/table`,
        queryParams: {
          name: "",
          pageNum: 1,
          pageSize: 99999,
          queryName: ""
        },
        type: "list",
        method: 'post',
        props: {
          label: 'name',
          value: 'id'
        }
      }, {
        prop: "expTime",
        label: "请求超时",
        placeholder: "请输入请求超时",
        type: "text"
      }, {
        prop: "reqInterval",
        label: "请求间隔",
        placeholder: "请输入请求间隔",
        type: "text"
      }, {
        prop: "dataDeep",
        label: "数据深度",
        placeholder: "请输入数据深度",
        type: "text"
      }, {
        prop: "alarmDeep",
        label: "告警深度",
        placeholder: "请输入告警深度",
        type: "text"
      }, {
        prop: "isActivate",
        label: "是否启用",
        fixed: false,
        type: "switch",
        switch: {
          inActive: 0,
          active: 1
        }
      }],
      formData: [],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "Search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "Refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      title: "",
      url: {
        list: `${process.env.VUE_APP_IOT_API}/netsesmoke/pagePost`,
        zyAdd: `${process.env.VUE_APP_IOT_API}/netsesmoke/save`,
        zyEdit: `${process.env.VUE_APP_IOT_API}/netsesmoke/update`,
        zyDel: `${process.env.VUE_APP_IOT_API}/netsesmoke/delete`
      }
    });
    watch(() => state.form.zyForm.sn, () => {
      state.form.zyForm.acqAddr = state.form.zyForm.sn?.length == 32 && state.form.zyForm.sn?.startsWith("F") ? state.form.zyForm.sn?.slice(4, 16) : state.form.zyForm.sn;
    }, {
      immediate: true
    });
    state.formData = state.zcFormdata;
    state.formRules = state.zcFormRules;
    const router = useRouter();
    // 弹窗标题
    state.title = "新增";
    state.cloneQueryParams = JSON.parse(JSON.stringify(state.queryParams));
    state.cloneForm = JSON.parse(JSON.stringify(state.form));
    // 表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const methods = reactive({
      toDeviceInfo(row) {
        router.push({
          path: "/module/sysIot/deviceInfo",
          query: {
            deviceType: "SMOKE",
            id: row.id,
            path: "netsesmoke",
            active: row.activeStatus,
            isOnline: row.isOnline,
            operation: row.operation,
            sn: row.sn,
            productId: row.productId,
            name: row.name,
            productName: row.productName,
            productModel: row.productModel
          }
        });
      },
      handleSwitch(row) {
        ElMessageBox.confirm(`确定是否${row.isForbidden == 1 ? '启用' : '禁用'}?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          let params = row;
          request({
            url: state.url.zyEdit,
            method: "post",
            data: params
          }).then(res => {
            if (res.code == 1000) {
              ElMessage.success("操作成功");
              methods.getList();
            } else {
              ElMessage.warning(res.message);
            }
          });
        }).catch(() => {
          row.isForbidden = row.isForbidden == 1 ? 0 : 1;
        });
      },
      handleClick(val) {},
      cityChange(val, operate) {
        if (val && val.length > 0) {
          if (!operate) {
            state.queryParams.city = val.join(',');
          }
        }
      },
      // 详情
      handleView(row) {
        state.title = "详情";
        request({
          url: state.url.queryById,
          method: 'get',
          params: {
            id: row.id
          }
        }).then(res => {
          state.form = res.data;
          state.dialogVisible = true;
        }).catch(err => {
          state.saveLoading = false;
        });
      },
      changeTableheader(val) {
        state.showTableHeader = val;
      },
      handleSelectionAll(val) {},
      handleSelectionChange(val) {},
      //增加
      handleAdd() {
        state.title = "新增";
        state.form = JSON.parse(JSON.stringify(state.cloneForm));
        request({
          url: process.env.VUE_APP_IOT_API + "/netSeFtuZc/getDataDropdown",
          method: "post"
        }).then(res => {
          const temp = [];
          if (res.data?.length) {
            for (let item of res.data) {
              temp.push({
                value: item.id,
                label: item.name
              });
            }
          }
          state.zyFormData[0]['options'] = temp;
        }).finally(() => {
          state.dialogVisible = true;
        });
      },
      //删除
      handleDel(isBatch, row) {
        if (isBatch && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        let ids = [];
        if (isBatch) {
          ids = state.multipleSelection.map(item => {
            return item.id;
          });
        } else {
          ids = [row.id];
        }
        ElMessageBox.confirm("确定是否删除?", "删除", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          request({
            url: state.url.zyDel,
            method: "post",
            data: ids
          }).then(res => {
            if (res.code == 1000) {
              ElMessage.success("删除成功");
              methods.getList();
            } else {
              ElMessage.warning(res.message);
            }
          });
        });
      },
      handleImport() {},
      handleExport(row) {
        if (!state.tableData.rows || state.tableData.rows.length == 0) {
          ElMessage.closeAll();
          ElMessage.warning('暂无数据可导出！');
          return;
        }
        //没有勾选默认导出全部，勾选就导出勾选的部分
        let tipTitle = '';
        if (!row.id && !state.multipleSelection.length) {
          tipTitle = '是否确认导出所有的数据项?';
        } else {
          tipTitle = '是否确认导出所选的数据项?';
        }
        ElMessageBox.confirm(tipTitle, "导出", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          row.loading = true;
          //导出参数增加勾选的参数
          if (!state.multipleSelection.length) {
            state.queryParams.PIdList = "";
          } else if (row.id || state.multipleSelection.length) {
            if (row.id) {
              state.multipleSelection = [];
            }
            let zyParames = null;
            if (state.multipleSelection.length) {
              zyParames = state.multipleSelection.map(item => {
                return item.pId;
              }).join(',');
            } else {
              zyParames = row.pId;
            }
            state.queryParams.PIdList = zyParames;
          }
          request({
            url: state.url.exports,
            method: "post",
            params: state.queryParams,
            responseType: "blob"
          }).then(res => {
            let fileName = decodeURI(res.headers["content-disposition"].split("=")[1]);
            downFile(fileName, res.data);
            row.loading = false;
          }).catch(err => {
            console.log(err);
            row.loading = false;
          });
        });
      },
      disposeSubmitForm: function () {
        const disposeFormRef = unref(formDispose);
        let cloneStateForm = JSON.parse(JSON.stringify(state.disposeForm));
        disposeFormRef.commonForm.validate(valid => {
          if (valid) {
            state.disposeSaveLoading = true;
            request({
              url: `${process.env.VUE_APP_BASE_API_modbus}/device/command/update`,
              method: 'post',
              data: cloneStateForm
            }).then(res => {
              state.dialogVisibleTwo = false;
              state.disposeSaveLoading = false;
              methods.getList();
              ElMessage.success("配置成功");
            }).catch(err => {
              state.saveLoading = false;
              state.disposeSaveLoading = false;
            });
          }
        });
      },
      //确定
      submitForm: function () {
        const formRef = unref(formContainer);
        const zyFormRef = unref(formContainerZY);
        let cloneStateForm = JSON.parse(JSON.stringify(state.form));
        zyFormRef.commonForm.validate(valid => {
          if (valid) {
            if (cloneStateForm.zyForm.city) {
              cloneStateForm.zyForm.city = Array.isArray(cloneStateForm.zyForm.city) ? cloneStateForm.zyForm.city.join(',') : "";
            }
            state.saveLoading = true;
            delete cloneStateForm.zyForm.isForbidden;
            if (cloneStateForm.zyForm.id) {
              //编辑
              request({
                url: state.url.zyEdit,
                method: 'post',
                data: cloneStateForm.zyForm
              }).then(res => {
                state.saveLoading = false;
                state.dialogVisible = false;
                methods.getList();
                ElMessage.success("编辑成功");
              }).catch(err => {
                state.saveLoading = false;
              });
            } else {
              //新增
              request({
                url: state.url.zyAdd,
                method: 'post',
                data: cloneStateForm.zyForm
              }).then(res => {
                state.saveLoading = false;
                state.dialogVisible = false;
                methods.getList();
                ElMessage.success("新增成功");
              }).catch(err => {
                console.log(err);
                state.saveLoading = false;
              });
            }
          }
        });
        // }
      },
      // 取消
      closeDialog() {
        state.dialogVisible = false;
      },
      formHandleForm() {
        console.log("formHandle");
      },
      getList() {
        nextTick(() => {
          state.tableLoading = true;
          request({
            url: state.url.list,
            method: 'post',
            data: state.queryParams
          }).then(res => {
            state.tableData = res.data;
            state.tableLoading = false;
          }).catch(err => {
            state.saveLoading = false;
            state.tableLoading = false;
          });
        });
      },
      /** 搜索按钮操作 */
      handleQuery() {
        state.queryParams.pageNum = 1;
        methods.getList();
      },
      /** 重置按钮操作 */
      resetQuery() {
        state.queryParams = JSON.parse(JSON.stringify(state.cloneQueryParams));
        methods.handleQuery();
      },
      handleMultipleSelection() {},
      sortEvents() {},
      handleEdit(row) {
        if (!row.id && state.multipleSelection.length !== 1) {
          ElMessage.closeAll();
          ElMessage.warning("请选择一条数据编辑");
          return;
        }
        state.title = "编辑";
        state.form.zyForm = JSON.parse(JSON.stringify(row));
        if (state.form.zyForm.city) {
          state.form.zyForm.city = row.city.split(',');
        }
        state.dialogVisible = true;
      },
      handleDispose(row) {
        state.disposeForm.deviceId = row.id;
        state.dialogVisibleTwo = true;
        request({
          url: `${process.env.VUE_APP_BASE_API_modbus}/device/getModbusDevice/${row.id}`,
          method: 'get'
        }).then(res => {
          state.disposeForm.id = res.data.id;
          state.disposeForm.slaveAddr = res.data.slaveAddr;
          state.disposeForm.port = res.data.port;
          state.disposeForm.baud = res.data.baud;
          state.disposeForm.dataSize = res.data.dataSize;
          state.disposeForm.parity = res.data.parity;
          state.disposeForm.ruleId = res.data.ruleId;
          state.disposeForm.expTime = res.data.expTime;
          state.disposeForm.reqInterval = res.data.reqInterval;
          state.disposeForm.dataDeep = res.data.dataDeep;
          state.disposeForm.alarmDeep = res.data.alarmDeep;
          state.disposeForm.isActivate = res.data.isActivate;
        }).catch(err => {});
      }
    });
    onMounted(() => {});
    return {
      ...toRefs(state),
      ...toRefs(methods),
      formContainer,
      formContainerZY,
      formDispose
    };
  }
});