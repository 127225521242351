import { getDatasetDetail, getAnnotation } from "@/api/AI/index";
export default {
  name: 'DatasetView',
  data() {
    return {
      id: '',
      form: {
        name: '',
        labels: [],
        type: ''
      },
      //编辑逻辑 是否标注
      annotated: '已标注',
      //预览 标注未标注form
      annotationForm: {
        type: 'preview',
        annotated: true,
        page_num: 1
      },
      annotationList: [],
      total: 0
    };
  },
  watch: {
    '$route': {
      handler(v) {
        if (v.query) {
          const id = v.query.id;
          this.id = id;
          this.initData(id);
        }
      },
      immediate: true
    }
  },
  methods: {
    initData(id) {
      this.getDatasetDetail(id);
      this.getAnnotation();
    },
    //获取详情数据
    getDatasetDetail(id) {
      getDatasetDetail(id).then(res => {
        if (res.status === 200) {
          let {
            name,
            labels,
            type
          } = res.data;
          this.form = {
            name,
            labels,
            type
          };
        }
      });
    },
    //标注未标注切换
    radioChange(v) {
      console.log(v);
      if (v === '未标注') {
        this.annotationForm = {
          type: 'preview',
          annotated: false,
          page_num: 1
        };
      } else {
        this.annotationForm = {
          type: 'preview',
          annotated: true,
          page_num: 1
        };
      }
      this.getAnnotation();
    },
    handleCurrentChange(val) {
      this.annotationForm.page_num = val;
      this.getAnnotation();
    },
    //获取标注，未标注 预览
    getAnnotation() {
      getAnnotation(this.id, this.annotationForm).then(res => {
        if (res.status === 200) {
          res.data.data.forEach(item => {
            item.checked = false;
          });
          this.annotationList = res.data.data;
          this.total = res.data.count;
        }
      });
    }
  }
};