import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { listJobLog, delJobLog, exportJobLog, cleanJobLog, getDicts } from "@/api/system/monitor/jobLog";
import { useRouter } from 'vue-router';
import { deepClone, downloadFile, selectDictLabel } from "@/utils";
import { parseTime } from "@/utils/lzCommon";
export default defineComponent({
  name: "JobLog",
  components: {},
  props: ['path'],
  setup(props) {
    // 任务组名字典
    const jobGroupOptions = ref([]);
    const state = reactive({
      // 表单参数
      viewForm: {},
      open: false,
      tableLoading: false,
      multipleSelection: [],
      tableHeader: [{
        prop: "jobLogId",
        label: "日志编号",
        fixed: false,
        type: "text"
      }, {
        prop: "jobName",
        label: "任务名称",
        fixed: false,
        type: "text"
      }, {
        prop: "jobGroup",
        label: "任务组名",
        statusList: [{
          label: '默认',
          value: "DEFAULT"
        }, {
          label: '系统',
          value: "SYSTEM"
        }],
        fixed: false,
        type: "text"
      }, {
        prop: "invokeTarget",
        label: "调用目标字符串",
        fixed: false,
        type: "text"
      }, {
        prop: "jobMessage",
        label: "日志信息",
        fixed: false,
        type: "text"
      }, {
        prop: "status",
        label: "执行状态",
        statusList: [{
          label: '正常',
          value: "0"
        }, {
          label: '停用',
          value: "1"
        }]
      }, {
        prop: "createTime",
        label: "执行时间",
        fixed: false,
        type: "text"
      }, {
        prop: "operation",
        label: "操作",
        width: "400px",
        fixed: 'right',
        operation: [{
          name: "详情",
          clickFun: row => methods.handleView(row),
          hasPermi: ['monitor:job:query']
        }]
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        jobName: "",
        jobGroup: "",
        status: "",
        dateRange: [],
        beginTime: "",
        endTime: ""
      },
      searchFormData: [{
        prop: "jobName",
        label: "任务名称",
        placeholder: "请输入任务名称",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "jobGroup",
        label: "任务组名",
        placeholder: "请选择任务组名",
        options: jobGroupOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        type: "list",
        isSelect: true,
        span: 5
      }, {
        prop: "status",
        type: "list",
        label: "执行状态",
        placeHolder: "请选择执行状态",
        options: [{
          label: "正常",
          value: "0"
        }, {
          label: "停用",
          value: "1"
        }],
        props: {
          label: "label",
          value: "value"
        },
        isShow: true,
        span: 5
      }, {
        prop: "dateRange",
        label: "时间",
        type: "daterange",
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['monitor:job:remove2'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => methods.handleDel(item)
      }, {
        buttonName: "清空",
        buttonStyle: "danger",
        hasPermi: ['monitor:job:clear'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => methods.handleClean(item)
      }, {
        buttonName: "导出",
        hasPermi: ['monitor:job:export2'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => methods.handleExport(item)
      }]
    });
    const router = useRouter();
    //表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const methods = reactive({
      // 任务组名字典翻译
      jobGroupFormat(row, column) {
        return selectDictLabel(jobGroupOptions.value, row.jobGroup);
      },
      //搜索
      handleQuery() {
        methods.getList();
      },
      //重置
      resetQuery() {
        state.queryParams = {
          pageSize: 10,
          pageNum: 1,
          jobName: "",
          jobGroup: "",
          status: "",
          dateRange: [],
          beginTime: "",
          endTime: ""
        };
        methods.getList();
      },
      //删除
      handleDel(row) {
        if (!row.jobLogId && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        if (row.jobLogId) {
          state.multipleSelection = [];
        }
        state.ids = state.multipleSelection.map(item => item.jobLogId);
        let jobLogIds = row.jobLogId || state.ids;
        ElMessageBox.confirm("是否确认删除数据项?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delJobLog(jobLogIds);
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("删除成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      },
      //列表查询
      getList() {
        let params = {
          "beginTime": state.queryParams.beginTime,
          "endTime": state.queryParams.endTime,
          "jobName": state.queryParams.jobName,
          "status": state.queryParams.status,
          "jobGroup": state.queryParams.jobGroup,
          "pageNum": state.queryParams.pageNum,
          "pageSize": state.queryParams.pageSize
        };
        state.tableLoading = true;
        listJobLog(params).then(response => {
          state.tableData.rows = response.rows;
          state.tableData.total = response.total;
          state.tableLoading = false;
        });
      },
      //字典下拉查询
      getDicts() {
        getDicts("sys_job_group").then(response => {
          jobGroupOptions.value = response.data;
        });
      },
      //导出
      handleExport() {
        ElMessageBox.confirm('是否确认导出所有定时任务数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return exportJobLog(state.queryParams);
        }).then(response => {
          downloadFile(response.message);
        }).catch(err => {
          console.log(err);
        });
      },
      //清空
      handleClean() {
        ElMessageBox.confirm("是否确认清空所有调度日志数据项?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return cleanJobLog();
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("清空成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      },
      /** 任务详细信息 */
      handleView(row) {
        state.viewForm = {};
        state.viewForm = row;
        state.open = true;
      }
    });
    methods.getDicts();
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router)
    };
  }
});