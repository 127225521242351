import { ElMessage } from 'element-plus'

export const isNotNullORBlank = (...args) => {
  for (var i = 0; i < args.length; i++) {
    var argument = args[i]
    if (argument == null || argument === '' || argument === undefined) {
      ElMessage.warning({ message: '数据不能为空!' })
      return false
    }
  }
  return true
}

export const formatFileSize = (size) => {
  var fileSize = size + ' bytes'
  if (size > 1024 * 1024 * 1024) {
    fileSize = (size / (1024 * 1024 * 1024)).toFixed(1) + 'GB'
  } else if (size > 1024 * 1024) {
    fileSize = (size / (1024 * 1024)).toFixed(1) + 'MB'
  } else if (size > 1024) {
    fileSize = (size / 1024).toFixed(1) + 'KB'
  }
  return fileSize
}

export const getFileExtension = (file) => {
  let fileName = file.name
  let idx = fileName.lastIndexOf('.')
  if (idx === -1) {
    return fileName
  } else {
    let extension = fileName.substring(idx + 1).toLowerCase()
    return extension
  }
}

export const getFileName = (file) => {
  let fileName = file.name
  let idx = fileName.lastIndexOf('.')
  if (idx === -1) {
    return fileName
  } else {
    let fn = fileName.substring(0, idx)
    return fn
  }
}

export const parseTimeRemaining = (timeRemaining, parsedTimeRemaining) => {
  return parsedTimeRemaining
    .replace(/\syears?/, '年')
    .replace(/\days?/, '天')
    .replace(/\shours?/, '小时')
    .replace(/\sminutes?/, '分钟')
    .replace(/\sseconds?/, '秒')
}
