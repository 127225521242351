function findFirstLevelNode(nodeData) {
  if (nodeData.children) {
    return findFirstLevelNode(nodeData.children[0]);
  } else {
    return nodeData;
  }
}
import lzTree from "../lz-tree";
import { Refresh } from "@element-plus/icons-vue";
import { defineComponent, ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
import request from "../../utils/onlineRequest";
export default defineComponent({
  name: "treeView",
  components: {
    lzTree,
    Refresh
  },
  props: {
    placeholder: {
      type: String,
      default: "Please enter Device Name"
    },
    url: {
      type: String,
      default: ""
    },
    searchUrl: {
      type: String,
      default: ""
    },
    nodeKey: {
      type: String,
      default: () => {
        return "name";
      }
    },
    initNode: {
      type: Number,
      default: 0
    },
    defaultCheckNode: {
      type: Object,
      default: () => {
        return {};
      }
    },
    otherParams: {
      type: Object,
      default: () => {
        return null;
      }
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          id: "id",
          label: "nodeName",
          children: "children"
        };
      }
    },
    clickableData: {
      type: Array,
      default: () => {
        [];
      }
    }
  },
  emits: ["handle-node-click", "update:otherParams", "update:defaultCheckNode"],
  setup(props, {
    emit
  }) {
    const lazyLoadTreeSelect = ref(null);
    const isDrak = ref(null);
    const query = ref("");
    const onQueryChanged = query => {
      treeLoading.value = true;
      handleSearchByName(query);
    };
    let treeLoading = ref(false);
    let deviceTree = ref([]);
    const route = useRoute();
    const store = useStore();
    const queryLineData = (parentId = "") => {
      return new Promise((resolve, reject) => {
        let otherParams = props.otherParams;
        // 处理从别的页面带参数跳转过来初次通过参数查询设备树
        if (otherParams) {
          request({
            url: props.searchUrl ? props.searchUrl : "/energy-comm-provider/cim/searchCimTopuDevice",
            method: "post",
            data: {
              deviceName: "",
              ...otherParams
            }
          }).then(res => {
            resolve(res.data);
          }).catch(err => {
            reject(err);
          });
        } else {
          request({
            url: props.url ? props.url : "/energy-comm-provider/cim/getCimTopuDeviceTree",
            method: "post",
            data: {
              parentId: parentId
            }
          }).then(res => {
            resolve(res.data);
          }).catch(err => {
            reject(err);
          });
        }
      });
    };
    const queryDefaultTreeData = () => {
      treeLoading.value = true;
      queryLineData().then(res => {
        if (res) {
          res = [res];
          deviceTree.value = res;
          if (Object.keys(props.defaultCheckNode).length) {
            checkNode.value = props.defaultCheckNode;
          } else {
            findFirstDevice();
          }
          treeLoading.value = false;
        }
      });
    };
    const refresh = () => {
      // 处理列表进详情后，恢复树的状态
      emit("update:otherParams", null);
      emit("update:defaultCheckNode", {});
    };
    //找到第一个设备节点
    const findFirstDevice = async () => {
      let deviceTreeCopy = deviceTree.value;
      let value = deviceTreeCopy.length && deviceTreeCopy[0];
      let initCurrentNode = {};
      while (value.isLeaf !== true) {
        await queryLineData(value.id).then(res => {
          lazyLoadTreeSelect.value.updateChildren(value, res);
          if (res.length) {
            value = res[0];
          } else {
            value.isLeaf = true;
          }
          //初始化默认选中
          if (props.initNode && props.initNode === value.nodeType) {
            initCurrentNode = value;
          }
        });
      }
      console.log("value:", value);
      if (props.initNode) {
        // alert(props.initNode);
        console.log("initNode:", initCurrentNode);
        checkNode.value = {
          [props.defaultProps.id]: initCurrentNode[props.defaultProps.id]
        };
        emit("handle-node-click", initCurrentNode);
      } else {
        checkNode.value = {
          [props.defaultProps.id]: value[props.defaultProps.id]
        };
        emit("handle-node-click", value);
      }
    };

    //
    let checkNode = ref({});
    checkNode.value = props.defaultCheckNode;
    queryDefaultTreeData();

    //懒加载数据
    const loadNode = node => {
      console.log("load-node:", node);
      queryLineData(node.id).then(res => {
        console.log("load-node:=====", res);
        if (res.length === 0) {
          node.isLeaf = true;
          emit("handle-node-click", node);
        }
        lazyLoadTreeSelect.value.updateChildren(node, res);
      });
    };
    const handleNodeClick = function (value) {
      console.log("handle-node-click", value);
      if (!value.psrId) {
        return;
      }
      let isCondition = false;
      //可点击条件
      const conditionData = props.clickableData;
      console.log('conditionData', props.clickableData);
      if (Array.isArray(conditionData) && conditionData.includes(value.psrTypeName)) {
        isCondition = true;
      }

      //可点击
      if (value.isLeaf || isCondition) {
        emit("handle-node-click", value);
        checkNode.value = {
          [props.defaultProps.id]: value[props.defaultProps.id]
        };
        return;
      }
    };
    const currentNodeKey = computed(() => "netsmaintrans");
    const handleSearchByName = inputName => {
      if (!inputName) {
        queryDefaultTreeData();
      } else {
        request({
          url: props.searchUrl ? props.searchUrl : "/energy-comm-provider/cim/searchCimTopuDevice",
          method: "post",
          data: {
            deviceName: inputName,
            assetId: ""
          }
        }).then(res => {
          treeLoading.value = false;
          if (!res.data) {
            deviceTree.value = [];
          } else {
            res.data = [res.data];
            deviceTree.value = res.data;
          }
        }).catch(err => {});
      }
    };
    watch(() => props.otherParams, newVal => {
      queryDefaultTreeData();
    });
    watch(() => store.state.user.themeMode, val => {
      if (val == "dark") {
        isDrak.value = true;
      } else {
        isDrak.value = false;
      }
    });
    onMounted(() => {
      if (store.state.user.themeMode == "dark") {
        isDrak.value = true;
      } else {
        isDrak.value = false;
      }
    });
    return {
      checkNode,
      deviceTree,
      treeLoading,
      route,
      isDrak,
      handleNodeClick,
      refresh,
      currentNodeKey,
      lazyLoadTreeSelect,
      handleSearchByName,
      query,
      onQueryChanged,
      loadNode
    };
  }
});