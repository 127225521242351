import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "test"
};
export default {
  __name: 'test',
  setup(__props) {
    //import IndicatorCard from '@/components/IndicatorCard'
    const config = {
      icon: 'icon-liudaxitongpeidianshi',
      width: '340px'
    };
    const control = {
      name: 'CPU负载',
      value: '1774888887.0',
      unit: '%',
      occurredTime: '2023-05-17 13:53:26',
      "productModel": "2501",
      "deviceId": "1643173317965778944",
      "identifier": "PhV_phsA",
      "productName": "北京智能断路器勿删"
    };
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1);
    };
  }
};