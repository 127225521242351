import { scrollTo } from "@/utils/scroll-to";
import { computed, reactive, toRefs, ref, watch } from 'vue';
export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default() {
        return [5, 10, 20, 30, 50, 100];
      }
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper"
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  emits: ["pagination", "getList", "update:page", "update:limit"],
  setup(props, context) {
    let currentPage = ref(1);
    currentPage = computed({
      get() {
        return props.page;
      },
      set(val) {
        context.emit("update:page", val);
      }
    });
    // let pageSize = ref(10);
    let pageSize = computed({
      get() {
        return props.limit;
      },
      set(val) {
        context.emit("update:limit", val);
      }
    });
    const methods = reactive({
      handleSizeChange(val) {
        context.emit("update:limit", val);
        // bug 14042 N-功能测试-告警管理-告警类型管理-分页选择5条/页，点击第二页，再选择20条/页，列表中告警类型等几列信息空白 qz 2022/10/13
        context.emit("pagination", {
          pageSize: val,
          pageNum: 1
        });
        if (props.autoScroll) {
          scrollTo(0, 800);
        }
      },
      handleCurrentChange(val) {
        context.emit("update:page", val);
        context.emit("pagination", {
          pageSize: pageSize.value,
          pageNum: val
        });
        if (props.autoScroll) {
          scrollTo(0, 800);
        }
      }
    });
    watch(() => props.total, (newVal, oldVal) => {
      if (newVal === (currentPage - 1) * props.limit && newVal !== 0) {
        currentPage -= 1;
        context.emit("pagination", {
          pageSize: pageSize.value,
          pageNum: currentPage
        });
      }
    });
    return {
      ...toRefs(methods),
      currentPage,
      pageSize
    };
  }
};