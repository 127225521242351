import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, Transition as _Transition, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createSlots as _createSlots, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = {
  class: "table-list"
};
const _hoisted_2 = {
  key: 0,
  class: "table-search"
};
const _hoisted_3 = {
  class: "control-btn"
};
const _hoisted_4 = {
  class: "btn-group"
};
const _hoisted_5 = {
  class: "control-other"
};
const _hoisted_6 = {
  style: {
    "padding-left": "40px"
  }
};
const _hoisted_7 = {
  key: 2,
  class: "table-page"
};
import DesignForm from '../../form/components/form.vue';
import { reactive, ref, onMounted, computed, nextTick, onBeforeUnmount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Tooltip from '../../components/tooltip.vue';
import { getRequest } from '@/api/designForm';
import { ElMessage, ElMessageBox } from 'element-plus';
import { dateFormatting } from '@/utils';
import ListTreeSide from './listTreeSide.vue';
import formatResult from '@/utils/formatResult';
import request from '@/utils/onlineRequest';
export default {
  __name: 'list',
  props: {
    tableData: {
      type: Object,
      default: () => {
        return {
          columns: {}
        };
      }
    },
    childHead: {
      type: Array,
      default: () => []
    },
    searchData: {
      type: Object,
      default: () => {
        return {
          list: [],
          form: {}
        };
      }
    },
    beforeRequest: {
      type: Function
    },
    afterResponse: {
      type: [Function, String]
    },
    beforeDelete: {
      type: Function
    },
    showPage: {
      type: Boolean,
      default: true
    },
    requestUrl: {
      type: String // 请求的api
    },
    deleteUrl: {
      type: String
    },
    // 删除的api
    dict: {
      type: Object,
      default: () => {
        return {};
      }
    },
    fixedBottomScroll: {
      type: Boolean,
      default: false
    },
    formId: {
      type: [String, Number]
    },
    // 表单id，当前列表所使用的表单id，用于加载数据，新增，编辑删除等数据处理
    autoLoad: {
      type: Boolean,
      default: true
    } // 初始时自动请求加载数据
  },
  emits: ['selectionChange', 'btnClick', 'subEdit', 'subDelete', 'getTableData'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;

    //列表上面及表格列表里添加删除按钮事件

    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const container = ref();
    const searchFormEl = ref();
    const table = ref();
    const state = reactive({
      loading: false,
      tableDataList: [],
      // 表格行数据
      currentPage: 1,
      pageSize: props.tableData.config?.pageSize || 10,
      total: 0,
      selectionChecked: [],
      dict: {},
      // 接口返回的
      searchFormDown: false,
      treeValue: {},
      // 侧栏树选中的值
      tableScrollMargin: 0,
      columnsCheck: []
    });
    const treeData = computed(() => {
      return props.tableData.treeData || {};
    });
    // 可折叠查询表单
    const searchFormExpand = computed(() => {
      return props.searchData?.list?.length && props.tableData.config?.expand;
    });
    const columnsFilter = computed(() => {
      if (!state.columnsCheck.length) {
        return props.tableData.columns;
      } else {
        return props.tableData.columns.filter(item => {
          return state.columnsCheck.includes(item.prop || item.type);
        });
      }
    });
    // 列显示隐藏设置
    const columnsSetting = computed(() => {
      return props.tableData.config?.columnsSetting ?? true;
    });
    // 获取存在storage的dict，进入系统时可将所有字典预先加载存入storage
    const listDict = computed(() => {
      const storage = window.localStorage.getItem('akFormDict');
      let storageDict = {};
      if (storage) {
        storageDict = JSON.parse(storage);
      }
      return Object.assign(storageDict, props.dict, state.dict);
    });
    const isFixedBottomScroll = computed(() => {
      // 如果数据里没配置，则使用props
      const fbs = props.tableData.config?.fixedBottomScroll;
      return fbs ?? props.fixedBottomScroll;
    });
    // 列表行操作按钮下拉时返回第一个，否则返回全部
    const operateBtnList = computed(() => {
      const btn = props.tableData.operateBtn;
      if (props.tableData.config?.operateDropdown && btn) {
        return [btn[0]];
      }
      return btn;
    });
    // 筛选查询列表数据
    const getListData = page => {
      // 优先使用config配置的
      const getUrl = props.tableData.config?.requestUrl || props.requestUrl;
      if (!getUrl) {
        console.error(new Error('请先设置请求requestUrl'));
        return;
      }
      if (page) {
        state.currentPage = page;
      }
      state.loading = true;
      // 筛选查询一般不存在校验，这里直接取值
      const formValue = Object.assign({}, searchFormEl.value?.getValue(true), state.treeValue);
      let newData = formValue;
      const beforeRequest = props.tableData.events?.beforeRequest;
      if (typeof beforeRequest === 'function') {
        newData = beforeRequest(formValue || {}, route, store);
      }
      if (typeof props.beforeRequest === 'function') {
        newData = props.beforeRequest(formValue || {}, route, store);
      }
      if (newData === false) {
        return;
      }
      if (!newData) {
        // beforeRequest没有return时
        newData = formValue;
      }
      const params = {
        pageInfo: {
          pageSize: state.pageSize,
          pageIndex: state.currentPage
        },
        pageSize: state.pageSize,
        pageNum: state.currentPage,
        formId: props.formId,
        ...newData
      };
      getRequest(getUrl, params).then(res => {
        state.loading = false;
        let formatRes = res.data;
        const afterResponse = props.tableData.events?.afterResponse;
        if (typeof afterResponse === 'string' && afterResponse) {
          formatRes = formatResult(formatRes, afterResponse);
        } else if (typeof afterResponse === 'function') {
          formatRes = afterResponse(formatRes) ?? formatRes;
        }
        if (props.afterResponse && typeof props.afterResponse === 'string') {
          formatRes = formatResult(formatRes, props.afterResponse);
        } else if (typeof props.afterResponse === 'function') {
          formatRes = props.afterResponse(formatRes) ?? formatRes;
        }
        if (formatRes === false) {
          return;
        }
        state.tableDataList = formatRes?.list || formatRes?.rows || formatRes; // 兼容下可以不返回list
        emits('getTableData', state.tableDataList);
        setTimeout(() => {
          fixedBottomScroll();
        }, 200); // 加个延时主要是等待列表渲染完，即列表查询区域等，计算才准确。
        state.dict = formatRes.dict || {};
        state.total = parseInt(formatRes.pageInfo?.total || formatRes?.total || 0);
      }).catch(res => {
        state.tableDataList = [];
        state.total = 0;
        state.dict = {};
        state.loading = false;
        ElMessage.error(res.message || '数据加载异常');
      });
    };
    // 仅清空筛选输入
    const searchClear = () => {
      searchFormEl.value.resetFields(); // 这个只是清空了model的值
      searchFormEl.value.setValue(searchFormEl.value.getValue()); // 重新将取到的空值对筛选表单赋值
      getListData(1); // 重新请求数据
    };
    const handleSizeChange = page => {
      state.pageSize = page;
      getListData(1);
    };
    const handleCurrentChange = page => {
      getListData(page);
    };
    const addOrEdit = (btn, row) => {
      //　数据添加编辑打开方式为弹窗时，这里不处理
      if (props.tableData.config?.openType === 'dialog') {
        return false;
      }
      if (!props.formId) {
        console.error('请设置表单ID或去掉key=add/edit属性');
        return;
      }
      router.push({
        path: `/devPlatform/design/form/form`,
        query: {
          form: props.formId,
          id: row?.id
        }
      });
    };
    // 删除 idList支持多个 ,params为附近参数
    const delClick = (idList, params) => {
      state.loading = true;
      const delUrl = props.tableData.config?.deleteUrl || props.deleteUrl;
      const delParams = Object.assign({
        formId: props.formId,
        id: idList.toString() // 多个时转字符串
      }, params || {});
      let delParamsAll;
      const beforeDelete = props.tableData.events?.beforeDelete;
      if (typeof beforeDelete === 'function') {
        delParamsAll = beforeDelete(delParams, route);
      }
      if (typeof props.beforeDelete === 'function') {
        delParamsAll = props.beforeDelete(delParams, route);
      }
      if (delParamsAll === false) {
        return;
      }
      getRequest(delUrl, delParamsAll ?? delParams).then(res => {
        state.loading = false;
        ElMessage.success(res.message || '删除成功');
        getListData(); // 请求列表数据
      }).catch(res => {
        state.loading = false;
        ElMessage.error(res.message || '删除失败');
      });
    };
    // 表格每行的操作按钮点击事件
    const operateBtnClick = (btn, row) => {
      emits('btnClick', btn, row);
      if (btn.click) {
        const result = btn.click(row, router);
        if (result === false) {
          return; // 回调事件里处理
        }
      }
      if (btn.key === 'edit') {
        addOrEdit(btn, row);
      } else if (btn.key === 'del') {
        if (btn.tip) {
          // 有删除提示
          ElMessageBox.confirm(btn.tip, '温馨提示', {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            delClick(row.id);
          });
        } else {
          delClick(row.id);
        }
      } else if (btn.key === 'view') {
        router.push({
          path: `/devPlatform/design/form/form`,
          query: {
            form: btn.formId,
            id: row?.id
          }
        });
      }
    };
    // 根据条件显示操作按钮
    const getOperateVisible = (btn, row) => {
      if (btn.visible && typeof btn.visible === 'string') {
        const Fn = new Function('$', `return (${btn.visible})`);
        return Fn(row);
      }
      return btn.visible !== false;
    };
    const controlBtnClick = row => {
      // 对外抛个事件，方便添加了其他按钮时，同时添加回调事件，
      emits('btnClick', row);
      if (row.click) {
        const result = row.click(row);
        if (result === false) {
          return; // 回调事件里处理
        }
      }
      if (row.key === 'add') {
        // 跳到新增页
        addOrEdit(row);
      } else if (row.key === 'del') {
        // 批量删除
        if (state.selectionChecked.length) {
          let idList = [];
          state.selectionChecked.forEach(item => {
            idList.push(item.id);
          });
          delClick(idList);
        } else {
          ElMessage.error('请选择需要删除的选项！');
        }
      }
    };
    const selectionChange = row => {
      state.selectionChecked = row;
      emits('selectionChange', row);
    };
    // 个性化设置
    const getDictLabel = (scope, item, type) => {
      if (scope.row.$index !== -1) {
        // 表格没数据时也会引用，此时$index=-1，应该是组件ui问题
        const val = scope.row[item.prop];
        if (type === 'formatter' && val) {
          // 时间日期类格式化 
          return dateFormatting(val, item.config?.formatter);
        } else {
          const key = listDict.value[item.config?.dictKey];
          if (Object.keys(listDict.value).length && key) {
            return key[val];
          } else {
            return val;
          }
        }
      }
    };
    const formBtnClick = type => {
      if (type === 'submit') {
        getListData(1);
      } else if (type === 'reset') {
        searchClear();
      }
    };
    // 侧栏树点击事件
    const treeNodeClick = val => {
      if (!treeData.value.name) {
        console.error(new Error('请设置侧栏树name值'));
        return;
      }
      state.treeValue = {
        [treeData.value.name]: val
      };
      getListData(1);
    };
    // 固定横向滚动条在浏览器底部
    const fixedBottomScroll = () => {
      if (isFixedBottomScroll.value) {
        nextTick(() => {
          const tableEl = container.value;
          if (!tableEl) {
            return;
          }
          const tableBodyWrapDom = tableEl.querySelector('.el-scrollbar__wrap'); // table父一级
          if (!tableBodyWrapDom) {
            return;
          }
          const tableBodyDom = tableEl.querySelector('.el-table__body'); // table
          const {
            top: tableBodyDomTop
          } = tableBodyWrapDom.getBoundingClientRect();
          const tableHeight = tableBodyDom.offsetHeight; // 表格的高度
          const windowHeight = window.innerHeight;
          if (tableBodyDomTop > windowHeight || tableBodyWrapDom.classList.contains('is-scrolling-none')) {
            // 此时列表在可视窗口的下侧不可见区域，因此不做任何修改
            tableBodyWrapDom.style.height = 'unset';
            tableBodyWrapDom.style.marginBottom = 'unset';
            tableBodyWrapDom.style.overflowY = '';
          } else {
            // 窗口高度 - 列表距顶部值 且 不超过自身实际值
            let wrapHeight = Math.min(windowHeight - tableBodyDomTop - 10, tableHeight);
            // console.log(
            //   `window.innerHeight:${windowHeight},tableBodyDomTop:${tableBodyDomTop},tableBodyDom.offsetHeight:${tableHeight}`
            // )
            tableBodyWrapDom.style.minHeight = '60px';
            tableBodyWrapDom.style.height = wrapHeight + 10 + 'px';
            // 需要用marginBottom填充，以保持列表原有高度，避免页面的纵向滚动条变化导致页面滚动的不流畅
            state.tableScrollMargin = tableHeight - wrapHeight + 'px';
          }
        });
      }
    };
    const scrollBox = computed(() => {
      // 当前框架滚动区域
      return document.querySelector('.main-container');
    });
    // 显示隐藏列设置
    const popoverShowClick = () => {
      if (!state.columnsCheck?.length) {
        // 为空时，则全部勾选上
        props.tableData.columns.forEach(item => {
          state.columnsCheck.push(item.prop || item.type);
        });
      }
    };
    // 可根据条件设置表单初始查询值
    const setSearchFormValue = obj => {
      searchFormEl.value.setValue(obj);
    };
    // 列显示隐藏设置收起时，这里可将设置保存于服务端或本地
    const popoverHideClick = () => {};

    //子表的编辑
    const subEditHandle = row => {
      emits('subEdit', row);
    };

    //子表的删除
    const subDeleteHandle = row => {
      state.loading = true;
      const delUrl = props.tableData.config?.deleteUrl || props.deleteUrl;
      const delParams = Object.assign({
        formId: props.formId,
        id: row.id,
        subTable: true
        // 多个时转字符串
      });
      getRequest(delUrl, delParams).then(res => {
        state.loading = false;
        ElMessage.success(res.message || '删除成功');
        getListData(); // 请求列表数据
      }).catch(res => {
        state.loading = false;
        ElMessage.error(res.message || '删除失败');
      });
    };
    const setTableData = data => {
      state.tableDataList = data;
    };
    const exposeData = {
      getListData: getListData,
      ElMessageBox,
      ElMessage,
      request
    };
    onMounted(() => {
      if (props.autoLoad) {
        getListData(); // 这里不能自动加载数据，需要依赖于tableData各种判断，会出现先于tableData加载完成
      }
      if (isFixedBottomScroll.value) {
        scrollBox.value.addEventListener('scroll', fixedBottomScroll);
        window.addEventListener('resize', fixedBottomScroll);
      }
    });
    onBeforeUnmount(() => {
      if (isFixedBottomScroll.value) {
        scrollBox.value.removeEventListener('scroll', fixedBottomScroll);
        window.removeEventListener('resize', fixedBottomScroll);
      }
    });
    expose({
      getListData,
      delClick,
      table,
      setSearchFormValue,
      setTableData
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_checkbox = _resolveComponent("el-checkbox");
      const _component_el_checkbox_group = _resolveComponent("el-checkbox-group");
      const _component_el_popover = _resolveComponent("el-popover");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_popconfirm = _resolveComponent("el-popconfirm");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_tag = _resolveComponent("el-tag");
      const _component_el_switch = _resolveComponent("el-switch");
      const _component_arrow_down = _resolveComponent("arrow-down");
      const _component_el_icon = _resolveComponent("el-icon");
      const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
      const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
      const _component_el_dropdown = _resolveComponent("el-dropdown");
      const _component_el_pagination = _resolveComponent("el-pagination");
      const _directive_loading = _resolveDirective("loading");
      return _withDirectives((_openBlock(), _createElementBlock("div", {
        class: "table-list-comm",
        ref_key: "container",
        ref: container
      }, [_unref(treeData).show ? (_openBlock(), _createBlock(ListTreeSide, {
        key: 0,
        data: _unref(treeData),
        onNodeClick: treeNodeClick
      }, null, 8, ["data"])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_1, [__props.searchData?.list?.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_Transition, {
        name: "autoHeight"
      }, {
        default: _withCtx(() => [_withDirectives(_createVNode(DesignForm, {
          "is-search": "",
          disabled: state.loading,
          "add-form": false,
          "form-data": __props.searchData,
          dict: _unref(listDict),
          requestUrl: "",
          ref_key: "searchFormEl",
          ref: searchFormEl,
          onBtnClick: formBtnClick
        }, {
          default: _withCtx(() => [_renderSlot(_ctx.$slots, "searchForm")]),
          _: 3
        }, 8, ["disabled", "form-data", "dict"]), [[_vShow, !state.searchFormDown]])]),
        _: 3
      })])) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default"), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tableData.controlBtn, item => {
        return _openBlock(), _createBlock(_component_el_button, _mergeProps({
          key: item.key
        }, item, {
          onClick: $event => controlBtnClick(item)
        }), {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
          _: 2
        }, 1040, ["onClick"]);
      }), 128)), _renderSlot(_ctx.$slots, "controlBtn")]), _createElementVNode("div", _hoisted_5, [_unref(searchFormExpand) ? (_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        title: "展开/收起筛选",
        size: "small",
        circle: "",
        icon: "Search",
        onClick: _cache[0] || (_cache[0] = $event => state.searchFormDown = !state.searchFormDown)
      })) : _createCommentVNode("", true), _unref(columnsSetting) ? (_openBlock(), _createBlock(_component_el_popover, {
        key: 1,
        placement: "bottom-end",
        width: 80,
        trigger: "click",
        onShow: popoverShowClick,
        onHide: popoverHideClick
      }, {
        default: _withCtx(() => [_createVNode(_component_el_checkbox_group, {
          modelValue: state.columnsCheck,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => state.columnsCheck = $event)
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tableData.columns, item => {
            return _openBlock(), _createBlock(_component_el_checkbox, {
              label: item.prop || item.type,
              key: item.prop || item.type
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(item.label), 1)]),
              _: 2
            }, 1032, ["label"]);
          }), 128))]),
          _: 1
        }, 8, ["modelValue"])]),
        reference: _withCtx(() => [_createVNode(_component_el_button, {
          size: "small",
          circle: "",
          icon: "SetUp",
          title: "设置列显示隐藏"
        })]),
        _: 1
      })) : _createCommentVNode("", true)])]), _unref(columnsFilter)?.length ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["table-main", {
          'hide-vertical-scroll': _unref(isFixedBottomScroll)
        }])
      }, [_createVNode(_component_el_table, _mergeProps({
        data: state.tableDataList
      }, __props.tableData.tableProps, {
        "table-layout": "auto",
        style: {
          "width": "100%"
        },
        "max-height": "500",
        onSelectionChange: selectionChange,
        ref_key: "table",
        ref: table
      }), {
        default: _withCtx(() => [__props.childHead.length ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          type: "expand",
          align: "center"
        }, {
          default: _withCtx(scope => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_table, {
            data: scope.row.children
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.childHead, child => {
              return _openBlock(), _createBlock(_component_el_table_column, {
                key: child.prop,
                label: child.label,
                prop: child.prop
              }, null, 8, ["label", "prop"]);
            }), 128)), _createVNode(_component_el_table_column, {
              label: "操作",
              width: "120"
            }, {
              default: _withCtx(scopeChild => [_createVNode(_component_el_button, {
                link: "",
                type: "primary",
                size: "small",
                onClick: $event => subEditHandle(scopeChild.row)
              }, {
                default: _withCtx(() => [_createTextVNode("编辑")]),
                _: 2
              }, 1032, ["onClick"]), _createVNode(_component_el_popconfirm, {
                title: "确定删除该记录?",
                "confirm-button-text": "确认",
                "cancel-button-text": "取消",
                onConfirm: $event => subDeleteHandle(scopeChild.row)
              }, {
                reference: _withCtx(() => [_createVNode(_component_el_button, {
                  size: "small",
                  link: "",
                  type: "primary"
                }, {
                  default: _withCtx(() => [_createTextVNode("删除")]),
                  _: 1
                })]),
                _: 2
              }, 1032, ["onConfirm"])]),
              _: 1
            })]),
            _: 2
          }, 1032, ["data"])])]),
          _: 1
        })) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(columnsFilter), item => {
          return _openBlock(), _createBlock(_component_el_table_column, _mergeProps({
            key: item.prop || item.label
          }, item, {
            config: "",
            align: "center",
            "header-align": "center"
          }), _createSlots({
            _: 2
          }, [item.help ? {
            name: "header",
            fn: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.column.label) + " ", 1), _createVNode(Tooltip, {
              content: item.help
            }, null, 8, ["content"])]),
            key: "0"
          } : undefined, _ctx.$slots[item.prop] ? {
            name: "default",
            fn: _withCtx(scope => [_renderSlot(_ctx.$slots, item.prop, {
              row: scope.row,
              $index: scope.$index
            })]),
            key: "1"
          } : item.config?.tagList && Object.keys(item.config?.tagList).length ? {
            name: "default",
            fn: _withCtx(scope => [_createVNode(_component_el_tag, {
              type: item.config?.tagList[scope.row[item.prop]],
              effect: "light"
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(getDictLabel(scope, item)), 1)]),
              _: 2
            }, 1032, ["type"])]),
            key: "2"
          } : item.config?.dictKey ? {
            name: "default",
            fn: _withCtx(scope => [_createTextVNode(_toDisplayString(getDictLabel(scope, item)), 1)]),
            key: "3"
          } : item.type === 'switch' ? {
            name: "default",
            fn: _withCtx(scope => [_createVNode(_component_el_switch, {
              modelValue: scope.row[item.prop],
              "onUpdate:modelValue": $event => scope.row[item.prop] = $event,
              disabled: item?.disabled,
              "active-value": 1,
              "inactive-value": 0,
              onChange: $event => item.change(scope.row, exposeData)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onChange"])]),
            key: "4"
          } : item.config?.formatter ? {
            name: "default",
            fn: _withCtx(scope => [_createTextVNode(_toDisplayString(getDictLabel(scope, item, 'formatter')), 1)]),
            key: "5"
          } : item.prop === '__control' ? {
            name: "default",
            fn: _withCtx(scope => [_createElementVNode("div", {
              class: "table-operate-btn"
            }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(operateBtnList), (btn, index) => {
              return _openBlock(), _createElementBlock(_Fragment, {
                key: btn.key || index
              }, [btn.key === 'del' ? (_openBlock(), _createElementBlock(_Fragment, {
                key: 0
              }, [getOperateVisible(btn, scope.row) ? (_openBlock(), _createBlock(_component_el_popconfirm, {
                key: 0,
                title: btn.tip || '确定删除该记录?',
                "confirm-button-text": "确认",
                "cancel-button-text": "取消",
                onConfirm: $event => operateBtnClick(btn, scope.row)
              }, {
                reference: _withCtx(() => [_createVNode(_component_el_button, _mergeProps({
                  size: "small",
                  link: "",
                  type: "primary"
                }, btn), {
                  default: _withCtx(() => [_createTextVNode(_toDisplayString(btn.label), 1)]),
                  _: 2
                }, 1040)]),
                _: 2
              }, 1032, ["title", "onConfirm"])) : _createCommentVNode("", true)], 64)) : (_openBlock(), _createElementBlock(_Fragment, {
                key: 1
              }, [getOperateVisible(btn, scope.row) ? (_openBlock(), _createBlock(_component_el_button, _mergeProps({
                key: 0,
                link: "",
                size: "small",
                type: "primary"
              }, btn, {
                onClick: $event => operateBtnClick(btn, scope.row)
              }), {
                default: _withCtx(() => [_createTextVNode(_toDisplayString(btn.label), 1)]),
                _: 2
              }, 1040, ["onClick"])) : _createCommentVNode("", true)], 64))], 64);
            }), 128)), __props.tableData.config?.operateDropdown ? (_openBlock(), _createBlock(_component_el_dropdown, {
              key: 0
            }, {
              dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.tableData.operateBtn.slice(1), (m, index) => {
                  return _openBlock(), _createElementBlock(_Fragment, {
                    key: index
                  }, [getOperateVisible(m, scope.row) ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    style: {
                      "padding": "5px 16px"
                    },
                    onClick: $event => operateBtnClick(m, scope.row)
                  }, {
                    default: _withCtx(() => [_createTextVNode(_toDisplayString(m.label), 1)]),
                    _: 2
                  }, 1032, ["onClick"])) : _createCommentVNode("", true)], 64);
                }), 128))]),
                _: 2
              }, 1024)]),
              default: _withCtx(() => [_createVNode(_component_el_button, {
                size: "small",
                type: "primary",
                link: ""
              }, {
                default: _withCtx(() => [_createTextVNode(" 更多"), _createVNode(_component_el_icon, {
                  class: "el-icon--right"
                }, {
                  default: _withCtx(() => [_createVNode(_component_arrow_down)]),
                  _: 1
                })]),
                _: 1
              })]),
              _: 2
            }, 1024)) : _createCommentVNode("", true)])]),
            key: "6"
          } : undefined]), 1040);
        }), 128))]),
        _: 3
      }, 16, ["data"])], 2)) : _createCommentVNode("", true), __props.showPage ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_el_pagination, {
        background: "",
        currentPage: state.currentPage,
        "onUpdate:currentPage": _cache[2] || (_cache[2] = $event => state.currentPage = $event),
        "page-sizes": [5, 10, 20, 30, 40, 50],
        "page-size": state.pageSize,
        "onUpdate:pageSize": _cache[3] || (_cache[3] = $event => state.pageSize = $event),
        layout: "total, sizes, prev, pager, next, jumper",
        total: state.total,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["currentPage", "page-size", "total"])])) : _createCommentVNode("", true), state.tableScrollMargin ? (_openBlock(), _createElementBlock("div", {
        key: 3,
        style: _normalizeStyle({
          height: state.tableScrollMargin
        })
      }, null, 4)) : _createCommentVNode("", true)])])), [[_directive_loading, state.loading]]);
    };
  }
};