import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3387c66c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  style: {
    "display": "flex",
    "justify-content": "space-between"
  }
};
const _hoisted_3 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_4 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { ref, reactive, unref, toRefs, watch, onMounted, nextTick } from "vue";
import { queryMetaLabel, addMetaLabel, updateMetaLabel, deleteMetaLabel, queryMetaLabelNames } from "@/api/metaLabel";
import { ElMessageBox, ElMessage } from "element-plus";
export default {
  __name: 'index',
  setup(__props) {
    const formContainer = ref(null);
    const checkDataName = (rule, value, callback) => {
      if (value.length >= 2 && value.length <= 30) {
        callback();
      } else {
        callback(new Error("长度限制：2-30"));
      }
    };
    const state = reactive({
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 总条数
      total: 0,
      list: [],
      dataTypeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      dialogVisible: false,
      saveLoading: false,
      form: {
        enName: "",
        chName: "",
        dataType: "",
        length: "",
        unit: "",
        formula: "",
        nullAble: "",
        defaultValue: "",
        description: ""
      },
      // 表单校验
      rules: {
        enName: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          pattern: /^[a-zA-Z0-9_]{2,30}$/,
          message: "仅支持英文、数字、下划线，长度限制：2-30",
          trigger: "blur"
        }],
        chName: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          validator: checkDataName,
          trigger: "blur"
        }],
        dataType: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }],
        formula: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }],
        length: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }, {
          pattern: /^[1-9]\d*$/,
          message: "支持正整数",
          trigger: "blur"
        }],
        nullAble: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }]
      },
      queryParams: {
        name: "",
        formula: "",
        pageSize: 10,
        pageNum: 1
      },
      tableHeader: [{
        prop: "enName",
        label: "英文名称"
      }, {
        prop: "chName",
        label: "中文名称"
      }, {
        prop: "dataType",
        label: "数据类型"
      }, {
        prop: "createDate",
        label: "创建时间"
      }, {
        prop: "operation",
        label: "操作",
        width: "150px",
        fixed: "right",
        operation: [{
          name: '编辑',
          clickFun: row => handleUpdate(row),
          hasPermi: ['dataAssetManagement:metaLabel:edit']
        }, {
          name: '删除',
          clickFun: row => handleDelete(row),
          hasPermi: ['dataAssetManagement:metaLabel:remove']
        }]
      }],
      formData: [{
        prop: "enName",
        label: "英文名称",
        type: "text",
        placeholder: "请输入英文名称",
        span: 12
      }, {
        prop: "chName",
        label: "中文名称",
        type: "text",
        placeholder: "请输入中文名称",
        span: 12
      }, {
        prop: 'dataType',
        label: '数据类型',
        type: 'list',
        options: [{
          label: 'bigint',
          value: 'bigint'
        }, {
          label: 'int',
          value: 'int'
        }, {
          label: 'varchar',
          value: 'varchar'
        }, {
          label: 'Boolean（布尔）',
          value: 'Boolean（布尔）'
        }],
        props: {
          label: 'label',
          value: 'value'
        }
      }, {
        prop: "length",
        label: "数据字段长度",
        type: "text",
        placeholder: "请输入数据字段长度",
        span: 12
      }, {
        prop: "unit",
        label: "单位",
        type: "text",
        placeholder: "请输入单位",
        span: 12
      }, {
        prop: "formula",
        label: "元数据标签公式",
        type: "text",
        placeholder: "请输入元数据标签公式",
        span: 12
      }, {
        prop: "nullAble",
        label: '是否可为空',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: true,
          value: '是',
          labelName: '是'
        }, {
          label: false,
          labelName: '否',
          value: '否'
        }],
        span: 12
      }, {
        prop: "defaultValue",
        label: "默认值",
        type: "text",
        placeholder: "请输入默认值",
        span: 12
      }, {
        prop: "description",
        label: "描述",
        type: "textarea",
        placeholder: "请输入描述",
        span: 12
      }],
      metaLabelNameLoading: false,
      metaLabelNameList: []
    });
    const clear = () => {
      if (state.queryParams.name == "" || state.queryParams.name == null) {
        state.metaLabelNameList = [];
        state.queryParams.name = "";
      }
    };
    const queryMetaLabelNamesHandle = key => {
      if (key.trim().length === 0) {
        return;
      }
      state.metaLabelNameLoading = true;
      const params = {
        pageNum: 1,
        pageSize: 999999,
        name: key
      };
      queryMetaLabelNames(params).then(response => {
        state.metaLabelNameLoading = false;
        if (response.code === 1000) {
          state.metaLabelNameList = [];
          if (response.data.length > 0) {
            for (const item of response.data) {
              const option = {
                id: response.data.indexOf(item),
                name: item
              };
              state.metaLabelNameList.push(option);
            }
            state.queryParams.name = '';
          }
        }
      });
    };
    const getList = () => {
      state.loading = true;
      queryMetaLabel(state.queryParams).then(response => {
        state.list = response.data;
        state.loading = false;
      });
    };
    /** 搜索按钮操作 */
    const handleQuery = () => {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 增按钮操作 */
    const handleAdd = () => {
      reset();
      state.dialogVisible = true;
      state.title = "创建数据标签";
    };
    /** 修改按钮操作 */
    const handleUpdate = row => {
      reset();
      state.form = {
        ...row
      };
      state.dialogVisible = true;
      state.title = "修改数据标签";
    };
    // 表单重置
    const reset = () => {
      state.form = {};
      const formRef = unref(formContainer);
      if (formRef) {
        formRef.commonForm.resetForm("form");
      }
    };
    /** 重置按钮操作 */
    const resetQuery = () => {
      state.queryParams.name = null;
      state.queryParams.formula = null;
      handleQuery();
    };
    // 多选框选中数据
    const handleSelectionChange = selection => {
      state.ids = selection.map(item => item.id);
      state.single = selection.length !== 1;
      state.multiple = !selection.length;
    };
    /** 提交按钮 */
    const submitForm = () => {
      const formRef = unref(formContainer);
      formRef.commonForm.validate(valid => {
        if (valid) {
          state.saveLoading = true;
          if (state.form.id != undefined) {
            updateMetaLabel(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("修改成功");
                state.dialogVisible = false;
                getList();
              } else {
                ElMessage.error(response.message);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          } else {
            addMetaLabel(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("新增成功");
                state.dialogVisible = false;
                //新增后重置
                resetQuery();
              } else {
                ElMessage.error(response.message);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          }
        }
      });
    };

    /** 删除按钮操作 */
    const handleDelete = row => {
      const labelIds = row.id != null ? [row.id] : state.ids;
      ElMessageBox.confirm("确定是否删除该数据标签?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return deleteMetaLabel({
          ids: labelIds
        });
      }).then(res => {
        if (res.code === 1000) {
          getList();
          ElMessage.success("删除成功");
        } else {
          ElMessage.warning(res.message);
        }
      }).catch(function () {});
    };
    return (_ctx, _cache) => {
      const _component_el_option = _resolveComponent("el-option");
      const _component_el_select = _resolveComponent("el-select");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_commonTable = _resolveComponent("commonTable");
      const _component_commonForm = _resolveComponent("commonForm");
      const _component_commonDialog = _resolveComponent("commonDialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_row, {
        gutter: 10,
        class: "mb8"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 8,
          model: _ctx.queryParams
        }, {
          default: _withCtx(() => [_createVNode(_component_el_select, {
            modelValue: state.queryParams.name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => state.queryParams.name = $event),
            style: {
              "width": "100%"
            },
            span: 4,
            filterable: "",
            remote: "",
            placeholder: "请输入数据标签的中/英文名称查询",
            "remote-method": queryMetaLabelNamesHandle,
            loading: _ctx.metaLabelNameLoading,
            size: "mini",
            onFocus: _cache[1] || (_cache[1] = $event => clear())
          }, {
            default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.metaLabelNameList, item => {
              return _openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.name
              }, null, 8, ["label", "value"]);
            }), 128))]),
            _: 1
          }, 8, ["modelValue", "loading"])]),
          _: 1
        }, 8, ["model"]), _createVNode(_component_el_col, {
          span: 8,
          model: _ctx.queryParams
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: state.queryParams.formula,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => state.queryParams.formula = $event),
            placeholder: "请输入数据标签公式查询",
            clearable: "",
            size: "mini",
            onKeyup: _withKeys(handleQuery, ["enter"])
          }, null, 8, ["modelValue", "onKeyup"])]),
          _: 1
        }, 8, ["model"]), _createVNode(_component_el_col, {
          span: 8
        }, {
          default: _withCtx(() => [_createVNode(_component_el_button, {
            type: "primary",
            icon: "Search",
            size: "mini",
            onClick: handleQuery
          }, {
            default: _withCtx(() => [_createTextVNode("搜索")]),
            _: 1
          }), _createVNode(_component_el_button, {
            icon: "Refresh",
            size: "mini",
            onClick: resetQuery,
            class: "commonDarkBtn"
          }, {
            default: _withCtx(() => [_createTextVNode("重置")]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]), _createElementVNode("div", _hoisted_4, [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
        class: "addBtn",
        icon: "Plus",
        size: "mini",
        onClick: handleAdd
      }, {
        default: _withCtx(() => [_createTextVNode("新增")]),
        _: 1
      })), [[_directive_hasPermi, ['dataAssetManagement:metaLabel:add']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        class: "deleteBtn",
        icon: "Delete",
        size: "mini",
        disabled: state.multiple,
        onClick: handleDelete
      }, {
        default: _withCtx(() => [_createTextVNode("删除")]),
        _: 1
      }, 8, ["disabled"])), [[_directive_hasPermi, ['dataAssetManagement:metaLabel:remove']]])])]), _withDirectives(_createVNode(_component_commonTable, {
        ref: "tableContainer",
        class: "flex-table",
        tableData: state.list,
        tableHeader: state.tableHeader,
        queryParams: state.queryParams,
        onGetList: getList,
        hasSelect: true,
        columnSetting: false,
        onSelectionChange: handleSelectionChange
      }, null, 8, ["tableData", "tableHeader", "queryParams"]), [[_directive_loading, state.loading]]), _createVNode(_component_commonDialog, {
        modelValue: state.dialogVisible,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => state.dialogVisible = $event),
        width: "520px",
        title: state.title,
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm,
          loading: state.saveLoading
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }, 8, ["loading"]), _createVNode(_component_el_button, {
          onClick: _cache[3] || (_cache[3] = $event => state.dialogVisible = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_commonForm, {
          ref_key: "formContainer",
          ref: formContainer,
          form: state.form,
          formRules: state.rules,
          formData: state.formData,
          isHandle: false
        }, null, 8, ["form", "formRules", "formData"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};