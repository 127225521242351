import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TreeSelect = _resolveComponent("TreeSelect");
  return _openBlock(), _createBlock(_component_TreeSelect, {
    title: "树状图",
    onHandleNodeClick: _ctx.handleNodeClick,
    data: _ctx.deviceTree,
    currentNodeKey: _ctx.currentNodeKey,
    "default-props": _ctx.defaultProps,
    defaultCheckNode: _ctx.defaultCheckNode,
    nodeKey: _ctx.nodeKey
  }, null, 8, ["onHandleNodeClick", "data", "currentNodeKey", "default-props", "defaultCheckNode", "nodeKey"]);
}