import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "flex-item"
};
import { onMounted, inject, computed, nextTick } from 'vue';
import FormItem from './formItem.vue';
import { constFormProps } from '../../utils';
export default {
  __name: 'flexBox',
  props: {
    data: Object,
    default: () => ({})
  },
  setup(__props) {
    const props = __props;
    const formProps = inject(constFormProps, {});
    const tableDataNew = computed(() => {
      return formProps.value.model[props.data.name];
    });
    const type = computed(() => {
      return formProps.value.type;
    });
    const getRow = () => {
      let temp = {};
      props.data.list.forEach(item => {
        temp[item.name] = item.control.modelValue;
      });
      return temp;
    };
    const addRow = () => {
      tableDataNew.value.push(getRow());
    };
    const deleteRow = index => {
      tableDataNew.value.splice(index, 1);
    };
    const init = () => {
      if (tableDataNew.value?.length === 0) {
        tableDataNew.value.push(getRow());
      }
    };
    onMounted(() => {
      nextTick(() => {
        init();
      });
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_form_item = _resolveComponent("el-form-item");
      return _openBlock(), _createElementBlock(_Fragment, null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(tableDataNew), (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          key: index,
          class: "flex-group"
        }, [_createElementVNode("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data.list, (list, i) => {
          return _openBlock(), _createBlock(FormItem, {
            key: i,
            tProp: `${__props.data.name}.${index}.${list.name}`,
            modelValue: item[list.name],
            "onUpdate:modelValue": $event => item[list.name] = $event,
            data: list
          }, null, 8, ["tProp", "modelValue", "onUpdate:modelValue", "data"]);
        }), 128))]), __props.data.config?.delBtnText && [1, 2].includes(_unref(type)) ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          class: "flex-delete-btn",
          onClick: $event => deleteRow(index),
          type: "primary",
          link: "",
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(__props.data.config.delBtnText), 1)]),
          _: 2
        }, 1032, ["onClick"])) : _createCommentVNode("", true)]);
      }), 128)), __props.data.config?.addBtnText && [1, 2].includes(_unref(type)) ? (_openBlock(), _createBlock(_component_el_form_item, {
        key: 0
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          class: "flex-add-btn",
          size: "small",
          onClick: addRow
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(__props.data.config.addBtnText), 1)]),
          _: 1
        })]),
        _: 1
      })) : _createCommentVNode("", true)], 64);
    };
  }
};