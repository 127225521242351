import sidebar from "@/layout/components/Sidebar/index.vue";
import ResizeMixin from "@/layout/mixin/ResizeHandler";
import { mapState } from "vuex";
import RowNavbar from "../RowNavbar.vue";
export default {
  name: "appLinkContainer",
  components: {
    sidebar,
    RowNavbar
  },
  props: {
    container: {
      type: Object,
      default: null
    },
    isNotQiankun: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      iframeSrc: "",
      isShowDirection: false
    };
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      sidebar: state => state.common.sidebar
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === "mobile"
      };
    },
    iframeStyle() {
      return {
        width: "100%",
        padding: "0px",
        height: "100%",
        overflow: "hidden",
        border: 0
      };
    },
    isAppContainer() {
      let route = this.$route;
      if (route.path === "/index") {
        return true;
      } else if (route.path.startsWith("/appLinkContainer")) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        if (route.meta && route.meta.link) {
          this.iframeSrc = route.meta.link;
        }
      },
      immediate: true
    }
  }
};