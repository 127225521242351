import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { getDicts, listJob, getJob, delJob, addJob, updateJob, exportJob, runJob, changeJobStatus } from "@/api/system/monitor/job";
import { useRouter } from 'vue-router';
import { deepClone, downloadFile, selectDictLabel } from "@/utils";
import { parseTime } from "@/utils/lzCommon";
export default defineComponent({
  name: "Job",
  components: {},
  props: ['path'],
  setup(props) {
    const formContainer = ref(ElForm);
    // 任务组名字典
    const jobGroupOptions = ref([]);
    const state = reactive({
      openView: false,
      title: "添加任务",
      saveLoading: false,
      dialogVisible: false,
      tableLoading: false,
      multipleSelection: [],
      viewForm: {},
      form: {
        jobId: undefined,
        jobName: "",
        jobGroup: "",
        invokeTarget: "",
        cronExpression: "",
        concurrent: "0",
        misfirePolicy: "1",
        status: "0"
      },
      formData: [{
        prop: "jobName",
        label: "任务名称",
        placeholder: "请输入任务名称",
        type: "text",
        isSelect: true
      }, {
        prop: "jobGroup",
        label: "任务分组",
        placeholder: "请输入任务分组",
        type: "list",
        options: jobGroupOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        isSelect: true
      }, {
        prop: "invokeTarget",
        label: "调用方法",
        placeholder: "请输入调用方法",
        type: "text",
        isSelect: true,
        span: 24
      }, {
        prop: "cronExpression",
        label: "cron表达式",
        placeholder: "请输入cron表达式",
        type: "text",
        isSelect: true,
        span: 12
      }, {
        prop: "concurrent",
        label: '是否并发',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "0",
          value: '0',
          labelName: '允许'
        }, {
          label: "1",
          labelName: '禁止',
          value: '1'
        }],
        span: 12
      }, {
        prop: "misfirePolicy",
        label: '执行策略',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "1",
          value: '1',
          labelName: '立即执行'
        }, {
          label: "2",
          value: '2',
          labelName: '执行一次'
        }, {
          label: "3",
          value: '3',
          labelName: '放弃执行'
        }],
        span: 12
      }, {
        prop: "status",
        label: '岗位状态',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "0",
          value: '0',
          labelName: '正常'
        }, {
          label: "1",
          labelName: '停用',
          value: '1'
        }],
        span: 12
      }],
      formRules: {
        jobName: [{
          required: true,
          message: "任务名称不能为空",
          trigger: "blur"
        }],
        invokeTarget: [{
          required: true,
          message: "调用目标字符串不能为空",
          trigger: "blur"
        }],
        cronExpression: [{
          required: true,
          message: "cron执行表达式不能为空",
          trigger: "blur"
        }]
      },
      tableHeader: [{
        prop: "jobId",
        label: "任务编号",
        fixed: false,
        type: "text"
      }, {
        prop: "jobName",
        label: "任务名称",
        fixed: false,
        type: "text"
      }, {
        prop: "jobGroup",
        label: "任务组名",
        statusList: [{
          label: '默认',
          value: "DEFAULT"
        }, {
          label: '系统',
          value: "SYSTEM"
        }],
        fixed: false,
        type: "text"
      }, {
        prop: "invokeTarget",
        label: "调用目标字符串",
        fixed: false,
        type: "text"
      }, {
        prop: "cronExpression",
        label: "cron执行表达式",
        fixed: false,
        type: "text"
      }, {
        prop: "status",
        label: "状态",
        statusList: [{
          label: '正常',
          value: "0"
        }, {
          label: '停用',
          value: "1"
        }]
      }, {
        prop: "operation",
        label: "操作",
        width: "400px",
        fixed: 'right',
        operation: [{
          name: "执行一次",
          clickFun: row => methods.handleRun(row),
          hasPermi: ['monitor:job:implement']
        }, {
          name: "详情",
          clickFun: row => methods.handleView(row),
          hasPermi: ['monitor:job:details']
        }]
      }],
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      queryParams: {
        pageSize: 10,
        pageNum: 1,
        jobName: "",
        jobGroup: "",
        status: ""
      },
      searchFormData: [{
        prop: "jobName",
        label: "任务名称",
        placeholder: "请输入任务名称",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "jobGroup",
        label: "任务组名",
        placeholder: "请选择任务组名",
        options: jobGroupOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        type: "list",
        isSelect: true,
        span: 5
      }, {
        prop: "status",
        type: "list",
        label: "任务状态",
        placeHolder: "请选择任务状态",
        options: [{
          label: "正常",
          value: "0"
        }, {
          label: "停用",
          value: "1"
        }],
        props: {
          label: "label",
          value: "value"
        },
        isShow: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['monitor:job:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => methods.handleAdd(item)
      }, {
        buttonName: "修改",
        hasPermi: ['monitor:job:edit'],
        className: 'editBtn',
        buttonIcon: "Edit",
        exp: item => methods.handleUpdate(item)
      }, {
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['monitor:job:remove'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => methods.handleDel(item)
      }, {
        buttonName: "导出",
        hasPermi: ['monitor:job:export'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => methods.handleExport(item)
      }, {
        buttonName: "日志",
        hasPermi: ['monitor:job:journal'],
        className: 'anotherBtn',
        buttonIcon: "Share",
        exp: item => methods.handleJobLog(item)
      }]
    });
    const router = useRouter();
    //表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const methods = reactive({
      /** 任务日志列表查询 */
      handleJobLog() {
        router.push({
          path: "./jobLog",
          query: {}
        });
      },
      //搜索
      handleQuery() {
        methods.getList();
      },
      close() {
        state.form = {
          jobName: "",
          jobGroup: "",
          invokeTarget: "",
          cronExpression: "",
          concurrent: "0",
          misfirePolicy: "1",
          status: "0"
        };
      },
      //重置
      resetQuery() {
        state.queryParams = {
          pageSize: 10,
          pageNum: 1,
          jobName: "",
          jobGroup: "",
          status: ""
        };
        methods.getList();
      },
      //新增
      handleAdd() {
        state.dialogVisible = true;
        state.title = "添加任务";
        state.form.cronExpression = "0 0/5 * * * ?";
        state.form.invokeTarget = "EITask.";
      },
      //修改
      handleUpdate(row) {
        if (!row.jobId && !state.multipleSelection.length || state.multipleSelection.length > 1) {
          ElMessage.closeAll();
          ElMessage.warning("请选择一条数据修改");
          return;
        }
        if (row.jobId) {
          state.multipleSelection = [];
        }
        let jobId = row.jobId || state.multipleSelection[0].jobId;
        getJob(jobId).then(response => {
          state.form = response.data;
          state.dialogVisible = true;
          state.title = "修改任务";
        });
      },
      //表单确定
      submitForm: function () {
        const formRef = unref(formContainer);
        formRef.commonForm.validate(valid => {
          if (valid) {
            state.saveLoading = true;
            if (state.title == "修改任务") {
              updateJob(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("修改成功");
                  state.saveLoading = false;
                  state.dialogVisible = false;
                  methods.getList();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            } else if (state.title == "添加任务") {
              // 新增
              addJob(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("新增成功");
                  state.dialogVisible = false;
                  state.saveLoading = false;
                  methods.resetQuery();
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            }
          }
        });
      },
      //删除
      handleDel(row) {
        if (!row.jobId && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        if (row.jobId) {
          state.multipleSelection = [];
        }
        state.ids = state.multipleSelection.map(item => item.jobId);
        let jobIds = row.jobId || state.ids;
        ElMessageBox.confirm("确定是否删除?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delJob(jobIds);
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("删除成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      },
      //列表查询
      getList() {
        state.tableLoading = true;
        listJob(state.queryParams).then(response => {
          state.tableData.rows = response.rows;
          state.tableData.total = response.total;
          state.tableLoading = false;
        });
      },
      //字典下拉查询
      getDicts() {
        getDicts("sys_job_group").then(response => {
          jobGroupOptions.value = response.data;
        });
      },
      //导出
      handleExport() {
        ElMessageBox.confirm('是否确认导出所有定时任务数据项?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return exportJob(state.queryParams);
        }).then(response => {
          downloadFile(response.message);
        }).catch(err => {
          console.log(err);
        });
      },
      /* 立即执行一次 */
      handleRun(row) {
        ElMessageBox.confirm('确认要立即执行一次"' + row.jobName + '"任务吗?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return runJob(row.jobId, row.jobGroup);
        }).then(() => {
          ElMessage.success("执行成功");
        }).catch(function () {});
      },
      // 任务组名字典翻译
      jobGroupFormat(row, column) {
        return selectDictLabel(jobGroupOptions.value, row.jobGroup);
      },
      /** 任务详细信息 */
      handleView(row) {
        state.viewForm = {};
        getJob(row.jobId).then(response => {
          state.viewForm = response.data;
          state.viewForm.nextValidTime = parseTime(state.viewForm.nextValidTime);
          state.openView = true;
        });
      }
    });
    methods.getDicts();
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      formContainer
    };
  }
});