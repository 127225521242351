<template><section><html><head></head><body><h1>如何快速开始</h1>
<p>快速开始正确步骤：</p>
<h2>1.创建数据源</h2>
<p>有了数据源，你才知道接下来的表单、列表等都需要有哪些数据库表字段，以及各字段的类型等相关信息；</p>
<p>所有可使用的数据源在菜单表单数据源中，其中添加业务数据源在原来数据中添加</p>
<h2>2.创建表单</h2>
<p>首先对表单配置常用参数，选择前面创建的数据源。如果没有数据源时可根据后端同学提供的接口URL，配置好<code v-pre="">接口数据事件</code>；</p>
<p>然后呢就可以愉快的拖拽表单组件来进行设计了；</p>
<p>最后将设计好的表单保存到服务端或是导出vue文件使用</p>
<h2>3.列表页设计</h2>
<p>完成表单设计后，通过表单提交的内容总得需要个列表展示出来吧；</p>
<p>同样的先配置好数据列表配置；</p>
<p>设置条件查询表单；</p>
<p>添加新增和删除导出等等入口；</p>
<p>最后将设计好的数据保存在服务端或导出vue文件使用</p>
<h2>4.内容管理</h2>
<p>从左侧导航展开内容管理，即可看到设计的列表页及表单提交上来的数据</p>
<h2>5.设计管理</h2>
<p>可从设计管理导航进入查看前面提交保存的表单、列表页等设计</p>
<h2>6.查看导出vue文件示例</h2>
<p>展开导航系统管理，子级用户管理、角色管理等等都是使用导出vue设计的方式实现</p>
</body></html></section></template>

