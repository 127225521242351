import { listDefinition, delDefinition, suspendOrActiveApply } from "@/api/activiti/definition";
import { getToken } from "@/utils/auth";
import { deepClone } from "@/utils";
import { ElMessage } from 'element-plus';
export default {
  name: "",
  data() {
    return {
      modelerUrl: "",
      modelVisible: false,
      // 遮罩层
      loading: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      tableData: {
        total: 0,
        rows: []
      },
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      src: "",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        id: null,
        rev: null,
        name: null,
        type: null
      },
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 设置上传的请求头部
        headers: {
          Authorization: "Bearer " + getToken()
        },
        // 上传的地址
        url: "/device-operate-monitor/processDefinition/uploadStreamAndDeployment"
      },
      searchFormData: [{
        prop: "key",
        label: "流程KEY",
        placeholder: "请输入流程KEY",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "name",
        label: "名称",
        placeholder: "请输入名称",
        type: "text",
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => this.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => this.resetQuery()
      }],
      buttonsList: [{
        buttonName: "查看",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.operateProcess(1)
      }, {
        buttonName: "挂起/激活",
        buttonStyle: "success",
        className: 'editBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.operateProcess(2)
      }, {
        buttonName: "删除",
        buttonStyle: "success",
        className: 'deleteBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.operateProcess(3)
      }, {
        buttonName: "在线绘制流程",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.OnlineDrawingProcess()
      }, {
        buttonName: "部署流程",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-download",
        exp: () => this.handleImport()
      }],
      showTableHeader: [],
      tableHeader: [{
        prop: "id",
        label: "流程ID",
        fixed: false,
        type: "text"
      }, {
        prop: "key",
        label: "流程KEY",
        fixed: false,
        type: "text"
      }, {
        prop: "name",
        label: "流程名称",
        fixed: false,
        type: "text"
      }, {
        prop: "version",
        label: "版本",
        fixed: false,
        type: "text"
      }, {
        prop: "deploymentTime",
        label: "部署时间",
        fixed: false,
        type: "text"
      }, {
        prop: "deploymentId",
        label: "部署ID",
        fixed: false,
        type: "text"
      }, {
        prop: "suspendState_",
        label: "状态",
        fixed: false,
        type: "text"
      }],
      multipleSelection: []
    };
  },
  created() {
    this.cloneQueryParams = JSON.parse(JSON.stringify(this.queryParams));
    this.showTableHeader = deepClone(this.tableHeader);
  },
  methods: {
    operateProcess(val) {
      if (this.multipleSelection?.length !== 1) {
        ElMessage.closeAll();
        ElMessage.warning("最多或最少选择一条数据进行操作!");
        return;
      }
      if (val === 1) {
        this.OnlineModificationProcess(this.multipleSelection[0]);
      } else if (val === 2) {
        this.suspendOrActiveApply(this.multipleSelection[0]);
      } else if (val === 3) {
        this.handleDelete(this.multipleSelection[0]);
      }
    },
    /** 查询Definition列表 */
    getList() {
      this.loading = true;
      listDefinition(this.queryParams).then(response => {
        this.tableData.rows = response.data.rows || [];
        for (let item of this.tableData.rows) {
          if (item.suspendState != 1) {
            item.suspendState_ = '挂起';
          } else {
            item.suspendState_ = '激活';
          }
        }
        this.tableData.total = response.data.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = JSON.parse(JSON.stringify(this.cloneQueryParams));
      this.handleQuery();
    },
    suspendOrActiveApply(row) {
      var suspendOrActive = row.suspendState == 2 ? '激活' : '挂起';
      this.$confirm('确认要' + suspendOrActive + '流程定义吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        var data = {
          "id": row.id,
          "suspendState": row.suspendState
        };
        return suspendOrActiveApply(data);
      }).then(() => {
        this.getList();
        this.msgSuccess("转换成功");
      }).catch(function () {});
    },
    handleImport() {
      this.upload.title = "上传模型图";
      this.upload.open = true;
    },
    OnlineDrawingProcess() {
      this.modelVisible = true;
      localStorage.setItem("VUE_APP_BASE_API", process.env.VUE_APP_BASE_API);
      this.modelerUrl = "/bpmnjs/index.html?type=addBpmn";
    },
    OnlineModificationProcess(data) {
      this.modelVisible = true;
      localStorage.setItem("VUE_APP_BASE_API", process.env.VUE_APP_BASE_API);
      this.modelerUrl = '/bpmnjs/index.html?type=lookBpmn&deploymentFileUUID=' + data.deploymentId + '&deploymentName=' + encodeURI(data.resourceName);
    },
    // 提交上传文件
    submitFileForm() {
      this.$refs.upload.submit();
    },
    // 文件上传中处理
    handleFileUploadProgress(event, file, fileList) {
      this.upload.isUploading = true;
    },
    // 文件上传成功处理
    handleFileSuccess(response, file, fileList) {
      this.upload.open = false;
      this.upload.isUploading = false;
      this.$refs.upload.clearFiles();
      // this.$alert(response.msg, "导入结果", {dangerouslyUseHTMLString: true});
      this.getList();
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除编号为"' + row.key + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delDefinition(row.deploymentId);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      }).catch(function () {});
    },
    modelCancel() {
      this.getList();
    }
  }
};