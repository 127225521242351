import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  key: 0,
  class: "no-date"
};
const _hoisted_2 = {
  key: 1,
  class: "list"
};
const _hoisted_3 = ["onClick"];
const _hoisted_4 = {
  style: {
    "width": "1000px",
    "overflow": "auto",
    "padding": "20px"
  }
};
const _hoisted_5 = ["src"];
import { reactive } from 'vue';
// import { FormData } from '../../types'

export default {
  __name: 'template',
  emits: ['click'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const state = reactive({
      visible: false,
      list: []
    });
    const open = () => {
      state.visible = true;
      init();
    };
    const init = () => {
      const template = require.context("./template", true, /\.js$/);
      console.log(template);
      state.list = [];
      template.keys().forEach(key => {
        const file = template(key);
        state.list.push({
          imgPath: file.imgPath,
          title: file.title,
          formData: file.formData
        });
      });
    };
    const selectClick = item => {
      emits('click', item.formData);
      state.visible = false;
    };
    expose({
      open
    });
    return (_ctx, _cache) => {
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_popover = _resolveComponent("el-popover");
      return state.visible ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["use-template", {
          active: state.visible
        }])
      }, [_createElementVNode("span", {
        class: "close icon-close",
        onClick: _cache[0] || (_cache[0] = $event => state.visible = false)
      }), state.list.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_1, "暂无可用模板")) : (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.list, (item, index) => {
        return _openBlock(), _createElementBlock("div", {
          class: "item",
          key: index,
          onClick: $event => selectClick(item)
        }, [_createTextVNode(_toDisplayString(item.title) + " ", 1), _createVNode(_component_el_popover, {
          "popper-style": "width:1030px",
          placement: "right-start"
        }, {
          reference: _withCtx(() => [_createVNode(_component_el_image, {
            src: item.imgPath
          }, null, 8, ["src"])]),
          default: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createElementVNode("img", {
            style: {
              "width": "100%"
            },
            src: item.imgPath,
            alt: ""
          }, null, 8, _hoisted_5)])]),
          _: 2
        }, 1024)], 8, _hoisted_3);
      }), 128))]))], 2)) : _createCommentVNode("", true);
    };
  }
};