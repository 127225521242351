import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-41a5788e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "sidebar-container flex-box flex_d-column"
};
const _hoisted_2 = {
  class: "collapse"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_icon = _resolveComponent("el-icon");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_SubItem = _resolveComponent("SubItem");
  const _component_el_menu = _resolveComponent("el-menu");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, [_createVNode(_component_el_tooltip, {
    content: _ctx.$t('message.menuCollapseAndExpand'),
    placement: "bottom",
    "show-after": 500,
    "hide-after": 0,
    transition: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_el_icon, {
      class: _normalizeClass(["iconfont icon-zhankai", {
        'icon-shouqi2': !_ctx.collapse
      }]),
      style: {
        "font-size": "22px",
        "margin-right": "10px"
      },
      onClick: _ctx.collapseHandle
    }, null, 8, ["class", "onClick"])]),
    _: 1
  }, 8, ["content"])]), _createVNode(_component_el_scrollbar, {
    class: "flex-item_f-1"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_menu, {
      "default-active": _ctx.active,
      "background-color": _ctx.theme.backgroundColor !== '#fff' ? _ctx.theme.backgroundColor : '',
      "text-color": _ctx.theme.textColor,
      "active-text-color": _ctx.theme.activeTextColor,
      "unique-opened": true,
      collapse: _ctx.collapse
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuData, item => {
        return _openBlock(), _createBlock(_component_SubItem, {
          key: item.path,
          path: item.path,
          data: item
        }, null, 8, ["path", "data"]);
      }), 128))]),
      _: 1
    }, 8, ["default-active", "background-color", "text-color", "active-text-color", "collapse"])]),
    _: 1
  })]);
}