import {request} from '@/utils/request'

// 登录方法
export function login(username, ppww, code, uuid) {
  const data = {
    username,
    ppww,
    code,
    uuid
  }
  return request({
    url: '/login',
    method: 'post',
    params: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return new Promise(function (reslove) {
    window.location.href =`${window.g.VUE_APP_AUTH_URL}/logout`
    reslove()
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

// 获取路由
export const getRouters = () => {
  return request({
    url: '/getRouters',
    method: 'get',
  })
}