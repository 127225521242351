import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_commonForm = _resolveComponent("commonForm");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_form = _resolveComponent("el-form");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_commonForm, {
    ref: "formContainer",
    form: $data.form,
    formData: $data.formData,
    isDetail: true,
    isHandle: false
  }, null, 8, ["form", "formData"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.processData, (historyData, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: index,
      class: "processInfo"
    }, [_createElementVNode("h2", null, _toDisplayString(historyData.taskNodeName), 1), _createElementVNode("h3", null, "审批人:" + _toDisplayString(historyData.createName), 1), _createElementVNode("h3", null, "审批时间:" + _toDisplayString(historyData.createdDate), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(historyData.formHistoryDataDTO, (fistoryFormData, indexH) => {
      return _openBlock(), _createBlock(_component_el_form, {
        key: indexH,
        "label-width": "80px",
        "label-position": "top"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form_item, {
          label: fistoryFormData.title
        }, {
          default: _withCtx(() => [_createVNode(_component_el_input, {
            modelValue: fistoryFormData.value,
            "onUpdate:modelValue": $event => fistoryFormData.value = $event,
            disabled: ""
          }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
          _: 2
        }, 1032, ["label"])]),
        _: 2
      }, 1024);
    }), 128))]);
  }), 128))]);
}