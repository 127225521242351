const state = {
    loading: false,
  }
  
  const mutations = {
    GET_LOADING(state) {
      return state.loading
    },
    OPEN_LOADING(state) {
      state.loading = true
    },
    CLOSE_LOADING(state) {
      state.loading = false
    },
  }
  
  const actions = {}
  
  const getters = {}
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions,
  }
  