function findFirstLevelNode(nodeData) {
  if (nodeData.children) {
    return findFirstLevelNode(nodeData.children[0]);
  } else {
    return nodeData;
  }
}
import TreeSelect from "../TreeSelect";
import { defineComponent, reactive, toRefs, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";
export default defineComponent({
  name: "deviceTree",
  components: {
    TreeSelect
  },
  props: {
    nodeKey: {
      type: String,
      default: () => {
        return 'name';
      }
    }
  },
  emits: ["handle-node-click"],
  setup(props, {
    emit
  }) {
    let defaultProps = ref({
      value: "path",
      label: "label",
      children: "children"
    });
    let deviceTree = ref([]);
    const route = useRoute();
    const store = useStore();
    const currentRouteName = route.name;
    // const allDeviceTree = computed(() => store.state.permission.deviceTree);
    const allDeviceTree = [{
      "name": "DeviceManage",
      "path": "/deviceManage",
      "redirect": "noRedirect",
      "component": "deviceManage",
      "alwaysShow": true,
      "meta": {
        "title": "设备管理",
        "icon": "iconfont icon-shebeijiankangguanli",
        "link": null,
        "menuType": "M",
        "oneLevel": true,
        "path": "deviceManage"
      },
      "children": [{
        "redirect": "noRedirect",
        "component": "Layout",
        "alwaysShow": true,
        "meta": {
          "title": "变电",
          "icon": "#",
          "link": null,
          "menuType": "M",
          "oneLevel": false,
          "path": ""
        },
        "children": [{
          "name": "Netsmaintrans",
          "path": "netsmaintrans",
          "component": "Layout",
          "meta": {
            "title": "主变压器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsmaintrans"
          }
        }, {
          "name": "Netsbreaker",
          "path": "netsbreaker",
          "component": "Layout",
          "meta": {
            "title": "断路器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsbreaker"
          }
        }, {
          "name": "Netsswitch",
          "path": "netsswitch",
          "component": "Layout",
          "meta": {
            "title": "隔离开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsswitch"
          }
        }, {
          "name": "Netsloadswitch",
          "path": "netsloadswitch",
          "component": "Layout",
          "meta": {
            "title": "负荷开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsloadswitch"
          }
        }, {
          "name": "Netsfuse",
          "path": "netsfuse",
          "component": "Layout",
          "meta": {
            "title": "熔断器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsfuse"
          }
        }, {
          "name": "Netsbus",
          "path": "netsbus",
          "component": "Layout",
          "meta": {
            "title": "母线",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsbus"
          }
        }, {
          "name": "Netsreactance",
          "path": "netsreactance",
          "component": "Layout",
          "meta": {
            "title": "电抗器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsreactance"
          }
        }, {
          "name": "Netsct",
          "path": "netsct",
          "component": "Layout",
          "meta": {
            "title": "电流互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsct"
          }
        }, {
          "name": "Netspt",
          "path": "netspt",
          "component": "Layout",
          "meta": {
            "title": "电压互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netspt"
          }
        }, {
          "name": "Netscombinedtransducers",
          "path": "netscombinedtransducers",
          "component": "Layout",
          "meta": {
            "title": "组合互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netscombinedtransducers"
          }
        }, {
          "name": "Netscapacitor",
          "path": "netscapacitor",
          "component": "Layout",
          "meta": {
            "title": "电力电容器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netscapacitor"
          }
        }, {
          "name": "Netscouplingcapacitor",
          "path": "netscouplingcapacitor",
          "component": "Layout",
          "meta": {
            "title": "耦合电容器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netscouplingcapacitor"
          }
        }, {
          "name": "Netsswitchcabinet",
          "path": "netsswitchcabinet",
          "component": "Layout",
          "meta": {
            "title": "开关柜",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsswitchcabinet"
          }
        }, {
          "name": "Netsfaultindicator",
          "path": "netsfaultindicator",
          "component": "Layout",
          "meta": {
            "title": "故障指示器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsfaultindicator"
          }
        }, {
          "name": "Netscable",
          "path": "netscable",
          "component": "Layout",
          "meta": {
            "title": "站内电缆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netscable"
          }
        }, {
          "name": "Netssvc",
          "path": "netssvc",
          "component": "Layout",
          "meta": {
            "title": "静态无功补偿器(SVC)",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netssvc"
          }
        }, {
          "name": "Netssvg",
          "path": "netssvg",
          "component": "Layout",
          "meta": {
            "title": "静止无功补偿发生器(SVG)",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netssvg"
          }
        }, {
          "name": "Netsbay",
          "path": "netsbay",
          "component": "Layout",
          "meta": {
            "title": "间隔单元",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netsbay"
          }
        }, {
          "name": "Netssubstation",
          "path": "netssubstation",
          "component": "Layout",
          "meta": {
            "title": "变电站",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netssubstation"
          }
        }, {
          "name": "Netgpowerplant",
          "path": "netgpowerplant",
          "component": "Layout",
          "meta": {
            "title": "电厂",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netgpowerplant"
          }
        }]
      }, {
        "redirect": "noRedirect",
        "component": "Layout",
        "alwaysShow": true,
        "meta": {
          "title": "输电",
          "icon": "#",
          "link": null,
          "menuType": "M",
          "oneLevel": false,
          "path": ""
        },
        "children": [{
          "name": "Nettbranch",
          "path": "nettbranch",
          "component": "Layout",
          "meta": {
            "title": "导线",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettbranch"
          }
        }, {
          "name": "Nettpoletowerport",
          "path": "nettpoletowerport",
          "component": "Layout",
          "meta": {
            "title": "运行杆塔",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettpoletowerport"
          }
        }, {
          "name": "Nettcablesegment",
          "path": "nettcablesegment",
          "component": "Layout",
          "meta": {
            "title": "电缆段",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettcablesegment"
          }
        }, {
          "name": "Nettcablebranchtank",
          "path": "nettcablebranchtank",
          "component": "Layout",
          "meta": {
            "title": "电缆分支箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettcablebranchtank"
          }
        }, {
          "name": "Nettcableinterconnecttank",
          "path": "nettcableinterconnecttank",
          "component": "Layout",
          "meta": {
            "title": "电缆交叉互联箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettcableinterconnecttank"
          }
        }, {
          "name": "Nettuserport",
          "path": "nettuserport",
          "component": "Layout",
          "meta": {
            "title": "高压用户接入点",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettuserport"
          }
        }, {
          "name": "Nettsegment",
          "path": "nettsegment",
          "component": "Layout",
          "meta": {
            "title": "档距段",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettsegment"
          }
        }, {
          "name": "Nettpoletower",
          "path": "nettpoletower",
          "component": "Layout",
          "meta": {
            "title": "物理杆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettpoletower"
          }
        }, {
          "name": "Nettline",
          "path": "nettline",
          "component": "Layout",
          "meta": {
            "title": "线路",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettline"
          }
        }, {
          "name": "Nettcable",
          "path": "nettcable",
          "component": "Layout",
          "meta": {
            "title": "电缆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "nettcable"
          }
        }]
      }, {
        "redirect": "noRedirect",
        "component": "Layout",
        "alwaysShow": true,
        "meta": {
          "title": "中压配电",
          "icon": "#",
          "link": null,
          "menuType": "M",
          "oneLevel": false,
          "path": ""
        },
        "children": [{
          "name": "Netdfbranch",
          "path": "netdfbranch",
          "component": "Layout",
          "meta": {
            "title": "导线",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfbranch"
          }
        }, {
          "name": "Netdfpoletowerport",
          "path": "netdfpoletowerport",
          "component": "Layout",
          "meta": {
            "title": "运行杆塔",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfpoletowerport"
          }
        }, {
          "name": "Netdftrans",
          "path": "netdftrans",
          "component": "Layout",
          "meta": {
            "title": "柱上变压器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdftrans"
          }
        }, {
          "name": "Netdfbreaker",
          "path": "netdfbreaker",
          "component": "Layout",
          "meta": {
            "title": "柱上断路器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfbreaker"
          }
        }, {
          "name": "Netdfloadswitch",
          "path": "netdfloadswitch",
          "component": "Layout",
          "meta": {
            "title": "柱上负荷开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfloadswitch"
          }
        }, {
          "name": "Netdfswitch",
          "path": "netdfswitch",
          "component": "Layout",
          "meta": {
            "title": "柱上隔离开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfswitch"
          }
        }, {
          "name": "Netdfrecloser",
          "path": "netdfrecloser",
          "component": "Layout",
          "meta": {
            "title": "柱上重合器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfrecloser"
          }
        }, {
          "name": "Netdffuse",
          "path": "netdffuse",
          "component": "Layout",
          "meta": {
            "title": "柱上跌落式熔断器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdffuse"
          }
        }, {
          "name": "Netdfcapacitor",
          "path": "netdfcapacitor",
          "component": "Layout",
          "meta": {
            "title": "柱上电容器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfcapacitor"
          }
        }, {
          "name": "Netdfpt",
          "path": "netdfpt",
          "component": "Layout",
          "meta": {
            "title": "柱上电压互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfpt"
          }
        }, {
          "name": "Netdfct",
          "path": "netdfct",
          "component": "Layout",
          "meta": {
            "title": "柱上电流互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfct"
          }
        }, {
          "name": "Netdfcombinedtransducers",
          "path": "netdfcombinedtransducers",
          "component": "Layout",
          "meta": {
            "title": "柱上组合互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfcombinedtransducers"
          }
        }, {
          "name": "Netdfsectioner",
          "path": "netdfsectioner",
          "component": "Layout",
          "meta": {
            "title": "柱上分段器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfsectioner"
          }
        }, {
          "name": "Netdfcablesegment",
          "path": "netdfcablesegment",
          "component": "Layout",
          "meta": {
            "title": "电缆段",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfcablesegment"
          }
        }, {
          "name": "Netdstrans",
          "path": "netdstrans",
          "component": "Layout",
          "meta": {
            "title": "配电变压器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdstrans"
          }
        }, {
          "name": "Netdsbreaker",
          "path": "netdsbreaker",
          "component": "Layout",
          "meta": {
            "title": "断路器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsbreaker"
          }
        }, {
          "name": "Netdsswitch",
          "path": "netdsswitch",
          "component": "Layout",
          "meta": {
            "title": "隔离开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsswitch"
          }
        }, {
          "name": "Netdsloadswitch",
          "path": "netdsloadswitch",
          "component": "Layout",
          "meta": {
            "title": "负荷开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsloadswitch"
          }
        }, {
          "name": "Netdsfuse",
          "path": "netdsfuse",
          "component": "Layout",
          "meta": {
            "title": "熔断器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsfuse"
          }
        }, {
          "name": "Netdsbus",
          "path": "netdsbus",
          "component": "Layout",
          "meta": {
            "title": "母线",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsbus"
          }
        }, {
          "name": "Netdsreactance",
          "path": "netdsreactance",
          "component": "Layout",
          "meta": {
            "title": "电抗器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsreactance"
          }
        }, {
          "name": "Netdsct",
          "path": "netdsct",
          "component": "Layout",
          "meta": {
            "title": "电流互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsct"
          }
        }, {
          "name": "Netdspt",
          "path": "netdspt",
          "component": "Layout",
          "meta": {
            "title": "电压互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdspt"
          }
        }, {
          "name": "Netdscombinedtransducers",
          "path": "netdscombinedtransducers",
          "component": "Layout",
          "meta": {
            "title": "组合互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdscombinedtransducers"
          }
        }, {
          "name": "Netdscapacitor",
          "path": "netdscapacitor",
          "component": "Layout",
          "meta": {
            "title": "电力电容器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdscapacitor"
          }
        }, {
          "name": "Netdsswitchcabinet",
          "path": "netdsswitchcabinet",
          "component": "Layout",
          "meta": {
            "title": "开关柜",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsswitchcabinet"
          }
        }, {
          "name": "Netdscable",
          "path": "netdscable",
          "component": "Layout",
          "meta": {
            "title": "站内电缆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdscable"
          }
        }, {
          "name": "Netdsswitchtank",
          "path": "netdsswitchtank",
          "component": "Layout",
          "meta": {
            "title": "站内环网柜",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsswitchtank"
          }
        }, {
          "name": "Netdsmeasuringtank",
          "path": "netdsmeasuringtank",
          "component": "Layout",
          "meta": {
            "title": "计量柜",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsmeasuringtank"
          }
        }, {
          "name": "Netdsfaultindicator",
          "path": "netdsfaultindicator",
          "component": "Layout",
          "meta": {
            "title": "站内故障指示器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsfaultindicator"
          }
        }, {
          "name": "Netdffeeder",
          "path": "netdffeeder",
          "component": "Layout",
          "meta": {
            "title": "馈线",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdffeeder"
          }
        }, {
          "name": "Netdfsegment",
          "path": "netdfsegment",
          "component": "Layout",
          "meta": {
            "title": "档距段",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfsegment"
          }
        }, {
          "name": "Netdsbay",
          "path": "netdsbay",
          "component": "Layout",
          "meta": {
            "title": "间隔",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdsbay"
          }
        }, {
          "name": "Netdfpoletower",
          "path": "netdfpoletower",
          "component": "Layout",
          "meta": {
            "title": "物理杆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfpoletower"
          }
        }, {
          "name": "Netdfline",
          "path": "netdfline",
          "component": "Layout",
          "meta": {
            "title": "线路",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfline"
          }
        }, {
          "name": "Netdfcable",
          "path": "netdfcable",
          "component": "Layout",
          "meta": {
            "title": "电缆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfcable"
          }
        }, {
          "name": "Netrelatebreakerfeeder",
          "path": "netrelatebreakerfeeder",
          "component": "Layout",
          "meta": {
            "title": "联络开关馈线关系",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netrelatebreakerfeeder"
          }
        }, {
          "name": "Netdfuserport",
          "path": "netdfuserport",
          "component": "Layout",
          "meta": {
            "title": "中压用户接入点",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfuserport"
          }
        }, {
          "name": "Netdfdsubstation",
          "path": "netdfdsubstation",
          "component": "Layout",
          "meta": {
            "title": "开关站",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfdsubstation"
          }
        }, {
          "name": "Netdfdhouse",
          "path": "netdfdhouse",
          "component": "Layout",
          "meta": {
            "title": "配电室",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfdhouse"
          }
        }, {
          "name": "Netdfswitchtank",
          "path": "netdfswitchtank",
          "component": "Layout",
          "meta": {
            "title": "环网柜",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfswitchtank"
          }
        }, {
          "name": "Netdftranstank",
          "path": "netdftranstank",
          "component": "Layout",
          "meta": {
            "title": "箱式变电站",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdftranstank"
          }
        }, {
          "name": "Netdfcablebranchtank",
          "path": "netdfcablebranchtank",
          "component": "Layout",
          "meta": {
            "title": "电缆分支箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfcablebranchtank"
          }
        }, {
          "name": "Netdfuserdhouse",
          "path": "netdfuserdhouse",
          "component": "Layout",
          "meta": {
            "title": "用户站",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netdfuserdhouse"
          }
        }]
      }, {
        "redirect": "noRedirect",
        "component": "Layout",
        "alwaysShow": true,
        "meta": {
          "title": "低压配电",
          "icon": "#",
          "link": null,
          "menuType": "M",
          "oneLevel": false,
          "path": ""
        },
        "children": [{
          "name": "Netlfpoletower",
          "path": "netlfpoletower",
          "component": "Layout",
          "meta": {
            "title": "低压杆塔",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfpoletower"
          }
        }, {
          "name": "Netlfbreaker",
          "path": "netlfbreaker",
          "component": "Layout",
          "meta": {
            "title": "低压柱上开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfbreaker"
          }
        }, {
          "name": "Netlfswitch",
          "path": "netlfswitch",
          "component": "Layout",
          "meta": {
            "title": "低压柱上隔离开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfswitch"
          }
        }, {
          "name": "Netlffuse",
          "path": "netlffuse",
          "component": "Layout",
          "meta": {
            "title": "低压柱上熔断器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlffuse"
          }
        }, {
          "name": "Netlfcapacitor",
          "path": "netlfcapacitor",
          "component": "Layout",
          "meta": {
            "title": "低压柱上电容器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfcapacitor"
          }
        }, {
          "name": "Netlfsvc",
          "path": "netlfsvc",
          "component": "Layout",
          "meta": {
            "title": "低压无功补偿装置",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfsvc"
          }
        }, {
          "name": "Netlfmeterbox",
          "path": "netlfmeterbox",
          "component": "Layout",
          "meta": {
            "title": "低压表箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfmeterbox"
          }
        }, {
          "name": "Netlfcable",
          "path": "netlfcable",
          "component": "Layout",
          "meta": {
            "title": "低压电缆",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfcable"
          }
        }, {
          "name": "Netlfcablesegment",
          "path": "netlfcablesegment",
          "component": "Layout",
          "meta": {
            "title": "低压电缆段",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfcablesegment"
          }
        }, {
          "name": "Netlfuserport",
          "path": "netlfuserport",
          "component": "Layout",
          "meta": {
            "title": "低压用户接入点",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfuserport"
          }
        }, {
          "name": "Netlsbreaker",
          "path": "netlsbreaker",
          "component": "Layout",
          "meta": {
            "title": "低压开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsbreaker"
          }
        }, {
          "name": "Netlsbus",
          "path": "netlsbus",
          "component": "Layout",
          "meta": {
            "title": "低压母线",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsbus"
          }
        }, {
          "name": "Netlsswitchcabinet",
          "path": "netlsswitchcabinet",
          "component": "Layout",
          "meta": {
            "title": "低压开关柜",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsswitchcabinet"
          }
        }, {
          "name": "Netlsct",
          "path": "netlsct",
          "component": "Layout",
          "meta": {
            "title": "低压电流互感器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsct"
          }
        }, {
          "name": "Netlsfuse",
          "path": "netlsfuse",
          "component": "Layout",
          "meta": {
            "title": "低压熔断器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsfuse"
          }
        }, {
          "name": "Netlscapacitor",
          "path": "netlscapacitor",
          "component": "Layout",
          "meta": {
            "title": "低压电容器",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlscapacitor"
          }
        }, {
          "name": "Netlsswitch",
          "path": "netlsswitch",
          "component": "Layout",
          "meta": {
            "title": "低压隔离开关",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsswitch"
          }
        }, {
          "name": "Netlsbay",
          "path": "netlsbay",
          "component": "Layout",
          "meta": {
            "title": "低压间隔",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlsbay"
          }
        }, {
          "name": "Netlflowvoltagearea",
          "path": "netlflowvoltagearea",
          "component": "Layout",
          "meta": {
            "title": "低压台区",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlflowvoltagearea"
          }
        }, {
          "name": "Netlfbranch",
          "path": "netlfbranch",
          "component": "Layout",
          "meta": {
            "title": "低压线路",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfbranch"
          }
        }, {
          "name": "Netlfsegment",
          "path": "netlfsegment",
          "component": "Layout",
          "meta": {
            "title": "低压档距段",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfsegment"
          }
        }, {
          "name": "Netlfbranchtank",
          "path": "netlfbranchtank",
          "component": "Layout",
          "meta": {
            "title": "低压配电箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfbranchtank"
          }
        }, {
          "name": "Netlfcablebranchtank",
          "path": "netlfcablebranchtank",
          "component": "Layout",
          "meta": {
            "title": "低压电缆分支箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfcablebranchtank"
          }
        }, {
          "name": "Netlfcableterminaltank",
          "path": "netlfcableterminaltank",
          "component": "Layout",
          "meta": {
            "title": "低压电缆终端箱",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netlfcableterminaltank"
          }
        }, {
          "name": "Netrelatelarealstation",
          "path": "netrelatelarealstation",
          "component": "Layout",
          "meta": {
            "title": "低压站房台区关系",
            "icon": "#",
            "link": null,
            "menuType": "C",
            "oneLevel": false,
            "path": "netrelatelarealstation"
          }
        }]
      }, {
        "redirect": "noRedirect",
        "component": "Layout",
        "alwaysShow": true,
        "meta": {
          "title": "分布式电源",
          "icon": "#",
          "link": null,
          "menuType": "M",
          "oneLevel": false,
          "path": ""
        },
        "children": [{
          "redirect": "noRedirect",
          "component": "Layout",
          "alwaysShow": true,
          "meta": {
            "title": "风电类",
            "icon": "#",
            "link": null,
            "menuType": "M",
            "oneLevel": false,
            "path": ""
          },
          "children": [{
            "name": "Netnewindstation",
            "path": "netnewindstation",
            "component": "Layout",
            "meta": {
              "title": "风电厂站",
              "icon": "#",
              "link": null,
              "menuType": "C",
              "oneLevel": false,
              "path": "netnewindstation"
            }
          }, {
            "redirect": "noRedirect",
            "component": "Layout",
            "alwaysShow": true,
            "meta": {
              "title": "风电设备",
              "icon": "#",
              "link": null,
              "menuType": "M",
              "oneLevel": false,
              "path": ""
            },
            "children": [{
              "name": "Netnewindconverter",
              "path": "netnewindconverter",
              "component": "Layout",
              "meta": {
                "title": "变流器",
                "icon": "#",
                "link": null,
                "menuType": "C",
                "oneLevel": false,
                "path": "netnewindconverter"
              }
            }]
          }]
        }, {
          "redirect": "noRedirect",
          "component": "Layout",
          "alwaysShow": true,
          "meta": {
            "title": "光伏类",
            "icon": "#",
            "link": null,
            "menuType": "M",
            "oneLevel": false,
            "path": ""
          },
          "children": [{
            "name": "Netnephotovoltaicstation",
            "path": "netnephotovoltaicstation",
            "component": "Layout",
            "meta": {
              "title": "光伏电站",
              "icon": "#",
              "link": null,
              "menuType": "C",
              "oneLevel": false,
              "path": "netnephotovoltaicstation"
            }
          }, {
            "redirect": "noRedirect",
            "component": "Layout",
            "alwaysShow": true,
            "meta": {
              "title": "光伏设备",
              "icon": "#",
              "link": null,
              "menuType": "M",
              "oneLevel": false,
              "path": ""
            },
            "children": [{
              "name": "Netnephotovoltaicinverter",
              "path": "netnephotovoltaicinverter",
              "component": "Layout",
              "meta": {
                "title": "逆变器",
                "icon": "#",
                "link": null,
                "menuType": "C",
                "oneLevel": false,
                "path": "netnephotovoltaicinverter"
              }
            }]
          }]
        }]
      }]
    }];
    const currentRoute = allDeviceTree.find(item => item.name == currentRouteName);
    const currentNodeKey = computed(() => "netsmaintrans");
    const handleTree = function (treeList = []) {
      treeList.forEach(item => {
        item.label = item.meta.title;
        handleTree(item.children);
      });
    };
    let res = currentRoute?.children || [];
    handleTree(res);
    deviceTree.value = res;
    let defaultCheckNode = computed(() => store.state.defaultCheckNode);
    const handleNodeClick = function (value) {
      store.commit("setDefaultCheckNode", [{
        path: value.path,
        label: value.label
      }]);
      emit("handle-node-click", value);
    };
    if (res.length) {
      let defaultNode = [];
      if (defaultCheckNode?.value?.length) {
        defaultNode = defaultCheckNode.value[0];
      } else {
        defaultNode = findFirstLevelNode(res[0]);
      }
      console.log("defaultNode0", defaultNode);
      handleNodeClick(defaultNode);
    }
    return {
      defaultCheckNode,
      deviceTree,
      defaultProps,
      handleNodeClick,
      currentNodeKey
    };
  }
});