import { computed, defineComponent, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import useDictionary from '@/mixins/dictionary';
export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();
    const list = computed(() => store.state.message.list);
    const total = computed(() => store.state.message.page.total);
    const {
      dictionaryMap,
      getDictionary
    } = useDictionary();
    const readHandle = row => {
      store.dispatch('message/read', [row.id]);
    };
    const readAllHandle = () => {
      store.dispatch('message/readAll');
    };
    const viewHandle = () => {
      router.push({
        name: 'personal',
        query: {
          panel: 'message'
        }
      });
    };
    onBeforeMount(() => {
      getDictionary('websocket');
      store.dispatch('message/getList');
    });
    return {
      list,
      total,
      dictionaryMap,
      readHandle,
      readAllHandle,
      viewHandle
    };
  }
});