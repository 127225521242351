import { initGlobalState } from 'qiankun'
import {reactive} from 'vue'
// 父应用的初始state
// Vue.observable是为了让initialState变成可响应：https://cn.vuejs.org/v2/api/#Vue-observable。
export const initialState = reactive({
  menu: null,
  user: {},
  auth: {},
  tags: [],
  app: 'main',
  baiduinit: window,
  permissions: [],
})

const actions = initGlobalState(initialState)

actions.onGlobalStateChange((newState, prev) => {
  console.log('父应用改变数据', newState, prev)
  for (const key in newState) {
    initialState[key] = newState[key]
  }
})

// 自定义一个get获取state的方法下发到子应用
actions.getGlobalState = (key) => {
  // 有key，表示取globalState下的某个子级对象
  // 无key，表示取全部
  return key ? initialState[key] : initialState
}

export default actions
