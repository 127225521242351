import {request} from '@/utils/request'

// 查询区域列表
export function listArea(data) {
  return request({
    url: '/area/table',
    method: 'post',
    data: data
  })
}

// 查询区域列表
export function getTree(id) {
  return request({
    url: '/area/tree/' + id,
    method: 'post',
    data: {}
  })
}
