import { listTask, formDataShow, formDataSave, queryTelesignallCommaId, updateStatus } from "@/api/activiti/task";
import leaveHistoryForm from "@/views/processEngine/leave/leaveHistoryForm";
import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { socketInstance } from '@/utils/socket';
import { getToken } from '@/utils/auth';
import { getLeave } from '@/api/workflow/leave';
import { deepClone } from '@/utils';
import bus from '@/utils/bus';
export default {
  name: "",
  components: {
    leaveHistoryForm
  },
  data() {
    return {
      isYKrunning: 1,
      intervalNum: 0,
      currentRow: {},
      setSerachInterval: null,
      confirmLoading: false,
      clientSocket: null,
      id: '',
      definitionKey: '',
      businessKey: '',
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      tableData: {
        total: 0,
        rows: []
      },
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      // 表单参数
      form: {
        formData: []
      },
      // 表单校验
      rules: {},
      buttonsList: [{
        buttonName: "审批",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.operateProcess()
      }],
      showTableHeader: [],
      tableHeader: [{
        prop: "instanceName",
        label: "流程名称",
        fixed: false,
        type: "text"
      }, {
        prop: "name",
        label: "任务节点名称",
        fixed: false,
        type: "text"
      }, {
        prop: "status_",
        label: "任务状态",
        fixed: false,
        type: "text"
      }, {
        prop: "assignee",
        label: "办理人",
        fixed: false,
        type: "text"
      }, {
        prop: "createdDate",
        label: "更新时间",
        fixed: false,
        type: "text"
      }],
      multipleSelection: []
    };
  },
  created() {
    this.cloneQueryParams = JSON.parse(JSON.stringify(this.queryParams));
    this.showTableHeader = deepClone(this.tableHeader);
  },
  methods: {
    operateProcess() {
      if (this.multipleSelection?.length !== 1) {
        ElMessage.closeAll();
        ElMessage.warning("最多或最少选择一条数据进行操作!");
        return;
      }
      this.examineAndApprove(this.multipleSelection[0]);
    },
    /** 查询请假列表 */
    getList() {
      this.loading = true;
      listTask(this.queryParams).then(response => {
        this.tableData.rows = response.data.rows || [];
        for (let item of this.tableData.rows) {
          if (item.name === '遥控执行') {
            item.status_ = '待处理';
          } else if (item.name === '遥控取消') {
            item.status_ = '待确认';
          } else {
            item.status_ = '待审核';
          }
        }
        this.tableData.total = response.data.total || 0;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      if (this.setSerachInterval) {
        ElMessage.closeAll();
        ElMessage.warning('遥控流程执行中，不可关闭!');
        return;
      } else {
        this.currentRow = {};
        this.open = false;
        this.reset();
      }
    },
    // 表单重置
    reset() {
      this.definitionKey = '', this.businessKey = '';
      if (this.setSerachInterval) {
        clearInterval(this.setSerachInterval);
        this.setSerachInterval = null;
        this.intervalNum = 0;
      }
      this.form = {
        formData: []
      };
      if (this.$refs["form"]) {
        this.$refs["form"].resetFields();
      }
    },
    /** 审批按钮操作 */
    async examineAndApprove(row) {
      this.reset();
      this.definitionKey = row.definitionKey;
      this.businessKey = row.businessKey;
      this.id = row.id;
      const temp = await getLeave(this.businessKey);
      if (!temp.data) {
        ElMessage.closeAll();
        ElMessage.success("业务数据错误，请联系管理员处理！");
        return;
      }
      this.currentRow = deepClone(temp.data);
      formDataShow(row.id).then(response => {
        // FormProperty_3qipis2--__!!radio--__!!审批意见--__!!i--__!!同意--__--不同意
        // FormProperty_0lffpcm--__!!textarea--__!!批注--__!!f--__!!null
        let datas = response.data;
        let formData = [];
        for (let i = 0; i < datas.length; i++) {
          let strings = datas[i].split('--__!!');
          let controlValue = null;
          let controlDefault = null;
          switch (strings[1]) {
            case 'radio':
              controlValue = 0;
              controlDefault = strings[4];
              break;
            // default:
          }
          formData.push({
            controlId: strings[0],
            controlType: strings[1],
            controlLable: strings[2],
            controlIsParam: strings[3],
            controlValue: controlValue,
            controlDefault: controlDefault
          });
        }
        this.form.formData = formData;
        this.radioChange(0);
        this.open = true;
        this.title = "审批";
      });
    },
    setCreateName(val) {
      if (this.multipleSelection[0].name !== '遥控执行' && this.multipleSelection[0].name !== '遥控取消') {
        this.form.formData[2].controlValue = val;
      }
    },
    radioChange(val) {
      this.isYKrunning = 1;
      if (this.form.formData.length) {
        if (this.form.formData[0].controlLable === "遥控是否执行") {
          // 不执行
          if (val === 1) {
            this.isYKrunning = 3;
            // 执行
          } else {
            this.isYKrunning = 2;
          }
        }
      }
    },
    /** 提交按钮 */
    submitForm(val) {
      this.confirmLoading = true;
      formDataSave(this.id, this.form.formData).then(response => {
        // 下发后，需要等待最长2min，结合socket来判断遥控是否成功，超过2min，去查询此点号的值，若一样则代表失败，若不一样则代表成功（北斗丢包场景处理）
        if (response.code !== 1000) {
          ElMessage.closeAll();
          ElMessage.warning(response.message);
        } else {
          //不同意直接请求列表
          if (this.form.formData[0].controlValue === 1) {
            this.confirmLoading = false;
            this.open = false;
            this.currentRow = {};
            this.getList();
            return;
          } else {
            if (val) {
              this.setSerachInterval = setInterval(() => {
                if (this.intervalNum >= 120) {
                  clearInterval(this.setSerachInterval);
                  this.setSerachInterval = null;
                  this.intervalNum = 0;
                  this.getValueByComma();
                } else {
                  this.intervalNum++;
                }
              }, 1000);
            } else {
              this.confirmLoading = false;
              this.open = false;
              this.currentRow = {};
              this.getList();
            }
          }
        }
      }).catch(() => {
        this.confirmLoading = false;
      });
    },
    initWebSocket() {
      return new Promise((resolve, reject) => {
        socketInstance({
          token: 'Bearer ' + getToken(),
          errorCallBack: () => {
            reject();
          },
          subscribeSocket: this.subscribeSocket
        }).then(client => {
          resolve(client);
        }).catch(err => {
          reject(err);
        });
      });
    },
    subscribeSocket(clientSocket) {
      // 遥信遥测推送
      clientSocket.subscribe('/rf/yxc', res => {
        res = JSON.parse(res.body);
        if (res.read_type === 'yx' && res.read_deviceId === this.currentRow.psrId && this.currentRow.telesignallDot === res.read_point) {
          if (this.setSerachInterval) {
            clearInterval(this.setSerachInterval);
            this.setSerachInterval = null;
            this.intervalNum = 0;
            this.getValueByComma();
          }
        }
      });
      clientSocket.subscribe('/rf/yk', res => {
        res = JSON.parse(res.body);
        if (res.read_deviceId === this.currentRow.psrId && this.currentRow.telesignallDot === res.read_point) {
          if (this.setSerachInterval) {
            clearInterval(this.setSerachInterval);
            this.setSerachInterval = null;
            this.intervalNum = 0;
            // 遥控10s后再查询就是为了规避遥控推送了，但是遥信还没推送上来的情况，这样查询就会导致结果错误，其实遥控成功了。
            setTimeout(() => {
              this.getValueByComma();
            }, 10000);
          }
        }
      });
    },
    getValueByComma() {
      queryTelesignallCommaId({
        psrId: this.currentRow.psrId,
        telesignallDot: this.currentRow.telesignallDot
      }).then(res => {
        if (res.code === 1000) {
          this.confirmLoading = false;
          if (res.message == this.currentRow.telesignallStatus) {
            ElMessage.closeAll();
            ElMessage.warning('遥控失败');
            this.updateProcessStatus('5');
          } else {
            ElMessage.closeAll();
            ElMessage.success('遥控成功');
            this.updateProcessStatus('6');
          }
        }
      }).catch(() => {
        this.confirmLoading = false;
      });
    },
    updateProcessStatus(status) {
      updateStatus({
        id: this.currentRow.id,
        status
      }).then(res => {
        this.currentRow = {};
        this.open = false;
        this.getList();
      });
    },
    closeDialog() {
      if (this.setSerachInterval) {
        ElMessage.closeAll();
        ElMessage.warning('遥控流程执行中，不可关闭!');
        return;
      } else {
        this.currentRow = {};
        this.open = false;
        this.reset();
      }
    }
  },
  async mounted() {
    bus.$on('refreshTaskList', () => {
      console.log(this, 'tttttttttttttttttttthis');
      this.getList();
    });
    this.clientSocket = await this.initWebSocket().catch(err => {
      console.log('连接失败！');
    });
  },
  destroyed() {
    if (this.setSerachInterval) {
      clearInterval(this.setSerachInterval);
      this.setSerachInterval = null;
      this.intervalNum = 0;
    }
    this.clientSocket && this.clientSocket.disconnect();
    this.clientSocket = null;
  }
};