import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import { useRouter } from 'vue-router';
import { ref, reactive, nextTick } from 'vue';
import { ElMessage } from 'element-plus';
import { getRequest } from '@/api/designForm';
export default {
  __name: 'source',
  setup(__props) {
    const router = useRouter();
    const tableListEl = ref();
    const formEl = ref();
    const dialog = reactive({
      visible: false,
      title: '',
      type: 1,
      id: ''
    });
    const tableData = ref({
      columns: [{
        label: '勾选',
        type: 'selection'
      }, {
        prop: 'id',
        label: 'Id'
      }, {
        prop: 'name',
        label: '名称',
        width: 150
      }, {
        prop: 'databaseName',
        label: '数据库名称',
        width: 150
      }, {
        prop: 'host',
        label: '主机名/ IP地址',
        width: 150
      }, {
        prop: 'port',
        label: '端口',
        width: 150
      }, {
        prop: 'userName',
        label: '用户名称',
        width: 150
      }, {
        prop: 'useConnectionPooling',
        label: '是否使用连接池',
        config: {
          tagList: {
            0: '否',
            1: '是'
          },
          dictKey: 'useConnectionPooling'
        }
      }, {
        prop: 'remark',
        label: '备注',
        width: 150
      }, {
        prop: 'updateDate',
        label: '修改时间',
        config: {
          formatter: '{y}-{m}-{d} {h}:{i}:{s}'
        },
        width: 170
      }, {
        label: '操作',
        prop: '__control',
        width: '160px'
      }],
      controlBtn: [
        // {
        //   label: '新增',
        //   type: 'primary',
        //   size: 'small',
        //   click: () => {
        //     dialog.visible = true
        //     dialog.title = '创建数据源'
        //     dialog.type = 1
        //     dialog.id = ''
        //   }
        // },
        // { label: '删除', key: 'del', size: 'small' }
      ],
      operateBtn: [{
        label: '编辑',
        click: row => {
          dialog.visible = true;
          dialog.title = '修改数据源';
          dialog.type = 2;
          dialog.id = row.id;
          nextTick(() => {
            formEl.value.getData({
              id: row.id
            });
          });
        }
      }, {
        label: '删除',
        key: 'del'
      }, {
        label: '详情',
        click: row => {
          router.push({
            path: '/devPlatform/design/sourceTable',
            query: {
              id: row.id
            }
          });
        }
      }
      // {
      //   label: '创建表单',
      //   click: (row) => {
      //     router.push({ path: '/devPlatform/design/form', query: { source: row.id } })
      //   }
      // }
      ],
      config: {
        expand: true
      }
    });
    // 筛选表单
    // const searchData = ref({
    //   list: [
    //     {
    //       type: 'input',
    //       control: {
    //         modelValue: '',
    //         placeholder: '请输入名称'
    //       },
    //       config: {},
    //       name: 'name',
    //       item: {
    //         label: '名称'
    //       }
    //     },
    //     {
    //       type: 'input',
    //       control: {
    //         modelValue: '',
    //         placeholder: '请输入数据库表名'
    //       },
    //       config: {},
    //       name: 'tableName',
    //       item: {
    //         label: '数据表名'
    //       }
    //     },
    //     {
    //       type: 'button',
    //       control: {
    //         label: '查询',
    //         type: 'primary',
    //         key: 'submit'
    //       },
    //       config: {},
    //       name: ''
    //     },
    //     {
    //       type: 'button',
    //       config: {},
    //       name: '',
    //       control: {
    //         label: '清空',
    //         key: 'reset'
    //       }
    //     }
    //   ],
    //   form: {
    //     size: 'small'
    //   }
    // })
    const formData = ref({
      list: [{
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入名称'
        },
        config: {},
        name: 'name',
        item: {
          label: '名称'
        },
        customRules: [{
          type: 'required',
          message: '请输入名称',
          trigger: 'blur'
        }]
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入数据表名'
        },
        config: {
          editDisabled: true
        },
        name: 'host',
        item: {
          label: '主机名/IP地址'
        },
        customRules: [{
          type: 'required',
          message: '请输入主机名/ IP地址',
          trigger: 'blur'
        }]
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入数据库名称'
        },
        config: {
          editDisabled: true
        },
        name: 'databaseName',
        item: {
          label: '数据库名称'
        },
        customRules: [{
          type: 'required',
          message: '请输入数据库名称',
          trigger: 'blur'
        }]
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: '请输入用户名'
        },
        config: {
          editDisabled: true
        },
        name: 'userName',
        item: {
          label: '用户名'
        },
        customRules: [{
          type: 'required',
          message: '请输入用户名',
          trigger: 'blur'
        }]
      }, {
        type: 'password',
        control: {
          modelValue: '',
          placeholder: '请输入密码'
        },
        config: {
          editDisabled: true
        },
        name: 'password',
        item: {
          label: '密码'
        },
        customRules: [{
          type: 'required',
          message: '请输入密码',
          trigger: 'blur'
        }]
      }, {
        type: "select",
        control: {
          modelValue: 1,
          appendToBody: true
        },
        options: [{
          label: "是",
          value: 1
        }, {
          label: "否",
          value: 0
        }],
        config: {
          optionsType: 0
        },
        name: "useConnectionPooling",
        item: {
          label: "是否使用连接池"
        }
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: ''
        },
        config: {
          editDisabled: true
        },
        customRules: [{
          type: 'required',
          message: '请输入端口',
          trigger: 'blur'
        }],
        name: 'port',
        item: {
          label: '端口'
        }
      }, {
        type: 'input',
        control: {
          modelValue: '',
          placeholder: ''
        },
        config: {
          editDisabled: true
        },
        name: 'remark',
        item: {
          label: '备注'
        }
      }, {
        type: "div",
        control: {},
        config: {
          span: 24,
          textAlign: "center"
        },
        list: [{
          type: "button",
          control: {
            label: "校验",
            key: "none"
          },
          config: {
            span: 0
          }
        }, {
          type: "button",
          control: {
            label: "保存",
            key: "submit",
            type: "primary"
          },
          config: {
            span: 0
          }
        }]
      }],
      form: {
        labelWidth: '120px',
        class: 'form-row-2',
        size: 'default',
        name: 'source'
      },
      config: {
        addUrl: 'sourceSave',
        editUrl: 'sourceEdit',
        requestUrl: 'sourceById'
      }
    });
    // 提交表单前校验
    const beforeSubmit = params => {
      if (dialog.type === 2) {
        // 添加编辑提交参数
        params.id = dialog.id;
      }
      return params;
    };
    // 提交完成事件
    const afterSubmit = type => {
      if (type === 'success') {
        dialog.visible = false;
        tableListEl.value.getListData();
      }
    };

    //校验数据库
    const checkDataBase = type => {
      if (type === 'none') {
        let obj = formEl.value.getValue();
        getRequest('/form-design/customDatasource/check', obj).then(res => {
          ElMessage.success('数据库校验成功');
        }).catch(err => {
          // ElMessage.error('数据库校验失败')
        });
      }
    };
    return (_ctx, _cache) => {
      const _component_ak_list = _resolveComponent("ak-list");
      const _component_ak_form = _resolveComponent("ak-form");
      const _component_el_dialog = _resolveComponent("el-dialog");
      return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_ak_list, {
        ref_key: "tableListEl",
        ref: tableListEl,
        requestUrl: "sourceList",
        deleteUrl: "sourceDelete",
        dict: {
          useConnectionPooling: {
            0: '否',
            1: '是'
          }
        },
        tableData: tableData.value
      }, null, 8, ["tableData"]), _createVNode(_component_el_dialog, {
        modelValue: dialog.visible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialog.visible = $event),
        title: dialog.title,
        width: "800px",
        "destroy-on-close": ""
      }, {
        default: _withCtx(() => [_createVNode(_component_ak_form, {
          ref_key: "formEl",
          ref: formEl,
          formData: formData.value,
          type: dialog.type,
          addUrl: "sourceSave",
          editUrl: "sourceEdit",
          requestUrl: "sourceById",
          beforeSubmit: beforeSubmit,
          afterSubmit: afterSubmit,
          onBtnClick: checkDataBase
        }, null, 8, ["formData", "type"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};