import {bigRequest as request} from '@/utils/request'

// 查询元数据标签
export function queryMetaLabel(query) {
  return request({
    url: '/queryMetaLabel',
    method: 'post',
    data: query
  })
}

// 添加元数据标签
export function addMetaLabel(query) {
  return request({
    url: '/metaLabel',
    method: 'post',
    data: query
  })
}

// 编辑元数据标签
export function updateMetaLabel(query) {
  return request({
    url: '/update/metaLabel',
    method: 'post',
    data: query
  })
}

// 删除多个元数据标签
export function deleteMetaLabel(data) {
  return request({
    url: '/delete/metaLabel',
    method: 'post',
    data: data
  })
}

// 删除单个元数据标签
export function metaLabel(id) {
  return request({
    url: '/delete/metaLabel/' + id,
    method: 'post',
  })
}

// 查询元数据字段名称联想
export function queryMetaLabelNames(query) {
  return request({
    url: '/queryMetaLabelNames',
    method: 'post',
    data: query
  })
}
