import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-68391e50"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "top-container"
};
const _hoisted_2 = {
  class: "search-left"
};
const _hoisted_3 = {
  key: 0,
  class: "button-container"
};
const _hoisted_4 = {
  class: "dialog-footer"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_commonForm = _resolveComponent("commonForm");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_el_table = _resolveComponent("el-table");
  const _component_commonDialog = _resolveComponent("commonDialog");
  const _directive_hasPermi = _resolveDirective("hasPermi");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_commonForm, {
    form: _ctx.queryParams,
    formData: _ctx.searchFormData,
    formHandle: _ctx.formHandle
  }, null, 8, ["form", "formData", "formHandle"])]), _ctx.buttonsList.length ? (_openBlock(), _createElementBlock("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.buttonsList, (item, index) => {
    return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
      key: index,
      class: _normalizeClass([item.className, item.buttonIcon]),
      icon: item.buttonIcon,
      loading: item.loading,
      type: item.buttonStyle,
      onClick: $event => item.exp(item)
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(item.buttonName), 1)]),
      _: 2
    }, 1032, ["class", "icon", "loading", "type", "onClick"])), [[_directive_hasPermi, item.hasPermi ? item.hasPermi : []]]);
  }), 128))])) : _createCommentVNode("", true)]), _withDirectives((_openBlock(), _createBlock(_component_el_table, {
    data: _ctx.deptList,
    "row-key": "deptId",
    "default-expand-all": "",
    "tree-props": {
      children: 'children',
      hasChildren: 'hasChildren'
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table_column, {
      prop: "deptName",
      label: "部门名称",
      "show-overflow-tooltip": ""
    }), _createVNode(_component_el_table_column, {
      prop: "orderNum",
      label: "排序",
      "show-overflow-tooltip": ""
    }), _createVNode(_component_el_table_column, {
      prop: "status",
      label: "状态",
      formatter: _ctx.statusFormat
    }, null, 8, ["formatter"]), _createVNode(_component_el_table_column, {
      label: "创建时间",
      align: "center",
      prop: "createTime"
    }), _createVNode(_component_el_table_column, {
      label: "操作",
      align: "center",
      "class-name": "small-padding fixed-width"
    }, {
      default: _withCtx(scope => [_withDirectives((_openBlock(), _createBlock(_component_el_button, {
        size: "medium",
        type: "text",
        onClick: $event => _ctx.handleUpdate(scope.row)
      }, {
        default: _withCtx(() => [_createTextVNode("修改")]),
        _: 2
      }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dept:edit']]]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        size: "medium",
        type: "text",
        onClick: $event => _ctx.handleAdd(scope.row)
      }, {
        default: _withCtx(() => [_createTextVNode("新增")]),
        _: 2
      }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dept:add']]]), scope.row.parentId != 0 ? _withDirectives((_openBlock(), _createBlock(_component_el_button, {
        key: 0,
        size: "mini",
        type: "text",
        onClick: $event => _ctx.handleDel(scope.row)
      }, {
        default: _withCtx(() => [_createTextVNode("删除")]),
        _: 2
      }, 1032, ["onClick"])), [[_directive_hasPermi, ['system:dept:remove']]]) : _createCommentVNode("", true), _createVNode(_component_el_button, {
        size: "medium",
        type: "text",
        onClick: $event => _ctx.handleVoucher(scope.row)
      }, {
        default: _withCtx(() => [_createTextVNode("client凭证")]),
        _: 2
      }, 1032, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])), [[_directive_loading, _ctx.tableLoading]]), _createVNode(_component_commonDialog, {
    modelValue: _ctx.dialogVisible,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.dialogVisible = $event),
    title: _ctx.title,
    onClose: _ctx.close
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.submitForm,
      loading: _ctx.saveLoading
    }, {
      default: _withCtx(() => [_createTextVNode("确 定")]),
      _: 1
    }, 8, ["onClick", "loading"]), _createVNode(_component_el_button, {
      onClick: _cache[0] || (_cache[0] = $event => _ctx.dialogVisible = false)
    }, {
      default: _withCtx(() => [_createTextVNode("取 消")]),
      _: 1
    })])]),
    default: _withCtx(() => [_createVNode(_component_commonForm, {
      ref: "formContainer",
      form: _ctx.form,
      formRules: _ctx.formRules,
      formData: _ctx.formData,
      isHandle: false
    }, null, 8, ["form", "formRules", "formData"])]),
    _: 1
  }, 8, ["modelValue", "title", "onClose"]), _createVNode(_component_commonDialog, {
    modelValue: _ctx.voucherDialogVisible,
    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => _ctx.voucherDialogVisible = $event),
    title: "Clinet凭证",
    onClose: _ctx.close
  }, {
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
      type: "primary",
      onClick: _ctx.buildVoucher,
      loading: _ctx.saveLoading
    }, {
      default: _withCtx(() => [_createTextVNode("自动生成")]),
      _: 1
    }, 8, ["onClick", "loading"]), _createVNode(_component_el_button, {
      onClick: _cache[2] || (_cache[2] = $event => _ctx.voucherDialogVisible = false)
    }, {
      default: _withCtx(() => [_createTextVNode("关 闭")]),
      _: 1
    })])]),
    default: _withCtx(() => [_createVNode(_component_commonForm, {
      ref: "voucherFormContainer",
      form: _ctx.voucherForm,
      formData: _ctx.voucherFormData,
      isHandle: false
    }, null, 8, ["form", "formData"])]),
    _: 1
  }, 8, ["modelValue", "onClose"])]);
}