import {lowcCodeRequest as request} from '@/utils/request';
// 添加
export function addCheckRule(query) {
  return request({
    url: "/sys/checkRule/add",
    method: "post",
    data: query
  });
}
//bug 15565 U-功能测试-系统中心-系统管理-在线开发，点击编辑，点击确定，提示code 500 mosen 2023/1/6
// 删除
export function delCheckRule(data) {
    return request({
      url: "/sys/checkRule/delete",
      method: "post",
      data
    });
}
// 批量删除
export function delCheckRuleBatch(data) {
    return request({
      url: "/sys/checkRule/deleteBatch",
      method: "post",
      data                                     
    });
}

// 编辑
export function editCheckRule(data) {
    return request({
      url: "/sys/checkRule/edit",
      method: "post",
      data
    });
}

// 查询列表
export function queryCheckRuleList(params) {
    return request({
      url: "/sys/checkRule/list",
      method: "get",
      params
    });
} 
