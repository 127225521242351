import {request} from '@/utils/request'

// 查询调度日志列表
export function listJobLog(query) {
  return request({
    url: '/monitor/jobLog/list',
    method: 'get',
    params: query
  })
}

// 删除调度日志
export function delJobLog(jobLogId) {
  return request({
    url: '/monitor/jobLog/delete/' + jobLogId,
    method: 'post'
  })
}

// 清空调度日志
export function cleanJobLog() {
  return request({
    url: '/monitor/jobLog/clean',
    method: 'post'
  })
}

// 导出调度日志
export function exportJobLog(query) {
  return request({
    url: '/monitor/jobLog/export',
    method: 'get',
    params: query
  })
}
  // 根据字典类型查询字典数据信息
  export function getDicts(dictType) {
    return request({
      url: '/system/dict/data/dictType/' + dictType,
      method: 'get'
    })
  }