import {bigRequest as request} from '@/utils/request'

// 查询数据质量规则
export function queryDataQualityStandard(query) {
  return request({
    url: '/queryDataQualityStandard',
    method: 'post',
    data: query
  })
}

// 查询数据质量关联的数据表
export function queryDataQualityRelationTable(query) {
  return request({
    url: '/queryDataQualityRelationTable',
    method: 'post',
    data: query
  })
}

// 查询数据质量统计
export function dataQualityStatistics(query) {
  return request({
    url: '/dataQualityStatistics',
    method: 'post',
    data: query
  })
}

// 添加数据质量规则
export function addDataQualityStandard(query) {
  return request({
    url: '/dataQualityStandard',
    method: 'post',
    data: query
  })
}

// 编辑数据质量规则
export function updateDataQualityStandard(query) {
  return request({
    url: '/update/dataQualityStandard',
    method: 'post',
    data: query
  })
}

// 删除多个数据质量规则
export function deleteDataQualityStandard(data) {
  return request({
    url: '/delete/dataQualityStandard',
    method: 'post',
    data: data
  })
}

// 删除单个数据质量规则
export function dataQualityStandard(id) {
  return request({
    url: '/delete/dataQualityStandard/'+id,
    method: 'post',
  })
}
//数据质量规则模糊查询
export function queryDataQualityStandardNames(query){
  return request({
    url:'queryDataQualityStandardNames',
    method:'post',
    data:query
  })
}
