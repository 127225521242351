import {
  Delete,
  Edit,
  Search,
  Share,
  Upload,
  Refresh,
  Plus,
  Download,
  Rank,
  Check,
} from "@element-plus/icons-vue";

const requireComponent = require.context("../components", true, /\.vue$/);
let componentObj = {};
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  componentObj[componentConfig.default.name] = componentConfig.default;
});

//Element-plus图标
componentObj.Delete = Delete;
componentObj.Edit = Edit;
componentObj.Search = Search;
componentObj.Share = Share;
componentObj.Upload = Upload;
componentObj.Refresh = Refresh;
componentObj.Plus = Plus;
componentObj.Download = Download;
componentObj.Rank = Rank;
componentObj.Check = Check
export default componentObj;
