import { unref as _unref, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import formGroup from "./formGroup";
import { ref, reactive, inject, computed, nextTick, watch, onMounted } from 'vue';
import { constFormProps } from '../../utils';
import { jsonParseStringify } from '@/utils';
import { useRoute } from 'vue-router';
import { getRequest } from '@/api/designForm';
export default {
  __name: 'relationDiv',
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    requestUrl: {
      type: String // 请求的api
    },
    name: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    const route = useRoute();
    const subHandle = inject('subHandle');
    const getData = () => {
      const newParams = {};
      if (props.requestUrl) {
        // 同时可使用props或是events里的事件，根据使用使用其中一种即可
        let newParams2;
        const beforeRequest = props.item.events?.beforeRequest;
        if (typeof beforeRequest === 'function') {
          newParams2 = beforeRequest(newParams, route);
        }
        if (typeof props.beforeRequest === 'function') {
          newParams2 = props.beforeRequest(newParams, route);
        }
        if (newParams2 === false) {
          // 停止数据请求
          return;
        }
        console.log(444444, props.item);
        let method = 'post';
        if (props.item?.config?.requestType === 'get') {
          method = 'get';
        }
        getRequest(props.requestUrl, newParams2, {}, method).then(res => {
          let data = {};
          const afterResponse = props.item.events?.afterResponse;
          //  console.log('res', res)
          if (afterResponse) {
            data = afterResponse(res);
          } else {
            data = res?.data;
          }
          subHandle('relationDiv', props.name, data);
        }).catch(() => {});
      }
    };
    getData();
    return (_ctx, _cache) => {
      return _openBlock(), _createBlock(_unref(formGroup), _normalizeProps(_guardReactiveProps(_ctx.$attrs)), null, 16);
    };
  }
};