export default {
  designSave: "/form-design/design/save", // 保存设计的表单
  designEdit: "/form-design/design/edit", // 保存设计的表单
  designList: "/form-design/design/list", // 获取所有已设计的表单
  designChange: "/form-design/design/change", // 改变设计表单部分设置内容
  designDelete: "/form-design/design/delete", // 根据id删除已设计
  designById: "/form-design/design/id", // 根据id获取已设计的表单
  sourceList: "/form-design/customDatasource/list", // 获取数据源列表
  sourceDelete: "/form-design/customDatasource/delete",
  sourceSave: "/form-design/customDatasource/save", // 创建数据源
  sourceEdit: "/form-design/customDatasource/edit", // 编辑数据源
  sourceById: "/form-design/customDatasource/id", // 根据id获取数据源
  getTableInfoByName: "/form-design/customDatasource/getTableInfoByName", //获取数据源表字段
  saveFormContent: "/form-design/content/save", // 往已设计好的表单里添加内容
  editFormContent: "/form-design/content/edit", // 往已设计好的表单里添加内容
  getContentList: "/form-design/content/list", // 获取表单内容列表
  delFormContent: "/form-design/content/delete", // 删除表单内容
  getFormContent: "/form-design/content/id", // 获取表单内容
  upload: "/form-design/upload/single", // 默认el-upload上传url，也可单独在设计时填写上传地址
};
