import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import request from "@/utils/onlineRequest";
export default {
  __name: 'areaCity',
  setup(__props) {
    const props = {
      lazy: true,
      value: "name",
      label: "name",
      checkStrictly: true,
      lazyLoad: (node, resolve) => {
        let id = null;
        if (!node.root) {
          id = node.data.id;
        } else {
          id = node.level;
        }
        request({
          url: "/sys-server/area/tree/" + id,
          method: "post",
          data: {}
        }).then(response => {
          let nodes = response.data;
          nodes.forEach(n => {
            n.leaf = !n.leaf;
          });
          resolve(nodes);
        }).catch(err => {
          console.log(err);
        });
      }
    };
    const changeHandle = () => {};
    return (_ctx, _cache) => {
      const _component_el_cascader = _resolveComponent("el-cascader");
      return _openBlock(), _createBlock(_component_el_cascader, {
        ref: "cascader",
        bind: _ctx.$attrs,
        onChange: changeHandle,
        props: props
      }, null, 8, ["bind"]);
    };
  }
};