import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1feecce9"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "top-container searchTop"
};
const _hoisted_3 = {
  class: "search-left"
};
const _hoisted_4 = {
  key: 0,
  class: "button-container"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { listConfig, getConfig, delConfig, addConfig, updateConfig, exportConfig } from "@/api/system/config";
import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { ElMessageBox, ElMessage } from 'element-plus';
import { getCurrentInstance } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const typeOptions = ref([]);
    const state = reactive({
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 参数表格数据
      configList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 类型数据字典
      dropList2: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        configName: undefined,
        configKey: undefined,
        configType: undefined
      },
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        configName: [{
          required: true,
          message: "参数名称不能为空",
          trigger: "blur"
        }],
        configKey: [{
          required: true,
          message: "参数键名不能为空",
          trigger: "blur"
        }],
        configValue: [{
          required: true,
          message: "参数键值不能为空",
          trigger: "blur"
        }]
      },
      searchFormData: [{
        prop: "configName",
        label: "参数名称",
        placeholder: "请输入参数名称查询",
        type: "text",
        isSelect: false,
        span: 4
      }, {
        prop: "configKey",
        label: "参数键名",
        placeholder: "请输入参数键名查询",
        type: "text",
        isSelect: false,
        span: 4
      }, {
        prop: "configType",
        label: "系统内置",
        placeholder: "请选择系统内置",
        type: "list",
        options: typeOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        isSelect: true,
        span: 4
      }, {
        prop: "dateRange",
        label: "日期",
        placeholder: "请选择日期",
        type: "daterange",
        change: val => {
          if (!val) {
            state.queryParams.beginTime = "";
            state.queryParams.endTime = "";
          } else {
            state.queryParams.beginTime = val[0];
            state.queryParams.endTime = val[1];
          }
        },
        isSelect: false,
        span: 4
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['system:config:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => handleAdd(item)
      }, {
        buttonName: "修改",
        buttonStyle: "primary",
        hasPermi: ['system:config:edit'],
        className: 'editBtn',
        buttonIcon: "Edit",
        exp: item => handleUpdate(item),
        disabledHandle(row) {
          return state.single;
        }
      }, {
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['system:config:remove'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => handleDelete(item),
        disabledHandle(row) {
          return state.multiple;
        }
      }, {
        buttonName: "导出",
        buttonStyle: "success",
        hasPermi: ['system:config:export'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => handleExport(item)
      }],
      tableHeader: [{
        prop: "configId",
        label: "参数主键"
      }, {
        prop: "configName",
        label: "参数名称"
      }, {
        prop: "configKey",
        label: "参数键名"
      }, {
        prop: "configValue",
        label: "参数键值"
      }, {
        prop: "configType",
        label: "系统内置",
        statusList: [{
          value: 'Y',
          label: "是"
        }, {
          value: 'N',
          label: "否"
        }]
      }, {
        prop: "remark",
        label: "备注"
      }, {
        prop: "createTime",
        label: "创建时间"
      }, {
        prop: "operation",
        label: "操作",
        width: "150px",
        fixed: "right",
        operation: [{
          name: '修改',
          clickFun: row => handleUpdate(row),
          hasPermi: ['system:config:edit']
        }, {
          name: '删除',
          clickFun: row => handleDelete(row),
          hasPermi: ['system:config:remove']
        }]
      }],
      formData: [{
        prop: "configName",
        label: "参数名称",
        placeholder: "请输入参数名称",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "configKey",
        label: "参数键名",
        placeholder: "请输入参数键名",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "configValue",
        label: "参数键值",
        placeholder: "请输入参数键值",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "configType",
        label: "系统内置",
        type: "radio",
        options: typeOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        span: 12,
        change: row => {}
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入内容",
        type: "textarea",
        isSelect: false,
        span: 24
      }],
      saveLoading: false
    });
    const form = ref(null);
    onMounted(() => {
      proxy.getDicts("sys_yes_no").then(response => {
        typeOptions.value = response.data;
      });
    });
    /** 查询参数列表 */
    const getList = function () {
      state.loading = true;
      if (state.queryParams.dateRange) {
        delete state.queryParams.dateRange;
      }
      console.log(state.queryParams);
      listConfig(state.queryParams).then(response => {
        state.configList = {
          rows: response.rows,
          total: response.total
        };
        state.loading = false;
      });
    };
    // 参数系统内置字典翻译
    const typeFormat = function (row, column) {
      return proxy.ruoyi.selectDictLabel(state.dropList2, row.configType);
    };
    // 取消按钮
    const cancel = function () {
      state.open = false;
      reset();
    };
    // 表单重置
    const reset = function () {
      state.form = {
        configId: undefined,
        configName: undefined,
        configKey: undefined,
        configValue: undefined,
        configType: "Y",
        remark: undefined
      };
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.queryParams = {
        pageNum: 1,
        pageSize: 10,
        configName: undefined,
        configKey: undefined,
        configType: undefined
      };
      handleQuery();
    };
    /** 新增按钮操作 */
    const handleAdd = function () {
      reset();
      state.open = true;
      state.title = "添加参数";
    };
    // 多选框选中数据
    const handleSelectionChange = function (selection) {
      state.ids = selection.map(item => item.configId);
      state.single = selection.length != 1;
      state.multiple = !selection.length;
    };
    /** 修改按钮操作 */
    const handleUpdate = function (row) {
      reset();
      const configId = row.configId || state.ids;
      getConfig(configId).then(response => {
        state.form = response.data;
        state.open = true;
        state.title = "修改参数";
      });
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(form);
      formRef.commonForm.validate(valid => {
        if (valid) {
          state.saveLoading = true;
          if (state.form.configId != undefined) {
            updateConfig(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("修改成功");
                state.open = false;
                getList();
              } else {
                ElMessage.error(response.msg);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          } else {
            addConfig(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("新增成功");
                state.open = false;
                //新增后重置
                resetQuery();
              } else {
                ElMessage.error(response.msg);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          }
        }
      });
    };
    /** 删除按钮操作 */
    const handleDelete = function (row) {
      const configIds = row.configId || state.ids;
      ElMessageBox.close();
      ElMessageBox.confirm('是否确认删除数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delConfig(configIds);
      }).then(res => {
        getList();
        ElMessage.success("删除成功");
      }).catch(function () {});
    };
    /** 导出按钮操作 */
    const handleExport = function () {
      const queryParams = state.queryParams;
      ElMessageBox.close();
      ElMessageBox.confirm('是否确认导出所有参数数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportConfig(queryParams);
      }).then(res => {
        proxy.ruoyi.download(res.message);
      }).catch(function () {});
    };
    return (_ctx, _cache) => {
      const _component_commonForm = _resolveComponent("commonForm");
      const _component_el_button = _resolveComponent("el-button");
      const _component_commonTable = _resolveComponent("commonTable");
      const _component_commonDialog = _resolveComponent("commonDialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_commonForm, {
        form: state.queryParams,
        formData: state.searchFormData,
        formHandle: state.formHandle
      }, null, 8, ["form", "formData", "formHandle"])]), state.buttonsList.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.buttonsList, (item, index) => {
        return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          key: index,
          class: _normalizeClass([item.className, item.buttonIcon]),
          icon: item.buttonIcon,
          loading: item.loading,
          type: item.buttonStyle,
          onClick: $event => item.exp(item),
          disabled: item.disabledHandle?.(item.prop, form.value)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.buttonName), 1)]),
          _: 2
        }, 1032, ["class", "icon", "loading", "type", "onClick", "disabled"])), [[_directive_hasPermi, item.hasPermi ? item.hasPermi : []]]);
      }), 128))])) : _createCommentVNode("", true)]), _withDirectives(_createVNode(_component_commonTable, {
        class: "flex-table",
        tableData: state.configList,
        tableHeader: state.tableHeader,
        queryParams: state.queryParams,
        multipleSelection: state.ids,
        "onUpdate:multipleSelection": _cache[0] || (_cache[0] = $event => state.ids = $event),
        onGetList: getList,
        hasSelect: true,
        columnSetting: false,
        onSelectionChange: handleSelectionChange
      }, null, 8, ["tableData", "tableHeader", "queryParams", "multipleSelection"]), [[_directive_loading, state.loading]]), _createVNode(_component_commonDialog, {
        modelValue: state.open,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => state.open = $event),
        width: "600px",
        title: state.title,
        "close-on-click-modal": false,
        onClose: cancel
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm,
          loading: _ctx.saveLoading
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }, 8, ["loading"]), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_commonForm, {
          ref_key: "form",
          ref: form,
          form: state.form,
          formRules: state.rules,
          formData: state.formData,
          isHandle: false
        }, null, 8, ["form", "formRules", "formData"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};