import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission"; // permission control
import "@/assets/styles/variables.scss"; // 全局样式
import "@/assets/sass/index.scss"; // 全局样式
import "@/assets/iconFonts/iconfont.css"; //引入iconfont字体图标

import "@/assets/designFromIconFonts/iconfont.css";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import Iconfont from "@/components/iconfont"; // 全局自定义组件
import Directive from "@/directive"; // 自定义指令
import { constantRoutes } from "@/router";
import registerCommonModule from "@/store/commonRegister";
import * as ruoyi from "@/utils/lzCommon.js";
import { getDicts } from "@/api/system/dict/data";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import en from "element-plus/es/locale/lang/en";
import lzxyComponents from "lzxy-iot-common/src/components/index.js";
//
// markdown样式
import "github-markdown-css";
// 代码高亮
import "highlight.js/styles/github.css";
//

//表单 表格全局组件
import AkForm from "@/views/design/form/components/form.vue";
//
import AkList from "@/views/design/dataList/components/list.vue";
//
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import i18n from './i18n/index';

registerCommonModule(store, {}, constantRoutes);
const app = createApp(App);
app.config.globalProperties.ruoyi = ruoyi;
app.config.globalProperties.getDicts = getDicts;

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}

Object.keys(lzxyComponents).forEach((key) => {
  app.component(key, lzxyComponents[key]);
});

app.component("Iconfont", Iconfont);
app.component("AkForm", AkForm);
app.component("AkList", AkList);
// app.use(AKDesign);

app
  .use(router)
  .use(store)
  .use(Directive)
  .use(ElementPlus, { locale: en })
  .use(i18n)
  .mount("#app");
