import { createVNode as _createVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import FormGroup from './formGroup.vue';
import { computed, ref, watch, onUnmounted, onMounted, nextTick, provide } from 'vue';
// import type { FormData, FormList } from '../../types'
import { getRequest } from '@/api/designForm';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessage } from 'element-plus';
import { constGetControlByName, constSetFormOptions, constFormBtnEvent, constControlChange, constFormProps } from '../../utils';
import formatResult from '@/utils/formatResult';
import { jsonParseStringify } from '@/utils';
export default {
  __name: 'form',
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          list: [],
          form: {},
          config: {}
        };
      }
    },
    type: {
      type: Number,
      default: 1
    },
    // 1新增；2修改；3查看（表单模式） ；4查看； 5设计
    disabled: Boolean,
    // 禁用表单提交
    requestUrl: String,
    // 编辑数据请求url
    beforeRequest: Function,
    // 请求编辑数据前参数处理方法，可对请求参数处理
    afterResponse: [Function, String],
    // 请求数据加载完成后数据处理方法，可对返回数据处理
    addUrl: String,
    // 表单数据新增提交保存url
    editUrl: String,
    // 表单数据修改保存提交url
    beforeSubmit: [Function, String],
    // 表单提交前数据处理，可对提交数据处理，新增和保存都会触发
    afterSubmit: Function,
    // 表单提交后，默认提示提交结果，可return false阻止提示
    value: Object,
    // 表单初始值，同setValue
    options: {
      type: Object,
      default: () => ({})
    },
    // 表单组件选项，同setOptions
    dict: Object,
    // 固定匹配的字典
    isSearch: {
      type: Boolean,
      default: false
    },
    // 列表里作为筛选使用
    addForm: {
      type: Boolean,
      //表单class ,查询区域不需要该class
      default: true
    }
  },
  emits: ['btnClick', 'change'],
  setup(__props, {
    expose,
    emit: emits
  }) {
    const props = __props;
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    let timer = 0;
    let eventName = '';
    let getValueEvent = '';
    // 注册window事件
    const setWindowEvent = bool => {
      if (props.formData.list.length > 0) {
        const formName = props.formData.form?.name;
        if (!formName) {
          // 导出.vue时，name可以没有
          return;
        }
        eventName = `get${formName}ControlByName`;
        getValueEvent = `get${formName}ValueByName`;
        if (formName && (!window[eventName] || !bool)) {
          // 根据name获取当前数据项
          // @ts-ignore
          window[eventName] = name => {
            return getNameForEach(props.formData.list, name);
          };
          // 根据name获取当前项的值
          // @ts-ignore
          window[getValueEvent] = name => {
            return model.value[name];
          };
        }
      }
    };
    watch(() => props.formData.config, () => {
      if (timer < 2) {
        setWindowEvent(); // 简单判断下，这里不是每次都更新
      }
      timer++;
      appendRemoveStyle(true); // 更新样式
    }, {
      deep: true
    });
    setWindowEvent();
    // 设置全局事件结束
    const resultDict = ref({});
    // 处理表单值开始
    const model = ref({});
    // 获取表单初始model值
    const getInitModel = () => {
      let obj = {};
      forEachGetFormModel(props.formData.list, obj);
      model.value = obj;
      console.log('init');
    };
    watch(() => props.formData.list, () => {
      // formData从接口获取时
      getInitModel();
    });
    // 从表单数据里提取表单所需的model
    const forEachGetFormModel = (list, obj) => {
      list.forEach(item => {
        if (['table', 'flex'].includes(item.type)) {
          obj[item.name] = jsonParseStringify(item.tableData);
        } else if (['grid', 'tabs'].includes(item.type)) {
          item.columns.forEach(col => {
            forEachGetFormModel(col.list, obj);
          });
        } else if (['card', 'div'].includes(item.type)) {
          forEachGetFormModel(item.list, obj);
        } else {
          const excludeType = ['title', 'divider', 'txt', 'button'];
          if (excludeType.indexOf(item.type) === -1) {
            obj[item.name] = jsonParseStringify(item.control.modelValue);
          }
        }
      });
    };

    // 表单组件值改变事件 tProp为子表格相关
    provide(constControlChange, ({
      key,
      value,
      data,
      tProp
    }) => {
      if (key) {
        if (!tProp) {
          // 表格和弹性布局不是这里更新，只触change
          model.value[key] = value;
        }
        // 当表格和弹性内的字段和外面字段冲突时，可通过tProps区分
        emits('change', {
          key,
          value,
          data,
          tProp
        });
      }
    });
    const dictForm = computed(() => {
      const storage = window.localStorage.getItem('akFormDict');
      let storageDict = {};
      if (storage) {
        storageDict = JSON.parse(storage);
      }
      // 全局的、当前表单配置的以及接口返回的
      return Object.assign({}, storageDict, props.dict, resultDict.value);
    });
    // 表单参数
    const formProps = computed(() => {
      return {
        model: model.value,
        type: props.type,
        hideField: props.formData.config?.hideField,
        showColon: props.formData.form.showColon,
        dict: dictForm.value
      };
    });
    provide(constFormProps, formProps);
    const subHandle = (type, key, value) => {
      if (type === 'table') {
        nextTick(() => {
          model.value[key] = value;
          // console.log(111, key, value)
          // console.log('model', model)
          // console.log('formProps', formProps)
        });
      }
      if (type === 'relationDiv') {
        for (let i in value) {
          model.value[i] = value[i];
        }
      }
    };
    provide('subHandle', subHandle);

    // 提供一个方法，用于根据name从formData.list里查找数据
    const getNameForEach = (data, name) => {
      let temp = {};
      for (const key in data) {
        const dataKey = data[key];
        if (dataKey.name === name) {
          return dataKey;
        }
        if (['grid', 'tabs'].includes(dataKey.type)) {
          dataKey.columns.forEach(co => {
            temp = getNameForEach(co.list, name);
          });
        }
        if (['card', 'div'].includes(dataKey.type)) {
          temp = getNameForEach(dataKey.list, name);
        }
      }
      return temp;
    };
    const getControlByName = name => {
      return getNameForEach(props.formData.list, name);
    };
    provide(constGetControlByName, getControlByName);
    // 表单检验方法
    const ruleForm = ref();
    const validate = callback => {
      ruleForm.value.validate((valid, fields) => {
        let fieldValue = fields;
        if (valid) {
          // 校验通过，返回当前表单的值
          fieldValue = getValue();
        }
        callback(valid, fieldValue);
      });
    };
    // 提供一个取值的方法
    const getValue = filter => {
      if (filter) {
        const obj = {};
        for (const key in model.value) {
          if (model.value.hasOwnProperty(key)) {
            const val = model.value[key];
            if (!/^\s*$/.test(val)) {
              obj[key] = val;
            }
          }
        }
        return obj;
      } else {
        return model.value;
      }
    };
    // 对表单设置初始值
    const setValue = obj => {
      // 直接更新model值即可

      model.value = Object.assign({}, model.value, jsonParseStringify(obj)); // 防止列表直接使用set方法对弹窗表单编辑，当重置表单时当前行数据被清空
      console.log('setValue', model.value);
    };
    // 对表单选择项快速设置
    const setFormOptions = ref({});
    provide(constSetFormOptions, setFormOptions);
    const setOptions = obj => {
      setFormOptions.value = obj;
    };
    // 追加移除style样式
    const appendRemoveStyle = type => {
      const {
        config = {}
      } = props.formData;
      const styleId = document.getElementById('formStyle');
      if (styleId && type) {
        // 存在时直接修改，不用多次插入
        styleId.innerText = config.style;
        return;
      }
      if (config.style && type) {
        const styleEl = document.createElement('style');
        styleEl.id = 'formStyle';
        styleEl.type = 'text/css';
        styleEl.appendChild(document.createTextNode(config.style));
        document.head.appendChild(styleEl);
      }
      if (!type) {
        // 移除
        styleId && styleId.parentNode.removeChild(styleId);
      }
    };

    // 按钮组件事件
    provide(constFormBtnEvent, obj => {
      emits('btnClick', obj.key);
      if ([3, 4, 5].includes(props.type)) {
        return ElMessage.error('当前模式不能提交表单');
      }
      switch (obj.key) {
        case 'submit':
          submit(); // 提交
          break;
        case 'reset':
          resetFields(); // 重置
          break;
        case 'cancel':
          // 取消返回，
          router.go(-1); //这个刷新后可能会失败
          break;
      }
    });

    // 获取表单数据，编辑时，外部调用
    const getData = (params = {}, row) => {
      const requestUrl = props.formData.config?.requestUrl || props.requestUrl;
      // if (props.type === 5 || !requestUrl || props.isSearch) {
      //   // console.error('执行了获取数据方法，但配置有误！') 

      // }
      if (!props.formData.config?.requestUrl) {
        let params = row;
        const beforeRequest = props.formData.events?.beforeRequest;
        if (typeof beforeRequest === 'function') {
          params = beforeRequest(row, route, store);
        }
        setValue(params);
        return;
      }
      loading.value = true;
      const newParams = params;
      // 同时可使用props或是events里的事件，根据使用使用其中一种即可
      let newParams2;
      const beforeRequest = props.formData.events?.beforeRequest;
      if (typeof beforeRequest === 'function') {
        newParams2 = beforeRequest(newParams, route, store);
      }
      if (typeof props.beforeRequest === 'function') {
        newParams2 = props.beforeRequest(newParams, route, store);
      }
      if (newParams2 === false) {
        // 停止数据请求
        return;
      }
      getRequest(requestUrl, newParams2 ?? newParams).then(res => {
        // console.log(res)
        loading.value = false;
        const result = res.data;
        if (result) {
          let formatRes = result;
          // 比较适用通用表单，保存在服务端
          const afterResponse = props.formData.events?.afterResponse;
          if (typeof afterResponse === 'string' && afterResponse) {
            formatRes = formatResult(result, afterResponse);
          } else if (typeof afterResponse === 'function') {
            formatRes = afterResponse(result) ?? result;
          }
          // 比较适用于导出vue文件
          if (typeof props.afterResponse === 'string' && props.afterResponse) {
            formatRes = formatResult(result, props.afterResponse);
          } else if (typeof props.afterResponse === 'function') {
            formatRes = props.afterResponse(result) ?? result;
          }
          if (formatRes === false) {
            return;
          }
          setValue(formatRes.result || formatRes);
          nextTick(() => {
            // 将dict保存，可用于从接口中设置表单组件options。
            if (formatRes.dict && Object.keys(formatRes.dict).length) {
              resultDict.value = formatRes.dict;
            }
          });
        }
      }).catch(res => {
        loading.value = false;
        return ElMessage.error(res.message);
      });
    };
    const submit = (params = {}) => {
      const addUrl = props.formData.config?.addUrl || props.addUrl;
      const editUrl = props.formData.config?.editUrl || props.editUrl;
      const apiUrl = props.type === 1 ? addUrl : editUrl;
      if (props.isSearch || !apiUrl || loading.value) {
        if (!props.isSearch && !apiUrl) {
          console.error(new Error('请在表单设计处配置接口事件url或选择数据源或设置props'));
        }
        // 列表里作为筛选时，不提交表单
        return;
      }
      validate((valid, fields) => {
        if (valid) {
          let formatParams = Object.assign({}, fields, params);
          let submitParams;
          const beforeSubmit = props.formData.events?.beforeSubmit;
          if (beforeSubmit && typeof beforeSubmit === 'string') {
            submitParams = formatResult(formatParams, beforeSubmit);
          } else if (typeof beforeSubmit === 'function') {
            submitParams = beforeSubmit(formatParams, route);
          }
          if (props.beforeSubmit && typeof props.beforeSubmit === 'string') {
            submitParams = formatResult(formatParams, props.beforeSubmit);
          } else if (typeof props.beforeSubmit === 'function') {
            submitParams = props.beforeSubmit(formatParams, route);
          }
          if (submitParams === false) {
            return;
          }
          loading.value = true;
          // 提交保存表单
          getRequest(apiUrl, submitParams ?? formatParams).then(res => {
            afterSubmit('success', res);
          }).catch(res => {
            afterSubmit('fail', res);
          });
        }
      });
    };
    // 不管成功失败，有事件时都需要执行回调
    const afterSubmit = (type, res) => {
      const afterSubmit = props.formData.events?.afterSubmit;
      let notReturn;
      if (typeof afterSubmit === 'function') {
        notReturn = afterSubmit(type, res);
      } else if (typeof props.afterSubmit === 'function') {
        notReturn = props.afterSubmit(type, res);
      }
      loading.value = false;
      // 不管结果，重置表单，防再次打开时保留上一次的值
      // resetFields()
      if (notReturn === false) {
        // 有返回false时则不提示
        return;
      }
      if (type === 'success') {
        ElMessage.success(res.message || '保存成功！');
      } else {
        ElMessage.error(res.message || '保存失败！');
      }
    };
    // 表单初始值
    watch(() => props.value, v => {
      v && setValue(v);
    }, {
      immediate: true
    });
    // 表单options
    watch(() => props.options, v => {
      v && setOptions(v);
    });
    // ------------------------数据处理结束------------------------
    // 重置表单方法
    const resetFields = () => {
      ruleForm.value.resetFields();
      // setValue(Object.assign(model.value, obj || {})) // 这才能清空组件显示的值
    };
    onMounted(() => {
      getInitModel();
      nextTick(() => {
        appendRemoveStyle(true);
      });
    });
    onUnmounted(() => {
      if (eventName) {
        // @ts-ignore
        window[eventName] = '';
      }
      appendRemoveStyle();
    });
    expose({
      setOptions,
      setValue,
      getValue,
      validate,
      resetFields,
      getData
    });
    return (_ctx, _cache) => {
      const _component_el_form = _resolveComponent("el-form");
      const _directive_loading = _resolveDirective("loading");
      return _withDirectives((_openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_form, _mergeProps(__props.formData.form, {
        ref_key: "ruleForm",
        ref: ruleForm,
        model: model.value,
        class: {
          'design-form': __props.type === 5,
          'detail-form': __props.type === 3 || __props.type === 4,
          'add-form': __props.addForm
        }
      }), {
        default: _withCtx(() => [_createVNode(FormGroup, {
          data: __props.formData.list
        }, null, 8, ["data"]), _renderSlot(_ctx.$slots, "default")]),
        _: 3
      }, 16, ["model", "class"])])), [[_directive_loading, loading.value]]);
    };
  }
};