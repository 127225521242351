import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  key: 0
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Iconfont = _resolveComponent("Iconfont");
  const _component_el_tooltip = _resolveComponent("el-tooltip");
  const _component_sub_item = _resolveComponent("sub-item", true);
  const _component_el_sub_menu = _resolveComponent("el-sub-menu");
  const _component_el_menu_item = _resolveComponent("el-menu-item");
  return _ctx.data.hidden === '0' && _ctx.data.meta.menuType !== 'D' ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.data.children && _ctx.data.children.length > 0 ? (_openBlock(), _createBlock(_component_el_sub_menu, {
    key: 0,
    index: _ctx.data.name
  }, {
    title: _withCtx(() => [_ctx.data.meta.icon ? (_openBlock(), _createBlock(_component_Iconfont, {
      key: 0,
      name: _ctx.data.icon || _ctx.data.meta.icon,
      class: "padding_r-5"
    }, null, 8, ["name"])) : _createCommentVNode("", true), _createVNode(_component_el_tooltip, {
      content: _ctx.data.meta.title,
      placement: "top"
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.data.meta.title), 1)]),
      _: 1
    }, 8, ["content"])]),
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.children, item => {
      return _openBlock(), _createBlock(_component_sub_item, {
        key: `${item.name}`,
        index: _ctx.linkActiveHandle(_ctx.data),
        data: item,
        path: `${_ctx.path}/${item.path}`
      }, null, 8, ["index", "data", "path"]);
    }), 128))]),
    _: 1
  }, 8, ["index"])) : (_openBlock(), _createBlock(_component_el_menu_item, {
    key: 1,
    index: _ctx.linkActiveHandle(_ctx.data),
    onClick: _ctx.clickHandle
  }, {
    title: _withCtx(() => [_createVNode(_component_el_tooltip, {
      content: _ctx.data.meta.title,
      placement: "top"
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.data.meta.title), 1)]),
      _: 1
    }, 8, ["content"])]),
    default: _withCtx(() => [_ctx.data.meta.icon ? (_openBlock(), _createBlock(_component_Iconfont, {
      key: 0,
      name: _ctx.data.meta.icon,
      class: "padding_r-5"
    }, null, 8, ["name"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["index", "onClick"]))])) : _createCommentVNode("", true);
}