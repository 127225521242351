const zh = {
    message: {
        themeGreen: '绿',
        themeBright: '绿',
        personalCenter: '个人中心',
        Logut: '退出登录',
        loading: '微应用加载中...',
        menuCollapseAndExpand: '折叠/展开菜单',
        logoutPrompt: '提示',
        confirmLogout: '确定注销并退出系统吗?',
        loginTimeout: '登录状态已过期，您可以继续留在该页面，或者重新登录?',
        reLogin: '重新登录?',
        cancel: '取消',
    }
  }
  export default zh