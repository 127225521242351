import { computed, defineComponent, watchEffect, watch, ref } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { getCurrentTab, setCurrentTab } from "@/utils/storage";
import SubItem from "./components/sub-item.vue";
import { titleCase } from '@/utils/index';
export default defineComponent({
  components: {
    SubItem
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const theme = computed(() => store.state.theme.theme.menu);
    const menus = computed(() => store.state.menu.menus);
    console.log("@@@@@@@@@@@@@@", menus.value);
    const active = computed(() => store.state.menu.active);
    const collapse = computed({
      get: () => store.state.menu.collapse,
      set: val => store.dispatch("menu/setCollapse", val)
    });
    let menuData = ref([]);

    /**
     * @description: 路由变化事件
     * @param {*}
     * @return {*}
     * @author: gumingchen
     */
    const routeHandle = argRoute => {
      console.log("argRoute", argRoute);

      // if (argRoute.meta.link && argRoute.meta?.activeMenu) {
      //   const name = titleCase(argRoute.meta.activeMenu)

      //   store.dispatch("menu/setActive", name);
      // } else {

      const name = argRoute.name;
      store.dispatch("menu/setActive", name);

      // }
    };
    watch(() => {
      return route.path;
    }, () => {
      // if (route.path.startsWith(getCurrentTab())) {
      routeHandle(route);
      // }
    }, {
      immediate: true
    });

    //处理路由路径拼接
    function pathHandler(it) {
      console.log("it----", it);
      it.children.forEach(o => {
        o.path = it.path + (o.path.startsWith("/") ? o.path : "/" + o.path);
        if (o.children) {
          pathHandler(o);
        }
      });
    }
    const filterRouterHandel = v => {
      let menu = JSON.parse(JSON.stringify(menus.value)).filter(item => {
        return item.path.startsWith(v);
      });
      // if (menu.length == 1 && menu[0].children) {
      //   menu[0].children.forEach((it) => {
      //     it.path =
      //       menu[0].path + (it.path.startsWith("/") ? it.path : "/" + it.path);
      //     // if (it.children) {
      //     //   pathHandler(it);
      //     // }
      //   });
      // }

      console.log(menu, "*****************888");
      // 一个为微服务菜单，放在一个目录下
      if (menu.length === 1 && menu[0].children) {
        if (menu[0].children.length === 1) {
          //系统中心
          pathHandler(menu[0]);
          menuData.value = menu[0].children[0].children;
        } else {
          menu[0].children.forEach(it => {
            it.path = menu[0].path + (it.path.startsWith("/") ? it.path : "/" + it.path);
            // if (it.children) {
            //   pathHandler(it);
            // }
          });
          menuData.value = menu[0].children;
        }
      } else {
        menuData.value = menu;
      }
      console.log("menu=--", menu);
      console.log(menuData.value, "*******9999");
      //menuData.value = menu;
    };
    const collapseHandle = () => {
      collapse.value = !collapse.value;
      store.dispatch("common/toggleSideBar");
    };
    watch(() => {
      return store.state.menu.currentOneLevel;
    }, v => {
      filterRouterHandel(v);
    }, {
      immediate: true
    });
    watch(() => {
      return store.state.menu.menus;
    }, () => {
      console.log("2", store.state.menu);
      filterRouterHandel(store.state.menu.currentOneLevel);
    }, {
      immediate: true
    });
    return {
      theme,
      active,
      collapse,
      menuData,
      collapseHandle
    };
  }
});