import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import actions from "@/micros/actions";
import ChildContainer from "./components/ChildContainer";
import { filterAsyncRouter } from "@/utils";
import { getToken, getCode, toLogin, accestokenByCode } from "@/utils/auth";
NProgress.configure({ showSpinner: false });
import { deepClone } from "@/utils";
let hasCode = window.location.href.includes("code");
const whiteList = [
  "/login",
  "/redirect",
  "/bind",
  "/register",
  "/3d/topo",
  "/404",
  "/401",
];
// 递归处理不同用户进入的默认路由
let curPath = "";
let getFirstPagePath = (route, path) => {
  curPath += route.path + "/";
  if (route.children) {
    getFirstPagePath(route.children[0]);
  }

  return curPath;
};

let childContainerHandle = (routes) => {
  const childPath = `/module`;
  routes.forEach((e) => {
    if (e.path.includes(childPath)) {
      e.props = {
        container: ChildContainer,
        isNotQiankun: false,
      };
    }
  });
  return routes;
};
let historyRoute = [];
router.beforeEach(async (to, from, next) => {
  NProgress.start();

  if (!getToken() && getCode()) {
    // 在没有token,并且第三方返回code时去换取token
    await accestokenByCode();
  }

  if (getToken()) {
    /* has token*/
    if (to.path === "/login") {
      next({ path: "/" });
      NProgress.done();
    } else {
      if (to.path === "/@/views/login") {
        next(`/login`);
      }

      if (store.state.common.roles.length === 0) {
        await store.dispatch("common/getServerTime");
        // 判断当前用户是否已拉取完user_info信息
        const res = await store.dispatch("common/GetInfo");
        // 拉取user_info
        const roles = res.roles;
        const accessRoutes = await store.dispatch("menu/getMenu", { roles });
        // 测试 默认静态页面
        // 根据roles权限生成可访问的路由表

        let microRoute = childContainerHandle(accessRoutes);
        let microRouteCopy = deepClone(microRoute); //c存到公共模块
        filterAsyncRouter(microRoute);
        let flatRouteCopy = generateFlatRoutes(microRouteCopy);

        let flatRoute = generateFlatRoutes(microRoute);

        console.log("-----路由--------", flatRoute);
        //   router.addRoute(flatRoute) // 动态添加可访问路由表
        deleteComponent(flatRoute);
        flatRoute.forEach((item) => {
          router.addRoute(item);
        });

        store.commit("common/addFlatRoute", flatRouteCopy);
        actions.setGlobalState({ routeList: flatRouteCopy });
        next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
        // next()
      } else {
        historyRoute.unshift(from.path);
        historyRoute.length = 2;
        if (to.path.includes("undefined")) {
          next({ path: historyRoute[1], replace: true });
        } else {
          //去掉地址栏code
          if (hasCode) {
            hasCode = false;
            return next({ path: "/", replace: true });

            // window.location.href = path;
          } else {
            if (to.path == "/") {
              let menu = store.state.menu.menus.find((item) => {
                return item.path.startsWith("/module") && item.hidden === "0";
              });

              console.log(444444444, menu);
              next(getFirstPagePath(menu));
              curPath = "";
            } else {
              next();
            }
          }
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      toLogin();
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  NProgress.done();
});

//跟公共包里的同名方法不一样，不能删除
function generateFlatRoutes(accessRoutes) {
  let flatRoutes = [];

  for (let item of accessRoutes) {
    let breadcrumb = [];
    if (item.meta && item.meta.title) {
      breadcrumb = [item.meta.title];
      item.meta.breadcrumb = breadcrumb;
    }
    let childrenFflatRoutes = [];
    if (item.children && item.children.length > 0) {
      childrenFflatRoutes = castToFlatRoute(
        item.children,
        "",
        item.meta.breadcrumb
      );
    }

    // 一级路由是布局路由,需要处理的只是其子路由数据

    flatRoutes.push({
      name: item.name,
      path: item.path,
      component: item.component,
      redirect: item.redirect,
      children: childrenFflatRoutes,
      props: item.props ? item.props : {},
      meta: item.meta,
    });
  }
  console.log("flatRoutes--", flatRoutes);
  return flatRoutes;
}

/**
 * 将子路由转换为扁平化路由数组（仅一级）
 * @param {待转换的子路由数组} routes
 * @param {父级路由路径} parentPath
 */
function castToFlatRoute(routes, parentPath, breadcrumb, flatRoutes = []) {
  for (let item of routes) {
    let childBreadcrumb = [];
    if (item.meta && item.meta.title) {
      childBreadcrumb = [...breadcrumb, item.meta.title];
      item.meta.breadcrumb = childBreadcrumb;
    }
    //配置菜单带有 query 参数
    if (item.path.indexOf("?") > 0) {
      item.path = item.path.substring(0, item.path.indexOf("?"));
    }
    if (item.children && item.children.length > 0) {
      if (item.redirect && item.redirect !== "noRedirect") {
        flatRoutes.push({
          name: item.name,
          path: (parentPath + "/" + item.path).substring(1),
          redirect: item.redirect,
          meta: item.meta,
        });
      }
      castToFlatRoute(
        item.children,
        parentPath + "/" + item.path,
        item.meta.breadcrumb,
        flatRoutes
      );
    } else {
      flatRoutes.push({
        name: item.name,
        path: (parentPath + "/" + item.path).substring(1),
        component: item.component,
        meta: item.meta,
      });
    }
  }

  return flatRoutes;
}

//拍平后的路由只有俩级，只有一级children
const deleteComponent = (flatRoute) => {
  flatRoute.forEach((item) => {
    //是微服务
    if (item.path.startsWith("/module")) {
      if (item.children && item.children.length) {
        item.children.forEach((it) => {
          delete it.component;
        });
      }
    }
  });
};
