import { ElMessage, ElMessageBox, ElForm } from 'element-plus';
import { defineComponent, reactive, toRefs, ref, unref, nextTick, getCurrentInstance, watch } from "vue";
import { treeselect, listUser, changeUserStatus, getDicts, getUser, addUser, getPublicKey, delUser, unlock, resetUserPwd, exportUser, updateUser } from "@/api/system/user";
import { useRouter } from 'vue-router';
import { deepClone, downloadFile } from "@/utils";
import JSEncrypt from 'jsencrypt';
export default defineComponent({
  name: "User",
  props: ['path'],
  setup(props) {
    const tree = ref();
    const resetForm = ref(ElForm);
    const formContainer = ref(ElForm);
    const router = useRouter();
    const sexOptions = ref([]);
    const postOptions = ref([]);
    const roleOptions = ref([]);
    const deptOptions = ref([]);
    const isShowSelect = ref(false);
    const state = reactive({
      split: 0.12,
      resetRow: null,
      resetPwdForm: {
        resetNewPwd: ''
      },
      resetRules: {
        resetNewPwd: [{
          required: true,
          message: "重置密码不能为空",
          trigger: "blur"
        }, {
          pattern: /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{8,12}$/,
          message: "请输入8-12位包含字母、数字、特殊符号的密码",
          trigger: "blur"
        }]
      },
      resetPwdStrongLevel: '',
      pwdTip: false,
      saveLoading: false,
      psdStrongLevel: '',
      statusOptions: [],
      roleOptions: [],
      title: "添加用户",
      dialogVisible: false,
      multipleSelection: [],
      tableLoading: false,
      // 请求到的表格数据
      tableData: {
        rows: [],
        total: 0
      },
      // 表单校验
      formRules: {
        userName: [{
          required: true,
          message: "用户名称不能为空",
          trigger: "blur"
        }],
        nickName: [{
          required: true,
          message: "用户昵称不能为空",
          trigger: "blur"
        }],
        deptId: [{
          required: true,
          message: "归属部门不能为空",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "用户密码不能为空",
          trigger: "blur"
        }, {
          pattern: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]])[A-Za-z\d`~!@#$%^&*()_+<>?:"{},.\/\\;'[\]]{8,12}$/,
          message: "请输入8-12位包含字母、数字、特殊符号的密码",
          trigger: "blur"
        }],
        email: [{
          required: true,
          message: "邮箱地址不能为空",
          trigger: "blur"
        }, {
          type: "email",
          message: "'请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }],
        phonenumber: [{
          required: true,
          message: "手机号码不能为空",
          trigger: "blur"
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }]
      },
      form: {
        nickName: "",
        deptId: undefined,
        phonenumber: "",
        email: "",
        password: "",
        sex: "",
        postIds: [],
        roleIds: [],
        status: "0",
        remark: ""
      },
      formData: [{
        prop: "nickName",
        label: "用户昵称",
        placeholder: "请输入用户昵称",
        type: "text",
        isSelect: true
      }, {
        prop: "deptId",
        label: "归属部门",
        placeholder: "请输入归属部门",
        props: {
          label: "label",
          value: "id",
          children: "children"
        },
        options: deptOptions,
        type: "tree",
        isSelect: true
      }, {
        prop: "phonenumber",
        label: "手机号码",
        placeholder: "请输入手机号码",
        type: "text",
        isSelect: true
      }, {
        prop: "email",
        label: "邮箱",
        placeholder: "请输入邮箱",
        type: "text",
        isSelect: true
      }, {
        prop: "userName",
        label: "用户名称",
        placeholder: "请输入用户名称",
        type: "text",
        isSelect: true
      }, {
        prop: "password",
        label: "用户密码",
        placeholder: "请输入用户密码",
        type: "passwordStrengthCheck",
        width: "100%",
        isPassworld: true,
        hideHandle: () => {
          return isShowSelect.value;
        },
        //是否显示
        isSelect: true
      }, {
        prop: "sex",
        label: "用户性别",
        placeholder: "请输入用户性别",
        type: "list",
        options: sexOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        isSelect: true
      }, {
        prop: "postIds",
        label: "岗位",
        placeholder: "请输入岗位",
        options: postOptions,
        props: {
          label: "postName",
          value: "postId",
          disabled: "status"
        },
        multiple: true,
        collapseTags: false,
        type: "list",
        isSelect: true
      }, {
        prop: "roleIds",
        label: "角色",
        placeholder: "请输入角色",
        options: roleOptions,
        props: {
          label: "roleName",
          value: "roleId"
        },
        multiple: true,
        collapseTags: false,
        type: "list",
        isSelect: true
      }, {
        prop: "status",
        label: '状态',
        props: {
          label: 'labelName'
        },
        type: "radio",
        options: [{
          label: "0",
          value: '0',
          labelName: '正常'
        }, {
          label: "1",
          labelName: '停用',
          value: '1'
        }],
        span: 12
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入备注",
        type: "textarea",
        isSelect: true,
        span: 24
      }],
      queryParams: {
        userName: "",
        phonenumber: "",
        status: "",
        dateRange: [],
        beginTime: "",
        endTime: "",
        pageNum: 1,
        pageSize: 10
      },
      tableHeader: [{
        prop: "userId",
        label: "用户编号",
        fixed: false,
        type: "text"
      }, {
        prop: "userName",
        label: "用户名称",
        fixed: false,
        type: "text"
      }, {
        prop: "nickName",
        label: "用户昵称",
        fixed: false,
        type: "text"
      }, {
        prop: "deptName",
        label: "部门",
        fixed: false,
        type: "text"
      }, {
        prop: "phonenumber",
        label: "手机号码",
        fixed: false,
        type: "text"
      }, {
        prop: "status",
        label: "状态",
        fixed: false,
        switch: {
          changeFun: row => methods.statusChange(row)
        }
      }, {
        prop: "createTime",
        label: "创建时间",
        fixed: false,
        type: "text"
      }, {
        prop: "operation",
        label: "操作",
        width: "400px",
        fixed: 'right',
        operation: [{
          name: "修改",
          clickFun: row => methods.handleUpdate(row),
          hasPermi: ['system:user:edit']
        }, {
          name: "删除",
          clickFun: row => methods.handleDel(row),
          hasPermi: ['system:user:remove']
        }, {
          name: "重置密码",
          clickFun: row => methods.handleResetPassword(row),
          hasPermi: ['system:user:resetPwd']
        }, {
          name: "解锁",
          clickFun: row => methods.unlock(row),
          hasPermi: ['system:user:unlock']
        }]
      }],
      searchFormData: [{
        prop: "userName",
        label: "用户名称",
        placeholder: "请输入用户名称查询",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "phonenumber",
        label: "手机号码",
        placeholder: "请输入手机号码查询",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        type: "list",
        label: "用户状态",
        placeHolder: "请选择用户状态",
        prop: "status",
        options: [{
          label: "正常",
          value: "0"
        }, {
          label: "停用",
          value: "1"
        }],
        props: {
          label: "label",
          value: "value"
        },
        isShow: true,
        span: 5
      }, {
        prop: "dateRange",
        label: "时间",
        type: "daterange",
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => methods.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => methods.resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['system:user:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => methods.handleAdd(item)
      }, {
        buttonName: "修改",
        // buttonStyle: "primary",
        hasPermi: ['system:user:edit'],
        className: 'editBtn',
        buttonIcon: "Edit",
        exp: item => methods.handleUpdate(item)
      }, {
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['system:user:remove'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => methods.handleDel(item)
      }, {
        buttonName: "导出",
        // buttonStyle: "danger",
        hasPermi: ['system:user:export'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => methods.handleExport(item)
      }],
      defaultProps: {
        children: "children",
        label: "label"
      },
      deptName: null,
      publicKey: "",
      ids: []
    });
    // 表头初始值
    let key = router.currentRoute.value.name + ":colsettings";
    let colSettings = JSON.parse(localStorage.getItem(key));
    if (colSettings) {
      state.showTableHeader = state.tableHeader.filter(item => {
        if (item.prop == "operation") {
          return true;
        }
        if (colSettings.includes(item.prop)) {
          return true;
        }
        return false;
      });
    } else {
      state.showTableHeader = deepClone(state.tableHeader);
    }
    const methods = reactive({
      handleClose() {
        resetForm.value.resetFields();
      },
      resetQuery() {
        state.queryParams = {
          userName: "",
          phonenumber: "",
          status: "",
          dateRange: [],
          beginTime: "",
          endTime: "",
          pageNum: 1,
          pageSize: 10
        }, methods.getList();
      },
      close() {
        methods.reset();
      },
      reset() {
        state.form = {
          nickName: "",
          deptId: undefined,
          phonenumber: "",
          email: "",
          password: "",
          sex: "",
          postIds: "",
          roleIds: "",
          status: "0",
          remark: ""
        };
      },
      //导出
      handleExport() {
        let params = {
          "beginTime": state.queryParams.beginTime,
          "endTime": state.queryParams.endTime,
          "phonenumber": state.queryParams.phonenumber,
          "status": state.queryParams.status,
          "userName": state.queryParams.userName,
          "pageNum": state.queryParams.pageNum,
          "pageSize": state.queryParams.pageSize
        };
        ElMessageBox.confirm('是否确认导出所有用户数据项?', "导出", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          return exportUser(params);
        }).then(response => {
          downloadFile(response.message);
        }).catch(err => {
          console.log(err);
        });
      },
      /** 重置密码按钮操作 */
      handleResetPwd() {
        const formRef = unref(resetForm);
        formRef.validate(valid => {
          if (valid) {
            let encrypt = new JSEncrypt();
            encrypt.setPublicKey(state.publicKey); //此处为RSA公钥
            let passwordRSA = encrypt.encrypt(state.resetPwdForm.resetNewPwd);
            resetUserPwd(state.resetRow.userId, passwordRSA).then(response => {
              if (response.code === 1000) {
                ElMessage.success("修改成功");
                state.pwdTip = false;
              } else {
                ElMessage.error(response.msg);
              }
            });
          }
        });
      },
      //重置密码
      handleResetPassword(row) {
        state.pwdTip = true;
        state.resetRow = row;
      },
      //表单下拉
      getOptions() {
        getDicts("sys_user_sex").then(response => {
          sexOptions.value = response.data;
        });
      },
      //获取公钥
      getPublicKey() {
        getPublicKey().then(response => {
          if (response.code === 1000) {
            state.publicKey = response.data;
          }
        });
      },
      //新增
      handleAdd() {
        state.dialogVisible = true;
        state.title = "添加用户";
        isShowSelect.value = false;
        getUser().then(response => {
          postOptions.value = response.posts;
          //岗位下拉数据某一条禁用
          for (let i = 0; i < postOptions.value.length; i++) {
            postOptions.value[i].disabled = postOptions.value[i].status == "0" ? false : true;
          }
          roleOptions.value = response.roles;
          //角色下拉数据某一条禁用
          for (let i = 0; i < roleOptions.value.length; i++) {
            roleOptions.value[i].disabled = roleOptions.value[i].status == "0" ? false : true;
          }
          console.log("roleOptions.value", roleOptions.value);
          // this.form.password = this.initPassword;
        });
      },
      /** 修改按钮操作 */
      handleUpdate(row) {
        methods.reset();
        // methods.getTreeselect()
        if (!row.userId && !state.multipleSelection.length || state.multipleSelection.length > 1) {
          ElMessage.closeAll();
          ElMessage.warning("请选择一条数据修改");
          return;
        }
        if (row.userId) {
          state.multipleSelection = [];
        }
        state.form.deptId = row.deptId;
        isShowSelect.value = true;
        state.dialogVisible = true;
        state.title = "修改用户";
        state.formRules.password[0].required = false;
        let userId = row.userId || state.multipleSelection[0].userId;
        getUser(userId).then(response => {
          state.form = response.data;
          postOptions.value = response.posts;
          //岗位下拉数据某一条禁用
          for (let i = 0; i < postOptions.value.length; i++) {
            postOptions.value[i].disabled = postOptions.value[i].status == "0" ? false : true;
          }
          roleOptions.value = response.roles;
          //角色下拉数据某一条禁用
          for (let i = 0; i < roleOptions.value.length; i++) {
            roleOptions.value[i].disabled = roleOptions.value[i].status == "0" ? false : true;
          }
          state.form.postIds = response.postIds;
          state.form.roleIds = response.roleIds;
          state.form.password = "";
        });
      },
      //表单确定
      submitForm: function () {
        const formRef = unref(formContainer);
        formRef.commonForm.validate(valid => {
          if (valid) {
            state.saveLoading = true;
            if (state.title == "修改用户") {
              updateUser(state.form).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("修改成功");
                  state.saveLoading = false;
                  state.dialogVisible = false;
                  methods.getList();
                } else {
                  ElMessage.warning(res.message);
                }
              }).catch(() => {
                state.saveLoading = false;
              });
            } else if (state.title == "添加用户") {
              // 新增
              let encrypt = new JSEncrypt();
              encrypt.setPublicKey(state.publicKey); //此处为RSA公钥
              let cloneParams = JSON.parse(JSON.stringify(state.form));
              cloneParams.password = encrypt.encrypt(cloneParams.password);
              addUser(cloneParams).then(response => {
                if (response.code === 1000) {
                  ElMessage.success("新增成功");
                  state.dialogVisible = false;
                  state.saveLoading = false;
                  methods.resetQuery();
                } else {
                  ElMessage.warning(res.message);
                }
              }).catch(res => {
                state.saveLoading = false;
              });
            }
          }
        });
      },
      //删除
      handleDel(row) {
        if (!row.userId && !state.multipleSelection.length) {
          ElMessage.closeAll();
          ElMessage.warning("请至少选择一条数据删除");
          return;
        }
        if (row.userId) {
          state.multipleSelection = [];
        }
        state.ids = state.multipleSelection.map(item => item.userId);
        let userIds = row.userId || state.ids;
        ElMessageBox.confirm("确定是否删除?", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return delUser(userIds);
        }).then(res => {
          ElMessage.closeAll();
          if (res.code == 1000) {
            ElMessage.success("删除成功");
          } else {
            ElMessage.warning(res.message);
          }
          methods.getList();
        }).catch(err => {
          console.log(err);
        });
      },
      //状态开关切换
      statusChange(row) {
        console.log('状态', row);
        let text = row.status === "0" ? "启用" : "停用";
        ElMessageBox.confirm('确认要' + text + '"' + row.userName + '"用户吗?', "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          return changeUserStatus(row.userId, row.status);
        }).then(() => {
          ElMessage.success(text + "成功");
          methods.getList();
        }).catch(function () {
          row.status = row.status === "0" ? "1" : "0";
        });
      },
      //解锁
      unlock(row) {
        ElMessageBox.confirm(`确认解锁${row.userName}用户?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          unlock(row.userName).then(res => {
            if (res.code == 1000) {
              ElMessage.success("解锁成功");
            } else {
              ElMessage.error(response.msg);
            }
          });
        }).catch(() => {});
      },
      //列表数据
      getList() {
        let params = {
          "beginTime": state.queryParams.beginTime,
          "endTime": state.queryParams.endTime,
          "phonenumber": state.queryParams.phonenumber,
          "status": state.queryParams.status,
          "userName": state.queryParams.userName,
          "pageNum": state.queryParams.pageNum,
          "pageSize": state.queryParams.pageSize,
          "deptId": state.queryParams.deptId
        };
        state.tableLoading = true;
        listUser(params).then(res => {
          state.tableData.rows = res.rows;
          state.tableData.total = res.total;
          for (let i = 0; i < state.tableData.rows.length; i++) {
            state.tableData.rows[i].deptName = state.tableData.rows[i].dept.deptName;
          }
          state.tableLoading = false;
        }).catch(err => {
          state.tableLoading = false;
        });
      },
      // 节点单击事件
      handleNodeClick(data) {
        state.queryParams.deptId = data.id;
        methods.getList();
      },
      handleQuery() {
        methods.getList();
      },
      /** 查询下拉树结构 */
      getTreeselect() {
        treeselect().then(response => {
          deptOptions.value = response.data;
        });
      },
      // 筛选节点
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      //重置密码强度判断添加
      ChangeResetPwdStrong(pwd) {
        if (pwd == null || pwd == '') {
          state.resetPwdStrongLevel = '';
        } else {
          console.log(pwd);
          let S_level = methods.checkStrong(pwd);
          switch (S_level) {
            case 0:
              state.resetPwdStrongLevel = '';
            case 1:
              state.resetPwdStrongLevel = 0;
              break;
            case 2:
              state.resetPwdStrongLevel = 1;
              break;
            default:
              state.resetPwdStrongLevel = 2;
          }
        }
      },
      //新增用户密码强度判断增加
      changePsdStrong(pwd) {
        console.log("5555");
        if (pwd == null || pwd == '') {
          state.psdStrongLevel = '';
        } else {
          let S_level = methods.checkStrong(pwd);
          switch (S_level) {
            case 0:
              state.psdStrongLevel = '';
            case 1:
              state.psdStrongLevel = 0;
              break;
            case 2:
              state.psdStrongLevel = 1;
              break;
            default:
              state.psdStrongLevel = 2;
          }
        }
      },
      //判断密码的强度
      CharMode(iN) {
        if (iN >= 48 && iN <= 57)
          //数字
          return 1;
        if (iN >= 65 && iN <= 90)
          //大写字母
          return 2;
        if (iN >= 97 && iN <= 122)
          //小写
          return 4;else return 8; //特殊字符
      },
      //bitTotal函数
      //计算出当前密码当中一共有多少种模式
      bitTotal(num) {
        let modes = 0;
        for (let i = 0; i < 4; i++) {
          if (num & 1) modes++;
          num >>>= 1;
        }
        return modes;
      },
      //返回密码的强度级别
      checkStrong(sPW) {
        if (sPW.length <= 4) return 0; //密码太短
        let Modes = 0;
        for (let i = 0; i < sPW.length; i++) {
          //测试每一个字符的类别并统计一共有多少种模式.
          Modes |= methods.CharMode(sPW.charCodeAt(i));
        }
        return methods.bitTotal(Modes);
      }
    });
    //左侧树筛选
    watch(() => state.deptName, value => {
      tree.value.filter(value);
    });
    methods.getTreeselect();
    methods.getOptions();
    methods.getPublicKey();
    return {
      ...toRefs(state),
      ...toRefs(methods),
      ...toRefs(router),
      tree,
      formContainer,
      resetForm,
      sexOptions,
      postOptions,
      roleOptions,
      isShowSelect,
      deptOptions
    };
  }
});