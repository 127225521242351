import Cookies from 'js-cookie';
import { debounce } from '../../utils/lzCommon';
export default {
  name: "Navbar",
  data() {
    return {
      menuData: [],
      oneLevelMenu: "",
      //一级选中菜单
      twoLevelMenu: "",
      //二级选中菜单
      threeLevelMenu: "",
      //三级选中菜单
      twoLevelMenuData: [],
      threeLevelMenuData: [],
      currentItem: "",
      currentPath: "",
      currentTime: {
        day: "",
        time: "",
        week: ""
      }
    };
  },
  computed: {
    routeData() {
      return this.$store.state.common.menu;
    },
    userInfo() {
      return this.$store.state.common.userInfo;
    },
    avatar() {
      return this.$store.state.common.avatar;
    }
  },
  created() {
    this.currentTime.day = this.parseTime(new Date()).split(" ")[0];
    if (!this.timeInterval) {
      this.timeInterval = setInterval(() => {
        this.currentTime.time = this.parseTime(new Date()).split(" ")[1];
      }, 1000);
    }
    const weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    this.currentTime.week = weeks[new Date().getDay()];
  },
  mounted() {
    let oneLevel = this.$refs.oneLevelRef;
    let key = 'oneLevelMenuScrollLeft';
    if (Cookies.get(key)) {
      oneLevel.scrollLeft = Number(Cookies.get(key));
    }
    oneLevel.addEventListener('scroll', debounce(function () {
      console.log('scroll', oneLevel.scrollLeft);
      Cookies.set(key, oneLevel.scrollLeft);
    }, 500));
  },
  watch: {
    routeData: {
      handler(v) {
        let data = this.dataHandle();
        this.menuData = data;
        //  console.log("data-------", data);
      },
      deep: true,
      immediate: true
    },
    $route: {
      handler(route) {
        this.currentPath = route.path;
        this.isActiveHandle();
      },
      immediate: true
    }
  },
  methods: {
    dataHandle() {
      // console.log("routeData", this.routeData);
      let data = JSON.parse(JSON.stringify(this.routeData));
      data = data.filter(item => {
        return item.meta && !item.meta.hidden;
      });

      // debugger
      let routePath = this.$route.path;
      let that = this;
      function returnData(d, p, id) {
        if (d.children && d.children.length === 1 && !d.alwaysShow) {
          d.path = d.path + d.children[0].path.startsWith("/") ? d.children[0].path : "/" + d.children[0].path;
          delete d.children;
        }
        if (d.children && d.children.length) {
          d.children.forEach((it, i) => {
            it.id = id + "-" + i;
            it.index = p + (it.path.startsWith("/") ? it.path : "/" + it.path);
            returnData(it, it.index, it.id);
          });
        }
      }
      data.forEach((item, id) => {
        if (item.children && item.children.length === 1 && !item.children[0].children) {
          item.path = item.path + (item.children[0].path.startsWith("/") ? item.children[0].path : "/" + item.children[0].path);
          item.meta = item.children[0].meta;
          delete item.children;
        }
        item.index = item.path;
        item.id = id + "";
        returnData(item, item.path, item.id);
      });

      //处理刷新路由
      console.log(666, data);
      return data;
    },
    linkRoute(item, path) {
      if (item && item.meta && item.meta.link && !item.meta.link.startsWith("http")) {
        this.$router.push(item.meta.link);
      } else {
        this.$router.push({
          path
        });
      }
    },
    levelClick(item, name, childName) {
      this[name] = item.id;
      if (item.children) {
        //清空第三级
        this.threeLevelMenuData = [];
        //
        if (name === "oneLevelMenu") {
          this.twoLevelMenu = item.children[0].id;
          if (item.children[0].children) {
            this.currentPath = item.children[0].children[0].index;
            // this.$router.push({
            //   path: item.children[0].children[0].index,
            // });
            this.linkRoute(item.children[0].children[0], item.children[0].children[0].index);
          } else {
            // this.$router.push({
            //   path: item.children[0].index,
            // });
            this.linkRoute(item.children[0], item.children[0].index);
          }
        }

        //
        if (name === "twoLevelMenu") {
          this.currentPath = item.children[0].index;

          // this.$router.push({
          //   path: item.children[0].index,
          // });
          this.linkRoute(item.children[0], item.children[0].index);
        }
        this[childName + "Data"] = item.children;
        //  console.log("childName", this.twoLevelMenuData);
      } else {
        this[childName + "Data"] = [];
        // this.$router.push({
        //   path: item.index,
        // });
        this.linkRoute(item, item.index);
      }
    },
    isActiveHandle() {
      let that = this;
      that.threeLevelMenuData = [];
      that.twoLevelMenuData = [];
      let twoLevelMenuData = [];
      let threeLevelMenuData = [];
      for (let a = 0; a < this.menuData.length; a++) {
        let item = this.menuData[a];
        twoLevelMenuData = item.children ? item.children : [];
        //只有一级
        console.log("item1", item);
        if (item.index === that.currentPath || item.meta && item.meta.link === that.currentPath) {
          //  console.log(66666, item);
          that.oneLevelMenu = item.id;
          that.twoLevelMenuData = twoLevelMenuData;
          return;
        } else if (item.children && item.children.length) {
          //处理第二级别

          for (let b = 0; b < item.children.length; b++) {
            let item1 = item.children[b];
            threeLevelMenuData = item1.children ? item1.children : [];

            //处理微服务外链
            if (that.currentPath.startsWith(item1.index) || item1.meta && item1.meta.link === that.currentPath) {
              let d = item1.id.split("-");
              that.oneLevelMenu = d[0];
              that.twoLevelMenu = d[1] ? d[0] + "-" + d[1] : "";
              that.twoLevelMenuData = twoLevelMenuData;
              that.threeLevelMenuData = threeLevelMenuData;
              return;
            }
            if (item1.children && item1.children.length) {
              for (let c = 0; c < item1.children.length; c++) {
                let item2 = item1.children[c];
                //  console.log("xxx", item2.index, that.currentPath);
                if (that.currentPath.startsWith(item2.index) || item2.meta && item2.meta.link === that.currentPath) {
                  let d = item2.id.split("-");
                  //  console.log("ddddddddd", d);
                  that.oneLevelMenu = d[0];
                  that.twoLevelMenu = d[1] ? d[0] + "-" + d[1] : "";
                  //that.threeLevelMenu = item2.id
                  that.twoLevelMenuData = twoLevelMenuData;
                  that.threeLevelMenuData = threeLevelMenuData;
                  return;
                }
              }
            }
          }
        }
      }

      // console.log("twoLevelMenuData", twoLevelMenuData);
    },
    async logout() {
      this.$confirm("确定注销并退出系统吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.$store.dispatch("common/LogOut").then(() => {});
      });
    }
  }
};