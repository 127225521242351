//

import { initGlobalState } from 'qiankun'

const initialState = {
    baiduinit: window
}
const actions = initGlobalState(initialState)

export default actions
