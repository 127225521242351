import { getLeave } from '@/api/workflow/leave';
import { historyFromData } from '@/api/activiti/historyFormdata';
export default {
  name: "",
  props: {
    businessKey: {
      type: String
    }
  },
  data() {
    return {
      form: {
        line: "",
        color: ""
      },
      formData: [{
        prop: "lineName",
        label: "所属线路",
        type: "text",
        span: 24,
        disabledHandle: () => {
          return true;
        }
      }, {
        prop: "confirmTelesignallName",
        label: "遥信名称",
        type: "text",
        span: 24,
        disabledHandle: () => {
          return true;
        }
      }, {
        prop: "status",
        label: "当前状态",
        type: "text",
        span: 24,
        disabled: true
      },
      // {s
      //   prop: "name",
      //   label: "确认遥信名",
      //   placeholder: "请输入遥信名",
      //   type: "textareaNotRemark",
      //   span:24,
      //   disabled:true
      // },
      {
        prop: "operate",
        label: "执行操作",
        type: "text",
        span: 24,
        disabled: true
      }],
      processData: []
    };
  },
  mounted() {
    this.getLeave();
    this.historyFromData();
  },
  methods: {
    getLeave() {
      getLeave(this.businessKey).then(response => {
        if (response.data.isOff === '2') {
          if (response.data.execAction === '1') {
            response.data['operate'] = '遥信-分';
          } else {
            response.data['operate'] = '遥信-合';
          }
          if (response.data.telesignallStatus === '1') {
            response.data['status'] = '遥信-分';
          } else {
            response.data['status'] = '遥信-合';
          }
        } else {
          if (response.execAction === '1') {
            response.data['operate'] = '投入';
          } else {
            response.data['operate'] = '退出';
          }
          if (response.data.telesignallStatus === '1') {
            response.data['status'] = '投入';
          } else {
            response.data['status'] = '退出';
          }
        }
        this.$emit('setCreateName', response.data.createName || '');
        this.form = response.data || {};
      });
    },
    historyFromData() {
      historyFromData(this.businessKey).then(response => {
        this.processData = response.data || [];
      });
    }
  }
};