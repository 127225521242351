function findFirstLevelNode(nodeData) {
  if (nodeData.children) {
    return findFirstLevelNode(nodeData.children[0]);
  } else {
    return nodeData;
  }
}
import lzTree from "../synergy-tree";
import { defineComponent, computed, ref, nextTick, provide, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import request from "../../utils/onlineRequest";
export default defineComponent({
  name: "synergyTreeView",
  components: {
    lzTree
  },
  props: {
    placeholder: {
      type: String,
      default: 'Please enter Device Name'
    },
    treeTitle: {
      type: String,
      default: "设备图"
    },
    lazyUrl: {
      type: String,
      default: ""
    },
    lazyLimitNode: {
      type: [Number, String],
      default: 8
    },
    lazyParamName: {
      type: String,
      default: "parentId"
    },
    url: {
      type: String,
      default: ""
    },
    searchParamName: {
      type: String,
      default: 'deviceName'
    },
    searchExtraParams: {
      type: Object,
      default: () => {
        return {};
      }
    },
    searchUrl: {
      type: String,
      default: ""
    },
    //初始化选中第一个节点下的第一个子元素 无论这个子元素时候存在 如果为false就是一直向下寻找直到找到为止 true为只找一次
    defaultQueryOneTime: {
      type: Boolean,
      default: false
    },
    nodeKey: {
      type: String,
      default: () => {
        return "name";
      }
    },
    defaultCheckNode: {
      type: Object,
      default: () => {
        return {};
      }
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          id: "id",
          label: "nodeName",
          children: "children",
          disabled: () => false
        };
      }
    },
    showCheckbox: {
      type: Boolean,
      default: false
    }
  },
  emits: ["handle-node-click"],
  setup(props, {
    emit
  }) {
    const lazyLoadTreeSelect = ref(null);
    let defaultCheck = ref(null);
    const query = ref("");
    const isDrak = ref(null);
    const onQueryChanged = query => {
      handleSearchByName(query);
    };
    let deviceTree = ref([]);
    const route = useRoute();
    const store = useStore();
    provide('showCheckbox', props.showCheckbox);
    const queryLineData = (parentId = "") => {
      return new Promise((resolve, reject) => {
        request({
          url: props.url ? props.url : "/energy-comm-provider/cim/getCimTopuDeviceTree",
          method: "post",
          data: {
            parentId: parentId
          }
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err);
        });
      });
    };
    const queryLazyData = (parentId = "") => {
      return new Promise((resolve, reject) => {
        debugger;
        request({
          url: props.lazyUrl ? props.lazyUrl : "/energy-comm-provider/cim/getCimTopuDeviceTree",
          method: "post",
          data: {
            [props.lazyParamName]: parentId
          }
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err);
        });
      });
    };
    const queryDefaultTreeData = () => {
      queryLineData().then(res => {
        if (!Array.isArray(res)) {
          res = [res];
        }
        deviceTree.value = res;
        if (Object.keys(props.defaultCheckNode).length) {
          checkNode.value = props.defaultCheckNode;
        } else {
          findFirstDevice();
        }
      });
    };

    //懒加载数据
    const loadNode = node => {
      console.log("load-node:", node);
      queryLazyData(node.id).then(res => {
        console.log("load-node:=====", res);
        if (res.length === 0) {
          node.isLeaf = true;
          emit("handle-node-click", node);
        }
        lazyLoadTreeSelect.value.updateChildren(node, res);
      });
    };

    //找到第一个设备节点
    const findFirstDevice = async () => {
      let deviceTreeCopy = deviceTree.value;
      let value = deviceTreeCopy.length && deviceTreeCopy[0];
      if (props.defaultQueryOneTime) {
        await queryLazyData(value.id).then(res => {
          lazyLoadTreeSelect.value.updateChildren(value, res);
          if (res.length) {
            value = res[0];
          }
        });
      } else {
        while ((value.nodeType || value.type) !== props.lazyLimitNode) {
          // 如果有children 且不是设备类型则继续向下查找
          if (value.children.length) {
            value = value.children[0];
          } else {
            await queryLazyData(value.id).then(res => {
              lazyLoadTreeSelect.value.updateChildren(value, res);
              if (res.length) {
                value = res[0];
              }
            });
          }
        }
      }
      defaultCheck.value = value;
      checkNode.value = {
        [props.defaultProps.id]: value[props.defaultProps.id]
      };
      lazyLoadTreeSelect.value.updateChildren(value, []);
      emit("handle-node-click", value);
    };
    const parentNodeClick = function (value) {
      emit("handle-node-click", value);
    };
    let checkNode = ref({});
    checkNode.value = props.defaultCheckNode;
    queryDefaultTreeData();
    const handleNodeClick = function (value) {
      checkNode.value = {
        [props.defaultProps.id]: value[props.defaultProps.id]
      };
      console.log('checkNode', checkNode);

      //nodeType = 5 是设备类型 也是叶子节点，不需要再发起请求

      // if ((value.nodeType || value.type) !== props.lazyLimitNode) {
      //   setTimeout(() => {
      //     queryLazyData(value.id).then((res) => {
      //       lazyLoadTreeSelect.value.updateChildren(value, res);
      //     });
      //   }, 0)
      // } else {
      //   lazyLoadTreeSelect.value.updateChildren(value, []);
      // }

      emit("handle-node-click", value);
    };
    const currentNodeKey = computed(() => "netsmaintrans");
    const handleSearchByName = inputName => {
      if (!inputName) {
        queryDefaultTreeData();
      } else {
        request({
          url: props.searchUrl ? props.searchUrl : "/energy-comm-provider/cim/searchCimTopuDevice",
          method: "post",
          data: {
            [props.searchParamName]: inputName,
            ...props.searchExtraParams
          }
        }).then(res => {
          if (!res.data) {
            deviceTree.value = [];
          } else {
            if (!Array.isArray(res.data)) {
              res.data = [res.data];
            }
            deviceTree.value = res.data;
          }
        }).catch(err => {});
      }
    };
    const handleNodeCheck = (node, value, checkedNodes, checkedNodeKeys) => {
      emit("nodeCheck", node, value, checkedNodes, checkedNodeKeys);
    };
    watch(() => props.lazyUrl, (newVal, oldVal) => {
      console.log('lazyUrl', newVal, '_______', oldVal);
      if (defaultCheck.value && defaultCheck.value.type == '线路' && defaultCheck.value.children.length == 0) {
        queryLazyData(defaultCheck.value.id).then(res => {
          lazyLoadTreeSelect.value.updateChildren(defaultCheck.value, res);
        });
      }
    });
    const setChecked = (node, value) => {
      lazyLoadTreeSelect.value.setChecked(node, value);
    };
    watch(() => store.state.user.themeMode, val => {
      if (val == "dark") {
        isDrak.value = true;
      } else {
        isDrak.value = false;
      }
    });
    onMounted(() => {
      if (store.state.user.themeMode == "dark") {
        isDrak.value = true;
      } else {
        isDrak.value = false;
      }
    });
    return {
      checkNode,
      deviceTree,
      loadNode,
      handleNodeClick,
      currentNodeKey,
      lazyLoadTreeSelect,
      handleSearchByName,
      query,
      isDrak,
      onQueryChanged,
      parentNodeClick,
      handleNodeCheck,
      setChecked
    };
  }
});