import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue";
const _hoisted_1 = ["innerHTML"];
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  class: "dialog-footer"
};
import { ref, nextTick, watch, onMounted, onUnmounted } from 'vue';
import { aceEdit } from '../utils';
export default {
  __name: 'aceDrawer',
  props: {
    modelValue: Boolean,
    title: String,
    direction: String,
    content: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: 'editJson'
    },
    codeType: String
  },
  emits: ['beforeClose', 'confirm', 'update:modelValue'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const editor = ref({});
    const visible = ref(false);
    watch(() => props.modelValue, val => {
      visible.value = val;
      if (val) {
        initEditor();
      }
    });
    const initEditor = () => {
      nextTick(() => {
        editor.value = aceEdit(props.content, props.id, props.codeType);
      });
    };
    const dialogConfirm = () => {
      const editVal = editor.value.getValue();
      emits('confirm', editVal);
    };
    const drawerBeforeClose = () => {
      emits('update:modelValue', false);
      emits('beforeClose');
    };
    onMounted(() => {});
    onUnmounted(() => {
      if (Object.keys(editor.value).length !== 0) {
        editor.value.destroy();
        editor.value.container.remove();
      }
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_drawer = _resolveComponent("el-drawer");
      return _openBlock(), _createBlock(_component_el_drawer, {
        modelValue: visible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => visible.value = $event),
        size: "60%",
        title: __props.title,
        direction: __props.direction || 'ltr',
        "custom-class": "ace-dialog",
        "append-to-body": true,
        "before-close": drawerBeforeClose
      }, {
        header: _withCtx(() => [_createElementVNode("div", {
          innerHTML: __props.title
        }, null, 8, _hoisted_1)]),
        default: _withCtx(() => [visible.value ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          id: __props.id
        }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_button, {
          type: "primary",
          size: "small",
          onClick: dialogConfirm
        }, {
          default: _withCtx(() => [_createTextVNode(" 确定 ")]),
          _: 1
        })])]),
        _: 1
      }, 8, ["modelValue", "title", "direction"]);
    };
  }
};