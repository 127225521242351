import { onMounted, onUnmounted, reactive, ref, unref } from 'vue'
import { socketInstance } from "./index.js";
import { getToken } from "@/utils/auth";
export function useSocket() {
    let clientSocket = ref(null)
    function initWebSocket(subscribeSocket, hostName,servicePrefix) {
        return new Promise((resolve, reject) => {
            socketInstance({
                token: "Bearer " + getToken(),
                errorCallBack: () => {
                    reject();
                },
                subscribeSocket: subscribeSocket,
                hostName,
                servicePrefix
            })
                .then(client => {
                    clientSocket.value = client
                    resolve(client)
                })
                .catch(err => {
                    reject(err);
                });
        });
    }
    /* 断网提示 */
    function offLine() {
        // this.$message.error( "您已断开连接，请刷新页面后重连。");
    }
    onMounted(() => window.addEventListener("offline", e => offLine()))
    onUnmounted(() => {
        clientSocket.value && clientSocket.value.disconnect();
        clientSocket = null;
        window.removeEventListener("offline", e => offLine());
    })
    return {
        initWebSocket,
        clientSocket
    }
}
