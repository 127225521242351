import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue";
const _hoisted_1 = {
  class: "design-container"
};
const _hoisted_2 = {
  class: "main-body"
};
const _hoisted_3 = {
  class: "main-form"
};
const _hoisted_4 = {
  key: 0,
  class: "empty-tips"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import headTools from '../components/headTools.vue';
import FormControl from './components/dragControl.vue';
import FormDesign from './components/form.vue';
import FormControlAttr from './components/formControlAttr.vue';
import VueFile from '../components/vueFile.vue';
import AceDrawer from '../components/aceDrawer.vue';
import { ref, reactive, provide, onMounted } from 'vue';
import { useStore } from 'vuex';
import { getRequest } from '@/api/designForm';
import { ElMessage } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { afterResponse, beforeRequest } from '../utils';
import { json2string, objToStringify, string2json, stringToObj } from '@/utils/form';
export default {
  __name: 'index',
  setup(__props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute().query || {};
    const state = reactive({
      formData: {
        list: [],
        form: {
          size: 'default'
        },
        config: {}
      },
      editor: {},
      loading: false,
      formDataPreview: {},
      previewVisible: false,
      // 预览窗口
      designType: route.type,
      // 当前页面设计类型，有效值search
      formDict: {},
      formOtherData: {
        source: route.source || null,
        dbId: route.dbId || null,
        //  subSource: route.subSource || null,
        formName: '未命名表单',
        subTableId: null
      }
    });
    const drawer = reactive({
      visible: false,
      type: '',
      title: '',
      codeType: '',
      direction: undefined,
      //弹出方向rtl / ltr
      callback: ''
    });
    const vueFileEl = ref();
    const formControlAttrEl = ref();
    // 当前表单设计类型，供各子组件调用以展示不同页面，统一方式不需要每个组件都从路由中取
    provide('formDesignType', state.designType);
    const getInitData = () => {
      const id = route.id; // 当前记录保存的id
      if (id) {
        // 获取初始表单数据
        state.loading = true;
        getRequest('designById', {
          id: id
        }).then(res => {
          const result = res.data;
          // 初始设计搜索时res.data=''
          if (result.data) {
            state.formData = stringToObj(result.data);
          }
          state.formDict = string2json(result.dict);
          // 恢复表单名称
          state.formOtherData.source = result.source;
          state.formOtherData.formName = result.name;
          state.formOtherData.dbId = result.dbId + '';
          state.formOtherData.subTableId = result.subTableId ? result.subTableId : null;
          if (result.source && state.designType !== 'search') {
            // 加载属性侧边栏的字段标识，搜索时不需要请求
            formControlAttrEl.value.getFormFieldBySource(result.source);
          }
          state.loading = false;
        }).catch(res => {
          // console.log(res)
          ElMessage.error(res.message || '加载异常');
          state.loading = false;
        });
      }
    };
    const headToolClick = type => {
      switch (type) {
        case 'del':
          state.formData.list = [];
          store.commit('designForm/setActiveKey', '');
          store.commit('designForm/setControlAttr', {});
          break;
        case 'eye':
          // 打开预览窗口
          store.commit('designForm/setActiveKey', '');
          store.commit('designForm/setControlAttr', {});
          state.previewVisible = true;
          let stringPreview = objToStringify(state.formData); // 防止预览窗口数据修改影响
          const formName = state.formData.form.name;
          const reg = new RegExp(`get${formName}ControlByName`, 'g');
          stringPreview = stringPreview.replace(reg, `getPreview${formName}ControlByName`);
          state.formDataPreview = stringToObj(stringPreview);
          state.formDataPreview.form.name = `Preview${formName}`; // 修改下表单名
          break;
        case 'json':
          // 生成脚本预览
          openAceEditDrawer({
            direction: 'rtl',
            content: state.formData,
            title: '可编辑修改或将已生成的脚本粘贴进来'
          });
          break;
        case 'save':
          saveData();
          break;
        case 'vue':
          vueFileEl.value.open(state.formData);
          break;
      }
    };
    let subEvent = ref(false);
    let control = null;

    // 弹窗确认
    const dialogConfirm = editVal => {
      // 生成脚本预览和导入json，都是将编辑器内容更新至state.formData
      try {
        if (typeof drawer.callback === 'function') {
          // callback
          const newObj = drawer.codeType === 'json' ? string2json(editVal) : stringToObj(editVal);
          drawer.callback(newObj);
        } else {
          switch (drawer.type) {
            case 'css':
              // 表单属性－编辑表单样式
              if (!state.formData.config) {
                state.formData.config = {};
              }
              state.formData.config.style = editVal;
              break;
            case 'dict':
              state.formDict = string2json(editVal);
              break;
            case 'beforeRequest':
            case 'beforeSubmit':
            case 'afterResponse':
            case 'afterSubmit':
              if (subEvent.value) {
                if (!control.value.events) {
                  control.value.events = {};
                }
                control.value.events[drawer.type] = stringToObj(editVal);
              } else {
                if (!state.formData.events) {
                  state.formData.events = {};
                }
                state.formData.events[drawer.type] = stringToObj(editVal);
              }
              break;
            default:
              state.formData = stringToObj(editVal);
          }
        }
        dialogCancel();
      } catch (res) {
        // console.log(res.message)
        //ElMessage.error(res.message)
      }
    };
    // 将数据保存在服务端
    const saveData = () => {
      // //　添加校验，没有选择数据源时则必须要配置接口url
      const {
        addUrl,
        editUrl,
        requestUrl
      } = state.formData.config;
      if (!state.formOtherData.source && (!addUrl || !editUrl) && state.designType !== 'search') {
        ElMessage.error('请选择数据源或配置接口url地址，否则表单无法提交保存');
        return;
      }
      if (state.formOtherData.subTableId && !state.formOtherData.relationKey) {
        ElMessage.error('请选择数据源子表与主表关联字段');
        return;
      }
      let params = {
        data: objToStringify(state.formData),
        source: state.formOtherData.source,
        // 数据源允许在表单属性设置里修改的
        name: state.formOtherData.formName,
        // 表单名称，用于在显示所有已创建的表单列表里显示
        subTableId: state.formOtherData.subTableId,
        dbId: state.formOtherData.dbId,
        type: 1,
        // 1表单 2列表
        dict: json2string(state.formDict)
      };
      let apiKey = 'designSave';
      if (route.id) {
        // 编辑状态 当前记录id
        Object.assign(params, {
          id: route.id
        });
        apiKey = 'designEdit';
      }
      // 列表搜索模式下只有修改
      if (state.designType === 'search') {
        params = {
          data: objToStringify(state.formData),
          dict: json2string(state.formDict),
          id: route.id
        };
      }
      state.loading = true;
      getRequest(apiKey, params).then(res => {
        ElMessage({
          message: res.message || '保存成功！',
          type: 'success'
        });
        // 根据不同情况跳转到不同地址
        const path = route.redirect || '/devPlatform/design/form/list';
        let query = {};
        if (route.redirect && route.redirect.indexOf('?') !== -1) {
          // 带有问号参数时，放在path传是有问题的，将id=1转为{id:1}
          const p = route.redirect.split('?')[1];
          const pSplit = p.split('&');
          pSplit.forEach(item => {
            const splitItem = item.split('=');
            query[splitItem[0]] = splitItem[1];
          });
        }
        router.push({
          path: path,
          query: query
        });
        state.loading = false;
      }).catch(res => {
        ElMessage.error(res.message || '保存异常');
        state.loading = false;
      });
      // 清空右侧内容管理菜单存在session的内容，刷新时可重新加载新菜单
      if (!route.id) {
        // 新增时
        window.sessionStorage.removeItem('formMenuList');
      }
      // 清空右侧栏信息
      store.commit('designForm/setActiveKey', '');
      store.commit('designForm/setControlAttr', {});
    };
    const openAceEditDrawer = (params, controlData) => {
      const {
        type,
        direction,
        codeType,
        title,
        callback,
        content
      } = params;
      drawer.direction = direction; // 窗口位置ltr/rtl
      drawer.type = type; // 作为窗口唯一标识，在窗口关闭时可根据type作不同处理
      drawer.codeType = codeType || ''; // 显示代码类型
      drawer.title = title ? `提示：${title}` : '';
      drawer.visible = true;
      drawer.callback = callback;
      let editData = codeType === 'json' ? json2string(content, true) : objToStringify(content, true);
      switch (type) {
        case 'css':
          editData = state.formData.config?.style || '';
          break;
        case 'dict':
          // 格式化一下
          editData = json2string(state.formDict, true);
          break;
        case 'beforeRequest':
        case 'beforeSubmit':
          if (controlData && controlData.value) {
            subEvent.value = true;
            control = controlData;
            const beforeData = controlData.value.events || {};
            if (beforeData[type]) {
              editData = objToStringify(beforeData[type], true);
            } else {
              editData = beforeRequest;
            }
          } else {
            const beforeData = state.formData.events || {};
            if (beforeData[type]) {
              editData = objToStringify(beforeData[type], true);
            } else {
              editData = beforeRequest;
            }
          }
          break;
        case 'afterResponse':
        case 'afterSubmit':
          if (controlData && controlData.value) {
            subEvent.value = true;
            control = controlData;
            const newData = controlData.value.events || {};
            if (newData[type]) {
              editData = objToStringify(newData[type], true);
            } else {
              editData = afterResponse;
            }
          } else {
            const newData = state.formData.events || {};
            if (newData[type]) {
              editData = objToStringify(newData[type], true);
            } else {
              editData = afterResponse;
            }
          }
          break;
        case 'optionsParams':
          if (!content) {
            editData = beforeRequest;
          }
          break;
        case 'optionsResult':
          if (!content) {
            editData = afterResponse;
          }
          break;
      }
      drawer.content = editData;
    };
    const drawerBeforeClose = () => {
      dialogCancel();
    };
    const dialogCancel = () => {
      drawer.visible = false;
      drawer.type = '';
      drawer.title = '';
      drawer.codeType = '';
      drawer.callback = '';
      drawer.content = '';
      //子表借口回调函数开关
      subEvent.value = false;
    };
    // 预览窗口提交测试
    const previewForm = ref();
    const previewSubmit = () => {
      previewForm.value.validate((valid, fields) => {
        if (valid) {
          // alert('校验通过')
          ElMessage.success('校验通过');
          console.log(fields);
        } else {
          // alert('校验不通过')
          // console.log('error submit!', fields)
          ElMessage.error('校验不通过');
          return false;
        }
      });
    };
    // 选择模板
    const selectTemplate = data => {
      state.formData = stringToObj(objToStringify(data));
    };
    // 搜索设计时左侧快速添加字段
    const searchCheckField = data => {
      state.formData.list.push(data);
    };
    getInitData();
    // 从数据源点创建表单过来时，带有参数source
    onMounted(() => {
      if (route.source) {
        formControlAttrEl.value.getFormFieldBySource(route.source);
      }
    });
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_dialog = _resolveComponent("el-dialog");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(FormControl, {
        formId: state.formOtherData.source,
        list: state.formData.list,
        onClickCheck: searchCheckField,
        onClick: selectTemplate
      }, null, 8, ["formId", "list"]), _createElementVNode("div", _hoisted_2, [_createVNode(headTools, {
        onClick: headToolClick
      }), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [state.formData.list.length === 0 ? (_openBlock(), _createElementBlock("div", _hoisted_4, " 从左侧拖拽来添加字段 ")) : _createCommentVNode("", true), _createVNode(FormDesign, {
        type: 5,
        formData: state.formData,
        "add-form": _ctx.designType === 'search' ? false : true,
        dict: state.formDict
      }, null, 8, ["formData", "add-form", "dict"])])), [[_directive_loading, state.loading]])]), _createVNode(FormControlAttr, {
        ref_key: "formControlAttrEl",
        ref: formControlAttrEl,
        formData: state.formData.form,
        formConfig: state.formData.config,
        formOtherData: state.formOtherData,
        "onUpdate:formOtherData": _cache[0] || (_cache[0] = $event => state.formOtherData = $event),
        onOpenDialog: openAceEditDrawer
      }, null, 8, ["formData", "formConfig", "formOtherData"]), _createVNode(AceDrawer, {
        modelValue: drawer.visible,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => drawer.visible = $event),
        title: drawer.title,
        direction: drawer.direction,
        content: drawer.content,
        "code-type": drawer.codeType,
        onBeforeClose: drawerBeforeClose,
        onConfirm: dialogConfirm
      }, null, 8, ["modelValue", "title", "direction", "content", "code-type"]), !['search'].includes(state.designType) ? (_openBlock(), _createBlock(VueFile, {
        key: 0,
        ref_key: "vueFileEl",
        ref: vueFileEl
      }, null, 512)) : _createCommentVNode("", true), _createVNode(_component_el_dialog, {
        modelValue: state.previewVisible,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => state.previewVisible = $event),
        title: "预览",
        fullscreen: true
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          size: "small",
          type: "primary",
          onClick: previewSubmit
        }, {
          default: _withCtx(() => [_createTextVNode(" 提交 ")]),
          _: 1
        }), _createVNode(_component_el_button, {
          size: "small",
          onClick: _cache[2] || (_cache[2] = $event => state.previewVisible = false)
        }, {
          default: _withCtx(() => [_createTextVNode(" 取消 ")]),
          _: 1
        })])]),
        default: _withCtx(() => [state.previewVisible ? (_openBlock(), _createBlock(FormDesign, {
          key: 0,
          "form-data": state.formDataPreview,
          dict: state.formDict,
          type: 1,
          ref_key: "previewForm",
          ref: previewForm
        }, null, 8, ["form-data", "dict"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }
};