import {bigRequest as request} from '@/utils/request'
// 查询数据表列表
export function queryMetaDataTableList(query) {
  return request({
    url: '/queryMetaDataTableList',
    method: 'post',
    data: query
  })
}

// 查询数据表名称
export function queryMetaTableNames(query) {
  return request({
    url: '/queryMetaTableNames',
    method: 'post',
    data: query
  })
}

// 查询数据表字段
export function queryTableColumn(query) {
  return request({
    url: '/queryTableColumn',
    method: 'post',
    data: query
  })
}

// 添加元数据表
export function addMetaDataTable(query) {
  return request({
    url: '/metaDataTable',
    method: 'post',
    data: query
  })
}

// 编辑元数据表
export function updateMetaDataTable(query) {
  return request({
    url: '/update/metaDataTable',
    method: 'post',
    data: query
  })
}

// 删除多个元数据表
export function deleteMetaDataTable(data) {
  return request({
    url: '/delete/metaDataTable',
    method: 'post',
    data: data
  })
}

// 删除单个元数据表
export function metaDataTable(id) {
  return request({
    url: '/delete/metaDataTable/' + id,
    method: 'post',
  })
}

// 关联数据标签
export function tableRelateLabel(query) {
  return request({
    url: '/tableRelateLabel',
    method: 'post',
    data: query
  })
}

// 关联质量规则
export function tableRelateQuality(query) {
  return request({
    url: '/tableRelateQuality',
    method: 'post',
    data: query
  })
}

// 根据tableId type 查询标签或者质量规则
export function queryRelation(query) {
  return request({
    url: '/queryRelation',
    method: 'post',
    data: query
  })
}

// 根据id 删除质量规则
export function removeRelation(query) {
  return request({
    url: '/removeRelation',
    method: 'post',
    data: query
  })
}

// 查询表数据
export function getQueryTableData(query) {
  return request({
    url: '/queryTableData',
    method: 'post',
    data: query
  })
}

// 删除表字段、指标
export function removeMetaTableColumn(query) {
  return request({
    url: '/removeMetaTableColumn',
    method: 'post',
    data: query
  })
}

// 添加表字段 指标
export function addMetaTableColumn(query) {
  return request({
    url: '/addMetaTableColumn',
    method: 'post',
    data: query
  })
}
