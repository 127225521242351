import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "main-tools"
};
import { reactive, computed } from 'vue';
export default {
  __name: 'headTools',
  props: {
    showKey: {
      type: Array,
      default: () => [] // showKey,hideKey设置其中一个即可，showKey优先
    },
    hideKey: {
      type: Array,
      default: () => [] // 设置了showKey时，hideKey无效
    }
  },
  emits: ['click'],
  setup(__props, {
    emit: emits
  }) {
    const props = __props;
    const btnList = computed(() => {
      const list = [{
        icon: 'del',
        label: '清空',
        key: 1
      }, {
        icon: 'eye',
        label: '预览',
        key: 2
      }, {
        icon: 'json',
        label: '生成脚本预览',
        key: 3
      }, {
        icon: 'vue',
        label: '导出vue文件',
        key: 4
      }, {
        icon: 'save',
        label: '保存',
        key: 5
      }];
      if (props.showKey?.length) {
        // 按照指定的key显示
        return list.filter(item => {
          return props.showKey.includes(item.key);
        });
      } else if (props.hideKey?.length) {
        // 按照指定的key隐藏
        return list.filter(item => {
          return !props.hideKey.includes(item.key);
        });
      } else {
        return list; // 否则显示全部
      }
    });
    const btnClick = type => {
      emits('click', type);
    };
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(btnList), item => {
        return _openBlock(), _createBlock(_component_el_button, {
          link: "",
          type: "primary",
          onClick: $event => btnClick(item.icon),
          key: item.icon
        }, {
          default: _withCtx(() => [_createElementVNode("i", {
            class: _normalizeClass(['icon-' + item.icon])
          }, null, 2), _createTextVNode(_toDisplayString(item.label), 1)]),
          _: 2
        }, 1032, ["onClick"]);
      }), 128))]);
    };
  }
};