<template><section><html><head></head><body><h1>数据源</h1>
<p>即mysql数据表，用于存储表单提交的数据，因此在设计一个表单前应先为该表单创建一个数据源</p>
<h2>设计入口</h2>
<p>/devPlatform/design/dataSource</p>
<p><img src="./img/data-source1.png" alt=""></p>
<p>数据表名即mysql数据库表名；</p>
<h4>数据库表字段：</h4>
<p>标题：表单显示的标签名称</p>
<p>其他表名字类型长度值等对应mysql</p>
</body></html></section></template>

