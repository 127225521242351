import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "oneLevelMenu"
};
import { reactive, watch, computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getCurrentTab, setCurrentTab } from "@/utils/storage";
import { getCurrentPath, setCurrentPath } from "@/utils/storage";
import { deepClone, linkActiveHandle } from "@/utils/index.js";
import { parseTime } from '@/utils/lz';
export default {
  __name: 'index',
  setup(__props, {
    expose
  }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      currentTab: getCurrentTab(),
      tabData: [],
      mainData: [],
      adminData: []
    });
    const sysTitle = ref(window.g.VUE_APP_TITLE);
    let menus = computed(() => {
      return store.state.menu.route;
    });
    console.log(66666, menus);
    const currentType = computed(() => {
      return store.state.menu.currentType;
    });
    const currentTime = ref({
      day: "",
      time: "",
      week: ""
    });
    const timeInterval = ref(null);
    currentTime.value.day = parseTime(new Date()).split(" ")[0];
    if (!timeInterval.value) {
      timeInterval.value = setInterval(() => {
        currentTime.value.time = parseTime(new Date()).split(" ")[1];
      }, 1000);
    }
    const weeks = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
    currentTime.value.week = weeks[new Date().getDay()];
    //获取主页面菜单（六大系统）
    state.mainData = menus.value.filter(item => {
      return item.path.startsWith("/module") && item.hidden === "0";
    }).map(it => {
      return {
        path: it.path,
        label: it.meta.title
      };
    });
    const frameWork = menus.value.find(item => {
      return item.path.startsWith("/baseFramework");
    });
    console.log(66666, frameWork);
    let adminData = [];
    if (frameWork) {
      adminData = frameWork.children.filter(item => {
        return item.hidden === "0";
      }).map(it => {
        return {
          path: "/" + it.path,
          label: it.meta.title
        };
      });
    }
    state.adminData = deepClone(adminData);
    console.log(44444, state.adminData);

    //菜单默认选中
    //path 判断是主系统菜单还是后台管理菜单
    // 切换一级下对应的菜单
    const menuActiveHandle = v => {
      console.log(22222222222222, v);

      //有hash是外链
      let hash = window.location.hash;
      if (hash) {
        hash = hash.slice(1);
        v = hash.startsWith("/") ? hash.startsWith("/") : "/" + hash;
        const str = linkActiveHandle(route.path);
        store.dispatch("menu/setActive", str);
      }
      let it = state.mainData.find(item => {
        return v.startsWith(item.path);
      });
      let adminIt = state.adminData.find(item => {
        return v.startsWith(item.path);
      });

      // path 判断是主系统菜单还是后台管理菜单
      if (it) {
        state.tabData = deepClone(state.mainData);
        store.commit("menu/setCurrentType", "main");
      }
      if (adminIt) {
        //后台
        it = state.adminData.find(item => {
          return v.startsWith(item.path);
        });
        state.tabData = deepClone(state.adminData);
        store.commit("menu/setCurrentType", "admin");
      }
      if (it) {
        state.currentTab = it.path;
        setCurrentTab(it.path);
        setCurrentPath(route.path);
        store.commit("menu/setLink", false);
        //切换左侧菜单数据

        store.commit("menu/currentOneLevelChange", it.path);
      }
    };
    ///
    //
    //
    //
    console.log("path===---", route.path, getCurrentPath(), getCurrentTab());

    //刷新
    if (getCurrentPath() === route.path) {
      menuActiveHandle(getCurrentTab());
    } else {
      menuActiveHandle(route.path);
      store.dispatch("menu/setActive", linkActiveHandle(route));
    }
    setCurrentPath(route.path);

    // let link = computed(() => {
    //   return store.state.menu.link;
    // });

    watch(() => {
      return route.path;
    }, v => {
      //重定向
      if (route.path.startsWith("/redirect")) {
        return;
      }
      menuActiveHandle(v);
    });

    // 一级菜单切换，匹配自己的菜单，选中菜单下的第一个菜单跳转
    const tabClick = val => {
      //store.commit("menu/currentOneLevelChange", val.paneName);
      if (val.paneName === "/index") {
        router.push("/index");
        return;
      }

      //如果有微服务菜单，则跳转第一个页面
      let menu = store.state.menu.menus.filter(item => {
        return item.path.startsWith(val.paneName);
      });
      console.log("menu", menu);
      if (menu.length) {
        let data = JSON.parse(JSON.stringify(menu));
        for (let i = 0; i < data.length; i++) {
          if (data[i].hidden !== "1") {
            const c = findFirstMenu(data[i]);
            console.log("cccccc--s", c);
            state.currentTab = val.paneName;
            setCurrentTab(val.paneName);
            if (c.meta.link) {
              store.commit("menu/setLink", true);
              if (c.meta.link.startsWith("http")) {
                // 以及菜单下只有一个菜单且外联是以http开头--暂时处理
                window.open(c.meta.link);
              } else {
                router.push(c.meta.link);
              }
              store.dispatch("menu/setActive", linkActiveHandle(c));
            } else {
              router.push({
                path: c.path
              });
            }

            //   menuActiveHandle(val.paneName);
            // console.log(99999, c.path);
            return;
          }
        }
      }
    };
    const findFirstMenu = val => {
      console.log("val", val);
      if (val.children && val.children.length) {
        val.children[0].path = val.path + (val.children[0].path.startsWith("/") ? val.children[0].path : "/" + val.children[0].path);
        return findFirstMenu(val.children[0]);
      } else {
        return val;
      }
    };
    //

    //切换后台管一级菜单
    const adminToggle = val => {
      let path = "";
      //切换到后台管理菜单
      if (val === "admin") {
        path = state.adminData[0].path;
      } else {
        path = state.mainData[0].path;
      }
      tabClick({
        paneName: path
      });
    };
    expose({
      adminToggle
    });
    return (_ctx, _cache) => {
      const _component_el_tab_pane = _resolveComponent("el-tab-pane");
      const _component_el_tabs = _resolveComponent("el-tabs");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_unref(menus).length ? (_openBlock(), _createBlock(_component_el_tabs, {
        key: 0,
        modelValue: state.currentTab,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => state.currentTab = $event),
        onTabClick: tabClick
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.tabData, (item, index) => {
          return _openBlock(), _createBlock(_component_el_tab_pane, {
            key: `tab-${index}`,
            label: item.label,
            name: item.path
          }, null, 8, ["label", "name"]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])) : _createCommentVNode("", true)]);
    };
  }
};