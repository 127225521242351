import { listLeave, getLeave, delLeave, addLeave, updateLeave, exportLeave } from '@/api/workflow/leave';
import { getDefinitionsByInstanceId } from '@/api/activiti/definition';
import { parseTime } from "@/utils/ruoyi";
import { ElMessage } from 'element-plus';
import leaveHistoryForm from "./leaveHistoryForm";
import { deepClone } from "@/utils";
export default {
  name: '',
  components: {
    leaveHistoryForm
  },
  data() {
    return {
      modelVisible: false,
      modelerUrl: '',
      userName: '',
      createName: '',
      businessKey: '',
      //用户信息
      user: {},
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      tableData: {
        total: 0,
        rows: []
      },
      // 弹出层标题
      title: '',
      open2: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        confirmTelesignallName: '',
        status: ''
      },
      cloneQueryParams: null,
      // 表单校验
      rules: {
        type: [{
          required: true,
          message: '请假类型不能为空',
          trigger: 'change'
        }],
        title: [{
          required: true,
          message: '标题不能为空',
          trigger: 'blur'
        }],
        reason: [{
          required: true,
          message: '原因不能为空',
          trigger: 'blur'
        }],
        leaveStartTime: [{
          required: true,
          message: '开始时间不能为空',
          trigger: 'blur'
        }],
        leaveEndTime: [{
          required: true,
          message: '结束时间不能为空',
          trigger: 'blur'
        }]
      },
      searchFormData: [{
        prop: "confirmTelesignallName",
        label: "遥信名称",
        placeholder: "请输入遥信名称",
        type: "text",
        isSelect: true,
        span: 5
      }, {
        prop: "status",
        label: "状态",
        placeholder: "请选择状态",
        optionsUrl: "/sys-server/system/dict/data/dictType/process_status",
        type: "list",
        isSelect: true,
        span: 5
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => this.handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => this.resetQuery()
      }],
      buttonsList: [{
        buttonName: "审批详情",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-delete",
        exp: () => this.showDetails()
      }, {
        buttonName: "查看进度",
        buttonStyle: "success",
        className: 'addBtn',
        buttonIcon: "el-icon-download",
        exp: () => this.showProcess()
      }],
      showTableHeader: [],
      tableHeader: [{
        prop: "lineName",
        label: "线路名称",
        fixed: false,
        type: "text"
      }, {
        prop: "confirmTelesignallName",
        label: "遥信名称",
        fixed: false,
        type: "text"
      }, {
        prop: "operate",
        label: "执行操作",
        fixed: false,
        type: "text"
      }, {
        prop: "status_",
        label: "状态",
        fixed: false,
        type: "text"
      }],
      multipleSelection: []
    };
  },
  created() {
    this.cloneQueryParams = JSON.parse(JSON.stringify(this.queryParams));
    this.showTableHeader = deepClone(this.tableHeader);
  },
  methods: {
    parseTimeFilter(val) {
      return parseTime(val, '{y}-{m}-{d}');
    },
    /** 查询请假列表 */
    getList() {
      this.loading = true;
      listLeave(this.queryParams).then(response => {
        for (let item of response.data.rows) {
          if (item.isOff === '2') {
            if (item.execAction === '1') {
              item['operate'] = '遥信-分';
            } else {
              item['operate'] = '遥信-合';
            }
          } else {
            if (response.execAction === '1') {
              item['operate'] = '投入';
            } else {
              item['operate'] = '退出';
            }
          }
        }
        this.tableData.rows = response.data.rows || [];
        this.tableData.total = response.data.total || 0;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParams = JSON.parse(JSON.stringify(this.cloneQueryParams));
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id);
      this.single = selection.length !== 1;
      this.multiple = !selection.length;
    },
    showDetails() {
      if (this.multipleSelection?.length !== 1) {
        ElMessage.closeAll();
        ElMessage.warning("最多或最少选择一条数据进行操作!");
        return;
      }
      this.historyFory(this.multipleSelection[0]);
    },
    /** 审批详情 */
    historyFory(row) {
      this.businessKey = row.id;
      this.open2 = true;
      this.title = '审批详情';
    },
    showProcess() {
      if (this.multipleSelection?.length !== 1) {
        ElMessage.closeAll();
        ElMessage.warning("最多或最少选择一条数据进行操作!");
        return;
      }
      if (this.multipleSelection[0].status != 0) {
        ElMessage.closeAll();
        ElMessage.warning("当前申请流程已审批完成，不支持查看进度!");
        return;
      }
      this.checkTheSchedule(this.multipleSelection[0]);
    },
    /** 进度查看 */
    checkTheSchedule(row) {
      getDefinitionsByInstanceId(row.instanceId).then(response => {
        let data = response.data;
        // this.url = '/bpmnjs/index.html?type=lookBpmn&deploymentFileUUID='+data.deploymentID+'&deploymentName='+ encodeURI(data.resourceName);
        this.modelerUrl = '/bpmnjs/index.html?type=lookBpmn&instanceId=' + row.instanceId + '&deploymentFileUUID=' + data.deploymentID + '&deploymentName=' + encodeURI(data.resourceName);
        this.modelVisible = true;
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      const ids = row.id || this.ids;
      this.$confirm('是否确认删除请假编号为"' + ids + '"的数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return delLeave(ids);
      }).then(() => {
        this.getList();
        ElMessage.closeAll();
        ElMessage.success("删除成功");
      });
    },
    /** 导出按钮操作 */
    handleExport() {
      const queryParams = this.queryParams;
      this.$confirm('是否确认导出所有请假数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        return exportLeave(queryParams);
      }).then(response => {
        this.download(response.msg);
      });
    }
  }
};