import SockJS from "sockjs-client";
import Stomp from "stompjs";
// 引入SockJS及Stomp并挂载到window上
async function initSocket() {
  try {
    await createSocketStomp();
    await createSocketClient();
    return new Promise((resolve, reject) => {
      resolve([window.SockJS, window.Stomp]);
    });
  } catch (err) {
    console.error(err);
  }
}
// 建立socket连接
export async function socketInstance({
  errorCallBack = () => {},
  sysPrefix,
  devurl,
  subscribeSocket = () => {},
  ...params
}) {
  // await initSocket();
  const hostName = devurl;
  const socket = new SockJS(`${hostName}/${sysPrefix}`);
  const stompClient = Stomp.over(socket);
  stompClient.debug = null;
  // stompClient.heartbeat.outgoing = 50000;
  return new Promise((resolve, reject) => {
    // debugger
    stompClient.connect(
      {
        ...params
      },
      () => {
        //成功
        subscribeSocket(stompClient);
        resolve(stompClient);
      },
      e => {
        errorCallBack();
        // reject("连接失败！");
        reject();
      }
    );
  });
}
