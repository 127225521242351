import { computed, defineComponent, ref, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import Action from "./components/action.vue";
import oneLevelMenu from "../../components/oneLevelMenu/index.vue";
import TopBar from '../../components/TopBar/index.vue';
export default defineComponent({
  components: {
    oneLevelMenu,
    Action,
    TopBar
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const collapse = computed({
      get: () => store.state.menu.collapse,
      set: val => store.dispatch("menu/setCollapse", val)
    });
    const currentType = computed(() => {
      return store.state.menu.currentType;
    });
    const isGLHome = computed(() => {
      //管廊项目的首页、告警首页、设备运行页面不需要展示顶部和左侧导航
      if (route.path.includes("manhole/home") || route.path.includes("manhole/alarmHome") || route.path.includes("manhole/deviceRunHome")) {
        return true;
      } else {
        return false;
      }
    });
    /**
     * @description: 菜单栏折叠事件
     * @param {*}
     * @return {*}
     * @author: gumingchen
     */

    const collapseHandle = () => {
      collapse.value = !collapse.value;
    };
    const menuRef = ref();
    const topBarRef = ref();
    let adminToggle = ref(null);

    //横向菜单
    const menuPositionSet = computed(() => {
      return store.state.common.menuPositionSet;
    });
    console.log('menuPositionSet', menuPositionSet.value);
    onMounted(() => {
      if (menuPositionSet.value === '0') {
        console.log('menuRef', menuRef);
        adminToggle.value = menuRef.value.adminToggle;
      } else {
        console.log('topBarRef', topBarRef.value.adminToggle);
        adminToggle.value = topBarRef.value.adminToggle;
      }

      // console.log("collapse", adminToggle);
    });
    let isDark = ref(true);
    watch(() => store.state.theme.mode, (newValue, oldValue) => {
      if (newValue == "dark") {
        isDark.value = true;
      } else {
        isDark.value = false;
      }
    });
    return {
      collapse,
      collapseHandle,
      menuRef,
      topBarRef,
      adminToggle,
      isDark,
      currentType,
      isGLHome,
      menuPositionSet
    };
  }
});