import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, mergeProps as _mergeProps } from "vue";
const _hoisted_1 = {
  class: "form-table form-table-add"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 2
};
const _hoisted_5 = {
  key: 0,
  class: "table-page"
};
const _hoisted_6 = {
  key: 1,
  class: "table-btn"
};
import FormItem from './formItem.vue';
import { ref, reactive, inject, computed, nextTick, watch, onMounted } from 'vue';
import Tooltip from '../../components/tooltip.vue';
import { constFormProps } from '../../utils';
import { jsonParseStringify } from '@/utils';
import { useRoute } from 'vue-router';
import { getRequest } from '@/api/designForm';
export default {
  __name: 'childTable',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    requestUrl: {
      type: String // 请求的api
    },
    name: {
      type: String
    }
  },
  setup(__props) {
    const props = __props;
    const route = useRoute();
    const state = reactive({
      pageNum: 1,
      pageSize: 10,
      total: 0
    });
    const handleSizeChange = page => {
      state.pageSize = page;
      state.pageNum = 1;
      getData();
    };
    const handleCurrentChange = page => {
      state.pageNum = page;
      getData();
    };
    const formProps = inject(constFormProps);

    //const tableDataNew = toRef(props.data, 'tableData')
    //const tableDataNew = toRef(formProps.value.model, props.data.name)
    const tableDataNew = computed(() => {
      return formProps.value.model[props.data.name];
    });
    watch(() => {
      return formProps;
    }, v => {
      console.log(555, v);
    }, {
      deep: true
    });
    console.log('list', props.data.list);
    const subHandle = inject('subHandle');
    const getData = () => {
      const newParams = {
        pageNum: state.pageNum,
        pageSize: state.pageSize
      };
      if (props.requestUrl) {
        // 同时可使用props或是events里的事件，根据使用使用其中一种即可
        let newParams2;
        const beforeRequest = props.data.events?.beforeRequest;
        if (typeof beforeRequest === 'function') {
          newParams2 = beforeRequest(newParams, route);
        }
        if (typeof props.beforeRequest === 'function') {
          newParams2 = props.beforeRequest(newParams, route);
        }
        if (newParams2 === false) {
          // 停止数据请求
          return;
        }
        getRequest(props.requestUrl, newParams2).then(res => {
          let data = [];
          const afterResponse = props.data.events?.afterResponse;
          //  console.log('res', res)
          if (afterResponse) {
            data = afterResponse(res);
            state.total = res.total;
          } else {
            data = res?.data?.rows || [];
            state.total = res?.data?.total;
          }
          subHandle('table', props.name, data);
        }).catch(() => {
          let data = [{
            line_name: '111',
            line_code: '111'
          }, {
            line_name: '222',
            line_code: '222'
          }];
          subHandle('table', props.name, data);
        });
      }
    };
    getData();
    const type = computed(() => {
      return formProps.value.type;
    });
    // 如果编辑页禁用时，则返回true
    const editDisabled = computed(() => {
      return formProps.value.type === 2 && props.data.config?.editDisabled;
    });
    const addColumn = () => {
      const temp = {};
      if (props.data.list) {
        props.data.list.forEach(item => {
          if (item.name) {
            temp[item.name] = item.control.modelValue;
          }
        });
        tableDataNew.value.push(jsonParseStringify(temp));
      }
    };
    const getText = text => {
      if (typeof text === 'string') {
        return text;
      } else {
        return text && text.toString();
      }
    };
    const delColumn = index => {
      tableDataNew.value.splice(index, 1);
    };
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_pagination = _resolveComponent("el-pagination");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_table, _mergeProps(__props.data.control, {
        class: [__props.data.className],
        data: _unref(tableDataNew)
      }), {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.data.list, (item, index) => {
          return _openBlock(), _createBlock(_component_el_table_column, {
            key: index,
            prop: item.name,
            label: item.item.label,
            width: item.item.span
          }, _createSlots({
            default: _withCtx(scope => [item.type === 'index' ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(scope.$index + 1), 1)) : _createCommentVNode("", true), _unref(type) === 4 || _unref(editDisabled) ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(getText(scope.row[item.name])), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_4, [_createVNode(FormItem, {
              modelValue: scope.row[item.name],
              "onUpdate:modelValue": $event => scope.row[item.name] = $event,
              tProp: `${__props.data.name}.${scope.$index}.${item.name}`,
              data: item
            }, null, 8, ["modelValue", "onUpdate:modelValue", "tProp", "data"])]))]),
            _: 2
          }, [item.help ? {
            name: "header",
            fn: _withCtx(scope => [_createTextVNode(_toDisplayString(scope.column.label) + " ", 1), _createVNode(Tooltip, {
              content: item.help
            }, null, 8, ["content"])]),
            key: "0"
          } : undefined]), 1032, ["prop", "label", "width"]);
        }), 128)), _unref(type) === 1 && __props.data.config.delBtnText && !_unref(editDisabled) ? (_openBlock(), _createBlock(_component_el_table_column, {
          key: 0,
          prop: "del",
          label: "操作"
        }, {
          default: _withCtx(scope => [_createVNode(_component_el_button, {
            link: "",
            type: "primary",
            onClick: $event => delColumn(scope.$index)
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(__props.data.config.delBtnText), 1)]),
            _: 2
          }, 1032, ["onClick"])]),
          _: 1
        })) : _createCommentVNode("", true)]),
        _: 1
      }, 16, ["class", "data"]), _unref(type) === 4 ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_el_pagination, {
        background: "",
        pageNum: state.pageNum,
        "onUpdate:pageNum": _cache[0] || (_cache[0] = $event => state.pageNum = $event),
        disabled: false,
        "page-sizes": [10, 20, 30, 40, 50],
        "page-size": state.pageSize,
        "onUpdate:pageSize": _cache[1] || (_cache[1] = $event => state.pageSize = $event),
        layout: "total, sizes, prev, pager, next, jumper",
        total: state.total,
        onSizeChange: handleSizeChange,
        onCurrentChange: handleCurrentChange
      }, null, 8, ["pageNum", "page-size", "total"])])) : _createCommentVNode("", true), _unref(type) === 1 && __props.data.config.addBtnText && !_unref(editDisabled) ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_el_button, {
        size: "small",
        onClick: addColumn
      }, {
        default: _withCtx(() => [_createTextVNode(_toDisplayString(__props.data.config.addBtnText), 1)]),
        _: 1
      })])) : _createCommentVNode("", true)]);
    };
  }
};