import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-25ce24d8"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "top-container searchTop"
};
const _hoisted_3 = {
  class: "search-left"
};
const _hoisted_4 = {
  key: 0,
  class: "button-container"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
const _hoisted_6 = {
  class: "dialog-footer"
};
import { listRole, getRole, delRole, addRole, updateRole, exportRole, dataScope, changeRoleStatus } from "@/api/system/role";
import { treeselect as menuTreeselect, roleMenuTreeselect } from "@/api/system/menu";
import { treeselect as deptTreeselect, roleDeptTreeselect } from "@/api/system/dept";
import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { ElMessageBox, ElMessage } from 'element-plus';
import { getCurrentInstance } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const form = ref(null);
    const scopeForm = ref(null);
    // 状态数据字典
    const statusOptions = ref([]);
    // 数据范围选项
    const dataScopeOptions = ref([{
      value: "1",
      label: "全部数据权限"
    }, {
      value: "2",
      label: "自定数据权限"
    }, {
      value: "3",
      label: "本部门数据权限"
    }, {
      value: "4",
      label: "本部门及以下数据权限"
    }]);
    const defaultProps = ref({
      children: "children",
      label: "label"
    });
    // 菜单列表
    const menuOptions = ref([]);
    // 部门列表
    const deptOptions = ref([]);
    const state = reactive({
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 角色表格数据
      roleList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 是否显示弹出层（数据权限）
      openDataScope: false,
      // 日期范围
      dateRange: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        roleName: undefined,
        roleKey: undefined,
        status: undefined,
        menuType: '0'
      },
      // 表单参数
      form: {
        menuType: '0'
      },
      // 表单校验
      rules: {
        roleName: [{
          required: true,
          message: "角色名称不能为空",
          trigger: "blur"
        }],
        roleKey: [{
          required: true,
          message: "权限字符不能为空",
          trigger: "blur"
        }],
        roleSort: [{
          required: true,
          message: "角色顺序不能为空",
          trigger: "blur"
        }]
      },
      saveLoading: false,
      searchFormData: [{
        prop: "roleName",
        label: "角色名称",
        placeholder: "请输入角色名称查询",
        type: "text",
        isSelect: false,
        span: 4
      }, {
        prop: "roleKey",
        label: "权限字符",
        placeholder: "请输入权限字符查询",
        type: "text",
        isSelect: false,
        span: 4
      }, {
        prop: "status",
        label: "角色状态",
        placeholder: "请选择角色状态",
        type: "list",
        options: statusOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        isSelect: true,
        span: 4
      }, {
        prop: "dateRange",
        label: "日期",
        placeholder: "请选择日期",
        type: "daterange",
        change: val => {
          if (!val) {
            state.queryParams.beginTime = "";
            state.queryParams.endTime = "";
          } else {
            state.queryParams.beginTime = val[0];
            state.queryParams.endTime = val[1];
          }
        },
        isSelect: false,
        span: 4
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      //列表顶部顶部操作按钮数据
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ['system:role:add'],
        className: 'addBtn',
        buttonIcon: "Plus",
        exp: item => handleAdd(item)
      }, {
        buttonName: "修改",
        buttonStyle: "primary",
        hasPermi: ['system:role:edit'],
        className: 'editBtn',
        buttonIcon: "Edit",
        exp: item => handleUpdate(item),
        disabledHandle() {
          return state.single;
        }
      }, {
        buttonName: "删除",
        buttonStyle: "danger",
        hasPermi: ['system:role:remove'],
        className: 'deleteBtn',
        buttonIcon: "Delete",
        exp: item => handleDelete(item),
        disabledHandle(row) {
          return state.multiple;
        }
      }, {
        buttonName: "导出",
        buttonStyle: "success",
        hasPermi: ['system:post:export'],
        className: 'exportBtn',
        buttonIcon: "Upload",
        exp: item => handleExport(item)
      }],
      tableHeader: [{
        prop: "roleId",
        label: "角色编号"
      }, {
        prop: "roleName",
        label: "角色名称"
      }, {
        prop: "roleKey",
        label: "权限字符"
      }, {
        prop: "roleSort",
        label: "显示顺序"
      }, {
        prop: "status",
        label: "状态",
        fixed: false,
        type: "switch",
        switch: {
          changeFun: row => {
            handleStatusChange(row);
          },
          active: 0,
          inActive: 1
        }
      }, {
        prop: "createTime",
        label: "创建时间"
      }, {
        prop: "operation",
        label: "操作",
        width: "270px",
        fixed: "right",
        operation: [{
          name: '修改',
          clickFun: row => handleUpdate(row),
          hasPermi: ['system:role:edit'],
          disabledHandle(row) {
            return row.roleKey === 'admin';
          }
        }, {
          name: '数据权限',
          clickFun: row => handleDataScope(row),
          hasPermi: ['system:role:jurisdiction'],
          disabledHandle(row) {
            return row.roleKey === 'admin';
          }
        }, {
          name: '删除',
          clickFun: row => handleDelete(row),
          hasPermi: ['system:role:remove'],
          disabledHandle(row) {
            return row.roleKey === 'admin';
          }
        }]
      }],
      formData: [{
        prop: "roleName",
        label: "角色名称",
        placeholder: "请输入角色名称",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "roleKey",
        label: "权限字符",
        placeholder: "请输入权限字符",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "roleSort",
        label: "角色顺序",
        placeholder: "请输入角色顺序",
        type: "textNum",
        isSelect: false,
        span: 12
      }, {
        prop: "status",
        label: "状态",
        type: "radio",
        options: statusOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        span: 12,
        change: row => {}
      }, {
        prop: "menuType",
        label: "菜单类型",
        type: "radio",
        options: [{
          label: '纵向菜单',
          value: '0'
        }, {
          label: '横向菜单',
          value: '1'
        }],
        props: {
          label: 'label',
          value: 'value'
        },
        span: 12,
        change: () => {}
      }, {
        prop: "",
        label: "菜单权限",
        placeholder: "请选择菜单权限",
        type: "elTree",
        props: defaultProps,
        isSelect: false,
        options: menuOptions,
        span: 24
      }, {
        prop: "remark",
        label: "备注",
        placeholder: "请输入内容",
        type: "textarea",
        isSelect: false,
        span: 24
      }],
      formScopeData: [{
        prop: "roleName",
        label: "角色名称",
        placeholder: "请输入角色名称",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "roleKey",
        label: "权限字符",
        placeholder: "请输入权限字符",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "dataScope",
        label: "查询权限",
        placeholder: "请选择查询权限",
        type: "list",
        options: dataScopeOptions,
        props: {
          label: "label",
          value: "value"
        },
        isSelect: false,
        span: 12
      }, {
        prop: "",
        label: "数据权限",
        placeholder: "请选择数据权限",
        type: "elTree",
        props: defaultProps,
        isSelect: false,
        options: deptOptions,
        span: 12,
        hideHandle(row) {
          return state.form.dataScope != '2';
        }
      }, {
        prop: "operDataScope",
        label: "数据操作权限",
        placeholder: "请选择数据操作权限",
        type: "list",
        options: [{
          label: "个人",
          value: '1'
        }, {
          label: "部门",
          value: '2'
        }],
        props: {
          label: "label",
          value: "value"
        },
        isSelect: false,
        span: 12
      }]
    });
    onMounted(() => {
      proxy.getDicts("sys_normal_disable").then(response => {
        statusOptions.value = response.data;
      });
    });
    /** 查询角色列表 */
    const getList = function () {
      state.loading = true;
      if (state.queryParams.dateRange) {
        delete state.queryParams.dateRange;
      }
      listRole(state.queryParams).then(response => {
        state.roleList = response;
        state.loading = false;
      });
    };
    /** 查询菜单树结构 */
    const getMenuTreeselect = function () {
      menuTreeselect().then(response => {
        menuOptions.value = response.data;
      });
    };
    // 所有菜单节点数据
    const getMenuAllCheckedKeys = function () {
      console.log(form.value);
      // 目前被选中的菜单节点
      let checkedKeys = form.value.menu[0].getHalfCheckedKeys();
      // 半选中的菜单节点
      let halfCheckedKeys = form.value.menu[0].getCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    };
    // 所有部门节点数据
    const getDeptAllCheckedKeys = function () {
      // 目前被选中的部门节点
      let checkedKeys = scopeForm.value.menu[0].getHalfCheckedKeys();
      // 半选中的部门节点
      let halfCheckedKeys = scopeForm.value.menu[0].getCheckedKeys();
      checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
      return checkedKeys;
    };
    /** 根据角色ID查询菜单树结构 */
    const getRoleMenuTreeselect = function (roleId) {
      roleMenuTreeselect(roleId).then(response => {
        menuOptions.value = response.menus;
        form.value.menu[0].setCheckedKeys(response.checkedKeys);
      });
    };
    /** 根据角色ID查询部门树结构 */
    const getRoleDeptTreeselect = function (roleId) {
      roleDeptTreeselect(roleId).then(response => {
        deptOptions.value = response.depts;
        scopeForm.value.menu[0].setCheckedKeys(response.checkedKeys);
      });
    };
    // 角色状态修改
    const handleStatusChange = function (row) {
      let text = row.status === "0" ? "启用" : "停用";
      ElMessageBox.close();
      ElMessageBox.confirm('确认要"' + text + '""' + row.roleName + '"角色吗?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return changeRoleStatus(row.roleId, row.status);
      }).then(res => {
        ElMessage.success(text + "成功");
      }).catch(function () {
        row.status = row.status === "0" ? "1" : "0";
      });
    };
    // 取消按钮
    const cancel = function () {
      state.open = false;
      // reset();
    };
    // 取消按钮（数据权限）
    const cancelDataScope = function () {
      state.openDataScope = false;
      // reset();
    };
    // 表单重置
    const reset = function () {
      // if (form.value.menu[0] != undefined) {
      //      form.value.menu[0].setCheckedKeys([]);
      //     }
      state.form = {
        roleId: undefined,
        roleName: undefined,
        roleKey: undefined,
        roleSort: 0,
        status: "0",
        menuType: '0',
        menuIds: [],
        deptIds: [],
        remark: undefined
      };
      // resetForm("form");
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      state.queryParams.pageNum = 1;
      getList();
    };
    /** 重置按钮操作 */
    const resetQuery = function () {
      state.dateRange = [];
      // state.resetForm("queryForm");
      //bug 8037  U-功能测试-系统中心-所有查询输入框重置功能无效 mosen 2021/9/1
      state.queryParams = {
        pageNum: 1,
        pageSize: 10,
        roleName: undefined,
        roleKey: undefined,
        status: '',
        menuType: ''
      };
      handleQuery();
    };
    // 多选框选中数据
    const handleSelectionChange = function (selection) {
      state.ids = selection.map(item => item.roleId);
      state.single = selection.length != 1;
      state.multiple = !selection.length;
    };
    /** 新增按钮操作 */
    const handleAdd = function () {
      state.open = true;
      state.title = "添加角色";
      reset();
      getMenuTreeselect();
    };
    /** 修改按钮操作 */
    const handleUpdate = function (row) {
      state.open = true;
      state.title = "修改角色";
      reset();
      const roleId = row.roleId || state.ids;
      nextTick(() => {
        getRoleMenuTreeselect(roleId);
      });
      getRole(roleId).then(response => {
        state.form = response.data;
        ;
      });
    };
    /** 分配数据权限操作 */
    const handleDataScope = function (row) {
      state.openDataScope = true;
      state.title = "分配数据权限";
      reset();
      nextTick(() => {
        getRoleDeptTreeselect(row.roleId);
      });
      getRole(row.roleId).then(response => {
        state.form = response.data;
      });
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(form);
      formRef.commonForm.validate(valid => {
        if (valid) {
          state.saveLoading = true;
          if (state.form.roleId != undefined) {
            state.form.menuIds = getMenuAllCheckedKeys();
            updateRole(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("修改成功");
                state.open = false;
                getList();
              } else {
                ElMessage.error(response.msg);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          } else {
            state.form.menuIds = getMenuAllCheckedKeys();
            addRole(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("新增成功");
                state.open = false;
                //新增后重置
                resetQuery();
              } else {
                ElMessage.error(response.msg);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          }
        }
      });
    };
    /** 提交按钮（数据权限） */
    const submitDataScope = function () {
      if (state.form.roleId != undefined) {
        state.form.deptIds = getDeptAllCheckedKeys();
        dataScope(state.form).then(response => {
          if (response.code === 1000) {
            ElMessage.success("修改成功");
            state.openDataScope = false;
            getList();
          } else {
            ElMessage.error(response.msg);
          }
        });
      }
    };
    /** 删除按钮操作 */
    const handleDelete = function (row) {
      const roleIds = row.roleId || state.ids;
      ElMessageBox.close();
      ElMessageBox.confirm('是否确认删除数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delRole(roleIds);
      }).then(res => {
        getList();
        ElMessage.success("删除成功");
      }).catch(function () {});
    };
    /** 导出按钮操作 */
    const handleExport = function () {
      const queryParams = state.queryParams;
      ElMessageBox.confirm('是否确认导出所有角色数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportRole(queryParams);
      }).then(res => {
        proxy.ruoyi.download(response.message);
      }).catch(function () {});
    };
    return (_ctx, _cache) => {
      const _component_commonForm = _resolveComponent("commonForm");
      const _component_el_button = _resolveComponent("el-button");
      const _component_commonTable = _resolveComponent("commonTable");
      const _component_commonDialog = _resolveComponent("commonDialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_commonForm, {
        form: state.queryParams,
        formData: state.searchFormData,
        formHandle: state.formHandle
      }, null, 8, ["form", "formData", "formHandle"])]), state.buttonsList.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.buttonsList, (item, index) => {
        return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          key: index,
          class: _normalizeClass([item.className, item.buttonIcon]),
          icon: item.buttonIcon,
          loading: item.loading,
          type: item.buttonStyle,
          disabled: item.disabledHandle?.(item.prop, form.value),
          onClick: $event => item.exp(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.buttonName), 1)]),
          _: 2
        }, 1032, ["class", "icon", "loading", "type", "disabled", "onClick"])), [[_directive_hasPermi, item.hasPermi ? item.hasPermi : []]]);
      }), 128))])) : _createCommentVNode("", true)]), _withDirectives(_createVNode(_component_commonTable, {
        class: "flex-table",
        tableData: state.roleList,
        tableHeader: state.tableHeader,
        queryParams: state.queryParams,
        multipleSelection: state.ids,
        "onUpdate:multipleSelection": _cache[0] || (_cache[0] = $event => state.ids = $event),
        onGetList: getList,
        hasSelect: true,
        columnSetting: false,
        onSelectionChange: handleSelectionChange
      }, null, 8, ["tableData", "tableHeader", "queryParams", "multipleSelection"]), [[_directive_loading, state.loading]]), _createVNode(_component_commonDialog, {
        modelValue: state.open,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => state.open = $event),
        width: "600px",
        title: state.title,
        "close-on-click-modal": false,
        onClose: cancel
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm,
          loading: _ctx.saveLoading
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }, 8, ["loading"]), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_commonForm, {
          ref_key: "form",
          ref: form,
          form: state.form,
          formRules: state.rules,
          formData: state.formData,
          isHandle: false
        }, null, 8, ["form", "formRules", "formData"])]),
        _: 1
      }, 8, ["modelValue", "title"]), _createVNode(_component_commonDialog, {
        modelValue: state.openDataScope,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => state.openDataScope = $event),
        width: "600px",
        title: state.title,
        "close-on-click-modal": false,
        onClose: cancelDataScope
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitDataScope
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancelDataScope
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_commonForm, {
          ref_key: "scopeForm",
          ref: scopeForm,
          form: state.form,
          formData: state.formScopeData,
          isHandle: false
        }, null, 8, ["form", "formData"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};