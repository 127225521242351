import { datasetAdd, getDatasetDetail, getAnnotation, datasetEdit, delAnnotation } from "@/api/AI/index";
import DataSetUpload from "./DataSetUpload.vue";
export default {
  name: "AddDataSet",
  components: {
    DataSetUpload
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    editId: {
      // 编辑
      type: [String, Number],
      default: ""
    }
  },
  data() {
    return {
      temObj: {},
      //标签修改时 临时对象
      tagEdit: false,
      //标签修改
      tagIndex: "",
      //标签修改位置
      id: "",
      //添加时id
      addLabel: false,
      remote_file: "",
      //远程上传文件
      labelForm: {
        name: "",
        color: "",
        attributes: [
          //   {
          //     name:'',
          //     input_type:'',
          //     default_value:'',
          //     values:[]
          // }
        ]
      },
      form: {
        name: "",
        type: "",
        labels: []
      },
      rules: {
        name: [{
          required: true,
          message: "必填",
          tigger: "blur"
        }],
        type: [{
          required: true,
          message: "必填",
          tigger: "change"
        }]
      },
      //编辑逻辑 是否标注
      annotated: "未标注",
      //预览 标注未标注form
      annotationForm: {
        type: "preview",
        annotated: true,
        page_num: 1,
        pageSize: 8
      },
      editDialogShow: false,
      //编辑文件上传弹窗
      annotationList: [],
      //标注 未标注图片
      total: 0,
      //图片总数
      isDelete: true
    };
  },
  computed: {
    isAdd() {
      if (this.editId) {
        return false; //编辑
      } else {
        return true; //添加
      }
    },
    title() {
      return this.isAdd ? "新增数据集" : "编辑数据集";
    }
  },
  watch: {
    editId: {
      handler(v) {
        if (v) {
          this.getDatasetDetail(v);
          this.getAnnotation();
        }
      },
      immediate: true
    }
  },
  methods: {
    //标签编辑
    tagEditHandle(item, index) {
      let labelForm = JSON.parse(JSON.stringify(item));
      console.log(labelForm);
      labelForm.attributes.forEach(item => {
        item.values = item.values.join("\n");
      });
      this.labelForm = labelForm;
      this.addLabel = true;

      //标识正在修改标签
      this.tagEdit = true;
      this.tagIndex = index;
    },
    //添加标签属性
    addLabelAttr() {
      this.labelForm.attributes.push({
        name: "",
        input_type: "",
        default_value: "",
        values: ""
      });
    },
    //添加标签确定
    addLabelHandle() {
      this.$refs.labelForm.validate(validate => {
        if (validate) {
          if (this.labelForm.attributes.length) {
            let attributes = this.labelForm.attributes.map(item => {
              let values = item.values.split("\n");
              return {
                name: item.name,
                input_type: item.input_type,
                default_value: values.length ? values[0] : "",
                values
              };
            });
            //
            let labelForm = JSON.parse(JSON.stringify(this.labelForm));
            labelForm.attributes = attributes;
            //修改
            if (this.tagEdit) {
              this.form.labels.splice(this.tagIndex, 1, labelForm);
            } else {
              //添加
              this.form.labels.push(labelForm);
            }
          } else {
            //修改
            if (this.tagEdit) {
              this.form.labels.splice(this.tagIndex, 1, this.labelForm);
            } else {
              //添加
              this.form.labels.push(this.labelForm);
            }
          }
          //收起添加标签 并清空数据
          this.cancleAddLabel();
        }
      });
    },
    //取消添加标签
    cancleAddLabel() {
      this.addLabel = false;
      this.tagEdit = false;
      this.labelForm = {
        name: "",
        color: "",
        attributes: []
      };
    },
    // 编辑标签确定

    saveDialog() {
      this.$refs.form.validate(async valid => {
        if (!this.form.labels.length) {
          this.$message.error("标签必填项,不能为空");
          return false;
        }
        if (valid) {
          //正在添加标签
          if (this.addLabel) {
            let on = await this.$refs.labelForm.validate();
            this.$message.warning("请完成标签添加");
            return false;
          }

          //修改上传文件
          if (!this.isAdd) {
            this.editSave();
            return false;
          }

          //保存标签接口
          datasetAdd(this.form).then(res => {
            this.id = res.data.id;
            console.log(res);
          }).catch(err => {}).then(() => {
            this.$refs.dataSetUpload.submit();

            // this.cancelDialog()
          }).then(() => {
            this.$emit("updateData");
            this.$message.success("保存成功");
            this.cancelDialog();
          });
        }
      });
    },
    //编辑上传文件
    editupload() {
      this.$refs.dataSetUpload.submit();
      this.editDialogShow = false;
    },
    editSave() {
      let form = JSON.parse(JSON.stringify(this.form));
      form.labels.forEach(item => {
        delete item.id;
      });
      datasetEdit(this.editId, form).then(res => {
        this.$emit("updateData");
        this.$message.success("修改成功");
        this.cancelDialog();
      });
    },
    //保存更新表格
    updateData() {
      if (this.isAdd) {
        this.$message.success("保存成功");
        this.cancelDialog();
      } else {
        this.$message.success("上传文件成功");
        this.getAnnotation();
      }
    },
    cancelDialog() {
      this.$emit("update:dialogVisible", false);
      this.$refs.form.resetFields();
    },
    //获取详情数据
    getDatasetDetail(id) {
      getDatasetDetail(id).then(res => {
        if (res.status === 200) {
          let {
            name,
            labels,
            type
          } = res.data;
          this.form = {
            name,
            labels,
            type
          };
        }
      });
    },
    //获取标注，未标注 预览
    getAnnotation() {
      getAnnotation(this.editId, this.annotationForm).then(res => {
        if (res.status === 200) {
          res.data.data.forEach(item => {
            item.checked = false;
          });
          this.annotationList = res.data.data;
          this.total = res.data.count;
          //bug 8506 N-功能测试-数据集-文件批量编辑后列表文件数与实际图片数量不一致 mosen 2021/11/23
          // if(res.data.data.length==0&& res.data.count>0){
          //     this.annotationForm.page_num=this.annotationForm.page_num-1;
          //     this.getAnnotation()
          // }
        }
      });
    },
    //标注未标注切换
    radioChange(v) {
      if (v === "未标注") {
        this.annotationForm = {
          type: "preview",
          annotated: false,
          page_num: 1
        };
      } else {
        this.annotationForm = {
          type: "preview",
          annotated: true,
          page_num: 1
        };
      }
      this.getAnnotation();
    },
    handleCurrentChange(val) {
      this.annotationForm.page_num = val;
      this.getAnnotation();
    },
    //删除card 图片
    deleteCard(item) {
      this.$confirm("确定删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delAnnotation(this.editId, {
          ids: [item.id]
        }).then(res => {
          this.$message.success("删除成功");
          this.getAnnotation();
          this.$emit("updateData");
        });
      });
    },
    //批量删除
    deleteBatchCard() {
      this.$confirm("确定删除勾选的数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        let ids = this.annotationList.filter(it => {
          return it.checked;
        }).map(item => {
          return item.id;
        });
        if (!ids.length) {
          this.$message.warning("请先勾选数据");
          return false;
        }
        delAnnotation(this.editId, {
          ids
        }).then(res => {
          this.$message.success("删除成功");
          this.getAnnotation();
          this.$emit("updateData");
        });
      });
    }
  }
};