// export const uploadUrl = "/form-design/";
import onlineRequest from "@/utils/onlineRequest";
import form from "./form";
import system from "./system";
const allApi = Object.assign(form, system);

export const getRequest = (apiKey, data, options = {}, method = "post") => {
  let url = allApi[apiKey] || apiKey;
  // 解决动态url 如/form-design/delete/id(id为动态时)
  // url设置为：/form-design/delete/$id
  // options参数设置：options:{apikey:$id:xx}
  if (Object.keys(options.apiKey || {}).length) {
    for (const key in options.apiKey) {
      url = url.replace(key, options.apiKey[key]);
    }
  }
  let obj = Object.assign(
    {
      url: url, // 添加个前缀
      method,
      data,
    },
    options
  );
  if (method === "get") {
    delete obj.data;
    obj.params = data;
  }
  console.log("obj", obj);

  return onlineRequest(obj);
};
