import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = {
  style: {
    "min-height": "300px"
  }
};
import { ref, reactive, onMounted, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getRequest } from '@/api/designForm';
import { ElMessage } from 'element-plus';
import { string2json, stringToObj } from '@/utils/form';
export default {
  __name: 'form',
  setup(__props) {
    const route = useRoute().query;
    const router = useRouter();
    const formEl = ref();
    const state = reactive({
      formData: {
        list: [],
        form: {},
        config: {}
      },
      dict: {},
      formId: route.form,
      id: route.id,
      loading: true
    });
    const getFormData = () => {
      if (!state.formId) {
        ElMessage.error('非法操作.');
        return false;
      }
      const params = {
        id: state.formId
      };
      getRequest('designById', params).then(res => {
        const result = res.data;
        if (result && Object.keys(result).length) {
          state.formData = stringToObj(result.data);
          state.dict = string2json(result.dict);
          // 编辑时加载表单初始数据。或设置了添加时获取请求
          if (route.id || state.formData.config?.addLoad) {
            formEl.value.getData({
              formId: state.formId,
              id: route.id
            });
          }
        }
        state.loading = false;
      }).catch(res => {
        state.loading = false;
        ElMessage.error(res.message || '非法操作..');
      });
    };
    const beforeSubmit = params => {
      params.formId = state.formId;
      params.id = route.id;
      return params;
    };
    const afterSubmit = type => {
      if (type === 'success') {
        router.go(-1);
      }
    };
    onMounted(() => {
      getFormData();
    });
    return (_ctx, _cache) => {
      const _component_ak_form = _resolveComponent("ak-form");
      const _directive_loading = _resolveDirective("loading");
      return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ak_form, {
        ref_key: "formEl",
        ref: formEl,
        formData: state.formData,
        type: 4,
        dict: state.dict,
        requestUrl: "getFormContent",
        addUrl: "saveFormContent",
        editUrl: "editFormContent",
        beforeSubmit: beforeSubmit,
        afterSubmit: afterSubmit
      }, null, 8, ["formData", "dict"])])), [[_directive_loading, state.loading]]);
    };
  }
};