import Cookies from 'js-cookie'
import axios from 'axios'
import querystring from 'querystring'
const TokenKey = 'Admin-Token'

export function getToken() {
  return Cookies.get(TokenKey)
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}

let title = window.g.VUE_APP_TITLE || '智能配网分析决策系统'
const staticSettting = {
  appId: 'xWIqqaOPHQqJ',
  appSecret: '2a06IArpGsVH9qLH8C1U1',
  authUrl: `${window.g.VUE_APP_AUTH_URL}`,
  // baseUrl: 'http://192.168.100.184:7878',
  baseUrl: `${window.g.VUE_APP_BASE_URL}`,
  grant_type: 'authorization_code',
}
let trustToken = ''
export let getCode = function () {
  let name = 'code'
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) return decodeURI(r[2])
  return null
}
export let accestokenByCode = async function () {
  let trustCode = getCode()

  if (!trustCode) {
    return false
  }

  let res = await axios.post(
    staticSettting.authUrl + '/oauth/token',
    {
      grant_type: 'authorization_code',
      code: trustCode,
      client: staticSettting.appId,
      redirect_uri: staticSettting.baseUrl,
    },
    {
      transformRequest: [
        function (data) {
          return querystring.stringify(data)
        },
      ],
      auth: {
        username: staticSettting.appId,
        password: staticSettting.appSecret,
      },
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    },
  )



  setToken(res.data.access_token)
  trustToken = res.data.access_token

  return trustToken
}

export async function analysisToken() {
  if (getCode()) {
    await accestokenByCode()
  }
}

export let toLogin = () => {
  const loginClass=window.g.VUE_APP_LOGIN_CLASS||''
  if (staticSettting.grant_type === 'authorization_code') {
    window.location.href =
      staticSettting.authUrl +
      '/oauth/authorize?response_type=code&client_id=' +
      staticSettting.appId +
      '&redirect_uri=' +
      staticSettting.baseUrl + '&title=' + title+ '&class=' + loginClass
  } else if (staticSettting.grant_type === 'password') {
    // 密码模式demo
    // let userInfo = prompt('username&password').split('&')
    // Token.accestokenByBasic(userInfo[0], userInfo[1], next)
  }
}
