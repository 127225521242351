import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-823f132e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "app-container"
};
const _hoisted_2 = {
  class: "top-container searchTop"
};
const _hoisted_3 = {
  class: "search-left"
};
const _hoisted_4 = {
  key: 0,
  class: "button-container"
};
const _hoisted_5 = {
  class: "dialog-footer"
};
import { listMenu, getMenu, delMenu, addMenu, updateMenu } from "@/api/system/menu";
import { ref, unref, reactive, toRefs, watch, onMounted, nextTick } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import { getCurrentInstance } from "vue";
export default {
  __name: 'index',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const form = ref(null);
    // 菜单状态数据字典
    const visibleOptions = ref([]);
    // 菜单树选项
    const menuOptions = ref([]);
    // 先new一个map用于数据保存
    const rowMaps = reactive(new Map());
    const state = reactive({
      showTable: true,
      tableDataCopy: [],
      searchFormData: [{
        prop: "menuName",
        label: "菜单名称",
        placeholder: "请输入菜单名称查询",
        type: "text",
        isSelect: false,
        span: 4
      }, {
        prop: "visible",
        label: "菜单状态",
        placeholder: "请选择菜单状态",
        type: "list",
        options: visibleOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        isSelect: true,
        span: 4
      }],
      formHandle: [{
        label: "搜索",
        type: "primary",
        icon: "el-icon-search",
        handle: () => handleQuery()
      }, {
        label: "重置",
        icon: "el-icon-refresh",
        class: "commonDarkBtn",
        handle: () => resetQuery()
      }],
      buttonsList: [{
        buttonName: "新增",
        buttonStyle: "primary",
        hasPermi: ["system:menu:add"],
        className: "addBtn",
        buttonIcon: "Plus",
        exp: item => handleAdd(item)
      }],
      tableHeader: [{
        prop: "menuName",
        label: "菜单名称"
      }, {
        prop: "icon",
        label: "图标",
        icon: "icon"
      }, {
        prop: "treeType",
        label: "类型",
        statusList: [{
          value: true,
          label: "设备树"
        }, {
          value: false,
          label: "菜单树"
        }]
      }, {
        prop: "orderNum",
        label: "排序"
      }, {
        prop: "perms",
        label: "权限标识"
      }, {
        prop: "component",
        label: "组件路径"
      }, {
        prop: 'activeMenu',
        label: '父级选中菜单'
      }, {
        prop: 'link',
        label: '外链地址'
      }, {
        prop: "visible",
        label: "可见",
        statusList: [{
          value: "1",
          label: "隐藏"
        }, {
          value: "0",
          label: "显示"
        }]
      }, {
        prop: "createTime",
        label: "创建时间"
      }, {
        prop: "operation",
        label: "操作",
        width: "270px",
        fixed: "right",
        operation: [{
          name: "修改",
          clickFun: row => handleUpdate(row),
          hasPermi: ["system:menu:edit"]
        }, {
          name: "新增",
          clickFun: row => handleAdd(row),
          hasPermi: ["system:menu:add"]
        }, {
          name: "删除",
          clickFun: row => handleDelete(row),
          hasPermi: ["system:menu:remove"]
        }]
      }],
      formData: [{
        prop: "parentId",
        label: "上级菜单",
        placeholder: "请选择上级菜单",
        type: "tree",
        options: menuOptions,
        props: {
          label: "menuName",
          value: "menuId",
          children: "children"
        },
        isSelect: false,
        span: 24
      }, {
        prop: "treeType",
        label: "类型",
        type: "radio",
        isSelect: false,
        options: [{
          label: "菜单树",
          value: false
        }, {
          label: "设备树",
          value: true
        }],
        props: {
          label: "label",
          value: "value"
        },
        change: () => {},
        span: 24
      }, {
        prop: "menuType",
        label: "菜单类型",
        type: "radio",
        isSelect: false,
        options: [{
          label: "目录",
          value: "M"
        }, {
          label: "菜单",
          value: "C"
        }, {
          label: "按钮",
          value: "F"
        }, {
          label: "详情",
          value: "D"
        }],
        props: {
          label: "label",
          value: "value"
        },
        change: () => {},
        span: 12
      }, {
        prop: "iconName",
        label: "菜单图标",
        placeholder: "请输入菜单图标",
        type: "iconSelect",
        selected: item => selected(item),
        isSelect: false,
        span: 12,
        width: "40%"
      }, {
        prop: "menuName",
        label: "菜单名称",
        placeholder: "请输入菜单名称",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "orderNum",
        label: "显示排序",
        placeholder: "请输入显示排序",
        type: "textNum",
        isSelect: false,
        span: 12
      }, {
        prop: "isFrame",
        label: "是否外链",
        type: "radio",
        isSelect: false,
        options: [{
          label: "是",
          value: "1"
        }, {
          label: "否",
          value: "0"
        }],
        props: {
          label: "label",
          value: "value"
        },
        change: () => {},
        hideHandle() {
          return state.form.menuType == "F";
        },
        span: 12
      }, {
        prop: "path",
        label: "路由地址",
        placeholder: "请输入路由地址",
        type: "text",
        isSelect: false,
        hideHandle() {
          return state.form.menuType == "F";
        },
        span: 12
      }, {
        prop: "visible",
        label: "菜单状态",
        type: "radio",
        isSelect: false,
        options: visibleOptions,
        props: {
          label: "dictLabel",
          value: "dictValue"
        },
        change: () => {},
        hideHandle() {
          return state.form.menuType == "F";
        },
        span: 12
      }, {
        prop: "component",
        label: "组件路径",
        placeholder: "请输入组件路径",
        type: "text",
        isSelect: false,
        hideHandle() {
          return state.form.menuType != "C" && state.form.menuType != "D";
        },
        span: 12
      }, {
        prop: "perms",
        label: "权限标识",
        placeholder: "请输入权限标识",
        type: "text",
        isSelect: false,
        hideHandle() {
          return state.form.menuType == "M";
        },
        span: 12
      }, {
        prop: "activeMenu",
        label: "激活父级菜单路径",
        placeholder: "请输入激活父级菜单路径",
        type: "text",
        isSelect: false,
        span: 12
      }, {
        prop: "link",
        label: "外链地址",
        placeholder: "请输入外链地址",
        type: "text",
        isSelect: false,
        hideHandle() {
          return state.form.isFrame != "1";
        },
        span: 12
      }],
      // 遮罩层
      loading: true,
      // 菜单表格树数据
      menuList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        menuName: undefined,
        visible: "0"
      },
      // 表单参数
      form: {
        icon: "",
        iconName: ""
      },
      // 表单校验
      rules: {
        menuName: [{
          required: true,
          message: "菜单名称不能为空",
          trigger: "blur"
        }],
        orderNum: [{
          required: true,
          message: "菜单顺序不能为空",
          trigger: "blur"
        }]
      },
      saveLoading: false,
      currentOpentree: null
    });
    onMounted(() => {
      // getList();
      proxy.getDicts("sys_show_hide").then(response => {
        visibleOptions.value = response.data;
      });
    });
    // 展开
    function load(tree, treeNode, resolve) {
      state.currentOpentree = JSON.parse(JSON.stringify(tree));
      // 层级关系备份
      const idCopy = JSON.parse(JSON.stringify(tree.idList));
      // 查找下一层数据
      let resolveArr = state.tableDataCopy;
      let id;
      // eslint-disable-next-line
      while (id = tree.idList.shift()) {
        const tarItem = resolveArr.find(item => item.menuId === id);
        tarItem.loadedChildren = true;
        resolveArr = tarItem.children;
      }

      // 处理下一层数据的属性
      resolveArr = JSON.parse(JSON.stringify(resolveArr));
      resolveArr.forEach(item => {
        item.hasChildren = item.children && item.children.length > 0;
        item.children = null;
        // 此处需要深拷贝，以防各个item的idList混乱
        item.idList = JSON.parse(JSON.stringify(idCopy));
        item.idList.push(item.menuId);
      });
      // 标识已经加载子节点
      tree.loadedChildren = true;
      // 渲染子节点
      resolve(resolveArr);
      rowMaps.set(tree.menuId, {
        tree: state.currentOpentree,
        treeNode,
        resolve
      });
    }
    // 选择图标
    const selected = function (item) {
      state.form.icon = `iconfont icon-${item.font_class}`;
      state.form.iconName = item.name;
    };
    /** 查询菜单列表 */
    const getList = function () {
      state.loading = true;
      listMenu(state.queryParams).then(response => {
        const treeData = proxy.ruoyi.handleTree(response.data, "menuId");
        state.tableDataCopy = treeData || []; // 备份的全量数据
        const menuListOne = JSON.parse(JSON.stringify(treeData)).map(item => {
          // 展示数据
          // hasChildren 表示需要展示一个箭头图标
          item.hasChildren = item.children && item.children.length > 0;
          // 只展示一层
          // 如果有children数据，会自动加载，就不是懒加载了，也可以配置tree-props里面的children为其他字段
          item.children = null;
          // 记住层级关系
          item.idList = [item.menuId];
          return item;
        });
        state.menuList = {
          rows: menuListOne
        };
        // 获取相关数据并load
        if (state.currentOpentree) {
          const {
            tree,
            treeNode,
            resolve
          } = rowMaps.get(state.currentOpentree.menuId);
          load(tree, treeNode, resolve);
        }
        state.loading = false;
      });
    };
    /** 转换菜单数据结构 */
    const normalizer = function (node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.menuId,
        label: node.menuName,
        children: node.children
      };
    };
    /** 查询菜单下拉树结构 */
    const getTreeselect = function () {
      listMenu({}).then(response => {
        menuOptions.value = [];
        const menu = {
          menuId: 0,
          menuName: "主类目",
          children: []
        };
        menu.children = proxy.ruoyi.handleTree(response.data, "menuId");
        menuOptions.value.push(menu);
      });
    };
    // 菜单显示状态字典翻译
    const visibleFormat = function (row, column) {
      if (row.menuType == "F") {
        return "";
      }
      return proxy.ruoyi.selectDictLabel(visibleOptions.value, row.visible);
    };
    // 取消按钮
    const cancel = function () {
      state.open = false;
      reset();
    };
    // 表单重置
    const reset = function () {
      state.form = {
        menuId: undefined,
        parentId: 0,
        treeType: false,
        menuName: undefined,
        icon: undefined,
        iconName: "",
        menuType: "M",
        orderNum: undefined,
        isFrame: "0",
        visible: "0"
      };
      // state.resetForm("form");
    };
    /** 搜索按钮操作 */
    const handleQuery = function () {
      getList();
    };
    //重置按钮
    const resetQuery = function () {
      state.queryParams = {
        menuName: undefined,
        visible: "0"
      };
      handleQuery();
    };
    /** 新增按钮操作 */
    const handleAdd = function (row) {
      state.open = true;
      state.title = "添加菜单";
      reset();
      state.form.menuId = null;
      getTreeselect();
      if (row != null) {
        state.form.parentId = row.menuId;
      }
    };
    /** 修改按钮操作 */
    const handleUpdate = function (row) {
      state.open = true;
      state.title = "修改菜单";
      getTreeselect();
      getMenu(row.menuId).then(response => {
        state.form = response.data;
      });
    };
    /** 提交按钮 */
    const submitForm = function () {
      const formRef = unref(form);
      formRef.commonForm.validate(valid => {
        if (valid) {
          state.saveLoading = true;
          if (state.form.menuId != undefined) {
            updateMenu(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("修改成功");
                state.open = false;
                getList();
              } else {
                ElMessage.error(response.msg);
              }
              state.saveLoading = false;
            }).catch(() => {
              state.saveLoading = false;
            });
          } else {
            addMenu(state.form).then(response => {
              if (response.code === 1000) {
                ElMessage.success("新增成功");
                state.open = false;
                //新建后重置筛选
                state.queryParams = {
                  menuName: undefined,
                  visible: "0"
                };
                getList();
              } else {
                ElMessage.error(err.message);
              }
              state.saveLoading = false;
            }).catch(err => {
              state.saveLoading = false;
            });
          }
        }
      });
    };
    /** 删除按钮操作 */
    const handleDelete = function (row) {
      ElMessageBox.close();
      ElMessageBox.confirm("是否确认删除数据项?", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delMenu(row.menuId);
      }).then(res => {
        getList();
        ElMessage.success("删除成功");
      }).catch(function () {});
    };
    return (_ctx, _cache) => {
      const _component_commonForm = _resolveComponent("commonForm");
      const _component_el_button = _resolveComponent("el-button");
      const _component_commonTable = _resolveComponent("commonTable");
      const _component_commonDialog = _resolveComponent("commonDialog");
      const _directive_hasPermi = _resolveDirective("hasPermi");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_commonForm, {
        form: state.queryParams,
        formData: state.searchFormData,
        formHandle: state.formHandle
      }, null, 8, ["form", "formData", "formHandle"])]), state.buttonsList.length ? (_openBlock(), _createElementBlock("div", _hoisted_4, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(state.buttonsList, (item, index) => {
        return _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          key: index,
          class: _normalizeClass([item.className, item.buttonIcon]),
          icon: item.buttonIcon,
          loading: item.loading,
          type: item.buttonStyle,
          onClick: $event => item.exp(item)
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(item.buttonName), 1)]),
          _: 2
        }, 1032, ["class", "icon", "loading", "type", "onClick"])), [[_directive_hasPermi, item.hasPermi ? item.hasPermi : []]]);
      }), 128))])) : _createCommentVNode("", true)]), state.showTable ? _withDirectives((_openBlock(), _createBlock(_component_commonTable, {
        key: 0,
        tableData: state.menuList,
        tableHeader: state.tableHeader,
        queryParams: state.queryParams,
        onGetList: getList,
        hasSelect: false,
        columnSetting: false,
        showPagination: false,
        rowKey: "menuId",
        treeProps: {
          children: 'children',
          hasChildren: 'hasChildren'
        },
        isLazy: true,
        load: load
      }, null, 8, ["tableData", "tableHeader", "queryParams"])), [[_directive_loading, state.loading]]) : _createCommentVNode("", true), _createVNode(_component_commonDialog, {
        modelValue: state.open,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => state.open = $event),
        width: "700px",
        title: state.title,
        "close-on-click-modal": false,
        onClose: cancel
      }, {
        footer: _withCtx(() => [_createElementVNode("div", _hoisted_5, [_createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("确 定")]),
          _: 1
        }), _createVNode(_component_el_button, {
          onClick: cancel
        }, {
          default: _withCtx(() => [_createTextVNode("取 消")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_commonForm, {
          ref_key: "form",
          ref: form,
          form: state.form,
          formRules: state.rules,
          formData: state.formData,
          isHandle: false
        }, null, 8, ["form", "formRules", "formData"])]),
        _: 1
      }, 8, ["modelValue", "title"])]);
    };
  }
};