import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d64a74d2"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "message-container flex-box flex_d-column height-300"
};
const _hoisted_2 = {
  class: "message-box"
};
const _hoisted_3 = {
  class: "message-content flex-item_f-1 margin-n-5"
};
const _hoisted_4 = {
  class: "message-time margin_b-3 font-size-12"
};
const _hoisted_5 = {
  class: "margin_l-10"
};
const _hoisted_6 = {
  class: "ellipse font-size-14"
};
const _hoisted_7 = {
  class: "flex-box flex_j_c-space-between padding-5"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Iconfont = _resolveComponent("Iconfont");
  const _component_el_badge = _resolveComponent("el-badge");
  const _component_el_tag = _resolveComponent("el-tag");
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_scrollbar = _resolveComponent("el-scrollbar");
  const _component_el_empty = _resolveComponent("el-empty");
  const _component_el_popover = _resolveComponent("el-popover");
  const _directive_repeat = _resolveDirective("repeat");
  const _directive_permission = _resolveDirective("permission");
  return _openBlock(), _createBlock(_component_el_popover, {
    width: 350,
    trigger: "click",
    "popper-class": "message-popover"
  }, {
    reference: _withCtx(() => [_createVNode(_component_el_badge, {
      value: _ctx.total,
      max: 20,
      class: "margin_r-25 cursor-pointer",
      hidden: _ctx.total === 0
    }, {
      default: _withCtx(() => [_createVNode(_component_Iconfont, {
        size: "16px",
        name: "bell"
      })]),
      _: 1
    }, 8, ["value", "hidden"])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_ctx.total ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createVNode(_component_el_scrollbar, {
      class: "flex-item_f-1"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "message-item flex-box flex_j_c-space-between flex_a_i-center",
          key: item.id
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_el_tag, {
          size: "small"
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.dictionaryMap[item.type]), 1)]),
          _: 2
        }, 1024), _createElementVNode("span", _hoisted_5, _toDisplayString(item.created_at), 1)]), _createElementVNode("div", _hoisted_6, _toDisplayString(item.content), 1)]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
          type: "primary",
          size: "small",
          link: "",
          onClick: $event => _ctx.readHandle(item)
        }, {
          default: _withCtx(() => [_createTextVNode("已读")]),
          _: 2
        }, 1032, ["onClick"])), [[_directive_repeat], [_directive_permission, 'websocketAdministrator:read']])]);
      }), 128))])]),
      _: 1
    }), _createElementVNode("div", _hoisted_7, [_createVNode(_component_el_button, {
      type: "primary",
      size: "small",
      link: "",
      onClick: _ctx.viewHandle
    }, {
      default: _withCtx(() => [_createTextVNode("查看所有")]),
      _: 1
    }, 8, ["onClick"]), _withDirectives((_openBlock(), _createBlock(_component_el_button, {
      type: "primary",
      size: "small",
      link: "",
      onClick: _ctx.readAllHandle
    }, {
      default: _withCtx(() => [_createTextVNode("全部已读")]),
      _: 1
    }, 8, ["onClick"])), [[_directive_repeat], [_directive_permission, 'websocketAdministrator:allRead']])])], 64)) : (_openBlock(), _createBlock(_component_el_empty, {
      key: 1,
      description: "暂无数据"
    }))])]),
    _: 1
  });
}