import { modelList, itemList, modelAdd, modelUpdate, modelDelete, modelDetails, fileUpload, modelTrain } from "@/api/AI/index";
import { formatFileSize, getFileExtension, getFileName } from "@/utils/utils";
import { datasetList } from "@/api/AI/index";
import axios from "axios";
import { getDate } from "@/utils/formatDate.js";
import { getToken } from "@/utils/auth";
import { setELUpload } from "@/utils/setHeader.js";
export default {
  name: "",
  data() {
    return {
      //训练弹出层
      trainDialogVisible: false,
      trainTitle: "",
      //选择的训练集id数组
      trainArr: [],
      //查询的训练集数据
      trainingSetData: [],
      trainQuery: {
        page_num: 1,
        page_size: 5,
        type: "train"
      },
      trainTotal: 0,
      //选择的校验集id数组
      validationArr: [],
      //查询的校验集数据
      validationSetData: [],
      validationQuery: {
        page_num: 1,
        page_size: 5,
        type: "validation"
      },
      validationTotal: 0,
      information: {
        epochs: 1,
        minioUrl: "",
        // trainImageSet:'',//训练集
        // valImageSet:'',//校验集
        parentModelId: ""
      },
      fileList: [],
      file: null,
      //上传文件数量限制
      limitNum: 1,
      value: "",
      // 是否显示弹出层
      dialogVisible: false,
      //新增模型下拉列表
      options: [],
      cloneForm: {},
      //表单参数
      form: {
        id: "",
        //模型id
        name: "",
        //模型名称
        problemId: "",
        //算法id
        description: "",
        //形容
        createBy: "",
        file: "",
        modelFile: null,
        outputFile: null
      },
      totalElement: "",
      title: "",
      // 查询参数
      query: {
        name: "",
        keyword: ""
      },
      rules: {
        name: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }],
        problemId: [{
          required: true,
          message: "不能为空",
          trigger: "blur"
        }],
        description: [{
          required: false,
          message: "不能为空",
          trigger: "blur"
        }],
        modelFile: [{
          required: true,
          message: "请上传模型文件",
          trigger: "blur"
        }]
      },
      loading: true,
      list: [],
      total: 0,
      page: 1,
      pageSize: 10,
      remnant: 0,
      //备注最大输入字符
      saveLoading: false,
      cloneForm: {},
      isEdit: false,
      isCreateBy: true
    };
  },
  filters: {
    FormatDay: function (val) {
      return getDate(val, "year");
    }
  },
  created() {
    this.cloneForm = {
      ...this.form
    };
    this.getStaticSelectData();
    this.getList();
    this.getTrainingSetData(); //训练集查询
    this.getValidationSetData(); //校验集查询
  },
  methods: {
    //关闭弹框的事件
    closeDialog() {
      this.trainQuery.page_num = 1;
      this.validationQuery.page_num = 1;
    },
    // 指定一个key标识这一行的数据
    getRowKey(row) {
      return row.id;
    },
    //开始训练
    startTraining() {
      if (this.trainArr.length == 1 && this.validationArr.length == 1) {
        let trainIds = this.trainArr.concat(this.validationArr);
        this.information.minioUrl = trainIds.toString();
        console.log("@@@@@@@@@", this.information.minioUrl);
        console.log("@@@@@@@@@", this.information);
        modelTrain(this.information).then(response => {
          if (response.data.err.code == "0") {
            this.msgSuccess("训练中！");
          } else {
            this.msgError("训练失败！");
          }
        }).catch(response => {});
        this.trainDialogVisible = false;
      } else {
        this.msgWarning("有且只能有一个训练集和校验集！");
      }
    },
    //获取标签名
    fileData(row) {
      let arr = [];
      row.labels.forEach((item, index) => {
        if (index > 2) {
          return;
        }
        arr.push(item.name);
      });
      return arr.join(",");
    },
    //查询训练集数据
    getTrainingSetData() {
      this.loading = true;
      datasetList(this.trainQuery).then(response => {
        this.trainingSetData = response.data.data;
        this.trainTotal = response.data.count;
        this.loading = false;
      });
    },
    //查询校验集数据
    getValidationSetData() {
      this.loading = true;
      datasetList(this.validationQuery).then(response => {
        this.validationSetData = response.data.data;
        this.validationTotal = response.data.count;
        this.loading = false;
      });
    },
    //获取选择的训练集id
    trainHandleSelectionChange(row) {
      let arr1 = [];
      row.forEach((item, index) => {
        arr1.push(item.id);
      });
      this.trainArr = arr1;
      console.log("!!!!!", arr1);
    },
    //获取选择的校验集id
    validationhandleSelectionChange(row) {
      let arr2 = [];
      row.forEach((item, index) => {
        arr2.push(item.id);
      });
      this.validationArr = arr2;
      console.log("!!!!!", arr2);
    },
    //文件移除时的钩子
    handleRemove(file) {
      // console.log(file);
      //bug 7850 N-功能测试-模型管理-修改页面，删除模型文件失败 mosen 2021/8/19
      this.form.outputFile = null;
      // this.fileList.length=0;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.msgWarning("请选取要上传的文件");
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      console.log("change");
      this.file = file.raw;
      this.form.modelFile = file.name;
      //选取的文件格式和大小校验
      let error = false;
      const self = this;
      const extension = getFileExtension(file) === "zip";
      if (!extension) {
        error = true;
        self.$notify.error("上传文件必须是zip格式!");
        return false;
      }
      if (file.size > 1024 * 1024 * 512) {
        error = true;
        this.msgWarning("上传文件最大不能超过512MB，当前文件大小：" + formatFileSize(file.size));
        this.fileList = [];
        this.file = null;
        return false;
      }
      if (error) {
        self.form.modelFile = null;
      } else {
        self.form.modelFile = file.name;
      }
      return false;
    },
    submitUpload() {},
    //详情页面跳转
    toDetails(val) {
      this.$router.push({
        path: "/aIplatform/modelDetails",
        query: {
          data: val,
          type: "modelDetails"
        }
      });
    },
    //下拉列表
    getStaticSelectData() {
      itemList().then(response => {
        this.options = response.data;
      });
    },
    /** 查询模型列表 */
    getList() {
      this.loading = true;
      let params1 = {
        ...this.query
      };
      params1.name ? params1.name : delete params1.name;
      params1.keyword ? params1.keyword : delete params1.keyword;
      let params = {
        query: params1
      };
      params.page = this.page - 1;
      params.pageSize = this.pageSize;
      modelList(params).then(response => {
        this.list = response.data.result;
        this.total = response.data.totalElement;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.page = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.query.name = "";
      this.query.keyword = "";
      this.handleQuery();
    },
    //描述
    descInput() {
      let txtVal = this.form.description.length;
      this.remnant = txtVal;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.isEdit = false;
      this.isCreateBy = true;
      this.form.modelFile = null;
      this.fileList = [];
      this.form = {
        ...this.cloneForm
      };
      this.dialogVisible = true;
      this.title = "新增模型";
    },
    /** 编辑按钮操作 */
    handleUpdate(row) {
      this.isEdit = true;
      this.isCreateBy = false;
      this.fileList = [];
      modelDetails(row.id).then(response => {
        this.form = response.data;
        this.form.outputFile = response.data.outputFile;
        // this.$set(this.form,'createBy',row.createBy)
        const {
          modelFileName
        } = response.data;
        this.$set(this.form, "modelFile", modelFileName);
        if (modelFileName) {
          this.fileList = [{
            name: modelFileName
          }];
        } else {
          this.fileList.length = 0;
        }
      });
      this.dialogVisible = true;
      this.title = "修改模型";
    },
    handleTrain(row) {
      this.getTrainingSetData(); //训练集查询
      this.getValidationSetData(); //校验集查询
      this.information.parentModelId = row.id;
      this.trainTitle = row.name;
      this.trainArr.length = 0;
      this.validationArr.length = 0;
      this.trainDialogVisible = true;
    },
    //提交
    submitForm: function () {
      this.$refs["form"].validate(valid => {
        if (valid) {
          if (this.isEdit) {
            let fileFormData = new FormData();
            console.log(!this.form.outputFile, "!this.form.outputFile");
            console.log(!this.file, "!this.file");
            console.log(!this.form.outputFile && !this.file);
            if (!this.form.outputFile && !this.file) {
              this.msgWarning("请选取要上传的文件");
              return;
            }
            if (this.file) {
              let filename = this.file.name;
              fileFormData.append("modelFile", this.file, filename);
            }
            fileFormData.append("id", this.form.id);
            fileFormData.append("name", this.form.name);
            fileFormData.append("description", this.form.description);
            fileFormData.append("problemId", this.form.problemId);
            fileFormData.append("outputFile", this.form.outputFile);
            const temp = setELUpload(fileFormData);
            let token = "";
            if (getToken()) {
              // 判定是否有token
              token = "Bearer " + getToken();
            }
            let config = {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: token,
                signature: temp.signature,
                timestamp: temp.timestamp,
                nonce: temp.nonce
              }
            };
            axios.post(process.env.VUE_APP_BASE_API_AI + "/trainManage/model/update", fileFormData, config).then(response => {
              if (response.data.code == "1000") {
                this.msgSuccess("修改成功");
                this.getList();
                this.file = null;
                this.form.modelFile = response.data.data;
                this.dialogVisible = false;
              } else {
                this.msgError(response.data.msg);
              }
              this.saveLoading = false;
            });
          } else {
            if (this.file != null && this.form.modelFile != "") {
              let fileFormData = new FormData();
              let filename = this.file.name;
              fileFormData.append("modelFile", this.file, filename);
              fileFormData.append("name", this.form.name);
              fileFormData.append("description", this.form.description);
              fileFormData.append("problemId", this.form.problemId);
              fileFormData.append("createBy", this.form.createBy);
              const temp = setELUpload(fileFormData);
              let token = "";
              if (getToken()) {
                // 判定是否有token
                token = "Bearer " + getToken();
              }
              let config = {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: token,
                  signature: temp.signature,
                  timestamp: temp.timestamp,
                  nonce: temp.nonce
                }
              };
              axios.post(process.env.VUE_APP_BASE_API_AI + "/trainManage/model/add", fileFormData, config).then(response => {
                if (response.data.code == "1000") {
                  this.msgSuccess("新增成功");
                  //新增后重置
                  this.resetQuery();
                  this.form.modelFile = response.data.data;
                  this.dialogVisible = false;
                } else {
                  this.msgError(response.data.msg);
                }
                this.saveLoading = false;
              });
            } else {
              this.msgWarning("请选取要上传的文件");
            }
          }
        }
      });
    }
  }
};