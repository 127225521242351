import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4a6135fa"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "count"
};
const _hoisted_2 = {
  class: "analysis_container shadowOne"
};
const _hoisted_3 = {
  class: "count-item"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  class: "text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.iconArrayList, (item, index) => {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['analysis_item', $props.iconArrayList.length < 7 ? 'lessthan7' : 'morethanOrEqual7']),
      key: index,
      style: _normalizeStyle({
        'background': _ctx.$store.state.user.themeMode == 'dark' ? $data.itemBackgroundColor[index % 7] : 'linear-gradient(45deg, ' + $data.lightLinearBgStart[index % 7] + ' 0%, ' + $data.lightLinearBgEnd[index % 7] + ' 100%)'
      })
    }, [_createElementVNode("i", {
      class: _normalizeClass(["iconfont", [item.iconName]]),
      style: _normalizeStyle({
        'background': _ctx.$store.state.user.themeMode == 'dark' ? $data.backgroundColor[index % 7] : 'rgba(255,255,255,0.5)',
        'color': _ctx.$store.state.user.themeMode == 'dark' ? '#fff' : $data.fontColors[index % 7]
      })
    }, null, 6), _createElementVNode("div", _hoisted_3, [_createElementVNode("span", _hoisted_4, _toDisplayString(item.title), 1), _createElementVNode("span", _hoisted_5, _toDisplayString(item.total), 1)])], 6);
  }), 128))])], 512);
}