import { datasetList, datasetAdd, datasetDelete, datasetsUpload } from "@/api/AI/index";
import AddDataSet from './components/addDataSet.vue';
import axios from 'axios';
import JsZip from 'jszip';
import { setELUpload } from '@/utils/setHeader.js';
import { getToken } from "@/utils/auth";
export default {
  components: {
    AddDataSet
  },
  data() {
    return {
      unshelveLoading: false,
      fileList: [],
      file: null,
      datasetsFile: null,
      limitNum: 1,
      //编辑id
      editId: '',
      // 是否显示弹出层
      dialogVisible: false,
      uploadDialogVisible: false,
      cloneForm: {},
      isEdit: false,
      //表单参数
      form: {
        name: '',
        type: ''
      },
      query: {
        //  page_num:1,
        //  page_size:10,
        type: ''
        //  search:"",
        //  order:"desc",
      },
      total: 0,
      title: '',
      loading: true,
      list: [],
      saveLoading: false,
      isEdit: false
    };
  },
  created() {
    this.cloneForm = {
      ...this.form
    };
    this.getList();
  },
  methods: {
    resetQuery() {
      this.query.search = "", this.query.type = "", this.getList();
    },
    //上传数据集
    datasetsUpload() {
      this.uploadDialogVisible = true;
    },
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false 或者返回 Promise 且被 reject，则停止上传
    beforeUploadFile(file) {
      let error = false;
      const self = this;
      const extension = getFileExtension(file) === 'zip';
      if (!extension) {
        error = true;
        self.$notify.error('上传文件必须是zip格式!');
        return false;
      }
      if (file.size > 1024 * 1024 * 512) {
        error = true;
        self.$notify.error('上传文件最大不能超过512MB，当前文件大小：' + formatFileSize(file.size));
        return false;
      }
      if (error) {
        self.datasetsFile = '';
      } else {
        self.datasetsFile = file.name;
      }
      return false;
    },
    // 文件超出个数限制时的钩子
    exceedFile(files, fileList) {
      this.msgWarning(`只能选择 ${this.limitNum} 个文件，当前共选择了 ${files.length + fileList.length} 个`);
    },
    // 文件状态改变时的钩子
    fileChange(file, fileList) {
      console.log('change');
      this.file = file.raw;
      this.datasetsFile = file.name;
      //bug 8505 N-功能测试-上传错误的数据集，未提示错误 mosen 2021/9/30
      //判断上传的数据集压缩文件是否含有json文件
      var _this = this;
      var _obj = Object;
      var new_zip = new JsZip();
      // console.log(new_zip.loadAsync(file.raw))
      new_zip.loadAsync(file.raw).then(function (file) {
        //----file压缩包里的内容----//
        var _array = _obj.values(file.files);
        console.log(_array);
        //是否存在json文件
        var hasObjFile = false;
        for (var i = 0; i <= _array.length - 1; i++) {
          var name = _array[i].name;
          var index = name.lastIndexOf(".");
          var ext = name.substr(index + 1);
          if (ext === 'json') {
            hasObjFile = true;
          }
        }
        if (!hasObjFile) {
          _this.msgWarning('请上传正确内部格式的压缩包！');
          _this.fileList = [];
        }
      }).catch(err => {
        this.msgWarning("请上传正确格式的文件！");
      });
    },
    // 文件上传成功时的钩子
    handleSuccess(res, file, fileList) {
      // this.form.file = res.data;

      this.$notify.success({
        title: '成功',
        message: `文件上传成功`
      });
    },
    // 文件上传失败时的钩子
    handleError(err, file, fileList) {
      this.$notify.error({
        title: '错误',
        message: `文件上传失败`
      });
    },
    submitUpload() {
      if (this.file != null) {
        let fileFormData = new FormData();
        let filename = this.file.name;
        fileFormData.append('file', this.file, filename);
        const temp = setELUpload(fileFormData);
        let token = '';
        if (getToken()) {
          // 判定是否有token
          token = "Bearer " + getToken();
        }
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token,
            'signature': temp.signature,
            'timestamp': temp.timestamp,
            'nonce': temp.nonce
          }
        };
        this.unshelveLoading = true;
        axios.post(process.env.VUE_APP_BASE_API_AI + '/dataset/datasets/upload/', fileFormData, config).then(response => {
          console.log('@@', response);
          if (response.data.message === '上传数据集成功') {
            this.msgSuccess(response.data.message);
            //新增后重置
            this.query.search = '';
            this.query.type = '';
            this.getList();
            this.file = null;
            this.fileList = [];
            this.datasetsFile = "";
            this.uploadDialogVisible = false;
            this.unshelveLoading = false;
          } else {
            this.msgError("上传失败");
          }
        });
      } else {
        this.msgWarning("请选取要上传的文件");
      }
    },
    dataDetails(scope) {
      this.$router.push({
        path: '/aIplatform/datasetView',
        query: {
          id: scope.row.id
        }
      });
    },
    //删除数据集
    datasetDelete(id) {
      this.$confirm("确定删除该条数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        datasetDelete(id).then(response => {
          this.$message.success('删除成功');
          this.getList();
        });
      });
    },
    /** 查询模型列表 */
    getList() {
      this.loading = true;
      let params1 = {
        ...this.query
      };
      params1.search ? params1.search : delete params1.search;
      params1.type ? params1.type : delete params1.type;
      datasetList(params1).then(response => {
        console.log(response, 3333333333333);
        this.list = response.data.data;
        this.total = response.data.count;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 新增按钮操作 */
    // handleAdd () {
    //   this.editId = ''
    //   this.isEdit = false;
    //   this.form = { ...this.cloneForm }
    //   this.dialogVisible = true;
    // },
    //编辑
    handleUpdate(row) {
      this.editId = row.id;
      this.dialogVisible = true;
    },
    //取消
    cancel() {
      this.uploadDialogVisible = false;
      this.fileList = [];
      this.file = null;
      this.unshelveLoading = false;
    }
  }
};